// Customizable Area Start
import React from "react";
import CommonReservationInfo from "../../../components/src/CommonReservationInfo.web"
import {
  styled,
  Box,
  Typography,
  Grid,
  OutlinedInput,
  Button
} from "@material-ui/core"
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { editFileIcon } from "./assets";


import InstuctionPageController, {
    Props,
  } from "./InstuctionPageController";

class InstuctionPage extends InstuctionPageController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  renderCheckInCard = () => {
    return (
      this.state.editItems.checkIn ?
        <>
          {this.state.checkInArray.map((checkIn) =>
            <Box className="mainBox">
              <Box className="grayBox">
                <Box className="headerBox">
                  <Typography className="cardText">{checkIn.name}</Typography>
                </Box>
              </Box>

              <Box className="whiteBox" style={{ background: "none"}}>

                {checkIn.sections_attributes.map((itemOne, index) =>
                  <Box className="inputBox" key={`attributes-${itemOne}`}>
                    <Typography className="cardText">{itemOne.title}</Typography>
                    <OutlinedInput
                      fullWidth
                      className="textField"
                      data-test-id={"handleCheckInput"}
                      value={itemOne.description}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.handleCheckInInputChange(event, index)}
                    />
                  </Box>
                )}

                <Box style={{ display: "flex", justifyContent: "space-between" }}>
                  <Box></Box>
                  <Button className="applyBtn" onClick={this.applyCheckInSubInstructions} data-test-id="checkInApplyBtn">APPLY</Button>
                </Box>
              </Box>

            </Box>

          )}
          
        </>
        :
        <>
          <Box className="mainBox">
            <Box className="grayBox">
              <Box className="headerBox">
                <Typography className="cardText">{this.cardHeaderText.checkInText}</Typography>
                <img src={editFileIcon} data-test-id="checkInEdit" onClick={() => this.handleEdit("checkIn")}  style={{ cursor: "pointer"}}/>
              </Box>
            </Box>

            <Box className="whiteBox">
              {this.state.checkInInfo.map(checkIn =>
                <Box className="flexBox">
                  <CheckCircleIcon className="listIcon" />
                  <Typography className="cardText">{checkIn.cardTitle}</Typography>
                </Box>
              )}
              
            </Box>

          </Box>
        </>
        

    )
  }

  renderLocationCard = () => {
    return (
      this.state.editItems.location ?
        <>
          {this.state.locationArray.map((location) =>
            <Box className="mainBox">
              <Box className="grayBox">
                <Box className="headerBox">
                  <Typography className="cardText">{location.name}</Typography>
                </Box>
              </Box>

              <Box className="whiteBox">

                {location.sections_attributes.map((itemTwo, index) =>
                  <Box className="inputBox" key={`attributes-${itemTwo}`}>
                    <Typography className="cardText">{itemTwo.title}</Typography>
                    <OutlinedInput
                      className="textField"
                      data-test-id={"locationChange"}
                      fullWidth
                      value={itemTwo.description}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.handleLocationInputChange(event, index)}
                    />
                  </Box>
                )}

                <Box style={{ display: "flex", justifyContent: "space-between" }}>
                  <Box></Box>
                  <Button className="applyBtn" onClick={this.applyLocationubInstructions} data-test-id="locationApplyBtn">APPLY</Button>
                </Box>
              </Box>

            </Box>

          )}
          
        </>
        :
        <>
          <Box className="mainBox">
            <Box className="grayBox">
              <Box className="headerBox">
                <Typography className="cardText">{this.cardHeaderText.locationText}</Typography>
                <img src={editFileIcon} data-test-id="locationEdit" onClick={() => this.handleEdit("location")}  style={{ cursor: "pointer"}}/>
              </Box>
            </Box>

            <Box className="whiteBox">
              {this.state.locationInfo.map(item =>
                <Box className="flexBox">
                  <CheckCircleIcon className="listIcon" />
                  <Typography className="cardText">{item.cardTitle}</Typography>
                </Box>
              )}
              
            </Box>

          </Box>
        </>
        

    )
  }

  renderAccessMethodCard = () => {
    return (
      this.state.editItems.accessMethod ?
        <>
          {this.state.accessMethodArray.map((accessMethod) =>
            <Box className="mainBox">
              <Box className="grayBox">
                <Box className="headerBox">
                  <Typography className="cardText">{accessMethod.name}</Typography>
                </Box>
              </Box>

              <Box className="whiteBox">

                {accessMethod.sections_attributes.map((itemThree, index) =>
                  <Box className="inputBox" key={`attributes-${itemThree}`}>
                    <Typography className="cardText">{itemThree.title}</Typography>
                    <OutlinedInput
                      className="textField"
                      data-test-id={"accMethodChange"}
                      fullWidth
                      value={itemThree.description}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.handleAccMethodInputChange(event, index)}
                    />
                  </Box>
                )}

                <Box style={{ display: "flex", justifyContent: "space-between" }}>
                  <Box></Box>
                  <Button className="applyBtn" onClick={this.applyAccMethodSubInstructions} data-test-id="accMethodApplyBtn">APPLY</Button>
                </Box>
              </Box>

            </Box>

          )}
          
        </>
        :
        <>
          <Box className="mainBox">
            <Box className="grayBox">
              <Box className="headerBox">
                <Typography className="cardText">{this.cardHeaderText.accessMethodText}</Typography>
                <img src={editFileIcon} data-test-id="accMethodEdit" onClick={() => this.handleEdit("accessMethod")}  style={{ cursor: "pointer"}}/>
              </Box>
            </Box>

            <Box className="whiteBox">
              {this.state.accessMethodInfo.map(accMethod =>
                <Box className="flexBox">
                  <CheckCircleIcon className="listIcon" />
                  <Typography className="cardText">{accMethod.cardTitle}</Typography>
                </Box>
              )}
              
            </Box>

          </Box>
        </>
        

    )
  }

  renderParkingCard = () => {
    return (
      this.state.editItems.parking ?
        <>
          {this.state.parkingArray.map((parking) =>
            <Box className="mainBox">
              <Box className="grayBox">
                <Box className="headerBox">
                  <Typography className="cardText">{parking.name}</Typography>
                </Box>
              </Box>

              <Box className="whiteBox">

                {parking.sections_attributes.map((itemFour, index) =>
                  <Box className="inputBox" key={`attributes-${itemFour}`}>
                    <Typography className="cardText">{itemFour.title}</Typography>
                    <OutlinedInput
                      className="textField"
                      data-test-id={"parkingChange"}
                      fullWidth
                      value={itemFour.description}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.handleParkingInputChange(event, index)}
                    />
                  </Box>
                )}

                <Box style={{ display: "flex", justifyContent: "space-between" }}>
                  <Box></Box>
                  <Button className="applyBtn" onClick={this.applyParkingSubInstructions} data-test-id="parkingApplyBtn">APPLY</Button>
                </Box>
              </Box>

            </Box>

          )}
          
        </>
        :
        <>
          <Box className="mainBox">
            <Box className="grayBox">
              <Box className="headerBox">
                <Typography className="cardText">{this.cardHeaderText.parkingText}</Typography>
                <img src={editFileIcon} data-test-id="parkingEdit" onClick={() => this.handleEdit("parking")}  style={{ cursor: "pointer"}}/>
              </Box>
            </Box>

            <Box className="whiteBox">
              {this.state.parkingInfo.map(parkingAttributes =>
                <Box className="flexBox">
                  <CheckCircleIcon className="listIcon" />
                  <Typography className="cardText">{parkingAttributes.cardTitle}</Typography>
                </Box>
              )}
              
            </Box>

          </Box>
        </>
        

    )
  }

  renderWifiCard = () => {
    return (
      this.state.editItems.wifi ?
        <>
          {this.state.wifiArray.map((wifi) =>
            <Box className="mainBox">
              <Box className="grayBox">
                <Box className="headerBox">
                  <Typography className="cardText">{wifi.name}</Typography>
                </Box>
              </Box>

              <Box className="whiteBox">

                {wifi.sections_attributes.map((itemFive, index) =>
                  <Box className="inputBox" key={`attributes-${itemFive}`}>
                    <Typography className="cardText">{itemFive.title}</Typography>
                    <OutlinedInput
                      className="textField"
                      data-test-id={"wifiChange"}
                      fullWidth
                      value={itemFive.description}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.handleWifiInputChange(event, index)}
                    />
                  </Box>
                )}

                <Box style={{ display: "flex", justifyContent: "space-between" }}>
                  <Box></Box>
                  <Button className="applyBtn" onClick={this.applyWifiSubInstructions} data-test-id="wifiApplyBtn">APPLY</Button>
                </Box>
              </Box>

            </Box>

          )}
          
        </>
        :
        <>
          <Box className="mainBox">
            <Box className="grayBox">
              <Box className="headerBox">
                <Typography className="cardText">{this.cardHeaderText.wifiText}</Typography>
                <img src={editFileIcon} data-test-id="wifiEdit" onClick={() => this.handleEdit("wifi")}  style={{ cursor: "pointer"}}/>
              </Box>
            </Box>

            <Box className="whiteBox">
              {this.state.wifiInfo.map(wifiAttributes =>
                <Box className="flexBox">
                  <CheckCircleIcon className="listIcon" />
                  <Typography className="cardText">{wifiAttributes.cardTitle}</Typography>
                </Box>
              )}
              
            </Box>

          </Box>
        </>
        

    )
  }

  renderContactCard = () => {
    return (
      this.state.editItems.contacts ?
        <>
          {this.state.contactsArray.map((contacts) =>
            <Box className="mainBox">
              <Box className="grayBox">
                <Box className="headerBox">
                  <Typography className="cardText">{contacts.name}</Typography>
                </Box>
              </Box>

              <Box className="whiteBox">

                {contacts.sections_attributes.map((itemSix, index) =>
                  <Box className="inputBox" key={`attributes-${itemSix}`}>
                    <Typography className="cardText">{itemSix.title}</Typography>
                    <OutlinedInput
                      className="textField"
                      data-test-id={"contactsChange"}
                      fullWidth
                      value={itemSix.description}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.handleContatcsInputChange(event, index)}
                    />
                  </Box>
                )}

                <Box style={{ display: "flex", justifyContent: "space-between" }}>
                  <Box></Box>
                  <Button className="applyBtn" onClick={this.applyContatcsSubInstructions} data-test-id="contactsApplyBtn">APPLY</Button>
                </Box>
              </Box>

            </Box>

          )}
          
        </>
        :
        <>
          <Box className="mainBox">
            <Box className="grayBox">
              <Box className="headerBox">
                <Typography className="cardText">{this.cardHeaderText.contactsText}</Typography>
                <img src={editFileIcon} data-test-id="contactsEdits" onClick={() => this.handleEdit("contacts")}  style={{ cursor: "pointer"}}/>
              </Box>
            </Box>

            <Box className="whiteBox">
              {this.state.contactsInfo.map(contactAttr =>
                <Box className="flexBox">
                  <CheckCircleIcon className="listIcon" />
                  <Typography className="cardText">{contactAttr.cardTitle}</Typography>
                </Box>
              )}
              
            </Box>

          </Box>
        </>
        

    )
  }

  renderRecommandationCard = () => {
    return (
      this.state.editItems.localRecomanded ?
        <>
          {this.state.recommandedArray.map((recommadedArr) =>
            <Box className="mainBox">
              <Box className="grayBox">
                <Box className="headerBox">
                  <Typography className="cardText">{recommadedArr.name}</Typography>
                </Box>
              </Box>

              <Box className="whiteBox">

                {recommadedArr.sections_attributes.map((itemSeven, index) =>
                  <Box className="inputBox" key={`attributes-${itemSeven}`}>
                    <Typography className="cardText">{itemSeven.title}</Typography>
                    <OutlinedInput
                      className="textField"
                      data-test-id={"recommandedChange"}
                      fullWidth
                      value={itemSeven.description}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.handleRecomandedInputChange(event, index)}
                    />
                  </Box>
                )}

                <Box style={{ display: "flex", justifyContent: "space-between" }}>
                  <Box></Box>
                  <Button className="applyBtn" onClick={this.applyRecomandedSubInstructions} data-test-id="rmdApplyBtn">APPLY</Button>
                </Box>
              </Box>

            </Box>

          )}
          
        </>
        :
        <>
          <Box className="mainBox">
            <Box className="grayBox">
              <Box className="headerBox">
                <Typography className="cardText">{this.cardHeaderText.recommandedText}</Typography>
                <img src={editFileIcon} data-test-id="rmdEdit" onClick={() => this.handleEdit("localRecomanded")}  style={{ cursor: "pointer"}}/>
              </Box>
            </Box>

            <Box className="whiteBox">
              {this.state.recommandedInfo.map(recomanded =>
                <Box className="flexBox">
                  <CheckCircleIcon className="listIcon" />
                  <Typography className="cardText">{recomanded.cardTitle}</Typography>
                </Box>
              )}
              
            </Box>

          </Box>
        </>
        

    )
  }

  render() {
    // Customizable Area Start
    
    return (
        <div>
        <CommonReservationInfo
          navigation={this.props.navigation}
          id="InstructionPage"
        >
            <InstructionWrapper>
              <Box>
                <Typography className="titleText">Check-In Instructions</Typography>
                <Typography className="subTitleText">Welcome, please fill in the input fields to allow your</Typography>
                <Typography className="subTitleText">clients have a seamless experience.</Typography>

                <Box style={{ marginTop: "25px" }}>
                  <Grid container spacing={3}>
                    <Grid item md={6}>
                      {this.renderCheckInCard()}
                    </Grid>
                    <Grid item md={6}>
                      {this.renderLocationCard()}
                    </Grid>
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid item md={6}>
                      {this.renderAccessMethodCard()}
                    </Grid>
                    <Grid item md={6}>
                      {this.renderParkingCard()}
                    </Grid>
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid item md={6}>
                      {this.renderWifiCard()}
                    </Grid>
                    <Grid item md={6}>
                      {this.renderContactCard()}
                    </Grid>
                  </Grid>
                  <Grid container spacing={3}>
                    <Grid item md={6}>
                      {this.renderRecommandationCard()}
                    </Grid>
                  </Grid>
                </Box>

              </Box>

            </InstructionWrapper>
        </CommonReservationInfo>

    </div>
    );
    // Customizable Area End
  }
}

export default InstuctionPage;

const InstructionWrapper = styled(Box)({
    "& .titleText": {
        fontSize: '18px',
        fontFamily: 'Plus Jakarta Sans',
        fontWeight: 600,
        lineHeight: '30px',
        color: '#23395D'
      },
      "& .subTitleText": {
        fontFamily: 'Plus Jakarta Sans',
        fontWeight: 400,
        fontSize: '16px',
        color: '#475467'
      },
      "& .mainBox" : {
        border: "1px solid #E7E7E7",
        borderRadius: "12px",
      },
      "& .grayBox" : {
        background : "#F4F7FC",
        borderBottom: "1px solid #E7E7E7",
        borderTopLeftRadius: "12px",
        borderTopRightRadius: "12px"
      },
      "& .headerBox" : {
        padding: "15px",
        display: "flex",
        justifyContent: "space-between",
      }, 
      "& .flexBox" : {
        display: "flex",
        gap : "10px",
        margin: "10px 0"
      },
      "& .whiteBox" : {
        padding: "15px",  
      },
      "& .cardText" : {
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '14px',
        fontWeight: 600,
        lineHeight: '18px',
        color: '#23395D'
      },
      "& .listIcon" : {
        color: "#3173E1", 
        height: 20, 
        width: 20
      },
      "& .textField" : {
        padding: "10px 16px",
        fontWeight: 500,
        color: "#94A3B8",
        fontSize: "14px",
        height: 35,
        fontFamily: 'Plus Jakarta Sans',
        borderRadius: "10px",
        marginBottom: "10px"
      },
      "& .textField .MuiOutlinedInput-input" : {
        padding : 0,
      },
      "& .applyBtn" : {
        fontFamily: 'Plus Jakarta Sans',
        height: 45,
        width: 140,
        textTransform: "none",
        fontWeight: 600,
        color: 'white',
        fontSize: '14px',
        background: "#3173E1",
        borderRadius: "12px",
      },
  })

// Customizable Area End