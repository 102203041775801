// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";

import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { apiCalling } from '../../../components/src/asset'

export const configJSON = require("./config");

export interface Props {
  // Customizable Area Start
  activeLink? : string,
  navigation?: any,
  // Customizable Area End
}

interface S {
  // Customizable Area Start  
  notificationData: any[]
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class TopNotificationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getNotificationApiCallId: string = ""
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];
    this.state = {
      notificationData: []
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if(apiRequestCallId === this.getNotificationApiCallId) {
         this.responseForNotificationApi(responseJson)
      }
      
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount(){
      this.getNotificationData()
  }

  responseForNotificationApi = (responseJson: any) => {
    if(responseJson && responseJson.data) {
       this.setState({ notificationData : responseJson.data })
    } else {
      this.setState({ notificationData : [] })
    }

  }

  getNotificationData = () => {
    const header = {
        'token': localStorage.getItem("userToken")
    }

    this.getNotificationApiCallId = apiCalling({
        header: JSON.stringify(header),
        endPoint: configJSON.getNotificationEndPoint,
        method: configJSON.apiMethodTypeGet
    })
  }


  // Customizable Area End
}

// Customizable Area End