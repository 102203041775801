// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";

import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { apiCalling, truthyValue } from "../../../components/src/asset";
import { toast } from "react-toastify"
import moment from "moment"


export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

type ReservationCard = {
    catalougeId:number;
    catalougeType: string;
    catalougeName: string;
    deliveryLocation: string;
    pickUpPoint: string;
    dropOffPoint: string;
    renterName: string;
    imgUrl : string
};

type ReservationRequestCard = {
    catalougeId:number;
    catalougeType: string;
    catalougeName: string;
    startTime: string;
    startDate: string;
    startMonthTime: string
    endDate: string;
    endMonthTime: string
    renterName: string;
    imgUrl : string
    costVal : string
    earningVal: string
    deliveryLocation? : string
    deliveryDuration?: string;
};

type RentedReservationCard = {
    catalougeId:number;
    catalougeName: string;
    catalougeType: string;
    deliveryLocation: string;
    startingInfo: string;
    endingInfo: string;
    renterName: string;
    imgUrl : string
}

interface S {
  // Customizable Area Start  
  tabVal : number,
  reservationData : ReservationCard[],
  loader : boolean,
  rentalRequestReservationData : ReservationRequestCard[],
  rentedReservationData : RentedReservationCard[]
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class HostReservationsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getRentalReqApiCallId : string = ""
  acceptRentalRequestApiCallId : string = ""
  declineRentalRequestApiCallId : string = ""
  getUpcomingReservationApiCallId : string = ""
  getRentedReservationApiCallId: string = ""
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];
    this.state = {
        tabVal: 0,
        reservationData : [],
        loader: true,
        rentalRequestReservationData : [],
        rentedReservationData: [],
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      switch(apiRequestCallId) {
        case this.getRentalReqApiCallId:
          return this.handleGetRentalReqResponse(responseJson)
        case this.getUpcomingReservationApiCallId:
          return this.handleGetUpcomingReservationResponse(responseJson)
        case this.getRentedReservationApiCallId:
          return this.handleGetRentedReservationResponse(responseJson)
        case this.acceptRentalRequestApiCallId:
          return this.handleAcceptRentalReqResponse(responseJson)
        case this.declineRentalRequestApiCallId:
          return this.handleDeclineRentalReqResponse(responseJson)
      }
      
    }
    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidMount(): Promise<void> {
     this.getRentalReqData()
  }

  handleGetRentalReqResponse = (responseJson: any) => {
    if (responseJson && responseJson.catalogue && responseJson.catalogue.data && responseJson.catalogue.data.length > 0) {
      const rentalReqArray = responseJson.catalogue.data.map((itemValue: any) => {
        return {
          catalougeId: itemValue.id,
          catalougeName: truthyValue(itemValue.attributes.catalogue_name),
          catalougeType: itemValue.attributes.catalogue_type,
          imgUrl: this.handleImageUrl(itemValue),
          renterName: truthyValue(itemValue.attributes.renter_name),
          startTime: moment(itemValue.attributes.start_time).format('dddd, h:mm A'),
          costVal: "$0",
          earningVal: "$0",
          startDate: this.handleReqDates(itemValue.attributes.start_time).formattedDate,
          startMonthTime: `${this.handleReqDates(itemValue.attributes.start_time).formattedMonth} | ${this.handleReqDates(itemValue.attributes.start_time).formattedTime}`,
          endDate: this.handleReqDates(itemValue.attributes.end_time).formattedDate,
          endMonthTime: `${this.handleReqDates(itemValue.attributes.end_time).formattedMonth} | ${this.handleReqDates(itemValue.attributes.end_time).formattedTime}`,
          deliveryLocation: this.handleAddressText(itemValue),
          deliveryDuration: this.countDurationDays(itemValue.attributes.start_time, itemValue.attributes.end_time)
        }
      });

      this.setState({
        rentalRequestReservationData: rentalReqArray,
      }, () => this.getUpcomingReservationData())
    } else {
      this.setState({
        rentalRequestReservationData: [],
      }, () => this.getUpcomingReservationData())
    }
  }

  handleGetUpcomingReservationResponse = (responseJson: any) => {

    if (responseJson && responseJson.catalogue && responseJson.catalogue.data && responseJson.catalogue.data.length > 0) {
      const upcomingDataArray = responseJson.catalogue.data.map((itemValue: any) => {
        return {
          catalougeId: itemValue.id,
          catalougeName: truthyValue(itemValue.attributes.catalogue_name),
          catalougeType: itemValue.attributes.catalogue_type,
          deliveryLocation: this.handleAddressText(itemValue),
          pickUpPoint: this.handleStartEndTime(itemValue.attributes.start_time),
          dropOffPoint: this.handleStartEndTime(itemValue.attributes.end_time),
          renterName: truthyValue(itemValue.attributes.renter_name),
          imgUrl: this.handleImageUrl(itemValue)
        }
      });

      this.setState({
        reservationData: upcomingDataArray,
      }, () => this.getRentedReservationData())
    } else {
      this.setState({
        reservationData: [],
      }, () => this.getRentedReservationData())
    }


  }

  handleGetRentedReservationResponse = (responseJson: any) => {

    if (responseJson && responseJson.catalogue && responseJson.catalogue.data && responseJson.catalogue.data.length > 0) {
      const rentedDataArray = responseJson.catalogue.data.map((itemValue: any) => {
        return {
          catalougeId: itemValue.id,
          catalougeName: truthyValue(itemValue.attributes.catalogue_name),
          catalougeType: itemValue.attributes.catalogue_type,
          deliveryLocation: this.handleAddressText(itemValue),
          startingInfo: this.handleStartEndTime(itemValue.attributes.start_time),
          endingInfo: this.handleStartEndTime(itemValue.attributes.end_time),
          renterName: truthyValue(itemValue.attributes.renter_name),
          imgUrl: this.handleImageUrl(itemValue)
        }
      });

      this.setState({
        rentedReservationData: rentedDataArray,
        loader: false
      })
    } else {
      this.setState({ loader: false, rentedReservationData: [] })
    }




  }

  handleAcceptRentalReqResponse = (responseJson : any) => {
     if(responseJson && responseJson.message) {
      this.getRentalReqData()
      toast.success("Request Accepted Successfully")
     } else {
       toast.error("Something went wrong, please try again later")
       this.setState({ loader : false})
     }
  }

  handleDeclineRentalReqResponse = (responseJson : any) => {
    if(responseJson && responseJson.message) {
      this.getRentalReqData()
      toast.success("Request Declined Successfully")
     } else {
      toast.error("Something went wrong, please try again later")
      this.setState({ loader : false})
    }
  }

  handleImageUrl = (item : any) => {

    // imageUrl

    let imgUrl : string = ""
    if(item.attributes && item.attributes.images && item.attributes.images.length > 0 && item.attributes.images[0].url ) {
      imgUrl = item.attributes.images[0].url
    }

    return imgUrl
  } 

  handleStartEndTime = (originalDate: string) => {
    return moment(originalDate).format('dddd, MMM D, h:mma')
  }

  handleAddressText = (item : any) => {

    let address : string = ""
     if(item.attributes && item.attributes.address.length > 0 && item.attributes.address[0] ) {
       const addressObj = item.attributes.address[0]
       address = addressObj.address + "," + addressObj.city + "," + addressObj.country
     }
    return address
  }

  countDurationDays = (startTime: string, endTime: string) => {
    const duration = moment.duration(moment(endTime).diff(moment(startTime)));
    return duration.asDays();
  }

  handleReqDates = (dateString :string) => {
    const formattedDate = moment(dateString).format('DD');
    const formattedMonth = moment(dateString).format('MMMM');
    const formattedTime = moment(dateString).format('hh:mm A');

    return { formattedDate, formattedMonth, formattedTime }
  }
 
  navigateToBackPage = () => {
    this.props.navigation.goBack()
  }

  handleTabChange = (event: React.ChangeEvent<{}>, value: number) => {
    this.setState({ tabVal: value})
  }

  handleCatalougeTypeCondition = (chceckKey: string) => {
    return chceckKey === "Car" ? true : false
  }

  navigateToDetailPage = (catalougeId: number) => {
    this.props.navigation.navigate("HostReservationDetail", { reservationId : catalougeId})
  }

  navigateToEndTripPage = (bookedSlotId: number) => {
    this.props.navigation.navigate("HostPostTrip", { reservationId : bookedSlotId})
  }

  getRentalReqData = () => {
    const header = {
      "token": localStorage.getItem("userToken")
    };

    const status = "pending"
    const tripStatus = "not_started"
    
    this.getRentalReqApiCallId = apiCalling({
      header: JSON.stringify(header),
      endPoint: `${configJSON.getHostReservationApiEndPoint}?status=${status}&trip_status=${tripStatus}`,
      method: configJSON.apiMethodTypeGet
    })
  }

  getUpcomingReservationData = () => {
    const header = {
      "token": localStorage.getItem("userToken")
    };

    const status = "accepted"
    const tripStatus = "not_started"
    
    this.getUpcomingReservationApiCallId = apiCalling({
      header: JSON.stringify(header),
      method: configJSON.apiMethodTypeGet,
      endPoint: `${configJSON.getHostReservationApiEndPoint}?status=${status}&trip_status=${tripStatus}`,
    })
  }

  getRentedReservationData = () => {
    const header = {
      "token": localStorage.getItem("userToken")
    };

    const status = "accepted"
    const tripStatus = "started"
    
    this.getRentedReservationApiCallId = apiCalling({
      method: configJSON.apiMethodTypeGet,
      header: JSON.stringify(header),
      endPoint: `${configJSON.getHostReservationApiEndPoint}?status=${status}&trip_status=${tripStatus}`,
    })
  }

  acceptRentalReqData = (bookedSlotId: number) => {

    this.setState({ loader : true})
    const header = {
      "token": localStorage.getItem("userToken")
    };
    
    this.acceptRentalRequestApiCallId = apiCalling({
      header: JSON.stringify(header),
      endPoint:`${configJSON.changeReservationStatusApiEndPoint}?id=${bookedSlotId}&status=accepted`,
      method: configJSON.apiMethodTypePut
    })
  }

  declinedRentalReqData = (bookedSlotId: number) => {

    this.setState({ loader : true})

    const header = {
      "token": localStorage.getItem("userToken")
    };
    
    this.declineRentalRequestApiCallId = apiCalling({
      header: JSON.stringify(header),
      endPoint: `${configJSON.changeReservationStatusApiEndPoint}?id=${bookedSlotId}&status=declined`,
      method: configJSON.apiMethodTypePut
    })
  }

  // Customizable Area End
}

// Customizable Area End