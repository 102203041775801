import { Box, styled } from "@material-ui/core"
import React, { useState } from "react";
const TabsBox = styled(Box)({
  '& .tab-box': {
    maxWidth: '200px',
    width: '100%',
    borderRadius: 30,
    background: 'white',
    display: 'flex',
    overflow: 'hidden',
    border: '1px solid #D0D5DD'
  },
  '& .first-child': {
    flex: 1,
    padding: 10,
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '20px',
    color: '#475467',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 8,
    cursor: "pointer"
  },
  '& .first-child.active': {
    backgroundColor: '#F2F4F7',
    color: '#22395D',
    cursor: "pointer"

  },
  '& .side-border': {
    borderRight: '1px solid #D0D5DD'
  },
})

export default function Tabs({ listingVarientName }: any) {
  const [activeTab, setActiveTab] = useState("Home");
  const homeListing = () => {
    let type = "Home";
    setActiveTab(type);
    listingVarientName(type);
  }
  const carListing = () => {
    let type = "Car";
    setActiveTab(type);
    listingVarientName(type);
  }
  return <TabsBox>
    <Box className='tab-box'>
      <Box className={activeTab === "Home" ? 'first-child side-border active' : 'first-child side-border'} onClick={() => { homeListing() }}>
        <img className='home-icon' src={require("./home-tab.svg")} alt="" />
        Home</Box>
      <Box className={activeTab === "Car" ? 'first-child active' : 'first-child'} onClick={() => { carListing() }}><img className='car-icon' src={require("./car-tab.svg")} alt="" />Car</Box>
    </Box>
  </TabsBox>
}