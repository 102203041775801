// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";

import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { toast } from "react-toastify"
import { apiCalling } from '../../../components/src/asset'

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start  
  registerdMobileNo: string,
  accountId : string,
  loader : boolean,
  verifyOtp : string,
  successVerified : boolean,
  displayOtp: string
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class VerifyOTPController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getloggedInUserInfoApiCallId : string = ""
  verifyOtpApiCallId: string = ""
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];
    this.state = {
        registerdMobileNo: "",
        accountId: "",
        loader: true,
        verifyOtp: "",
        successVerified: false,
        displayOtp: ""
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      switch(apiRequestCallId) {
         case this.getloggedInUserInfoApiCallId : 
            return this.responseForLoggedInUser(responseJson)
        case this.verifyOtpApiCallId : 
            return this.responseForverifyOTP(responseJson)
        default:
            break;
      }
      
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount(){
      this.loggedInUserInfo()

      const displayedOtp = localStorage.getItem("verifyOtp");
      if(displayedOtp) {
        this.setState({ displayOtp: displayedOtp})
      }
  }

  responseForLoggedInUser = (responseJson : any) => {
    if(responseJson && responseJson.user && responseJson.user.data) {
        this.setState({ 
           accountId : responseJson.user.data.id, 
           registerdMobileNo: responseJson.user.data.attributes.full_phone_number ,
           loader : false
        })
    } else {
        this.setState({ loader : false})
    }
  }

  responseForverifyOTP = (responseJson: any) => {
    if(responseJson && responseJson.messages) {
      toast.success(responseJson.messages)
      this.setState({ successVerified : true, loader: false})  
    } else if(responseJson && responseJson.errors ) {
        const errors = responseJson.errors
        toast.error(errors)
        this.setState({ loader: false})
    } else {
      toast.error("Something went wrong!. Please try again later")
      this.setState({ loader: false})
       
    }
  }

  handleOTPChange = (otpValue : string) => {
    this.setState({ verifyOtp: otpValue})
 } 

  loggedInUserInfo = () => {
    const header = {
        'token': localStorage.getItem("userToken"),
    }

    this.getloggedInUserInfoApiCallId = apiCalling({
        header: JSON.stringify(header),
        endPoint: configJSON.getUserInfoApiEndPoint,
        method: configJSON.apiGETMethod
    })
  }

  verifyOTPNumber = () => {

    this.setState({ loader : true})

    const header = {
      "Content-Type": configJSON.exampleApiContentType,
      "token": localStorage.getItem("userToken")
    };

    const httpBody = {
      "data": {
        "attributes": {
          "pin": this.state.verifyOtp
        }
      }
    }

    this.verifyOtpApiCallId = apiCalling({
      header: JSON.stringify(header),
      method: configJSON.apiPOSTMethod,
      endPoint: configJSON.verifyOtpApiEndpoint,
      body: JSON.stringify(httpBody)
    })
  }

  navigateToPaymentPage = () => {
    this.props.navigation.navigate("PaymentSettings")
  }

   



  // Customizable Area End
}

// Customizable Area End