// Customizable Area Start
import React from "react";

import {
    Box,
    styled,
    Typography,
    InputAdornment,
    TextField,
    Button
} from "@material-ui/core"
import SearchIcon from '@material-ui/icons/Search';
import AppHeader from "../../../blocks/notifications/src/TopHeader.web";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import LocationDistancesController, {
    Props, configJSON, Address
} from "./LocationDistancesController.web";
import CloseIcon from '@material-ui/icons/Close';
import Footer from "../../../components/src/Footer.web";
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder';
const images = require("./assets")

export default class LocationDistances extends LocationDistancesController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    renderLocationService = () => {
        const { openEdit, location, addLocationBox, loacationDistanceData, milesdatEdit,
            milesEditOpen, mileChargeEdit, unmilitedEditValue, additionMiles, unlimitedMileageEdit } = this.state
        const userAddress = loacationDistanceData?.catalogue?.data?.attributes;
        return (
            <Box className="LocationSection">
                <Box className="backSection" onClick={this.handleBackButton} data-test-id="back-button">
                    <ArrowBackIosIcon className="backIcon" />
                    <Typography className="backText">Back</Typography>
                </Box>
                <Box className="backSectionIcon">
                    <ArrowBackIosIcon className="backIcon" />
                </Box>
                <Box className="locationSectionBox">
                    <Box className="locationServiceBox">
                        <Typography className="loactionText">{configJSON.locationDistanceText}</Typography>
                        <TextField className="locationField" placeholder="Add custom delivery locations"
                            onChange={this.handleLocationField}
                            data-test-id="handleLocationField"
                            value={location}
                            InputProps={{
                                disableUnderline: true,
                                startAdornment: (
                                    <InputAdornment position="start" className="inputIcons" style={{ cursor: "pointer" }}>
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            }} />
                    </Box>
                    {addLocationBox && <Box className="locationBox">
                        <Box className="showLocation">
                            <Box className="showLocation">
                                <QueryBuilderIcon />
                                <Typography className="newLoactionText">New Jersey</Typography>
                            </Box>
                            <CloseIcon onClick={this.handleCloseLoaction} data-test-id="handleCloseLoaction" style={{ cursor: "pointer" }} />
                        </Box>
                        <Box>
                            <Typography className="setPriceText">{configJSON.setPrice}</Typography>
                            <TextField className="locationField" placeholder="$56" style={{ width: "95%" }}
                                InputProps={{
                                    disableUnderline: true,
                                }}
                            />
                        </Box>
                        <Box className="btnsSection">
                            <Button className="discardBtn" onClick={this.handleCloseLoaction}>{configJSON.discardBtn}</Button>
                            <Button className="saveBtn">{configJSON.saveBtn}</Button>
                        </Box>
                    </Box>}
                    <Box className="locationServiceBox" style={{ display: addLocationBox ? "none" : "block" }}>
                        <Typography className="fuelText">{configJSON.fuelPolicy}</Typography>
                        <Box className="fuelBox" contentEditable={openEdit ? true : false}>
                            <Box>
                                <Typography className="addressFuel">{`${userAddress?.address.address}, ${userAddress?.address.city}, ${userAddress?.address.country} ${userAddress?.address.pincode}`}</Typography>
                                <Typography className="descriptionFuel">Default pickup & return location </Typography>
                            </Box>
                            <img src={images.editFile} onClick={this.handleOpenEdit} data-test-id="handleOpenEdit" />
                        </Box>
                    </Box>

                    <Box className="deliveryLocationBox">
                        <Typography className="fuelText">{configJSON.deliveryLocations}</Typography>
                        {userAddress?.delivery_address.map((data: Address, index: number) => {
                            return (
                                <Box className="planeBox" contentEditable>
                                    <Box className="iconDescriptionBox">
                                        <img src={images.deliveryPlane} />
                                        <Box>
                                            <Box className="deliveryLocationFields">
                                                <TextField
                                                    className="addressField"
                                                    value={this.state.cityName[index] ?? data.city}
                                                    onChange={(e: any) => this.handleDeliveryChange(e, index)}
                                                    name="cityName"
                                                    data-test-id="cityName"
                                                    InputProps={{ disableUnderline: true }}
                                                />
                                                <TextField
                                                    className="addressField"
                                                    value={this.state.countryName[index] ?? data.country}
                                                    onChange={(e: any) => this.handleDeliveryChange(e, index)}
                                                    name="countryName"
                                                    data-test-id="countryName"
                                                    InputProps={{ disableUnderline: true }}
                                                />
                                                <TextField
                                                    className="addressField"
                                                    value={this.state.pinCodename[index] ?? Number(data.pincode)}
                                                    onChange={(e: any) => this.handleDeliveryChange(e, index)}
                                                    name="pinCodename"
                                                    data-test-id="pincodeName"
                                                    InputProps={{ disableUnderline: true }}
                                                />

                                            </Box>
                                            <Typography className="descriptionFuel">${data.price} each way</Typography>
                                        </Box>
                                    </Box>
                                    <img src={images.editFile} data-test-id="handleEditLocations" onClick={() => this.handleEditLocations(data, index)} />
                                </Box>
                            )
                        })}
                    </Box>
                    <Box className="deliveryLocationBox">
                        <Typography className="fuelText">{configJSON.deliveryLocations}</Typography>
                        <Box className="planeBox">
                            <Typography className="addressFuel">Miles included per day</Typography>
                            <Box className="currencyEdit" style={{ border: milesdatEdit ? "1px solid #E7E7E7" : "none" }}>
                                {!milesdatEdit && <Typography>{milesEditOpen || userAddress?.miles_per_day}</Typography>}
                                {milesdatEdit && <TextField
                                    data-test-id="milesEditOpen"
                                    value={`${milesEditOpen || userAddress?.miles_per_day}`}
                                    onChange={this.handleInputChange("milesEditOpen")}
                                    InputProps={{
                                        disableUnderline: true,
                                    }} />}
                                <img src={images.editFileBlack} data-test-id="handlePutEditClick" style={{ display: milesdatEdit ? "none" : "block" }} onClick={this.handleEditDayMiles} />
                            </Box>
                        </Box>
                        <Box className="railBox">
                            <Typography className="addressFuel">Additional distance fee</Typography>
                            <Box className="currencyEdit" style={{
                                border: additionMiles ? "1px solid #E7E7E7" : "none",
                            }}>
                                {!additionMiles && <Typography>${mileChargeEdit || userAddress?.addtional_miles_charge}</Typography>}
                                {additionMiles && <TextField
                                    data-test-id="milesChargeEdit"
                                    value={mileChargeEdit || userAddress?.addtional_miles_charge}
                                    className="mileChargeEdit"
                                    onChange={this.handleInputChange("mileChargeEdit")}
                                    InputProps={{
                                        disableUnderline: true,
                                        className: "hide-arrows"
                                    }} />}
                                <img src={images.editFileBlack} style={{ display: additionMiles ? "none" : "block" }} data-test-id="mileChargeEdit" onClick={this.handleAdditionMiles} />
                            </Box>
                        </Box>
                        <Box className="railBox">
                            <Typography className="addressFuel">Unlimited mileage fee</Typography>
                            <Box className="currencyEdit" style={{
                                border: unlimitedMileageEdit ? "1px solid #E7E7E7" : "none",
                            }}>

                                {!unlimitedMileageEdit && <Typography>${mileChargeEdit || userAddress?.unlimited_miles}</Typography>}
                                {unlimitedMileageEdit && <TextField
                                    data-test-id="unmilitedEditValue"
                                    value={`${unmilitedEditValue || userAddress?.unlimited_miles}`}
                                    onChange={this.handleInputChange("unmilitedEditValue")}
                                    InputProps={{
                                        disableUnderline: true,
                                    }} />}
                                <img src={images.editFileBlack} data-test-id="handleunlimitedMileageEdit" style={{ display: unlimitedMileageEdit ? "none" : "block" }} onClick={this.handleunlimitedMileageEdit} />
                            </Box>
                        </Box>
                        {(additionMiles || milesdatEdit || unlimitedMileageEdit) && <Box className="btnsSection" style={{ justifyContent: "flex-end" }}>
                            <Button className="discardBtn" onClick={this.handleCloseEditBtn} data-test-id="handleCloseEditBtn">{configJSON.discardBtn}</Button>
                            <Button className="saveBtn" data-test-id="handleEditLocationData" onClick={this.handleEditLocationData}>{configJSON.saveBtn}</Button>
                        </Box>}
                    </Box>
                    <Box className="mainItemBox">
                        <Box className="itemBox">
                            <Typography className="itemFullName">Lamborghini Urus <span className="itemYear">(2020)</span></Typography>
                            <ExpandMoreIcon className="expandIcon" />
                        </Box>
                    </Box>
                </Box>
            </Box>
        )
    }


    render() {
        // Customizable Area Start

        return (
            <CustomBox>
                <AppHeader activeLink="Dashboard" navigation={this.props.navigation} />
                <Box className="mainBox">
                    {this.renderLocationService()}
                </Box>
                <Footer />
            </CustomBox>);
        // Customizable Area End
    }
}
const CustomBox = styled(Box)({
    "& .mainBox": {
        width: "100%",
        display: "flex",
        justifyContent: "center"
    },
    "& .LocationSection": {
        width: "80%",
        paddingTop: "48px",
        "@media(max-width:600px)": {
            width: "90%"
        }
    },
    "& .backSection": {
        display: 'flex',
        alignItems: "center",
        color: "#667085",
        gap: "6px",
        "@media(max-width: 600px)": {
            display: "none"
        }
    },
    "& .backSectionIcon": {
        display: "none",
        "@media(max-width: 600px)": {
            display: "block"
        }
    },
    "& .backIcon": {
        width: "20px",
        height: "20px"
    },
    "& .backText": {
        fontFamily: "Plus Jakarta Sans",
        fontSize: "14px",
        fontWeight: 500,
        lineHeight: "20px"

    },
    "& .locationSectionBox": {
        paddingTop: "32px",
        width: "72%",
        display: "flex",
        flexDirection: "column",
        gap: "32px",
        "@media(max-width:600px)": {
            width: "100%",
        }
    },
    "& .loactionText": {
        fontFamily: "Plus Jakarta Sans",
        fontSize: "30px",
        fontWeight: 600,
        lineHeight: "38px",
        textAlign: "left",
        color: "#101828",
        "@media(max-width:600px)": {
            textAlign: "center",
            marginTop: "-65px"
        }
    },
    "& .fuelText": {
        fontFamily: "Plus Jakarta Sans",
        fontSize: "20px",
        fontWeight: 600,
        lineHeight: "30px",
        textAlign: "left",
        color: "#101828"
    },
    "& .locationField": {
        padding: "10px 16px 10px 16px",
        borderRadius: "12px",
        border: "1px solid #D0D5DD",
        color: "#475467"
    },
    "& .showLocation": {
        display: "flex",
        justifyContent: "space-between",
        gap: "5px",
        alignItems: "center"
    },
    "& .btnsSection": {
        display: 'flex',
        gap: "10px"
    },
    "& .discardBtn": {
        border: "1px solid #979797",
        color: "#7B7B7B",
        fontFamily: "Plus Jakarta Sans",
        fontSize: "16px",
        fontWeight: 600,
        textTransform: "capitalize",
        width: "158px",
        height: "52px",
        borderRadius: "12px",
        cursor: "pointer"
    },
    "& .setPriceText": {
        fontFamily: "Plus Jakarta Sans",
        fontSize: "14px",
        fontWeight: 700,
        lineHeight: "22px",
        color: '#23395D'
    },
    "& .newLoactionText": {
        fontFamily: "Plus Jakarta Sans",
        fontSize: "15px",
        fontWeight: 500,
        lineHeight: "20px",
        color: '#23395D',
    },
    "& .saveBtn": {
        color: "#FFFFFF",
        fontFamily: "Plus Jakarta Sans",
        fontSize: "16px",
        fontWeight: 600,
        textTransform: "capitalize",
        backgroundColor: "#3173E1",
        width: "158px",
        height: "52px",
        borderRadius: "12px",
        cursor: "pointer"

    },
    "& .locationBox": {
        border: "1px solid #E7E7E7",
        padding: "10px 16px 10px 16px",
        borderRadius: "12px",
        boxShadow: "0px 4px 10px 0px #00000014",
        display: "flex",
        flexDirection: "column",
        gap: "16px"
    },
    "& .locationServiceBox": {
        display: 'flex',
        flexDirection: "column",
        gap: "16px"
    },
    "& .editFileImage": {
        cursor: "pointer"
    },
    "& .fuelBox": {
        border: "1px solid #D0D5DD",
        borderRadius: "12px",
        padding: "35px 24px 35px 24px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"
    },
    "& .addressFuel": {
        fontSize: "18px",
        fontWeight: 600,
        fontFamily: "Plus Jakarta Sans",
        lineHeight: "21.94px",
        color: "#23395D",
    },
    "& .deliveryLocationFields": {
        display: "flex",

    },
    "& .addressField": {
        "& .MuiInputBase-input": {
            fontSize: "18px !important",
            fontWeight: 600,
            fontFamily: "Plus Jakarta Sans !important",
            lineHeight: "21.94px",
            color: "#23395D !important",
            width: "100%",
            padding: "0px"
        }
    },
    "& .descriptionFuel": {
        fontFamily: "Plus Jakarta Sans",
        fontSize: "14px",
        fontWeight: 500,
        lineHeight: "17.64px",
        color: "#94A3B8"
    },
    "& .deliveryLocationBox": {
        display: 'flex',
        flexDirection: "column",
        gap: "16px"
    },
    "& .planeBox": {
        border: "1px solid #D0D5DD",
        borderRadius: "12px",
        padding: "35px 24px 35px 24px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"
    },
    "& .iconDescriptionBox": {
        display: 'flex',
        alignItems: "center",
        gap: "14px"
    },
    "& .railBox": {
        border: "1px solid #D0D5DD",
        borderRadius: "12px",
        padding: "35px 24px 35px 24px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between"
    },
    "& .currencyEdit": {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        gap: "5px",
        width: "106px",
        height: "52px",
        padding: "5px",
        textAlign: "center",
        borderRadius: "10px",
        paddingBottom: "0px"
    },
    "& .mileChargeEdit": {
        paddingLeft: "15%"
    },
    "& .mainItemBox": {
        display: "flex",
        justifyContent: "center",
        width: "100%"
    },
    "& .itemBox": {
        display: "none",
        "@media(max-width:600px)": {
            display: "flex",
            alignItems: "center",
            borderRadius: "1000px",
            gap: "5px",
            backgroundColor: "#0F73EE",
            padding: "8px 16px 8px 16px",
            justifyContent: "center",
            width: "max-content"
        }
    },
    "& .itemFullName": {
        fontFamily: " Plus Jakarta Sans",
        fontSize: "16px",
        fontWeight: 600,
        lineHeight: "20.16px",
        color: "#FFFFFF"
    },
    "& .itemYear": {
        fontSize: "12px"
    },
    "& .expandIcon": {
        color: "#FFFFFF"
    },
    "& .hide-arrows": {
        "&::-webkit-outer-spin-button": {
            "appearance": "none"
        }
    }
    //     ,
    // .hide-arrows::-webkit-inner-spin-button": {
    //   -webkit-appearance: none;
    //   margin: 0;

})

// Customizable Area End