// Customizable Area Start
import React from "react";
import {
    Box,
    styled,
    Tab,
    Tabs,
    Button,
    Grid,
    Typography,
    Divider,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Badge
} from "@material-ui/core"

import AppHeader from "../../../blocks/notifications/src/TopHeader.web"
import Footer from "../../../components/src/Footer.web";
import Loader from "../../../components/src/Loader.web";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { calenderIcon } from "../../../blocks/reservations/src/assets";
import { skipImageIcon } from "./assets";
 

import HostReservationsController, {
    Props,
  } from "./HostReservationsController";


class HostReservations extends HostReservationsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

    renderRentalRequestSection = () => {
        return (
            <Box style={{ margin: "30px 0" }}>
                <Accordion className="mainAccordianBox">
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                        <Box>
                            <Badge
                                invisible={this.state.loader}
                                color="secondary"
                                badgeContent={this.state.rentalRequestReservationData.length}
                                showZero
                            >
                                <Typography className="requestText">Rental request</Typography>
                            </Badge>
                        </Box>
                    </AccordionSummary>

                    <AccordionDetails>
                        {this.state.rentalRequestReservationData.length > 0 &&
                            <Grid container spacing={3}>
                                {this.state.rentalRequestReservationData.map(cardValue =>
                                    <Grid item md={6} key={cardValue.catalougeId}>
                                        <Box className="reservationCard bgWhite">
                                            <Grid container spacing={3}>
                                                <Grid item md={4}>
                                                    <img
                                                        className="imgStyle"
                                                        src={cardValue.imgUrl}
                                                    />
                                                </Grid>
                                                <Grid item md={8}>
                                                    <Typography className="catalougeText">{cardValue.catalougeName}</Typography>
                                                    <Typography className="renterTextReq">Renter: <span className="renterName">{cardValue.renterName}</span> <span className="startText">Start : </span> <span className="startText2"> {cardValue.startTime}</span></Typography>
                                                    <Typography className="costText">Trip Cost: {cardValue.costVal} •<span> Earnings: {cardValue.earningVal}</span> </Typography>
                                                    <Divider style={{ margin: "8px 0" }} />
                                                    <Box style={{ display: "flex", justifyContent: "space-evenly", alignItems: "center" }}>
                                                        <Box textAlign={"center"}>
                                                            <Typography className="dateText">{cardValue.startDate}</Typography>
                                                            <Typography className="timeText"> {cardValue.startMonthTime}</Typography>
                                                        </Box>
                                                        <Box>
                                                            <img src={skipImageIcon} />
                                                        </Box>
                                                        <Box textAlign={"center"}>
                                                            <Typography className="dateText">{cardValue.endDate}</Typography>
                                                            <Typography className="timeText"> {cardValue.endMonthTime}</Typography>
                                                        </Box>
                                                    </Box>
                                                    <Divider style={{ margin: "8px 0" }} />

                                                    {this.handleCatalougeTypeCondition(cardValue.catalougeType) ?
                                                        <Typography className="durationText"> Delivery :<span className="durationValue">{cardValue.deliveryLocation}</span></Typography>
                                                        :
                                                        <Typography className="durationText"> Duration :<span className="durationValue">{cardValue.deliveryDuration}</span></Typography>
                                                    }
                                                    <Box style={{ margin: "20px 0", display: "flex", gap: 8 }}>
                                                        <Button onClick={() => this.declinedRentalReqData(cardValue.catalougeId)} className="declineBtn" data-test-id="declineBtn">Decline</Button>
                                                        <Button onClick={() => this.acceptRentalReqData(cardValue.catalougeId)} className="startBtn" data-test-id="startBtnReq">Accept Trip</Button>
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                        </Box>
                                    </Grid>

                                )}

                            </Grid>
                        }

                    </AccordionDetails>

                </Accordion>
            </Box>
        )
    }

    renderUpcomingSection = () => {
        return (
            <Box>

                {this.renderRentalRequestSection()}


                <Box>
                    {this.state.reservationData.length > 0 &&
                        <Grid container spacing={3}>
                            {this.state.reservationData.map(cardValue =>
                                <Grid item md={6} sm={12} key={cardValue.catalougeId}>
                                    <Box className="reservationCard">
                                        <Grid container spacing={3}>
                                            <Grid item md={4} sm={12}>
                                                <img
                                                    className="imgStyle"
                                                    src={cardValue.imgUrl}
                                                />
                                            </Grid>
                                            <Grid item md={8} sm={12}>
                                                <Typography className="catalougeText">{cardValue.catalougeName}</Typography>
                                                <Typography className="deliveryText">{this.handleCatalougeTypeCondition(cardValue.catalougeType) && "Delivery:"}  <span className="colorDiff">{cardValue.deliveryLocation}</span></Typography>
                                                <Box style={{ display: "flex", alignItems: "center", gap: "8px", marginBottom: "8px" }}>
                                                    <img src={calenderIcon} alt="icon" />
                                                    <Typography className="pickUpText"> {this.handleCatalougeTypeCondition(cardValue.catalougeType) ? "Pick-up :" : "Start :"}  <span className="pickUpOutValue">{cardValue.pickUpPoint}</span></Typography>
                                                </Box>
                                                {this.handleCatalougeTypeCondition(cardValue.catalougeType) &&
                                                    <Box style={{ display: "flex", alignItems: "center", gap: "8px", marginBottom: "12px" }}>
                                                        <img src={calenderIcon} alt="icon" />
                                                        <Typography className="pickUpText"> Drop-off : <span className="pickUpOutValue">{cardValue.dropOffPoint}</span></Typography>
                                                    </Box>
                                                }

                                                <Typography className="renterText">Renter: <span className="renterName">{cardValue.renterName}</span></Typography>
                                                <Divider style={{ margin: "25px 0" }} />
                                                <Box style={{ margin: "20px 0", display: "flex", gap: 8 }}>
                                                    <Button className="modifyBtn" data-test-id="modifyBtn">Modify Trip</Button>
                                                    <Button className="startBtn" data-test-id="startBtn" onClick={() => this.navigateToDetailPage(cardValue.catalougeId)}>Start {this.handleCatalougeTypeCondition(cardValue.catalougeType) ? "Trip" : "Checking"}</Button>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Grid>

                            )}

                        </Grid>
                    }
                </Box>
            </Box>
        )
    }
 
    renderRentedSection = () => {
        return (
            <Box>

                <Typography className="onRentText"> On Rent</Typography>

                <Box>
                    {this.state.rentedReservationData.length > 0 &&
                        <Grid container spacing={3}>
                            {this.state.rentedReservationData.map(itemValue =>
                                <Grid item md={6} key={itemValue.catalougeId}>
                                    <Box className="reservationCard">
                                        <Grid container spacing={3}>
                                            <Grid item md={4}>
                                                <img
                                                    className="imgStyle"
                                                    src={itemValue.imgUrl}
                                                />
                                            </Grid>
                                            <Grid item md={8}>
                                                <Typography className="catalougeText">{itemValue.catalougeName}</Typography>
                                                <Typography className="deliveryText">{this.handleCatalougeTypeCondition(itemValue.catalougeType) && "Delivery:"}  <span className="colorDiff">{itemValue.deliveryLocation}</span></Typography>
                                                <Box style={{ display: "flex", alignItems: "center", gap: "8px", marginBottom: "8px" }}>
                                                    <img src={calenderIcon} alt="icon" />
                                                    <Typography className="pickUpText"> Starting <span className="pickUpOutValue">{itemValue.startingInfo}</span></Typography>
                                                </Box>
                                                <Box style={{ display: "flex", alignItems: "center", gap: "8px", marginBottom: "12px" }}>
                                                    <img src={calenderIcon} alt="icon" />
                                                    <Typography className="pickUpText"> Endong : <span className="pickUpOutValue">{itemValue.endingInfo}</span></Typography>
                                                </Box>

                                                <Typography className="renterText">Renter: <span className="renterName">{itemValue.renterName}</span></Typography>
                                                <Divider style={{ margin: "25px 0" }} />
                                                <Box style={{ margin: "20px 0", display: "flex", gap: 8 }}>
                                                    <Button className="modifyBtn" data-test-id="modifyTripBtn">Modify Trip</Button>
                                                    <Button className="endTripBtn" data-test-id="endTripBtn" onClick={() => this.navigateToEndTripPage(itemValue.catalougeId)}>End Trip</Button>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Grid>

                            )}

                        </Grid>
                    }
                </Box>
            </Box>
        )
    }

  render() {
    // Customizable Area Start
    
    return (
      <div>
        <AppHeader navigation={this.props.navigation} activeLink="Reservation"/>

            {this.state.loader ? <Loader loading={true} />
                :
                <MainWrapper>
                    <Box>
                        <Button
                            onClick={this.navigateToBackPage}
                            data-test-id="backBtn"
                            className="backBtn"
                            startIcon={<ArrowBackIosIcon style={{ color: "#667085", height: 16, width: 16 }} />}>
                            Back
                        </Button>
                    </Box>

                    <Box style={{ margin: "30px 0" }}>
                        <Tabs
                            className="tabsDiv"
                            value={this.state.tabVal}
                            data-test-id="tabs"
                            onChange={this.handleTabChange}
                        >
                            <Tab label="Upcoming" className="tabLabel"></Tab>
                            <Tab label="Rented" className="tabLabel"></Tab>
                        </Tabs>
                    </Box>

                    <Box>
                        {this.state.tabVal === 0 && this.renderUpcomingSection() }

                        {this.state.tabVal === 1 && this.renderRentedSection() }
                    </Box>


                </MainWrapper>
            }

         <Footer />
      </div>
    );
    // Customizable Area End
  }
}

export default HostReservations;

const MainWrapper = styled(Box)({
    maxWidth: "1220px",
    margin: "40px auto",
    '@media(max-width: 767px)': {
        margin: "0 30px",
        maxWidth: "100%"
      },
    "& .backBtn": {
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '14px',
        fontWeight: 500,
        lineHeight: '20px',
        textTransform: "none",
        textAlign: 'left',
        color: '#667085',
        background: "white"
    },
    "& .tabsDiv .Mui-selected": {
        color: "#3173E1",
        borderBottom: "none"
    },
    "& .tabsDiv .MuiTabs-indicator": {
        backgroundColor: "#3173E1",
    },
    "& .tabLabel": {
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '16px',
        fontWeight: 600,
        lineHeight: '24px',
        textAlign: 'left',
        textTransform: "none",
        color: "#23395D",
        borderBottom: "1px solid #E2E8F0"
    },
    "& .reservationCard" : {
        border: "1px solid #D0D5DD",
        padding: "16px",
        borderRadius: "12px",
        minHeight: "270px"
    },
    "& .catalougeText" : {
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '20px',
        fontWeight: 600,
        color: '#23395D',
        marginBottom: 8
    },
    "& .deliveryText" : {
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '16px',
        fontWeight: 400,
        color: '#667085',
        marginBottom: 12
    },
    "& .colorDiff" : {
        color: '#23395D',
        fontWeight: 600,
    },
    "& .pickUpText" : {
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '14px',
        fontWeight: 400,
        color: '#667085',
    },
    "& .pickUpOutValue" : {
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '14px',
        fontWeight: 600,
        color: '#23395D'
    },
    "& .renterText" : {
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '14px',
        fontWeight: 400,
        color: '#667085',
    },
    "& .renterName" : {
        fontWeight: 700,
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '14px',
        color: '#23395D'
    },
    "& .modifyBtn" : {
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '14px',
        fontWeight: 500,
        textTransform: "none",
        width: 165,
        height: 40,
        color: '#3173E1',
        background: "white",
        border: "1px solid #3173E1",
        borderRadius: "12px"
    },
    "& .startBtn" : {
        width: 165,
        height: 40,
        color: 'white',
        background: "#3173E1",
        borderRadius: "12px",
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '14px',
        fontWeight: 600,
        textTransform: "none",
    },
    "& .imgStyle" : {
        height: 180, 
        width: 180, 
        borderRadius: "12px"
    },
    "& .mainAccordianBox" : {
        boxShadow: "none",
        borderRadius : 12,
        background: "#EAECF0",
        padding: "10px"
     },
     "& .requestText" : {
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '20px',
        fontWeight: 600,
        color: '#23395D'
    },
     "& .bgWhite" : {
        background: "white"
     },
    "& .declineBtn": {
        fontSize: '14px',
        textTransform: "none",
        fontWeight: 500,
        width: 165,
        fontFamily: 'Plus Jakarta Sans',
        height: 40,
        background: "white",
        border: "1px solid #CB0A0A",
        color: '#CB0A0A',
        borderRadius: "12px"
    },
    "& .renterTextReq" : {
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '14px',
        fontWeight: 700,
        color: '#B0B0B0',
    },
    "& .startText" : {
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '14px',
        fontWeight: 500,
        color: '#3173E1',
    },
    "& .startText2" : {
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '14px',
        fontWeight: 700,
        color: '#3173E1',
    },
    "& .costText" : {
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '14px',
        fontWeight: 500,
        color: '#23395D',
    },
    "& .dateText" : {
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '24px',
        fontWeight: 500,
        color: '#3173E1',
    },
    "& .timeText" : {
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '12px',
        fontWeight: 500,
        color: '#3173E1',
    },
    "& .durationText" : {
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '14px',
        fontWeight: 500,
        textAlign: "center",
        color: '#23395D',
    },
    "& .durationValue" : {
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '14px',
        fontWeight: 500,
        color: '#3173E1',
    },
    "& .onRentText" : {
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '20px',
        fontWeight: 600,
        color: '#23395D',
        margin: "15px 0"
    },
    "& .endTripBtn" : {
        width: 165,
        height: 40,
        color: 'white',
        background: "#CB0A0A",
        borderRadius: "12px",
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '14px',
        fontWeight: 600,
        textTransform: "none",
    },
})

// Customizable Area End