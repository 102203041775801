// Customizable Area Start
import React from 'react';
import { Box, Typography, styled } from '@material-ui/core';

interface Props  {
    activeKey? : number,
    navigation?: any,
}


const ProfileSidebar = (props : Props) => {

    const sidebarContent = [
        { id: 1, label : "Account Information", activeMenu : 1, navigateTo : 'UserProfile'},
        { id: 2, label : "Payment Information", activeMenu : 2, navigateTo : 'PaymentSettings'},
        { id: 3, label : "Notifications", activeMenu : 3, navigateTo : 'NotificationsSettings'},
        { id: 4, label : "Approval Status", activeMenu : 4, navigateTo : ''},
        { id: 5, label : "Support Center", activeMenu : 5, navigateTo : 'SupportCenterSettings'},
        { id: 6, label : "Became a Host", activeMenu : 6, navigateTo : ''},
        { id: 7, label : "Terms of Services", activeMenu : 7, navigateTo : ''},
      ]

      const handleNavigation = (pathURL : string) => {
        if(pathURL !== "") {
            props.navigation.navigate(pathURL)
            return;
        }
      }

  return (
    <SidebarWrapper className='mainBox'>
          {sidebarContent.map(item =>
              <Box 
                 onClick={() => handleNavigation(item.navigateTo)} 
                 className={item.activeMenu ===  props.activeKey ? "activeBox" : ""} 
                 style={{ padding: "10px 16px", cursor: "pointer" }} 
                 key={item.id}>
                  <Typography 
                      className={item.activeMenu === props.activeKey ? "activeSidebarContentText" : "sidebarContentText"}
                  >
                    {item.label}
                  </Typography>
              </Box>
          )}
    </SidebarWrapper>
  )
}

export default ProfileSidebar

const SidebarWrapper = styled(Box)({
    "& .activeBox" : {
        background: "#F2F4F7",
        borderRadius: "12px",
        width: "max-content"
      }, 
    "& .sidebarContentText" : {
        fontFamily: "Plus Jakarta Sans",
        fontSize: "14px",
        fontWeight: 600,
        color: "#667085"
        
      },
      "& .activeSidebarContentText" : {
        color: "#101828",
        fontFamily: "Plus Jakarta Sans",
        fontSize: "14px",
        fontWeight: 600,
      },
})


// Customizable Area End