// Customizable Area Start
import React from "react";

import {
    Box,
    styled,
    Tab,
    Tabs,
    Button,
    Grid,
    Typography,
    Divider,
} from "@material-ui/core"

import AppHeader from "../../blocks/notifications/src/TopHeader.web"
import Footer from "./Footer.web";
import Loader from "./Loader.web";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import CustomStepper from "./CustomStepper.web";
import { chatBoxIcon, flightIcon, skipImageIcon } from "../../blocks/catalogue/src/assets";
import { starIcon } from "../../blocks/reservations/src/assets";
import VerifiedUserOutlinedIcon from '@material-ui/icons/VerifiedUserOutlined';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';
import DescriptionIcon from '@material-ui/icons/Description';



import HostReservationDetailController, {
    Props,
  } from "../../blocks/catalogue/src/HostReservationDetailController";


class CommonPage extends HostReservationDetailController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

    renderCommonDetails = () => {
        return (
            <Box>
                <Box className="outerBox">
                    <Box style={{ display: "flex", gap: 15, alignItems: "center" }}>
                        <img src={flightIcon} />
                        <Box>
                            <Typography className="infoTextpart1">LAX International Airport, Terminal 5</Typography>
                            <Typography className="infoTextpart2">Flight: AA 2570</Typography>
                        </Box>
                    </Box>
                </Box>

                <Box className="outerBox">
                    <Box>
                        <Typography className="infoTextpart1">Trip add-ons</Typography>
                        <Typography className="infoTextpart2">Extras requested by renter</Typography>
                    </Box>
                    {this.state.catalougeType === "Car" ?
                        <Box>
                            <Typography className="infoColorText">Pre-Paid Fuel</Typography>
                        </Box>
                        :
                        <Box>
                            <Typography className="infoColorText">$0</Typography>
                            <Typography className="infoTextpart3">+$0 deposit</Typography>
                        </Box>

                    }

                </Box>

                <Box className="outerBox">
                    <Box>
                        <Typography className="infoTextpart1">Trip cost</Typography>
                        <Typography className="infoTextpart2">Click for breakdown</Typography>
                    </Box>
                    <Box>
                        <Typography className="infoColorText">$0</Typography>
                        <Typography className="infoTextpart3">+$0 deposit</Typography>
                    </Box>
                </Box>

                <Box className="outerBox">
                    <Typography className="infoTextpart1">Your {this.state.catalougeType === "Car" ? "Trip" : "Stay"} Starts in: <span className="infoColorText"> {this.state.reservationInfo.duration} Days </span></Typography>
                    <Button className="modifyBtn">Modify Trip</Button>
                </Box>


            </Box>
        )
    }

    renderDocumentsFields = () => {
        return (
            <Box>
                <Typography className="breakDownText" style={{ padding: 10 }}>Documents</Typography>

                <Box className="bgTransparent" style={{ margin: "10px" }}>
                    <Box style={{ display: "flex", alignItems: "center", justifyContent: "space-around" }}>
                        <Box>
                            <VerifiedUserOutlinedIcon style={{ color: "#23395D", height: 24, width: 24 }} />
                        </Box>
                        <Box>
                            <Typography className="docText">Car Sharing Agreement</Typography>
                            <Typography className="docSubText">For law enforcement & legal needs</Typography>
                        </Box>
                        <Box>
                            <ArrowForwardIosIcon style={{ color: "#23395D", height: 24, width: 24 }} />
                        </Box>
                    </Box>
                </Box>

                <Box className="bgTransparent" style={{ margin: "10px" }}>
                    <Box style={{ display: "flex", alignItems: "center", justifyContent: "space-around" }}>
                        <Box>
                            <DescriptionIcon style={{ color: "#23395D", height: 24, width: 24 }} />
                        </Box>
                        <Box>
                            <Typography className="docText">Full Coverage Insurance Plan</Typography>
                            <Typography className="docSubText">Out-of-pocket maximum: $750</Typography>
                        </Box>
                        <Box>
                            <ArrowForwardIosIcon style={{ color: "#23395D", height: 24, width: 24 }} />
                        </Box>
                    </Box>
                </Box>
            </Box>
        )
    }

    steps = [
        {
            label: 'Rental Price',
            details: '$1,400 / Night',
            subDetails: "(x3 Night)",
            amount: '$0',
        },
        {
            label: '3+ Day Discount',
            details: 'Extended Trip Discount',
            subDetails: "(5%)",
            amount: '$0',
        },
        {
            label: 'Trip Payment',
            details: 'Refundable Deposit $500',
            subDetails: "",
            amount: '$0',
        },
        {
            label: 'LuxGo Service',
            details: 'Service Fee for Platform',
            subDetails: "",
            amount: '$0',
        },
        {
            label: 'Insurance Cost',
            details: 'Full Coverage Insurance Fee',
            subDetails: "",
            amount: '$0',
        },
        {
            label: 'Sales Taxes (7.25%)',
            details: 'California Sales Tax Rate',
            subDetails: "",
            amount: '$0',
        }
    ];

  render() {
    // Customizable Area Start
    
    return (
      <div>
        <AppHeader navigation={this.props.navigation} activeLink="Reservation"/>

        {this.state.loading ? <Loader loading={true} /> : 

        <DetailWrapper>
                <Box className="mainBoxes">
                    <Grid container spacing={3} className="mainCustomGrid"> 
                        <Grid item md={8} className="customGrid">
                            <Box>
                                <Button
                                    onClick={this.navigateToBackPage}
                                    className="backBtn"
                                    data-test-id="backBtn"
                                    startIcon={<ArrowBackIosIcon style={{ color: "#667085", height: 16, width: 16 }} />}>
                                    Back
                                </Button>
                            </Box>

                            <Typography className="bookedHomeText">
                                {this.props.pageTitle ? this.props.pageTitle : this.chcekCatalougeTypeCondition(this.state.catalougeType) ? "Booked Trip" : "Booked Apartments"}
                            </Typography>

                            <Box style={{ display: "flex", gap: 20, margin: "25px 0"}}>
                                <img src={this.state.reservationInfo.imgUrl} className="imgStyles" />
                                <Box>
                                    <Typography className="subHeader">{this.state.reservationInfo.catalougeName}</Typography>
                                    <Box style={{ display: "flex", gap: 5, alignItems: "center" }}>
                                        <img src={starIcon} />
                                        <Typography className="reviewsText">{this.state.reservationInfo.reviewInfo.rating}<span> {this.state.reservationInfo.reviewInfo.totalReviews} reviews</span></Typography>
                                    </Box>
                                </Box>
                            </Box>

                            <Box>
                                {this.props.children}
                            </Box>
                        
                        </Grid>

                        <Grid item md={4}>
                            <Box className="bgBrown">

                                    <Box className="bgTransparent">
                                        <Box style={{ display: "flex", justifyContent: "space-evenly", alignItems: "center" }}>
                                            <Box textAlign={"center"}>
                                                <Typography className="dateText">{this.state.reservationInfo.startDate}</Typography>
                                                <Typography className="timeText"> {this.state.reservationInfo.startMonthTime}</Typography>
                                            </Box>
                                            <Box>
                                                <img src={skipImageIcon} />
                                            </Box>
                                            <Box textAlign={"center"}>
                                                <Typography className="dateText">{this.state.reservationInfo.endDate}</Typography>
                                                <Typography className="timeText"> {this.state.reservationInfo.endMonthTime}</Typography>
                                            </Box>
                                        </Box>
                                    </Box>

                                    <Box className="bgTransparent" style={{ margin : "20px 0"}}>
                                            <Typography className="breakDownText">{this.chcekCatalougeTypeCondition(this.state.catalougeType) ? "Trip cost breakdown" : "Stay cost breakdown"}</Typography>
                                            <Divider style={{ margin: "15px 0"}} />

                                            <Box className="stepperBox">
                                                    <CustomStepper steps={this.steps}/>
                                            </Box>

                                            <Divider style={{ margin: "15px 0"}} />
                                             <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                                <Typography className="priceHeader">Customer Price</Typography>
                                                <Box className="priceBox">$0</Box>
                                             </Box>

                                    </Box>

                                    {this.chcekCatalougeTypeCondition(this.state.catalougeType) && this.renderDocumentsFields()}

                                    <Typography className="breakDownText" style={{ padding: 10}}>Renter Details</Typography>

                                    <Box className="bgTransparent bgLightGray">
                                        <Box style={{ display: "flex", justifyContent: "space-evenly", alignItems: "center"}}>
                                            <img src={this.state.renterProfile.renterProfile} style={{ height : 50, width: 50, borderRadius: "100%"}} />
                                            <Box>
                                                <Typography className="renterText2">Booked by</Typography>
                                                <Typography className="renterText">{this.state.renterProfile.renterName} <VerifiedUserIcon style={{ color: "#3173E1", height: 16, width: 16 }} /></Typography>
                                                <Typography className="smallText">{this.state.renterProfile.tripsCompleted} trips • Renter since {this.state.renterProfile.renterSince}</Typography>

                                            </Box>
                                            <img src={chatBoxIcon} />
                                        </Box>
                                    </Box>

                            </Box>
                        </Grid>
                    </Grid>
                </Box>
        </DetailWrapper>

        }
         <Footer />
      </div>
    );
    // Customizable Area End
  }
}

export default CommonPage;

const DetailWrapper = styled(Box)({
    maxWidth: "1220px",
    margin: "40px auto",
    "& .backBtn": {
        color: '#667085',
        fontSize: '14px',
        fontWeight: 500,
        textTransform: "none",
        textAlign: 'left',
        fontFamily: 'Plus Jakarta Sans',
        background: "white"
    },
    "& .customGrid":{
"@media(max-width:720px)":{
    width:"80%"
},
"@media(max-width:1200px)":{
    width:"100%"
}
    },
"& .mainCustomGrid":{
    "@media(max-width:720px)":{
        display:"flex",
        justifyContent:"center",
        width:"100%"
    },
    "@media(max-width:1200px)":{
        width:"90%"
    }
},
"& .mainBoxes":{
    "@media(max-width:1200px)":{
        display:"flex",
        width:"100%",
        flexDirection:"column",
        alignItems:"center"
    },
}
    ,
    "& .bookedHomeText" : {
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '30px',
        fontWeight: 600,
        textAlign: 'left',
        color: '#23395D',
        margin: "25px 0"
    },
    "& .imgStyles" : {
        height: 110, 
        width: 130, 
        borderRadius: "12px"
    },
    "& .subHeader" : {
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '30px',
        fontWeight: 600,
        lineHeight: '38px',
        textAlign: 'left',
        color: '#23395D'
    },
    "& .reviewsText" : {
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '16px',
        fontWeight: 500,
        color: '#23395D',
        margin: "5px 0"
    },
    "& .mainTabsDiv" : {
        borderBottom: "1px solid #E2E8F0"
      },
      "& .mainTabsDiv .Mui-selected" :{
          color : "#3173E1",
          borderBottom: "none"
      },
      "& .mainTabsDiv .MuiTabs-indicator" : {
          backgroundColor : "#3173E1"
      },
      "& .tabLabelText": {
        fontFamily: 'Plus Jakarta Sans',
        fontWeight: 600,
        fontSize: '16px',
        textAlign: 'left',
        textTransform: "none",
        color: "#23395D",
        borderBottom: "1px solid #E2E8F0"
    },
    "& .bgBrown": {
        background: "#EAECF0",
        padding: "20px",
        borderRadius: 12,
    },
    "& .bgTransparent": {
        background: "white",
        borderRadius: 12,
        border: "1px solid #E7E7E7",
        padding: "10px"
    },
    "& .timeText" : {
        fontSize: '12px',
        fontWeight: 500,
        fontFamily: 'Plus Jakarta Sans',
        color: '#3173E1',
    },
    "& .dateText" : {
        fontWeight: 500,
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '24px',
        color: '#3173E1',
    },
    "& .breakDownText" : {
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '16px',
        fontWeight: 600,
        color: '#23395D',
    },
    "& .priceHeader" : {
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '16px',
        fontWeight: 500,
        color: '#23395D',
    },
    "& .bgLightGray" : {
        background: "#EDF4FF"
    },
    "& .smallText": {
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '12px',
        fontWeight: 500,
        color: '#000000',
    },
    "& .renterText": {
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '16px',
        fontWeight: 600,
        color: '#23395D',
    },
    "& .renterText2": {
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '16px',
        fontWeight: 500,
        color: '#23395D',
    },
    "& .priceBox" : {
        padding: 10,
        width: 115,
        textAlign: "center",
        borderRadius: 12,
        border: "1px solid #E7E7E7",
        fontFamily: 'Plus Jakarta Sans',
        color: '#23395D',
        fontSize: '16px',
        fontWeight: 600,
    },

    "& .stepperBox .MuiStepConnector-vertical" : {
        marginLeft : 8,
        padding: 0
    },

    "& .outerBox" : {
        display: "flex",
        justifyContent: "space-between",
        padding: "20px",
        alignItems: "center",
        border: "1px solid #DADBDE",
        borderRadius: "12px",
        margin:"25px 0"
    }, 

    "& .infoTextpart1" : {
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '14px',
        fontWeight: 600,
        color: '#23395D',
    },
    "& .infoTextpart2" : {
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '12px',
        fontWeight: 500,
        color: '#2A5591',
    },
    "& .infoTextpart3" : {
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '12px',
        fontWeight: 500,
        color: '#94A3B8',
    },
    "& .infoColorText" : {
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '14px',
        fontWeight: 600,
        color: '#3173E1',
        textAlign: "end"
    },
    "& .modifyBtn": {
        fontFamily: 'Plus Jakarta Sans',
        height: "40px",
        width:"115px",
        fontSize: '14px',
        fontWeight: 500,
        textTransform: "none",
        color: '#3173E1',
        background: "white",
        border: "1px solid #3173E1",
        borderRadius: "12px"
    },
    "& .docText" : {
        fontWeight: 600,
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '14px',
        color: '##23395D',
    },
    "& .docSubText" : {
        fontWeight: 500,
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '12px',
        color: '#94A3B8',
    },
    
    
})

// Customizable Area End