// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";

import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import React from "react";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start  
  isImgUploaded : boolean
  uploadedImages : File[]
  imagesUrl : string[]
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class FinalCheckoutReservationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  fileRefs : React.RefObject<HTMLInputElement>
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];
    this.state = {
        isImgUploaded: true,
        uploadedImages: [],
        imagesUrl: []
    };
    this.fileRefs = React.createRef()
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      // some code
      
    }
    // Customizable Area End
  }

  // Customizable Area Start
  
  handleToggleImagesPreview = () => {
    this.setState({ isImgUploaded : false})
  }

  handleImagesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) {
      const newFiles = Array.from(files);
      const fileURLs = Array.from(files).map((file) => URL.createObjectURL(file));

      this.setState((prevState) => ({
        uploadedImages: [...prevState.uploadedImages, ...newFiles],
        imagesUrl: [...prevState.imagesUrl, ...fileURLs]
      }));
    }

  };

    deleteUploadedImages = (index: number) => {
        this.setState((prevState) => {
            const updatedFiles = [...prevState.uploadedImages];
            const updatedPreviews = [...prevState.imagesUrl];

            updatedFiles.splice(index, 1);
            updatedPreviews.splice(index, 1);

            return {
                uploadedImages: updatedFiles,
                imagesUrl: updatedPreviews,
            };
        });
    };

  handleRefs = () => {
    if (this.fileRefs.current) {
      this.fileRefs.current.click();
    }
  }


  // Customizable Area End
}

// Customizable Area End