// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";

import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { apiCalling, truthyValue } from "../../../components/src/asset";
import moment from "moment"

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface BookingHistoryCard {
  catalougeId: number;
  catalougeType: string;
  catalougeName: string;
  tripDuration : string;
  tripCost:string;
  renterName: string;
  imgUrl: string
}

interface S {
  // Customizable Area Start  
  loader :boolean,
  bookingHistoryData : BookingHistoryCard[]
  filteredBookingHistorydata : BookingHistoryCard[]
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class BookingHistoryController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getBookingHistoryApiCallId : string = ""
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];
    this.state = {
      loader : true,
      bookingHistoryData : [],
      filteredBookingHistorydata: []
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if(apiRequestCallId === this.getBookingHistoryApiCallId) {
        this.getHistoryDataResponse(responseJson)
      }


      
    }
    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidMount(): Promise<void> {
    this.getBookingHistoryData()
  }

  getHistoryDataResponse = (responseJson : any) => {
    if (responseJson && responseJson.catalogue && responseJson.catalogue.data && responseJson.catalogue.data.length > 0) {
      const bookingDataArray = responseJson.catalogue.data.map((itemValue: any) => {
        return {
          catalougeId: itemValue.id,
          catalougeType: itemValue.attributes.catalogue_type,
          catalougeName: truthyValue(itemValue.attributes.catalogue_name),
          tripDuration: this.handleTripDurationDates(itemValue.attributes.start_time, itemValue.attributes.end_time),
          tripCost: truthyValue(itemValue.attributes.total_earnings) ?? 0,
          renterName: truthyValue(itemValue.attributes.renter_name),
          imgUrl: this.handleBookedSlotImage(itemValue)
        }
      });

      this.setState({
        loader: false,
        bookingHistoryData: bookingDataArray,
        filteredBookingHistorydata: bookingDataArray
      })
    } else {
      this.setState({
        bookingHistoryData: [],
        filteredBookingHistorydata: [],
        loader: false
      })
    }
  }

  searchHistoryData = (event : React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value

    const modifiedData = this.state.filteredBookingHistorydata.filter(item =>
        item.catalougeName.toLowerCase().includes(inputValue.toLowerCase()) || 
        item.renterName.toLowerCase().includes(inputValue.toLowerCase())
      )

     this.setState({ bookingHistoryData : modifiedData})
  }

  handleBookedSlotImage= (item : any) => {
    let imgStr : string = ""
    if(item.attributes && item.attributes.images && item.attributes.images.length > 0 && item.attributes.images[0].url ) {
      imgStr = item.attributes.images[0].url
    }

    return imgStr
  } 

  handleTripDurationDates = (startDate: string, endDate :string) => {
    const start = moment(startDate).format('MMM DD');
    const end = moment(endDate).format('MMM DD');
    return `${start} - ${end}`;
  }

  navigateToBackPage = () => {
    this.props.navigation.goBack()
  }

  navigateToReportPage = () => {
    this.props.navigation.navigate("ReportIssue")
  }

  getBookingHistoryData = () => {
    const header = {
      "token": localStorage.getItem("userToken")
    };

    const status = "accepted"
    const tripStatus = "ended"

    this.getBookingHistoryApiCallId = apiCalling({
      header: JSON.stringify(header),
      endPoint: `${configJSON.getHostReservationApiEndPoint}?status=${status}&trip_status=${tripStatus}`,
      method: configJSON.apiMethodTypeGet
    })
  }

  // Customizable Area End
}

// Customizable Area End