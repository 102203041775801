// Customizable Area Start
import React from "react";

import {
  Tabs,
  Tab,
  Box,
  styled,
  Grid,
  Typography,
  Button,
  IconButton, 
  OutlinedInput,
  InputAdornment,
  Card,
  CardActionArea,
  Dialog,
  DialogTitle,
  DialogContent,
  Divider,
  Stepper,
  Step,
  StepContent,
  StepLabel,
  TextField
} from "@material-ui/core"
import { bookingIcon, upcomingIcons, settingIcon, helpcareIcon,earningGraph,
   hostBtnLogo, list1,list2,list3,list4, listCarIcon,listHomeIcon, reload, download,emojiIcon, replyIcon,
    greenTick, lightTick, arrowRight, carPic, homePic, carSedan, carUrus, house, msgIcon } from "./assets";
import {  searchIcon } from "../../../blocks/reservations/src/assets"; 
import AddCircleOutlineRoundedIcon from '@material-ui/icons/AddCircleOutlineRounded';
import SimpleImageSlider from "react-simple-image-slider";
import HighlightOffRoundedIcon from '@material-ui/icons/HighlightOffRounded';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import HostDashboardController, {
    Props,DataItem,
    configJSON, ReviewData
  } from "./HostDashboardController";
import EmojiPicker from "emoji-picker-react";
import AppHeader from "../../../blocks/notifications/src/TopHeader.web";
import Footer from "../../../components/src/Footer.web";
import Loader from "../../../components/src/Loader.web"
import CircularProgressWithLabel, {CircularProgressReview, TabPanel} from "../../../components/src/CustomCircularBox";
import Rating from "@material-ui/lab/Rating";
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Chart from "react-apexcharts";
import Schedule from "../../../components/src/HostSchedule.web";

class HostDashboard extends HostDashboardController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
 
  handleTabView = (value : number) => {
    switch(value) {
        case 0:
            return this.OverViewWrapper();
        case 1:
            return this.loadData();
        case 2:
            return this.EarningsWrapper();
        case 3:
            return this.ReviewsWrapper();
    }
  }

  tmpData = [
    {label : "Upcoming Bookings", icon: bookingIcon, navigate : ""},
    {label : "Booking History", icon: upcomingIcons, navigate : "BookingHistory"},
    {label : "Host Care & Support", icon: helpcareIcon, navigate : ""},
    {label : "Host Settings", icon: settingIcon, navigate : "HostSettings"},
  ]
  OverViewWrapper = () => {
    return (
        <OverViewTabWrapper>
              <p className="scheduleText">Schedule</p>

          {this.state.loaderOverView ? <Loader loading /> : <Box className="scheduleBox">
            <Schedule scheduleData={this.state.hostScheduleData} />
          </Box>}

              <Box style={{ width: "100%", maxWidth: "1220px"}}>
                <Grid container justifyContent="center" alignItems="center" spacing={3}>
                {this.tmpData.map((itemVal:any) => 
                   <Grid item key={itemVal} sm={3}>
                      <Box className="gridBoxes">
                        <Box style={{ textAlign: "center"}}>
                         <img src={itemVal.icon} alt="" />
                        <Typography className="titleText"> {itemVal.label}</Typography>
                        <Button className="viewBtn" data-test-id="viewBtn" onClick={() => this.navigateToGivenPage(itemVal.navigate)} variant="outlined" color="primary">View</Button>
                        </Box>
                      </Box>
                   </Grid>
                )}
                </Grid>
                 
              </Box>
        </OverViewTabWrapper>
    )
  }

  loadData = () => {
    return (
      this.state.loader ? <Loader loading /> : this.ListingWrapper()
    )
  }

  ListingWrapper = () => {
    return (
        <ListingTabWrapper>
          <Box style={{ display: 'flex', justifyContent: "space-between"}} className="searchMainListingBox">
              <OutlinedInput
              placeholder="Search here"
              startAdornment={<img src={searchIcon} />}
              className="searchBox"
              disabled={this.state.listData.length === 0}
              />
             <Button className="addBtn" data-test-id="addBtn" onClick={() => this.handleOpeneAddListModal()} startIcon={<AddCircleOutlineRoundedIcon />}>Add</Button>
          </Box>

          {this.state.listData.length > 0 ? 
          <Box>
            
            <Typography className="catalougeInfoText"> Vehicles ({this.state.noOfCars})  •  Homes ({this.state.noOfHome})</Typography>
          
             <Box>
                <Grid container spacing={3}>
                  {this.state.listData.map((item: any) => {
                    return (
                      <Grid key={item.id} item md={4} sm={6} xs={12} spacing={3}>
                       <Card data-test-id="carasoule" onMouseEnter={this.sliderOn} onMouseLeave={this.sliderOff}  style={{ maxWidth: '390px', margin: 'auto', width: '100%', borderRadius: 16, overflow: 'hidden' }}>
                         <CardActionArea data-test-id="navigateToInfo" onClick={() => { this.navigateToListInfoPage(item.id,item.attributes.name) }}>
                            {item.attributes.status === "active" && <Box style={{ padding: '4px 18px', background: '#CBF8E8', color: '#08C27F',fontFamily: "Plus Jakarta Sans",fontWeight: 500, fontSize: "16px", borderRadius: "0 0 16px 0", position: 'absolute', zIndex: 111, display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 8 }}>
                               •  Active
                            </Box>}
                            <SliderWrapper>
                              <SimpleImageSlider
                                style={{ objectFit: 'cover' }}
                                width={"100%"}
                                height={190}
                                images={item.attributes?.images}
                                showBullets={true}
                                showNavs={false}
                                autoPlay={false}
                                autoPlayDelay={2.5}
                              />
                            </SliderWrapper>
                            <Typography className="catalougeDataText">
                              {this.handleCardTitle(item)}
                            </Typography>
                            <Typography className="catalougeDataText">
                              {this.handleCardHeader(item)}
                            </Typography>
                            <Typography className="catalougeDataText">
                              {this.handleCardTrips(item)}
                            </Typography>
                         </CardActionArea>
                       </Card>
                     </Grid>

                    )
                  })}
                  
                </Grid>
             </Box>
          </Box> : 
          <Box>
          <Typography className="catalougeInfoText" style={{ textAlign : "center"}}> No Data Available</Typography>
            </Box>}


            <Dialog open={this.state.addListModal} data-test-id="modalClosebyAwayClick" onClose={() => this.handleCloseAddListModal()}>
          <DialogTitle id="customized-dialog-title">
            <Box style={{ width: 480, display: "flex", justifyContent: "space-between", alignItems: "center"}}>
               <Typography style={{
                 fontFamily: 'Plus Jakarta Sans',
                 fontSize: '18px',
                 fontWeight: 600,
               }}>Add to Listing</Typography> 
                <IconButton data-test-id="modalClosebyIcon" onClick={() => this.handleCloseAddListModal()}>
                   <HighlightOffRoundedIcon />
                </IconButton>
            </Box>
            <Divider />
          </DialogTitle>
          <DialogContent>
              <Box style={{ display: "flex", gap: "25px", margin: '10px 0'}}>
                 <img src={listHomeIcon} alt="icon" />
                 <Box>
                <Typography style={{
                  fontFamily: 'Plus Jakarta Sans',
                  fontSize: '18px',
                  cursor: "pointer",
                  fontWeight: 600,
                }}
                data-test-id={'navigateToAddHome'}
                onClick={() => this.navigateToAddHomeListingPage() }
                >Add to Home</Typography>
                 <Typography style={{
                  fontFamily: 'Plus Jakarta Sans',
                  fontSize: '14px',
                  fontWeight: 500,
                  color: "#4C6081"
                }}>Add a Home to your listings</Typography>
                 </Box>
              </Box>

              <Box style={{ display: "flex", gap: "25px", margin: '30px 0'}}>
                 <img src={listCarIcon} alt="icon" />
                 <Box>
                <Typography style={{
                  fontFamily: 'Plus Jakarta Sans',
                  fontSize: '18px',
                  fontWeight: 600,
                  cursor: "pointer"
                }}
                data-test-id={'navigateToAddCar'}
                onClick={() => this.navigateToAddCarListingPage() }
                >Add to Car</Typography>
                 <Typography style={{
                  fontFamily: 'Plus Jakarta Sans',
                  fontSize: '14px',
                  fontWeight: 500,
                  color: "#4C6081"
                }}>Add a Car to your listings</Typography>
                 </Box>
              </Box>
          </DialogContent>
            </Dialog>



        </ListingTabWrapper>
    )
  }

  EarningsWrapper = () => {
    const {payoutData} = this.state
    return (
      <EarningTabWrapper>
        <Box className="graphMainBox">
        <p className="scheduleText">Schedule</p>
        <Box className="scheduleBox">
          {/* <img src={earningGraph} alt="img" /> */}
          <div id="chart">
          <div id="chart-timeline">
            <Chart
              options={this.state.options}
              series={this.state.series}
              type="area"
              className="chartEarning"
              height={350}
    />
          </div>
        </div>
        </Box>
        </Box>
        <Box style={{width:"100%"}}>
        <p className="scheduleText">Snapshot</p>
        <Box style={{ width: "100%", maxWidth: "1220px" }}>
          <Grid className="tmpDataGridContainer" container alignItems="center">
            {this.tmpData.map((itemVal: any) =>
              <Grid item key={itemVal} sm={3} className="snapshotCard">
                <CircularProgressWithLabel value={68} />
                <Typography className="titleText"> Monthly Rentals</Typography>
              </Grid>
            )}
          </Grid>
        </Box>
        </Box>
        <Box style={{width:"100%"}}>
        <RootBox>
        <StyledTabs
          value={this.state.value}
          data-test-id="handleChange"
          onChange={this.handleChange}
          indicatorColor="primary"
          textColor="primary"
          TabIndicatorProps={{ style: { display: "none" } }}
        >
          <StyledTab label="Payouts" {...this.a11yProps(0)} />
          <StyledTab label="Transactions" {...this.a11yProps(1)} />
          <StyledTab label="Expenses" {...this.a11yProps(2)} disabled/>
        </StyledTabs>
      </RootBox>
      <TabPanelPayouts value={this.state.value} index={0} className="mainPayouts">
            {payoutData?.length>0 ? payoutData?.map((data: DataItem, index: number) => {
              return (
                <Box className="payoutBox" key={index} onClick = {this.handlePayoutModal} data-test-id="payoutModalOpen">
                  <div className="details">
                    <img className="payoutImg" src={data.received === true ? download : reload} />
                    <div className="payoutPrice">
                      <div className="revenue">
                        $ {data.total_revenue}
                      </div>
                      <div className="estimated">
                        {data.estimate_time}
                      </div>
                    </div>
                  </div>
                  <div className="arrowAndTick">
                    <img src={data.received === true ? greenTick : lightTick} className="tickIcon"/>
                    <img src={arrowRight} className="arrowIcon"/>
                  </div>
                </Box>
              )
            }):
            <Typography className="scheduleText">No Data Available</Typography>}
        </TabPanelPayouts>
        <TabPanelPayouts value={this.state.value} index={1} className="mainPayouts">
            {payoutData?.length>0 ? payoutData?.map((data: DataItem, index: number) => {
              return (
                <Box className="payoutBox" key={index} onClick={this.handleTransactionModal} data-test-id="transactionModalOpen">
                  <div className="details">
                    <img className="payoutImg" src={data.received === true ? carPic : homePic} />
                    <div className="payoutPrice">
                      <div className="revenue">
                        $ {data.total_revenue}
                      </div>
                      <div className="estimated">
                          (${data.estimate_time})
                      </div>
                    </div>
                  </div>
                  <div className="arrowAndTick">
                    <img src={data.received === true ? greenTick : lightTick} className="tickIcon"/>
                    <img src={arrowRight} className="arrowIcon"/>
                  </div>
                </Box>
              )
            }):
            <Typography className="scheduleText">No Data Available</Typography>}
        </TabPanelPayouts>
        <TabPanel value={this.state.value} index={2}>
            third
        </TabPanel>
      </Box>

        <CustomDialog data-test-id="payoutDialog" open={this.state.openPayoutModal} onClose={this.closePayoutModal} fullWidth
          PaperProps={{
            className: "setDialogueBox"
          }}
        >
          <DialogTitle className="dialogHeader">
            Payout Breakdown
            <IconButton aria-label="close" onClick={this.closePayoutModal}>
              <HighlightOffIcon style={{ color: "#475467" }} />
            </IconButton>
          </DialogTitle>
          <Divider />
          <DialogContent className="setDialogeContent">
            <Box className="showPayout">
              <Box>
                <Typography className="headerText">Payout</Typography>
                <Typography>Recieved on Fri, Oct 22</Typography>
              </Box>
              <Typography className="showPayment">$4,490</Typography>
            </Box>
            <Box className="payoutItems">
              <Box className="itemDetails">
                <Box>
                  <img src={carUrus} className="payoutImage"/>
                </Box>
                <Box>
                  <Typography className="itemName">
                    Lamborghini Urus <span className="itemYear">(2020)</span>
                  </Typography>
                  <Typography className="itemDuration">
                    3 Days (Nov 3 - Nov 5th)
                  </Typography>
                </Box>
              </Box>
              <Typography className="itemPrice">
                $1500
              </Typography>
            </Box>
            <Divider />
            <Box className="payoutItems">
              <Box className="itemDetails">
                <Box>
                  <img src={house} className="payoutImage"/>
                </Box>
                <Box>
                  <Typography className="itemName">
                   Hollywood Hills 
                  </Typography>
                  <Typography className="itemDuration">
                    3 Days (Nov 3 - Nov 5th)
                  </Typography>
                </Box>
              </Box>
              <Typography className="itemPrice">
                $2300
              </Typography>
            </Box>
            <Divider />
            <Box className="payoutItems">
              <Box className="itemDetails">
                <Box>
                  <img src={carSedan} className="payoutImage"/>
                </Box>
                <Box>
                  <Typography className="itemName">
                    Lamborghini Urus <span className="itemYear">(2020)</span>
                  </Typography>
                  <Typography className="itemDuration">
                    4 Days (Nov 7 - Nov 11th)
                  </Typography>
                </Box>
              </Box>
              <Typography className="itemPrice">
                $4500
              </Typography>
            </Box>
            <Divider />
          </DialogContent>
        </CustomDialog>

        <CustomDialog data-test-id="transactionDialog" open={this.state.openTransactionModal} onClose={this.closeTransactionModal} fullWidth
          PaperProps={{
            className: "setDialogueBox"
          }}
        >
          <DialogTitle className="dialogHeader">
            Transaction Breakdown
            <IconButton aria-label="close" onClick={this.closeTransactionModal}>
              <HighlightOffIcon style={{ color: "#475467" }} />
            </IconButton>
          </DialogTitle>
          <Divider />
          <DialogContent className="setDialogeContent">
            <Box className="showTransaction">
              <Box className="breakdownHeadingBox">
                <Box>
                  <img src={house} className="payoutImage" />
                </Box>
                <Box>
                  <Typography className="transactionBreakdown">
                    Breakdown
                  </Typography>
                  <Typography className="breakdownDesc">
                    Product: Modern WeHo Home
                  </Typography>
                  <Typography className="breakdownDesc">
                    Dates: Nov 2 - Nov 6
                  </Typography>
                </Box>
              </Box>
              <Stepper activeStep={3} orientation="vertical" className="stepperTransaction">
                <Step>
                  <StepLabel>
                    <Box className="stepLabelBox">
                      <Box>
                      <Typography className="stepLabelHeading">
                        Rental Payment
                      </Typography>
                      <Typography className="rentalDuration">
                        $1,400 / Night <span className="rentalTime">(x3 Night)</span>
                      </Typography>
                      </Box>
                      <Box className="rentalPrice">
                        $4,200
                      </Box>
                    </Box>
                  </StepLabel>
                  <StepContent></StepContent>
                </Step>
                <Step>
                  <StepLabel>
                  <Box className="stepLabelBox">
                      <Box>
                      <Typography className="stepLabelHeading">
                        Stay Fees
                      </Typography>
                      <Typography className="rentalDuration">
                        Extended Trip Discount <span className="rentalTime">(5%)</span>
                      </Typography>
                      </Box>
                      <Box className="rentalPriceRed">
                      - $210
                      </Box>
                    </Box>
                  </StepLabel>
                  <StepContent></StepContent>
                </Step>
                <Step>
                  <StepLabel>
                  <Box className="stepLabelBox">
                      <Box>
                      <Typography className="stepLabelHeading">
                        House Tax
                      </Typography>
                      <Typography className="rentalDuration">
                        Refunded by <span className="rentalTime">Oct 28</span>
                      </Typography>
                      </Box>
                      <Box className="rentalPriceRed">
                      - $500
                      </Box>
                    </Box>
                  </StepLabel>
                  <StepContent></StepContent>
                </Step>
              </Stepper>
              <Box className="total">
                <Typography className="totalReceived">
                  Total Received
                </Typography>
                <Typography className="totalPrice">
                    $4,490
                </Typography>
              </Box>
            </Box>
          </DialogContent>
        </CustomDialog>
      </EarningTabWrapper>
    )
  }

  ReviewsWrapper = () => {
    const {reviewData, replySection, showMore, replyValues, showEmojiPicker, seeAllBtn} = this.state
    const showReviewData= seeAllBtn? reviewData.reviews?.data : reviewData.reviews?.data.slice(0,5)
    return (
        <ReviewsTabWrapper>
          {reviewData.reviews.data.length>0? 
          <>
          <Box className="graphBox">
          <Typography className="avgText">{configJSON.avgRating}</Typography>
          <Box className="mainCard">
           <Box className="graphReviewBox">
                  <CircularProgressReview value={Number(reviewData.average_rating)}
                    rating1={reviewData.rating_1}
                    rating2={reviewData.rating_2}
                    rating3={reviewData.rating_3}
                    rating4={reviewData.rating_4}
                    rating5={reviewData.rating_5}
                  />
           <Box className="totalSection">
           <Typography className="totalReview">Total 512 reviews</Typography>
           </Box>
           </Box>
           {showReviewData.map((items: ReviewData, index:number) => {
            return (
              <Box className="reviewCard">
                  <Box className="headerBox">
                    <Box className="profileSection">
                      <img src={items.attributes.profile_img.url} className="profileImg" />
                      <Box>
                        <Typography className="reviewerName">{items.attributes.reviewer_name}</Typography>
                          <Box className="ratingDate">
                        <Rating name="read-only" value={items.attributes.rating} readOnly />
                        <Typography className="reviewerDate">{items.attributes.created_at}</Typography>
                        </Box> 
                      </Box>
                    </Box>
                    <img src={msgIcon} onClick={() => this.handleComment(index)} style={{cursor:"pointer"}} data-test-id="handleComment"/>
                  </Box>
                <Divider />
                <Typography className="showTime">{items.attributes.web_time_ago}</Typography>
                <Divider/>
                <Box className="commentSection">
                <Typography className="comment">{this.showComment(items.attributes.comment, index)}</Typography>
                <Box className="seeMoreBtn">
                {!showMore[index] ? <Button className="seeBtn" data-test-id="seeMoreBtn" onClick={() => this.seeMoreBtn(index)}>{configJSON.seeMoreText}</Button>
                  :
                <Button className="seeBtn" data-test-id="seeLessBtn" onClick={() => this.seeLessBtn(index)}>{configJSON.seeLessText}</Button>
                }<ChevronRightIcon className="forwarIcon"/>
                </Box>
                {replySection[index] && 
                <><TextField 
                className="replyField"
                value={this.state.replyValues[index] || ""}
                onChange={this.handleReply(index)}
                data-test-id="handleReply"
                placeholder={`@${items.attributes.reviewer_name} Thank You so much for your`}
                InputProps={{
                  disableUnderline: true,
                  endAdornment: (
                    <InputAdornment position="end" className="inputIcons" style={{ cursor: "pointer" }}>
                      <div tabIndex={0} onKeyDown={this.handleKeyPress} data-test-id="handleKeyPress">
                        <img src={emojiIcon} data-test-id="setSelectedReplyIndex" onClick={() => this.setSelectedReplyIndex(index)} />
                      </div>
                      <Button disabled={!replyValues[index]}>
                        <img src={replyIcon} onClick={() => this.sendReplyApiCall(items.id, index)} data-test-id="sendReplyApiCall" />
                      </Button>
                    </InputAdornment>
                  ),
                }}
                />
               {showEmojiPicker && this.state.selectedReplyIndex === index && (
              <EmojiPicker onEmojiClick={this.onEmojiClick} data-test-id="onEmojiClick" />
            )}
                </>}
                </Box>
              </Box>
            )
          })}
          </Box>
          </Box>
          <Box className="allBtn">
          {reviewData.reviews?.data.length>5 && (!seeAllBtn ? 
          <Button className="allReviewBtn" data-test-id="handleSeeAllBtn" onClick={this.handleSeeAllBtn}>{configJSON.allReview}<KeyboardArrowDownIcon/></Button>:
          <Button className="allReviewBtn" data-test-id="handleSeeLessBtn" onClick={this.handleSeeLessBtn}>{configJSON.allLessReview}<KeyboardArrowUpIcon/></Button>)
            }
          </Box>
          </> :
          <Box className="noData">
          <Typography className="noDataText"> No Data Available</Typography>
            </Box>
  }
        </ReviewsTabWrapper>
    )
  }

  render() {
    // Customizable Area Start
    
    return (
      <div>
         <AppHeader activeLink="Dashboard" navigation={this.props.navigation}/>

         {localStorage.getItem("userRole") !== "host" ? 
         <BecomeHostWrapper>

              <Typography className="becomeHostText">Become a host today!</Typography>

            <Box style={{ marginTop: 10, display: "flex", flexDirection: "column", alignItems: "flex-start"}}>

            <IconButton disableFocusRipple disableRipple>
                <img src={list1} alt="logo" />
            </IconButton>
            <IconButton disableFocusRipple disableRipple>
                <img src={list2} alt="logo" />
            </IconButton> 
            <IconButton disableFocusRipple disableRipple>
                <img src={list3} alt="logo" />
            </IconButton>
            <IconButton disableFocusRipple disableRipple>
                <img src={list4} alt="logo" />
            </IconButton>
            </Box>

            <Button fullWidth data-test-id="hostBtn" onClick={() => this.becomeHost()} disableFocusRipple disableRipple>
                <img src={hostBtnLogo} alt="logo" />
            </Button>

         </BecomeHostWrapper>
          : 
         <>
          <TabWrapper>
                    <Tabs
                        value={this.state.tabValue}
                        onChange={this.handleTabChange}
                        data-test-id='tabs'
                        indicatorColor="primary"
                        textColor="primary"

                    >
                        <Tab style={{ textTransform: "none" }} label="Overview" />
                        <Tab style={{ textTransform: "none" }} label="Listing" />
                        <Tab style={{ textTransform: "none" }} label="Earning" />
                        <Tab style={{ textTransform: "none" }} label="Reviews" />
                    </Tabs>   
          </TabWrapper>

           {this.handleTabView(this.state.tabValue)}
         </>
         }

         <Footer />
      </div>
    );
    // Customizable Area End
  }
}

export default HostDashboard;


const TabWrapper  = styled(Box) ({
    borderBottom : "1px solid #EAECF0", 
    display: "flex", 
    flexWrap: "wrap", 
    maxWidth: "1220px", 
    margin: "40px auto"
})

const BecomeHostWrapper  = styled(Box) ({
  display: "flex", 
  flexWrap: "wrap", 
  maxWidth: "1220px", 
  margin: "40px auto",
  flexDirection: "column",
  "& .becomeHostText" : {
    fontFamily: 'Plus Jakarta Sans',
    fontSize: '20px',
    fontWeight: 600,
    lineHeight: '25.2px',
  }
})

const OverViewTabWrapper  = styled(Box) ({
    display: "flex", 
    flexWrap: "wrap", 
    maxWidth: "1220px", 
    margin: "auto",
    "& .scheduleText" : {
      fontFamily: 'Plus Jakarta Sans',
      fontSize: '20px',
      fontWeight: 600,
      lineHeight: '30px',
      letterSpacing: '-0.01em',
      textAlign: 'left'
    },
    "& .scheduleBox" : {
      width: '1220px',
      height: '279px',
      padding: '25px 66px 25px 0px',
      gap: '10px',
      borderRadius: '12px',
      opacity: '0px',
      "& .apexcharts-svg.apexcharts-zoomable.hovering-zoom": {
        display: "none !important"
      }
    }, 
    "& .gridBoxes" : {
      height: '272px',
      padding: '27px 24px',
      borderRadius: '8px',
      border: '1px solid transparent', 
      boxShadow: "0 4px 10px rgba(0, 0, 0, 0.5)",
      opacity: '0px',
      margin: "30px 0"
    },
    "& .titleText" : {
      fontFamily: 'Plus Jakarta Sans',
      fontSize: '24px',
      fontWeight: 600,
      lineHeight: '30.24px',
      textAlign: 'center',
      margin: "20px 0",
      minHeight:"60px",
    }, 
    "& .viewBtn" : {
      width: '124px',
      height: '34px',
      padding: '9px 24px',
      gap: '6px',
      borderRadius: '8px',
      border: '0.8px solid #7639BC',
      opacity: '0px',
      color: "#7639BC",
      textTransform: "none",
      margin: "20px 0"
    }
})

const ListingTabWrapper  = styled(Box) ({
    maxWidth: "1220px", 
    margin: "auto",
    "@media(max-width:1260px)": {
      padding: "20px"
    },
    "& .searchMainListingBox": {
      display: "flex",
      justifyContent: "space-between",
      "@media(max-width: 520px)": {
        gap: "15px"
      }
    },
    "& .searchBox" : {
      width: "380px",
      height: 55,
      padding: "10px 16px",
      color: "#475467",
      fontFamily: 'Plus Jakarta Sans',
      fontWeight: 600,
      borderRadius: "12px",
      fontSize: "14px",
    },
    "& .searchBox .MuiOutlinedInput-input" : {
      padding : 10,
    },
    "& .addBtn" : {
      borderRadius: "8px",
      color: "White",
      background: "#0F73EE",
      textTransform: "none",
      width: "95px",
      height: "55px"
    },
    "& .catalougeInfoText" : {
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '16px',
        fontWeight: 600,
        color: "#23395D",
        margin : "15px 0px"

    }, 
    "& .catalougeDataText" :  {
       textAlign: "center",
       padding: '2px 0px 0px', 
       marginBottom: 0, 
       lineHeight: '24px', 
       fontSize: 16, 
       color: "#23395D", 
       fontWeight: 600,
       fontFamily: 'Plus Jakarta Sans', 
    }
})

const EarningTabWrapper  = styled(Box) ({
    display: "flex", 
    flexWrap: "wrap", 
    maxWidth: "1220px", 
    margin: "auto",
    msFlexDirection:"column",
    gap:"32px",
    "& .graphMainBox": {
      width: "100%"
    },
    "& .chartEarning": {
      "& .apexcharts-toolbar": {
        display: "none !important"
      }
    },
    "& .scheduleText" : {
      fontFamily: 'Plus Jakarta Sans',
      fontSize: '20px',
      fontWeight: 600,
      lineHeight: '30px',
      letterSpacing: '-0.01em',
      textAlign: 'left',
      color:"#23395D"
    },
    "& .tmpDataGridContainer": {
      display: 'flex', 
      gap: "24px",
      "@media(max-width:1240px)": {
        padding: "20px",
        justifyContent: "center",
      },
    },
    "& .snapshotCard":{
      background: "linear-gradient(92.64deg, #7A36BA 0%, #3173E1 114.09%)",
      maxWidth:"286px",
      borderRadius:"12px",
      padding:"24px 8px 31px 8px",
      minHeight:"279px",
      display:"flex",
      justifyContent:"center",
      flexDirection:"column",
      gap:"12px",
      alignItems:"center"
    },
    "& .titleText":{
    color:"#FFFFFF",
    fontFamily: 'Plus Jakarta Sans',
      fontSize: '20px',
      fontWeight: 600,
      lineHeight: '20px',
      textAlign: 'center',
    }
})


const ReviewsTabWrapper  = styled(Box) ({
    display: "flex", 
    flexDirection:"column",
    maxWidth: "1220px",
    margin: "auto",
    justifyContent:"center",
    alignItems:'center',
    "& .graphBox":{
      width:"100%",
      "@media (max-width:1220px)":{
        width:"80%",
      },
    },
    "& .avgText":{
      color:"#23395D",
      fontFamily: "Plus Jakarta Sans",
      fontSize:"20px",
      fontWeight: 600,
      lineHeight: "30px",
      paddingBottom:"20px"
    },
    "& .mainCard":{
      display:"grid",
      gridTemplateColumns:"repeat(2,1fr)",
      rowGap:"20px",
      columnGap:"24px",
      width:"100%",
      "@media (max-width:1220px)":{
        gridTemplateColumns:"repeat(1,1fr)",
      },
      "@media (max-width:600px)":{
        gridTemplateColumns:"repeat(1,1fr)",
        display:'flex',
        flexDirection:"column"
      }
    },
    "& .totalReview":{
      color:"#3173E1",
      fontFamily: "Plus Jakarta Sans",
      fontSize:"20px",
      fontWeight: 500,
      lineHeight: "20px",
      paddingBottom:"20px"
    },
    "& .totalSection":{
      backgroundColor:"#F9FAFB",
      padding:"24px 26px"
    },
    "& .graphReviewBox":{
       display:"flex",
       flexDirection:"column",
       gap:"20px"
    },
    "& .noData":{
      textAlign : "center", width:"100%"
    },
    "& .noDataText" : {
      fontFamily: 'Plus Jakarta Sans',
      fontSize: '20px',
      fontWeight: 600,
      lineHeight: '30px',
      letterSpacing: '-0.01em',
      color:"#23395D"
    },
    "& .reviewCard": {
      border: "1px solid #E7E7E7",
      boxShadow: "0px 8px 32px 0px #0000000F",
      borderRadius:"12px",
      padding:"24px 24px 32px 24px",
    },
    "& .profileSection":{
     display:"flex",
     gap:"8px",
     alignItems:"center",
    },
    "& .profileImg": {
      width: "44px",
      height: "44px",
      borderRadius: "50%"
    },
    "& .reviewerName":{
      color:"#23395D",
      fontFamily: "Plus Jakarta Sans",
      fontSize:"16px",
      fontWeight: 600,
      lineHeight: "20px",
      textAlign: "left"
    },
    "& .headerBox":{
    display:'flex',
    justifyContent:"space-between",
    alignItems:"center",
    width:"100%" ,
    paddingBottom:"10px",
   
    },
    "& .reviewerDate":{
      color:"#475467",
      fontFamily: "Plus Jakarta Sans",
      fontSize:"16px",
      fontWeight: 600,
      lineHeight: "20px",
    },
    "& .ratingDate":{
      display:"flex",
      gap:"12px",
      alignItems:"center",
      "@media(max-width:468px)":{
        flexDirection:"column"
      }
    },
    "& .showTime":{
      padding:"14px 0",
      color:"#475467",
      fontFamily: "Plus Jakarta Sans",
      fontSize:"16px",
      fontWeight: 500,
      lineHeight: "20px",
    },
    "& .commentSection":{
      paddingTop:"8px",
      display:"flex",
      flexDirection:"column",
      gap:"12px"
    },
    "& .comment":{
      color:"#475467",
      fontFamily: "Plus Jakarta Sans",
      fontSize:"16px",
      fontWeight: 400,
      lineHeight: "26px",
      wordBreak:"break-all"
    },
    "& .seeBtn":{
      textTransform:"none",
      color:"#3173E1",
      fontFamily: "Plus Jakarta Sans",
      fontSize:"14px",
      fontWeight: 600,
      lineHeight: "20px",
      padding:"0px !important",
      cursor:"pointer"
    },
    "& .seeMoreBtn":{
      display:"flex",
      gap:"6px",
      alignItems:"center"
    },
    "& .forwarIcon":{
      color:"#3173E1",

    },
    "& .replyField":{
      backgroundColor:"#EFF2F8",
      borderRadius:"8px",
      padding:"18px 24px",
    },
    "& .inputIcons":{
      display:"flex",
      alignItems:"center",
    },
    "& .allReviewBtn":{
      textTransform:"none",
      color:"#475467",
      fontFamily: "Plus Jakarta Sans",
      fontSize:"14px",
      fontWeight: 600,
      lineHeight: "20px",
      width:"100%", 
      textAlign:"center",
      border:"1px solid #D0D5DD",
      padding:"12px 0px",
      borderRadius:"30px",
      display:"flex",
      alignItems:"center",
      gap:'10px'
    },
    "& .allBtn":{
      paddingTop:"112px",
      width:"100%",
      cursor:"pointer",
      "@media (max-width:1220px)":{
        width:"80%",
      },
    }

})

const SliderWrapper = styled(Box)({
  '& button': {
    height: '5px !important',
    width: '5px !important',
    background: 'lightgray !important',
    border: 'none !important',
  }
})
const RootBox = styled(Box)({
  display: "flex",
  justifyContent: "center",
  marginTop: "20px",
  flexDirection:"column",
});

const StyledTabs = styled(Tabs)({
  borderRadius: "50px",
  backgroundColor: "#F2F4F7",
  border: "1px solid #D0D5DD",
  width:"354px"
});

const StyledTab = styled(Tab)({
  textTransform: "capitalize",
  fontWeight: 600,
  fontSize: "14px",
  minWidth: 120,
  fontFamily:"Plus Jakarta Sans",
  lineHeight:"20px",
  borderRight:"1px solid #D0D5DD",
  cursor:"pointer",
  "&.Mui-selected": {
    backgroundColor: "#3173E1",
    color: "white",
  },
});

const TabPanelPayouts = styled(TabPanel)({
  marginTop: "18px",
  "& .payoutBox": {
    borderTop:"1px solid #EAECF0", 
    borderBottom:"1px solid #EAECF0", 
    width:'100%', 
    display: "flex", 
    alignItems: "center", 
    padding: "16px", 
    gap: "10px",
    justifyContent: "space-between",
    cursor:"pointer"
  },
  "& .payoutImg": {
    height: "60px",
    width: "60px"
  },
  "& .payoutPrice" :{
    display:"flex", 
    flexDirection: "column"
  },
  "& .revenue": {
    fontFamily: "Plus Jakarta Sans",
    fontWeight: 600,
    fontSize: "26px",
    color: "#3173E1"
  },
  "& .estimated": {
    fontFamily: "Plus Jakarta Sans",
    fontWeight: 400,
    fontSize: "16px",
    color: "#5B6A82"
  },
  "& .arrowAndTick": {
    display: "flex",
    gap: "10px"
  },
  "& .tickIcon": {
    height: "24px",
    width: "24px"
  },
  "& .arrowIcon": {
    height: "24px",
    width: "24px"
  },
  "& .details": {
    display: "flex",
    gap: "10px",
    alignItems: "center"
  }
})
const CustomDialog = styled(Dialog)({
"& .dialogHeader":{
  "& .MuiTypography-root":{
  display:"flex",
  justifyContent:"space-between",
  width:"100%",
  alignItems:"center",
  fontFamily: "Plus Jakarta Sans",
fontSize: "18px",
fontWeight: 600,
lineHeight: "28px"
}
},
"& .setDialogueBox":{
  minWidth:"482px",
  borderRadius:"16px",
  padding:"0px 20px 32px 20px"
},
"& .setDialogeContent":{
  border:"1px solid #E7E7E7",
  borderRadius:"12px",
    padding:"0 !important",
    marginTop:"24px",
    overflowX: "hidden"
},
"& .showPayout":{
  backgroundColor:"#F4F7FC",
  height:"84px",
  width:"100%", 
  display:"flex",
  justifyContent:"space-between",
  alignItems:'center',
  maxWidth: "95%",
  padding: "20px 16px 20px 16px"
},
"& .showPayment":{
  fontFamily: "Plus Jakarta Sans",
fontSize: "22px",
fontWeight: 600,
lineHeight: "27.72px",
textAlign: "right",
color:"#3173E1"
},
"& .headerText":{
  fontFamily: "Plus Jakarta Sans",
  fontSize: "18px",
  fontWeight: 600,
  lineHeight: "22.68px",
  color:"#23395D",
},
"& .payoutItems": {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  padding: "24px 14px 14px 14px",
},
"& .itemDetails": {
  display: "flex",
  gap: "10px"
},
"& .itemName": {
  fontFamily: "Plus Jakarta Sans",
  fontSize: "18px",
  fontWeight: 600,
  lineHeight: "22.68px",
  color:"#23395D",
},
"& .itemYear": {
  fontFamily: "Plus Jakarta Sans",
  fontSize: "14px",
  fontWeight: 600,
  lineHeight: "17.64px",
  color:"#23395D",
},
"& .payoutImage": {
  height: "62px",
  width: "77px"
},
"& .itemDuration": {
  fontFamily: "Plus Jakarta Sans",
  fontSize: "14px",
  fontWeight: 500,
  lineHeight: "17.64px",
  color:"#596C8A",
},
"& .itemPrice": {
  fontFamily: "Plus Jakarta Sans",
  fontSize: "16px",
  fontWeight: 600,
  lineHeight: "20.16px",
  color:"#3173E1",
},
"& .breakdownHeadingBox": {
  display: "flex",
  gap: "16px",
  marginBottom: "16px",
  alignItems: "center",
},
"& .transactionBreakdown": {
  fontFamily: "Plus Jakarta Sans",
  fontSize: "16px",
  fontWeight: 600,
  lineHeight: "20.16px",
  color:"#23395D",
},
"& .breakdownDesc": {
  fontFamily: "Plus Jakarta Sans",
  fontSize: "12px",
  fontWeight: 500,
  lineHeight: "15.12px",
  color:"#23395D",
},
"& .showTransaction":{
  width:"100%", 
  maxWidth: "95%",
  padding: "20px 16px 20px 16px"
},
"& .stepperTransaction": {
  backgroundColor:"#F5F5F6",
  borderRadius: "10px",
  marginBottom: "16px",
  "& .MuiStepConnector-lineVertical": {
    minHeight: "50px !important"
  },
  "& .MuiStepConnector-line": {
    borderColor: "#3173E1 !important"
  },
  "& .MuiStepContent-root": {
    marginTop: "0px !important"
  },
  "& .MuiStepConnector-vertical": {
    padding: "0px !important"
  }
},
"& .stepLabelBox": {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between"
},
"& .stepLabelHeading": {
  fontFamily: "Plus Jakarta Sans",
  fontSize: "16px",
  fontWeight: 500,
  lineHeight: "20.16px",
  color:"#23395D",
},
"& .rentalDuration": {
  fontFamily: "Plus Jakarta Sans",
  fontSize: "12px",
  fontWeight: 500,
  lineHeight: "15.12px",
  color:"#B0B0B0",
},
"& .rentalTime": {
  fontFamily: "Plus Jakarta Sans",
  fontSize: "12px",
  fontWeight: 500,
  lineHeight: "15.12px",
  color:"#3173E1",
},
"& .rentalPrice": {
  fontFamily: "Montserrat !important",
  fontSize: "16px",
  fontWeight: 600,
  lineHeight: "19.5px",
  color:"#3173E1",
},
"& .rentalPriceRed": {
  fontFamily: "Montserrat",
  fontSize: "16px",
  fontWeight: 600,
  lineHeight: "19.5px",
  color:"#DC3D28",
},
"& .total": {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center"
},
"& .totalReceived": {
  fontFamily: "Plus Jakarta Sans",
  fontSize: "16px",
  fontWeight: 500,
  lineHeight: "20.16px",
  color:"#23395D",
},
"& .totalPrice": {
  fontFamily: "Plus Jakarta Sans",
  fontSize: "22px",
  fontWeight: 600,
  lineHeight: "27.72px",
  color:"#3173E1",
}
})
// Customizable Area End