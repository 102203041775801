// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";

import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { apiCalling } from '../../../components/src/asset'
import { toast } from "react-toastify"

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface NotificationStatus {
  text_notifications: boolean;
  promotions_and_announcements: boolean;
  email_notifications: boolean;
  weekly_notifications:boolean
  latest_offer: boolean
}


interface S {
  // Customizable Area Start  
  notificationStatus : NotificationStatus,
  loading : boolean
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class NotificationsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getNotificationStatusApiCallId: string = ""
  updateNotificationStatusApiCallId : string = ""
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];
    this.state = {
      notificationStatus : {
        text_notifications : false,
        email_notifications: false,
        promotions_and_announcements: false,
        weekly_notifications: false,
        latest_offer: false
      },
      loading : true,
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      switch(apiRequestCallId) {
        case this.getNotificationStatusApiCallId : 
          return this.responseForNotificationStatus(responseJson)
        case this.updateNotificationStatusApiCallId : 
          return this.responseForUpdateNotificationStatus(responseJson)
        default :
          break;
      }
      
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount(){
    this.getNotificationStatus()
      
  }

  responseForNotificationStatus = (responseJson : any) => {
    if(responseJson && ! responseJson.error) {
      const notificationStatus = {
        text_notifications : responseJson.text_notifications,
        email_notifications: responseJson.email_notifications,
        promotions_and_announcements: responseJson.promotions_and_announcements,
        weekly_notifications: responseJson.weekly_notifications,
        latest_offer: responseJson.latest_offer
        }
       this.setState({ notificationStatus : notificationStatus, loading: false})
    } else {
      this.setState({ loading: false})      
    }
  }

  responseForUpdateNotificationStatus = (responseJson : any) => {
    if(responseJson && responseJson.message) {
       toast.success("Notification Settings updated succesfully")
       this.getNotificationStatus()
    } else {
      this.setState({ loading: false})
      toast.success("Something went wrong. Try agin later")
    }
  }

  getNotificationStatus = () => {
    const header = {
      'token': localStorage.getItem("userToken")
    }

    this.getNotificationStatusApiCallId = apiCalling({
      header: JSON.stringify(header),
      method: configJSON.apiMethodTypeGet,
      endPoint: configJSON.getNotificationStatusApiEndPoint
    })
  }

  updateNotificationStatus = () => {
    this.setState({ loading : true})


    const header = {
      'token': localStorage.getItem("userToken")
    }

    const formData = new FormData()

    formData.append('notification[email_notifications]',this.handleStringValue(this.state.notificationStatus.email_notifications))
    formData.append('notification[text_notifications]',this.handleStringValue(this.state.notificationStatus.text_notifications))
    formData.append('notification[promotions_and_announcements]',this.handleStringValue(this.state.notificationStatus.promotions_and_announcements))
    formData.append('notification[weekly_notifications]',this.handleStringValue(this.state.notificationStatus.weekly_notifications))
    formData.append('notification[latest_offer]',this.handleStringValue(this.state.notificationStatus.latest_offer))

    this.updateNotificationStatusApiCallId = apiCalling({
      header: JSON.stringify(header),
      method: configJSON.apiMethodTypePut,
      endPoint: configJSON.updateNotificationStatusApiEndPoint,
      body: formData
    })
  }

  handleChangeForNotification = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ notificationStatus : { ...this.state.notificationStatus,  [event.target.name] : event.target.checked}})

  }

  handleStringValue = ( newValue : boolean) => {
    return newValue ? "true" : "false"
  }


  // Customizable Area End
}

// Customizable Area End