// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";

import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { apiCalling, truthyValue } from "../../../components/src/asset";
import { toast } from "react-toastify";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface SectionAttributes {
  title: string;
  description: string;
  id? :string
}

interface SubInstructionAttributes {
  name: string;
  sections_attributes: SectionAttributes[];
  id? : string;
}
interface BodyArray {
  id? : string | number
  name: string;
  sub_instructions_attributes: SubInstructionAttributes[];
}

interface InstructionData {
  instructionID: number,
  cardTitle: string
}

interface MainInstructionText {
  housePoliciyText : string,
  checkInInstructionText : string
}

interface EditKeys {
  checkIn : boolean,
  location: boolean,
  parking : boolean,
  accessMethod : boolean,
  wifi: boolean,
  localRecomanded: boolean,
  contacts: boolean
}

interface S {
  // Customizable Area Start  
  catalugeId: number | string
  loader : boolean,
  mainInstructionId: string
  editItems : EditKeys,
  checkInInfo : InstructionData[]
  checkInArray : SubInstructionAttributes[]
  locationInfo : InstructionData[]
  locationArray : SubInstructionAttributes[]
  accessMethodInfo : InstructionData[]
  accessMethodArray : SubInstructionAttributes[]
  parkingInfo : InstructionData[]
  parkingArray : SubInstructionAttributes[]
  wifiInfo : InstructionData[]
  wifiArray : SubInstructionAttributes[]
  recommandedInfo : InstructionData[]
  recommandedArray : SubInstructionAttributes[],
  contactsInfo : InstructionData[]
  contactsArray : SubInstructionAttributes[]
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class InstuctionPageController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getBookedSlotApiCallId: string = ""
  getCheckInINstructionApiCallId: string =""
  saveCheckInInstructionApiCallId: string = ""
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];
    this.state = {
      catalugeId: "",
      loader: true,
      mainInstructionId: "",
      editItems : {
        checkIn: false,
        location: false,
        parking: false,
        accessMethod: false,
        wifi: false,
        localRecomanded: false,
        contacts: false
      },
      checkInInfo: [],
      checkInArray: this.checkInArray,
      locationInfo:  [],
      locationArray: this.locationArray,
      accessMethodInfo:  [],
      accessMethodArray: this.accessMetodArray,
      parkingArray: this.parkingArray,
      parkingInfo:  [],
      wifiArray: this.wifiArray,
      wifiInfo:  [],
      recommandedInfo: [],
      recommandedArray: this.recomandedArray,
      contactsInfo: [],
      contactsArray: this.contactsArray
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      switch(apiRequestCallId) {
        case this.getBookedSlotApiCallId:
            return this.handleResponseForGetBookedSlotApi(responseJson)
        case this.getCheckInINstructionApiCallId: 
            return this.handleGetInstructionResponse(responseJson)
        case this.saveCheckInInstructionApiCallId: 
            return this.handleSaveInstructionResponse(responseJson)
      }

    }
    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidMount(): Promise<void> {
    const bookedSlotId = this.props.navigation.getParam("reservationId")
      this.getBookedSlot(bookedSlotId)
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined): void {
    if(prevState.catalugeId !== this.state.catalugeId) {
       this.getInstructionsData()
    }
  }

  handleResponseForGetBookedSlotApi = (responseJson: any) => {
    if (responseJson && responseJson.catalogue && responseJson.catalogue.data) {
      const catalougeId = truthyValue(responseJson.catalogue.data.attributes.catalogue_id)
      this.setState({ catalugeId : catalougeId})
    } 
  }

  handleSaveInstructionResponse = (responseJson: any) => {
    if (responseJson && responseJson.data && responseJson.data.length > 0) {
      this.getInstructionsData()
      toast.success("Instruction updated successfully")
      this.setState({ editItems: this.allSetToFalse() })
    } else {
      let errMsg = "Something went wrong"
      if (responseJson && responseJson.errors) {
        errMsg = responseJson.errors[0]
      }
      toast.error(errMsg)
      this.setState({
        loader: false
      })
    }
  }

  handleGetInstructionResponse = (responseJson: any) => {
    if (responseJson && responseJson.data && responseJson.data.length > 0) {
      const checkInInstructionId = this.findInstructionIdFromResponse(responseJson.data, this.mainInstruction.checkInInstructionText)

      const checkInArray = this.handleModifiedArrFromResponse(responseJson.data, this.cardHeaderText.checkInText)
      const locationArray = this.handleModifiedArrFromResponse(responseJson.data, this.cardHeaderText.locationText)
      const accMethodArray = this.handleModifiedArrFromResponse(responseJson.data, this.cardHeaderText.accessMethodText)
      const parkingArray = this.handleModifiedArrFromResponse(responseJson.data, this.cardHeaderText.parkingText)
      const wifiArray = this.handleModifiedArrFromResponse(responseJson.data, this.cardHeaderText.wifiText)
      const contactsArray = this.handleModifiedArrFromResponse(responseJson.data, this.cardHeaderText.contactsText)
      const recomamdsArray = this.handleModifiedArrFromResponse(responseJson.data, this.cardHeaderText.recommandedText)


      const filteredCheckInInfo = checkInArray.map((itemVal: any) => {
        return {
          instructionID: itemVal.id,
          cardTitle: `${truthyValue(itemVal.attributes.title)} : ${truthyValue(itemVal.attributes.description)}`
        }
      })

      const checkInInstructionArray: SubInstructionAttributes[] = [
        {
          "id": this.handleFindSubInstructionId(responseJson.data, checkInInstructionId, this.cardHeaderText.checkInText),
          "name": this.cardHeaderText.checkInText,
          "sections_attributes": checkInArray.map((item: any) => {
            return {
              id: item.id,
              title: truthyValue(item.attributes.title),
              description: truthyValue(item.attributes.description),
            }
          })
        },
      ]

      const filteredLocationInfo = locationArray.map((itemVal: any) => {
        return {
          instructionID: itemVal.id,
          cardTitle: `${truthyValue(itemVal.attributes.title)} : ${truthyValue(itemVal.attributes.description)}`
        }
      })

      const locationinstructionArray: SubInstructionAttributes[] = [
        {
          "id": this.handleFindSubInstructionId(responseJson.data, checkInInstructionId, this.cardHeaderText.locationText),
          "name": this.cardHeaderText.locationText,
          "sections_attributes": locationArray.map((item: any) => {
            return {
              id: item.id,
              title: truthyValue(item.attributes.title),
              description: truthyValue(item.attributes.description),
            }
          })
        },
      ]

      const filteredAccessMethodInfo = accMethodArray.map((itemVal: any) => {
        return {
          instructionID: itemVal.id,
          cardTitle: `${truthyValue(itemVal.attributes.title)} : ${truthyValue(itemVal.attributes.description)}`
        }
      })

      const accMethodInstructionArray: SubInstructionAttributes[] = [
        {
          "id": this.handleFindSubInstructionId(responseJson.data, checkInInstructionId, this.cardHeaderText.accessMethodText),
          "name": this.cardHeaderText.accessMethodText,
          "sections_attributes": accMethodArray.map((item: any) => {
            return {
              id: item.id,
              title: truthyValue(item.attributes.title),
              description: truthyValue(item.attributes.description),
            }
          })
        },
      ]

      const filteredParkingInfo = parkingArray.map((itemVal: any) => {
        return {
          instructionID: itemVal.id,
          cardTitle: `${truthyValue(itemVal.attributes.title)} : ${truthyValue(itemVal.attributes.description)}`
        }
      })

      const parkingInstructionArray: SubInstructionAttributes[] = [
        {
          "id": this.handleFindSubInstructionId(responseJson.data, checkInInstructionId, this.cardHeaderText.parkingText),
          "name": this.cardHeaderText.parkingText,
          "sections_attributes": parkingArray.map((item: any) => {
            return {
              id: item.id,
              title: truthyValue(item.attributes.title),
              description: truthyValue(item.attributes.description),
            }
          })
        },
      ]

      const filteredWifiInfo = wifiArray.map((itemVal: any) => {
        return {
          instructionID: itemVal.id,
          cardTitle: `${truthyValue(itemVal.attributes.title)} : ${truthyValue(itemVal.attributes.description)}`
        }
      })

      const wifiInstructionArray: SubInstructionAttributes[] = [
        {
          "id": this.handleFindSubInstructionId(responseJson.data, checkInInstructionId, this.cardHeaderText.wifiText),
          "name": this.cardHeaderText.wifiText,
          "sections_attributes": wifiArray.map((item: any) => {
            return {
              id: item.id,
              title: truthyValue(item.attributes.title),
              description: truthyValue(item.attributes.description),
            }
          })
        },
      ]

      const filteredContactsInfo = contactsArray.map((itemVal: any) => {
        return {
          instructionID: itemVal.id,
          cardTitle: `${truthyValue(itemVal.attributes.title)} : ${truthyValue(itemVal.attributes.description)}`
        }
      })

      const contactsInstructionArray: SubInstructionAttributes[] = [
        {
          "id": this.handleFindSubInstructionId(responseJson.data, checkInInstructionId, this.cardHeaderText.contactsText),
          "name": this.cardHeaderText.contactsText,
          "sections_attributes": contactsArray.map((item: any) => {
            return {
              id: item.id,
              title: truthyValue(item.attributes.title),
              description: truthyValue(item.attributes.description),
            }
          })
        },
      ]

      const filteredRecomandedInfo = recomamdsArray.map((itemVal: any) => {
        return {
          instructionID: itemVal.id,
          cardTitle: `${truthyValue(itemVal.attributes.title)} : ${truthyValue(itemVal.attributes.description)}`
        }
      })

      const recomandedInstructionArray: SubInstructionAttributes[] = [
        {
          "id": this.handleFindSubInstructionId(responseJson.data, checkInInstructionId, this.cardHeaderText.recommandedText),
          "name": this.cardHeaderText.recommandedText,
          "sections_attributes": recomamdsArray.map((item: any) => {
            return {
              id: item.id,
              title: truthyValue(item.attributes.title),
              description: truthyValue(item.attributes.description),
            }
          })
        },
      ]

      this.setState({
         mainInstructionId: checkInInstructionId,
         checkInInfo : filteredCheckInInfo,
         locationInfo: filteredLocationInfo,
         accessMethodInfo: filteredAccessMethodInfo,
         parkingInfo: filteredParkingInfo,
         wifiInfo: filteredWifiInfo,
         contactsInfo: filteredContactsInfo,
         recommandedInfo: filteredRecomandedInfo,
         checkInArray : this.stateValues(checkInInstructionArray,this.cardHeaderText.checkInText),
         locationArray: this.stateValues(locationinstructionArray,this.cardHeaderText.locationText),
         accessMethodArray: this.stateValues(accMethodInstructionArray,this.cardHeaderText.accessMethodText),
         parkingArray: this.stateValues(parkingInstructionArray,this.cardHeaderText.parkingText),
         wifiArray: this.stateValues(wifiInstructionArray,this.cardHeaderText.wifiText),
         contactsArray: this.stateValues(contactsInstructionArray,this.cardHeaderText.contactsText),
         recommandedArray: this.stateValues(recomandedInstructionArray,this.cardHeaderText.recommandedText),
      })

      
    } else {
      this.setState({
        loader: false
      })
    }
  }

  getBookedSlot = (id : number) => {
    const header = {
      "token": localStorage.getItem("userToken")
    };
    
    this.getBookedSlotApiCallId = apiCalling({
      header: JSON.stringify(header),
      endPoint: `${configJSON.getReservationInfoApiEndPoint}/${id}`,
      method: configJSON.apiMethodTypeGet
    })
  }

  saveInstructions = (bodyArray : BodyArray[]) => {

    if (!this.checkAllValidation(bodyArray)) {
      toast.error("Please fill all details")
      return false
    }

    this.setState({ loader : true})
    const header = {
        "token": localStorage.getItem("userToken"),
        "Content-Type" : "application/json"
      };

      const body  = {
        instructions_attributes : bodyArray
      }
      
      this.saveCheckInInstructionApiCallId = apiCalling({
        header: JSON.stringify(header),
        endPoint: configJSON.instructionApiEndpoint(this.state.catalugeId),
        method: configJSON.apiMethodTypePost,
        body: JSON.stringify(body)
      })
  }

  getInstructionsData = () => {
    const header = {
        "token": localStorage.getItem("userToken")
      };
      
      this.getCheckInINstructionApiCallId = apiCalling({
        header: JSON.stringify(header),
        method: configJSON.apiMethodTypeGet,
        endPoint: configJSON.instructionApiEndpoint(this.state.catalugeId),
      })
  }

  checkAllValidation = (arrBody : BodyArray[]) => {
    const allFilled = arrBody[0].sub_instructions_attributes.every(subInstruction =>
      subInstruction.sections_attributes.every(section => section.description !== "")
  );

  return allFilled
  }

  mainInstruction = {
    checkInInstructionText: "Check-in Instructions",
  }

  cardHeaderText = {
    checkInText : "Check-In Date and Time",
    locationText : "Location Details",
    accessMethodText: "Access Method",
    parkingText: "Parking Instructions",
    wifiText: "Wi-Fi Connection Details",
    recommandedText: "Local Recommendations",
    contactsText: "Emergency Contacts"
  }
  
  handleEdit = (keyName: keyof EditKeys) => {
    this.setState({
      editItems: {
        ...this.state.editItems,
        [keyName]: true
      }
    })
  }

  allSetToFalse = () => {
    const resObj : EditKeys = {
      checkIn :false,
      location: false,
      accessMethod: false,
      parking: false,
      wifi: false,
      localRecomanded: false,
      contacts: false
    } 

     return resObj
  }

  stateValues = (array : SubInstructionAttributes[], value : string) => {

    let finalArr: SubInstructionAttributes[] = []
    if (array[0].sections_attributes.length > 0) {
        return array
    } else {
        switch (value) {
            case this.cardHeaderText.checkInText:
              finalArr.push(this.checkInArray[0])
              break;
            case this.cardHeaderText.locationText:
              finalArr.push(this.locationArray[0])
              break;
            case this.cardHeaderText.accessMethodText:
              finalArr.push(this.accessMetodArray[0])
              break;
            case this.cardHeaderText.parkingText:
              finalArr.push(this.parkingArray[0])
              break;
            case this.cardHeaderText.wifiText:
              finalArr.push(this.wifiArray[0])
              break;
            case this.cardHeaderText.contactsText:
              finalArr.push(this.contactsArray[0])
              break;
            case this.cardHeaderText.recommandedText:
              finalArr.push(this.recomandedArray[0])
              break;
            
        }

        return finalArr
    }
}

  handleModifiedArrFromResponse = (array: any, filterVal: string) => {
    const updatedArr = array
      .flatMap((mainItem: any) => mainItem.attributes.sub_instructions.data)
      .filter((subItem: any) => subItem.attributes.name === filterVal)
      .flatMap((childItem: any) => childItem.attributes.sections.data);

    return updatedArr
  }

  findInstructionIdFromResponse = (array: any, instructionName: string) => {
    const actualId = array.filter((attr: any) => attr.attributes.name === instructionName).map((item: any) => item.id)
    const mainId = actualId.length > 0 ? truthyValue(actualId.toString()) : ""
    return mainId
  }

  handleFindSubInstructionId = (array : any[],instructionId : string, subInstructionName : string) => {
    const subId = array.filter((instruction) => instruction.id === instructionId)
    .flatMap(instruction => instruction.attributes.sub_instructions.data)
    .map(subInstruction => subInstruction)
    .filter(item => item.attributes.name === subInstructionName)
    .map(item => item.id)
      const mainId = subId.length > 0 ? truthyValue(subId.toString()) : ""
      return mainId
  }


  checkInArray: SubInstructionAttributes[] = [
    {
      "name": this.cardHeaderText.checkInText,
      "sections_attributes": [
        {
          title: "Check-In Time",
          description: "",
        },
        {
          title: "Check-out Time",
          description: "",
        }
      ]
    },
  ]

  locationArray: SubInstructionAttributes[] = [
    {
      "name": this.cardHeaderText.locationText,
      "sections_attributes": [
        {
          title: "Property Address",
          description: "",
        },
        {
          title: "Additional Instructions",
          description: "",
        }
      ]
    },
  ]

  accessMetodArray: SubInstructionAttributes[] = [
    {
      "name": this.cardHeaderText.accessMethodText,
      "sections_attributes": [
        {
          title: "Access Method",
          description: "",
        },
        {
          title: "Main Entrance",
          description: "",
        }
      ]
    },
  ]

  parkingArray: SubInstructionAttributes[] = [
    {
      "name": this.cardHeaderText.parkingText,
      "sections_attributes": [
        {
          title: "Parking",
          description: "",
        },
      ]
    },
  ]

  wifiArray: SubInstructionAttributes[] = [
    {
      "name": this.cardHeaderText.wifiText,
      "sections_attributes": [
        {
          title: "Wi-Fi Network",
          description: "",
        },
        {
          title: "Wi-Fi Password",
          description: "",
        }
      ]
    },
  ]

  contactsArray: SubInstructionAttributes[] = [
    {
      "name": this.cardHeaderText.contactsText,
      "sections_attributes": [
        {
          title: "Emergency Contacts",
          description: "",
        },
      ]
    },
  ]

  recomandedArray: SubInstructionAttributes[] = [
    {
      "name": this.cardHeaderText.recommandedText,
      "sections_attributes": [
        {
          title: "Restaurants",
          description: "",
        },
        {
          title: "Attractions",
          description: "",
        }
      ]
    },
  ]

  handleCheckInInputChange = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const { value } = event.target;

    this.setState(prevState => {
      const updatedCheckedArr = [...prevState.checkInArray];
      updatedCheckedArr[0].sections_attributes[index].description = value;
      return { checkInArray: updatedCheckedArr };
    });
  };

  applyCheckInSubInstructions = () => {

    const checkedInPass: BodyArray[] = [
      {
        name: this.mainInstruction.checkInInstructionText,
        id: this.state.mainInstructionId !== "" ? this.state.mainInstructionId : "",
        sub_instructions_attributes: this.state.checkInArray
      }
    ]

    this.saveInstructions(checkedInPass)
  }

  handleLocationInputChange = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const { value } = event.target;

    this.setState(prevState => {
      const updatedLocationArr = [...prevState.locationArray];
      updatedLocationArr[0].sections_attributes[index].description = value;
      return { locationArray: updatedLocationArr };
    });
  };

  applyLocationubInstructions = () => {

    const locationPass: BodyArray[] = [
      {
        name: this.mainInstruction.checkInInstructionText,
        id: this.state.mainInstructionId !== "" ? this.state.mainInstructionId : "",
        sub_instructions_attributes: this.state.locationArray
      }
    ]

    this.saveInstructions(locationPass)
  }

  handleAccMethodInputChange = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const { value } = event.target;

    this.setState(prevState => {
      const updatedAccMethodArr = [...prevState.accessMethodArray];
      updatedAccMethodArr[0].sections_attributes[index].description = value;
      return { accessMethodArray: updatedAccMethodArr };
    });
  };

  applyAccMethodSubInstructions = () => {

    const accMethodPass: BodyArray[] = [
      {
        name: this.mainInstruction.checkInInstructionText,
        id: this.state.mainInstructionId !== "" ? this.state.mainInstructionId : "",
        sub_instructions_attributes: this.state.accessMethodArray
      }
    ]

    this.saveInstructions(accMethodPass)
  }

  handleParkingInputChange = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const { value } = event.target;

    this.setState(prevState => {
      const updatedParkingArr = [...prevState.parkingArray];
      updatedParkingArr[0].sections_attributes[index].description = value;
      return { parkingArray: updatedParkingArr };
    });
  };

  applyParkingSubInstructions = () => {

    const parkingPass: BodyArray[] = [
      {
        name: this.mainInstruction.checkInInstructionText,
        id: this.state.mainInstructionId !== "" ? this.state.mainInstructionId : "",
        sub_instructions_attributes: this.state.parkingArray
      }
    ]

    this.saveInstructions(parkingPass)
  }

  handleWifiInputChange = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const { value } = event.target;

    this.setState(prevState => {
      const updatedWifiArr = [...prevState.wifiArray];
      updatedWifiArr[0].sections_attributes[index].description = value;
      return { wifiArray: updatedWifiArr };
    });
  };

  applyWifiSubInstructions = () => {

    const accMethodPass: BodyArray[] = [
      {
        name: this.mainInstruction.checkInInstructionText,
        id: this.state.mainInstructionId !== "" ? this.state.mainInstructionId : "",
        sub_instructions_attributes: this.state.wifiArray
      }
    ]

    this.saveInstructions(accMethodPass)
  }

  handleContatcsInputChange = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const { value } = event.target;

    this.setState(prevState => {
      const updatedContactsArr = [...prevState.contactsArray];
      updatedContactsArr[0].sections_attributes[index].description = value;
      return { contactsArray : updatedContactsArr };
    });
  };

  applyContatcsSubInstructions = () => {

    const contatcsPass: BodyArray[] = [
      {
        name: this.mainInstruction.checkInInstructionText,
        id: this.state.mainInstructionId !== "" ? this.state.mainInstructionId : "",
        sub_instructions_attributes: this.state.contactsArray
      }
    ]

    this.saveInstructions(contatcsPass)
  }

  handleRecomandedInputChange = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const { value } = event.target;

    this.setState(prevState => {
      const updatedrecomandedArr = [...prevState.recommandedArray];
      updatedrecomandedArr[0].sections_attributes[index].description = value;
      return { recommandedArray: updatedrecomandedArr };
    });
  };

  applyRecomandedSubInstructions = () => {

    const recomandedPass: BodyArray[] = [
      {
        name: this.mainInstruction.checkInInstructionText,
        id: this.state.mainInstructionId !== "" ? this.state.mainInstructionId : "",
        sub_instructions_attributes: this.state.recommandedArray
      }
    ]

    this.saveInstructions(recomandedPass)
  }

  


  // Customizable Area End
}

// Customizable Area End