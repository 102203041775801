import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import AsyncStorage from "../../../framework/src/StorageProvider.web";
const calParse = require("cal-parser");
import { v4 } from "uuid";
import DocumentPicker from "react-native-document-picker";
import { EventContentArg } from "@fullcalendar/react";
// Customizable Area End

export const configJSON = require("./config");

// Customizable Area Start
interface IEvents {
  id: string;
  title: string;
  start: Date;
  end?: Date;
  description: string;
  location: string;
}

interface IParseEvent {
  summary: { value: string };
  dtstart: { value: string };
  dtend: { value: string };
  description: { value: string };
  location: { value: string };
}

interface IGenerateEvent {
  [date: string]: {
    color: string;
    startingDay: boolean;
    endingDay: true;
  };
}
// Customizable Area End

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  selectedEvent: any;
  events: IEvents[];
  selectedDay: string | null;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ICalendarSupportController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      selectedEvent: null,
      events: [],
      selectedDay: null,
      token: "",
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token: string = message.getData(
        getName(MessageEnum.SessionResponseToken)
      );
      runEngine.debugLog("TOKEN", token);
      if (token) {
        this.setState({ token });
      }
    }
    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.getToken();

    let eventLists = !this.isPlatformWeb()
      ? await AsyncStorage.get(configJSON.CALENDAR_EVENTS_STORAGE_KEY)
      : await localStorage.get(configJSON.CALENDAR_EVENTS_STORAGE_KEY);
    this.setState({ events: eventLists ? JSON.parse(eventLists) : [] });
    // Customizable Area End
  }

  // Customizable Area Start
  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  storeEvent = async () => {
    !this.isPlatformWeb()
      ? await AsyncStorage.set(
          configJSON.CALENDAR_EVENTS_STORAGE_KEY,
          JSON.stringify(this.state.events)
        )
      : await localStorage.set(
          configJSON.CALENDAR_EVENTS_STORAGE_KEY,
          JSON.stringify(this.state.events)
        );
  };

  closeModalHandle = () => {
    this.setState({ selectedEvent: null });
  };

  getDate = (date: Date) => {
    return new Date(date).toLocaleString(undefined, {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      weekday: "long",
      hour: "2-digit",
      hour12: false,
      minute: "2-digit",
      second: "2-digit",
    });
  };

  formatDate = (date: Date = new Date()) => {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  };

  readICSFile = async (url: string) => {
    const icsFile = await fetch(url);
    const icsFileText = await icsFile.text();
    const parsed = calParse.parseString(icsFileText);

    let tempEvents: IEvents[] = [];
    parsed?.events?.map((event: IParseEvent) => {
      let tmp: IEvents = {
        id: v4(),
        title: event?.summary?.value,
        start: new Date(event?.dtstart?.value),
        description: event?.description?.value,
        location: event?.location?.value,
      };
      if (event?.dtend?.value) {
        tmp.end = new Date(event?.dtend?.value);
      }
      tempEvents.push(tmp);
    });
    this.setState(
      (prevState) => ({
        events: [...prevState.events, ...tempEvents],
      }),
      () => {
        this.storeEvent();
      }
    );
  };

  generateEvent = () => {
    let eventObj: IGenerateEvent = {};
    this.state.events.map((i) => {
      eventObj[this.formatDate(i.start)] = {
        color: "#89CFF0",
        startingDay: true,
        endingDay: true,
      };
    });
    return eventObj;
  };

  deleteEvent = (id: string) => {
    this.setState(
      (prevState) => ({
        events: prevState.events.filter((i) => i.id !== id),
      }),
      () => {
        this.storeEvent();
      }
    );
  };

  dayPress = (day: string) => {
    this.setState({ selectedDay: day });
  };

  loadFile = async () => {
    const res = await DocumentPicker.pickSingle({
      type: [DocumentPicker.types.allFiles],
    });
    res?.uri && this.readICSFile(res.uri);
  };

  exportHandle = async () => {
    const AddCalendarEvent = require("react-native-add-calendar-event");
    let sEvent = this.state.selectedEvent;
    let title = sEvent?.title ? sEvent.title : "";
    let startDate = sEvent?.start
      ? new Date(sEvent?.start).toISOString()
      : new Date().toISOString();
    let endDate = sEvent?.end ? new Date(sEvent?.end).toISOString() : startDate;
    let location = sEvent?.location ? sEvent.location : "";
    const eventConfig = {
      title,
      startDate,
      endDate,
      location,
    };

    const eventInfo = await AddCalendarEvent.presentEventCreatingDialog(
      eventConfig
    );
    if (eventInfo) {
      eventInfo?.action === "SAVED" &&
        this.showAlert(configJSON.message, configJSON.successsMsg);
    }
  };

  loadFileWeb = async (e: React.ChangeEvent<HTMLInputElement> | undefined) => {
    if (e?.target.files && e?.target.files.length > 0) {
      const url = URL.createObjectURL(e?.target.files[0]);
      this.readICSFile(url);
    }
  };

  eventClickHandler = (info: IEvents) => {
    this.setState({ selectedEvent: info });
  };

  eventClickHandlerWeb = (info: EventContentArg) => {
    this.setState({ selectedEvent: info });
  };
  // Customizable Area End
}
