// Customizable Area Start
import React from "react";
import {
    Box,
    styled,
    Typography,
    OutlinedInput,
    Button,
    Grid
  } from "@material-ui/core"
  
import CommonPage from "../../../components/src/CommonReservationInfo.web";
import { AntSwitch } from "../../../components/src/FilterModal.web";
import { deleteIcon, imageIcons } from "../../../blocks/catalogue/src/assets";

import FinalCheckoutReservationController, {
    Props,
  } from "./FinalCheckoutReservationController";


class FinalCheckoutReservation extends FinalCheckoutReservationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  renderAddPhotosPreview = () => {
      return (
          <Box className="imagesBox">
              {this.state.isImgUploaded ?
                  <Box style={{ display: "flex", flexDirection: "column", margin: "auto" }}>
                      <img src={imageIcons} alt="img" />
                      <Button data-test-id="addImg" onClick={() => this.handleToggleImagesPreview()} variant="outlined" style={{ borderRadius: 20, textTransform: "none", margin: "10px 0", color: "#3173E1" }}> Add Photos</Button>
                  </Box>
                  :
                  <Box style={{ display: "flex", flexDirection: "column", margin: "auto" }}>
                      <Typography>Drag and drop or  <span style={{ color: "#3173E1", textDecoration: "underline", cursor: "pointer", fontWeight: 600 }} data-test-id="uploadImages" onClick={() => this.handleRefs()}> browse </span> files</Typography>
                      <input type="file" data-test-id={"imagesChange"} onChange={this.handleImagesChange} ref={this.fileRefs} multiple style={{ display: "none" }} />
                  </Box>
              }
          </Box>
      )
  }


   renderImagePreviews = () => {
        return(
            <Box style={{ margin: "25px 0" }}>
                <Grid container spacing={3}>
                    {this.state.imagesUrl.map((url, index) => (
                        <Grid item md={4} sm={6} xs={12} key={index} style={{ position: "relative" }}>
                            <img data-test-id="deleteUploadedPhoto" onClick={() => this.deleteUploadedImages(index)} style={{ position: "absolute", top: 30, right: 30, cursor: "pointer" }} src={deleteIcon} alt="deleteIcon" />
                            <img key={index} src={url} alt={`preview-${index}`} style={{ height: '130px', width: "100%", borderRadius: 16 }} />
                        </Grid>
                    ))}
                </Grid>

                <Box style={{ padding: 10, border: "1px dashed #3173E1", borderRadius: 12, textAlign: 'center', margin: "10px 0" }}>
                    <Typography> Add More <span data-test-id="addMoreText" onClick={() => this.handleRefs()} style={{ color: "#3173E1", textDecoration: "underline", cursor: "pointer" }}>Photo(s)</span> </Typography> </Box>
                <input type="file" data-test-id={"addMorePhotos"} onChange={this.handleImagesChange} ref={this.fileRefs} multiple style={{ display: "none" }} />

            </Box>
        )
   }


  render() {
    // Customizable Area Start
    
    return (
      <div>
        <CommonPage navigation={this.props.navigation} id="Final Reservation" pageTitle="Final Checkin Details">
           <Wrapper>
               <Box className="marginBox">
                  <Typography className="labelText" style={{ marginBottom: "8px"}}>Mileage Odometer</Typography>
                  <OutlinedInput
                     className="milegeTextField"
                     placeholder="Enter Odometer"
                     fullWidth
                  />
               </Box>

               <Box className="marginBox">
                  <Typography className="labelText" style={{ marginBottom: "8px"}}>Provide Keys</Typography>
                  <Box className="keyBox">
                        <Typography className="labelText" style={{ fontSize: "14px"}}>Renter has recieved the Car Keys</Typography>
                        <AntSwitch defaultChecked />
                  </Box>
               </Box>

               <Box className="photoBox">
                   <Typography className="editText">Edit or add new photos</Typography>
                   <Typography className="noteText">We will help you take the best photos to make your listing stand out to guests. </Typography>

                    {this.state.imagesUrl.length === 0 ? this.renderAddPhotosPreview() : this.renderImagePreviews()}
               </Box>

               <Box style={{ display: "flex", gap: 20, margin: "20px 0" }}>
                  <Button data-test-id="backBtn" className="backBtn">Back</Button>
                  <Button data-test-id="nextBtn" className="continueBtn">Continue</Button>
                </Box>
           </Wrapper>
        </CommonPage>
        
      </div>
    );
    // Customizable Area End
  }
}

export default FinalCheckoutReservation;

const Wrapper = styled(Box)({
    "& .labelText" : {
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '16px',
        fontWeight: 600,
        color: '#23395D',
    },
    "& .marginBox" : {
        margin: "15px 0"
    },
    "& .milegeTextField" : {
        height: "45px",
        borderRadius: "12px",
        border: "1px solid #D0D5DD"
    },
    "& .keyBox": {
        padding: "15px 20px",
        display: "flex",
        justifyContent: "space-between",
        borderRadius: "12px",
        border: "1px solid #EAECF0",
        alignItems: "center"
    },
    "& .editText":{
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '20px',
        fontWeight: 600,
        color: '#23395D',
    },
    "& .noteText":{
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '14px',
        fontWeight: 400,
        color: '#475467',
    },
    "& .photoBox": {
        margin: "25px 0"
    },
    "& .continueBtn" : {
        gap: '8px',
        textTransform: "none",
        backgroundColor: '#3173E1',
        height: '48px',
        fontFamily: 'Plus Jakarta Sans',
        borderRadius: '12px',
        border: "1px solid #D0D5DD",
        margin: "15px 0",
        fontSize: '14px',
        width: '150px',
        fontWeight: 600,
        color: 'white',
        padding: '14px 16px',
      },
      "& .backBtn" : {
        borderRadius: '12px',
        backgroundColor: 'white',
        padding: '14px 16px',
        margin: "15px 0",
        gap: '8px',
        height: '48px',
        fontSize: '14px',
        textTransform: "none",
        fontFamily: 'Plus Jakarta Sans',
        color: '#475467',
        border: "1px solid #D0D5DD",
        width: '150px',
        fontWeight: 600,
      },
    "& .imagesBox" : {
        height: "300px",
        display: "flex",
        borderRadius: "12px",
        border: "1px dashed #3173E1",
        margin: "20px 0"
      },
})

// Customizable Area End