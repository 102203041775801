// Customizable Area Start
import React from "react";

import {
   Box,
   styled,
   Typography,
   Button,
   Grid,
   Radio,
   Divider
} from "@material-ui/core"

import AppHeader from "../../../blocks/notifications/src/TopHeader.web"
import Footer from "../../../components/src/Footer.web";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

import ReportIssueController, {
    Props,
} from "./ReportIssueController";
import { addPhotosIcon } from "./assets";


class ReportIssue extends ReportIssueController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  render() {
    // Customizable Area Start
    
    return (
      <div>
         <AppHeader navigation={this.props.navigation} activeLink="Dashboard" />
          
          <ReportWrapper>
                <Box>
                    <Button
                        onClick={this.redirectToBackPage}
                        startIcon={<ArrowBackIosIcon style={{ color: "#667085", height: 16, width: 16 }} />}
                        data-test-id="backBtn"
                        className="backButton"
                    >
                        Back
                    </Button>

                    {this.state.reportedIssue ?
                        <Box>

                            <Typography className="headerText" style={{ textTransform : "capitalize"}}> {this.handleIsuueTitle()}</Typography>
                            <Typography className="subHeaderText controlArea">
                                You can request a cleaning violation fee if a guest returns your vehicle in a condition that could only have resulted from irresponsible or abusive behavior. If the evidence supports an eligible cleaning violation, we'll charge the guest and add the violation fee to your host earnings. In some cases, we'll remove the guest from Roam.v
                            </Typography>

                            <div style={{ margin : "20px 0", alignItems: "center", display: "flex", gap : "10px"}}>
                                <Typography className="policyText">View the host cleaning policy</Typography>
                                <ArrowForwardIosIcon style={{ color : "#3173E1", height: 20, width: 20}} />
                            </div>

                            <Box className="priceBox">
                                <Typography className="feeText">{this.handleIsuueTitle()} fee</Typography>
                                <Typography className="feeValue"> $150.00</Typography>
                            </Box>   

                            <Divider style={{ margin : "30px 0", maxWidth: "650px"}} />

                            <Typography className="headerText"> Photo evidence<span style={{ color: "#CB0A0A"}}>*</span></Typography>
                            <Typography className="subHeaderText controlArea">
                                You must attach relevant pre- and post-trip photos taken within 24 hours of the trip start and end. Turo Go hosts can attach post-trip photos only. If you fail to supply sufficient evidence, we may be unable to charge a violation fee or remove the guest.
                            </Typography>

                            <Button className="addPhostosBtn" startIcon={<img src={addPhotosIcon} />}>
                                Add Photos
                            </Button>

                            <Box style={{ display: "flex", gap: 20, margin: "20px 0" }}>
                                <Button data-test-id="backtoReportPageBtn" onClick={this.backtoReportPage} className="backBtn">Back</Button>
                                <Button data-test-id="reportIssueBtn" className="nextBtn">Report an Issue</Button>
                            </Box>

                            

                        </Box>
                        :
                        <Box>
                            <Typography className="headerText"> Report an issue</Typography>
                            <Typography className="subHeaderText controlArea">
                                Select an issur to report directly to Customer Support. If there are multiple issues, you'll be able to report them one at a time.
                            </Typography>

                            <Box style={{ margin: "30px 0" }}>
                                <Grid container spacing={3}>
                                    {this.state.reportedCatogory.map(item =>
                                        <Grid item md={4} key={item.reportId}>
                                            <Box className="outerBox">
                                                <Box>
                                                    <Typography className="labelText">{item.reportTitle}</Typography>
                                                    <Typography className="subLabelText">{item.reportSubTitle}</Typography>
                                                </Box>
                                                <Box>
                                                    <Radio
                                                        color="primary"
                                                        value={item.reportType}
                                                        onChange={this.handleCatogoriesChange}
                                                        checked={this.state.selectedIssue === item.reportType}
                                                        data-test-id="radioBtn"
                                                    />
                                                </Box>
                                            </Box>
                                        </Grid>
                                    )}

                                </Grid>
                            </Box>

                            <Box style={{ display: "flex", gap: 20, margin: "20px 0" }}>
                                <Button data-test-id="backBtn" onClick={this.redirectToBackPage} className="backBtn">Back</Button>
                                <Button data-test-id="nextBtn" onClick={this.onToNextReportStep} className="nextBtn">Continue</Button>
                            </Box>
                        </Box>
                    }
                        
              </Box>

          </ReportWrapper>

         <Footer />
      </div>
    );
    // Customizable Area End
  }
}

export default ReportIssue;

const ReportWrapper = styled(Box)({
    maxWidth: "1220px",
    margin: "40px auto",
    "& .backButton": {
        fontFamily: 'Plus Jakarta Sans',
        color: '#667085',
        fontSize: '14px',
        fontWeight: 500,
        lineHeight: '20px',
        textTransform: "none",
        textAlign: 'left',
        background: "white"
    },
    "& .headerText": {
        fontSize: '30px',
        fontWeight: 600,
        fontFamily: 'Plus Jakarta Sans',
        color: '#23395D',
        margin: "15px 0"
      },
      "& .subHeaderText": {
        fontSize: '14px',
        fontWeight: 400,
        fontFamily: 'Plus Jakarta Sans',
        color: '#475467',
      },
      "& .outerBox" : {
        padding: 18,
        borderRadius: "12px",
        border: "1px solid #DDE0E6",
        display: "flex",
        justifyContent: "space-between",
        minHeight: 105
      },
      "& .labelText": {
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '18px',
        fontWeight: 600,
        textAlign: 'left',
        color: '#23395D'
      },
      "& .subLabelText": {
        fontSize: '14px',
        fontWeight: 500,
        fontFamily: 'Plus Jakarta Sans',
        color: '#23395D',
      },
      "& .nextBtn" : {
        width: '150px',
        margin: "15px 0",
        textTransform: "none",
        gap: '8px',
        border: "1px solid #D0D5DD",
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '14px',
        fontWeight: 600,
        color: 'white',
        backgroundColor: '#3173E1',
        height: '48px',
        borderRadius: '12px',
        padding: '14px 16px',
      },
      "& .backBtn" : {
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '14px',
        fontWeight: 600,
        color: '#475467',
        borderRadius: '12px',
        width: '150px',
        backgroundColor: 'white',
        padding: '14px 16px',
        margin: "15px 0",
        textTransform: "none",
        gap: '8px',
        height: '48px',
        border: "1px solid #D0D5DD", 
      },
      "& .controlArea" : {
        maxWidth: 650
      },
      "& .policyText" : {
        fontSize: '14px',
        fontWeight: 400,
        fontFamily: 'Plus Jakarta Sans',
        color: '#3173E1',
      },
      "& .priceBox" : {
        padding: "25px",
        background: "#EDF4FF",
        borderRadius: "12px",
        display:"flex",
        justifyContent: "space-between",
        margin: "20px 0",
        maxWidth: 600
      },
      "& .feeText" : {
        fontSize: '18px',
        fontWeight: 600,
        fontFamily: 'Plus Jakarta Sans',
        color: '#23395D',
      },
      "& .feeValue" : {
        fontSize: '22px',
        fontWeight: 600,
        fontFamily: 'Plus Jakarta Sans',
        color: '#3173E1',
      },
      "& .addPhostosBtn" : {
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '14px',
        fontWeight: 500,
        color: '#3173E1',
        borderRadius: '12px',
        width: 'auto',
        backgroundColor: '#EDF4FF',
        padding: '14px 16px',
        margin: "15px 0",
        textTransform: "none",
        gap: '8px',
        height: '45px',
      }
})
// Customizable Area End