import { AppBar, Button, IconButton, InputBase, Toolbar, Typography, Box, styled } from '@material-ui/core'
import React from 'react'
import { StyleSheet, Text, View } from 'react-native';

const CustomFooter = styled(AppBar)({
  background: 'black', 
  paddingBlock: 48,
  marginTop: 46,
  '@media(max-width: 1260px)': {
    '& .footer-box > div': {
      paddingInline: '20px !important',
      width: '94% !important',
    },
    '& .copyright-box': {
      width: '94% !important',
      paddingInline: '20px !important',
    }
  },
  '@media(max-width: 960px)': {
    '& .footer-box': {
      flexDirection: 'column',
      gap: 20
    },
  },
  '@media(max-width: 600px)': {
    '& .footer-second-box': {
      flexDirection: 'column',
      '& h6': {
        width: '100%',
        textAlign: 'center'
      },
      '& .navbar-right': {
        maxWidth: '100% !important',
      },
    },
    '& .left-footer-box': {
      maxWidth: '100% !important',
      marginRight: '0px !important',
      gap: '22px !important',
      marginBottom: '22px',
      '& button': {
        justifyContent: 'center !important',
      },
      '& p': {
        maxWidth: '320px',
        textAlign: 'center',
        margin: 'auto'
      }
    },
    '& .footer-box > div': {
      paddingInline: '0px !important',
    },
    '& .copyright-box': {
      flexDirection: 'column-reverse',
      alignItems: 'center !important',
      paddingInline: '0px !important',
      gap: 20,
    }
  },
});

const CustomTypography=styled(Typography)({
  color:"white",
})

const CustomTypography1=styled(Typography)({
  cursor:"pointer"
})

export default function Footer() {
  return <CustomFooter position="static">
    <Toolbar className='footer-box' style={{ justifyContent: 'space-between', maxWidth: 1220, margin: 'auto', width: '100%', paddingLeft: 0, paddingRight: 0, alignItems: 'flex-start', paddingBottom: "44px", borderBottom: "1px solid #475467" }}>
      <Box className='left-footer-box' style={{ display: "flex", flexDirection: "column", marginRight: "104px", justifyContent: "start", gap: 32, maxWidth: "320px" }}>
        <IconButton style={{ justifyContent: "start" }} edge="start" color="default" aria-label="menu">
        <a href='/'><img src={require("./LogoWhite.png")} /></a>
        </IconButton>
        <Typography style={{ fontSize: "16px", fontWeight: 400, lineHeight: "24px", color: '#D0D5DD' }}>Design amazing digital experiences that create more happy in the world.</Typography>
      </Box>
      <Box className='footer-second-box' style={{ display: "flex", maxWidth: "792px", width: "100%", gap: 24 }}>
        <Box className='navbar-right' style={{ display: 'flex',color:"#98A2B3", flexDirection: "column", maxWidth: "240px", width: "100%", alignItems: 'start', gap: 10 }}>
          <CustomTypography variant="h6" style={{ fontSize: "14px" }} >
            Roam
          </CustomTypography>
          <CustomTypography1 variant="h6" style={{ fontSize: "14px" }} >
            About
          </CustomTypography1>
          <CustomTypography1 variant="h6" style={{ fontSize: "14px" }} >
            Team
          </CustomTypography1>
          <CustomTypography1 variant="h6" style={{ fontSize: "14px" }} >
            Policies<span style={{marginLeft:"8px",borderRadius: "16px",border: "1px solid #98A2B3",padding: "2px 8px 2px 8px"}}>New</span>
          </CustomTypography1>
          <CustomTypography1 variant="h6" style={{ fontSize: "14px" }} >
            Press
          </CustomTypography1>
          <CustomTypography1 variant="h6" style={{ fontSize: "14px" }} >
            Help Center
          </CustomTypography1>
          <CustomTypography1 variant="h6" style={{ fontSize: "14px" }} >
            Releases
          </CustomTypography1>
        </Box>
        <Box className='navbar-right' style={{ display: 'flex',color:"#98A2B3", flexDirection: "column", maxWidth: "240px", width: "100%", alignItems: 'start', gap: 10 }}>
          <CustomTypography variant="h6" style={{ fontSize: "14px" }} >
            Explore
          </CustomTypography>
          <CustomTypography1 variant="h6" style={{ fontSize: "14px" }} >
            Book a car
          </CustomTypography1>
          <CustomTypography1 variant="h6" style={{ fontSize: "14px" }} >
            Book a home
          </CustomTypography1>
          <CustomTypography1 variant="h6" style={{ fontSize: "14px" }} >
            Trust & Safety
          </CustomTypography1>
          <CustomTypography1 variant="h6" style={{ fontSize: "14px" }} >
            News
          </CustomTypography1>
          <CustomTypography1 variant="h6" style={{ fontSize: "14px" }} >
            Media kit
          </CustomTypography1>
          <CustomTypography1 variant="h6" style={{ fontSize: "14px" }} >
            Contact
          </CustomTypography1>
        </Box>
        <Box className='navbar-right' style={{ display: 'flex',color:"#98A2B3", flexDirection: "column", maxWidth: "240px", width: "100%", alignItems: 'start', gap: 10 }}>
          <CustomTypography variant="h6" style={{ fontSize: "14px" }} >
            Hosting
          </CustomTypography>
          <CustomTypography1 variant="h6" style={{ fontSize: "14px" }} >
            Become a host
          </CustomTypography1>
          <CustomTypography1 variant="h6" style={{ fontSize: "14px" }} >
            All-star hotels
          </CustomTypography1>
          <CustomTypography1 variant="h6" style={{ fontSize: "14px" }} >
            Instruction & Protection
          </CustomTypography1>
          <CustomTypography1 variant="h6" style={{ fontSize: "14px" }} >
            Host Tools
          </CustomTypography1>
          <CustomTypography1 variant="h6" style={{ fontSize: "14px" }} >
            Tutorials
          </CustomTypography1>
          <CustomTypography1 variant="h6" style={{ fontSize: "14px" }} >
            Supports
          </CustomTypography1>
        </Box>
      </Box>


    </Toolbar>
    <Box className='copyright-box' style={{ display: "flex", justifyContent: "space-between",maxWidth: 1220, margin: 'auto', width: '100%', padding:"32px 0px 0px", alignItems: 'flex-start'}}>
      <Box>
        <Typography style={{color: '#D0D5DD', fontSize: 16}}>© {new Date().getFullYear()} Roam. All rights reserved.</Typography>
      </Box>
      <Box style={{display:"flex",gap:24}}>
        <a href=""><img src={require("./TikTokIcon.svg")}></img></a>
        <a href=""><img src={require("./TwitterIcon.svg")}></img></a>
        <a href=""><img src={require("./LinkedInIcon.svg")}></img></a>
        <a href=""><img src={require("./FacebookIcon.svg")}></img></a>
        <a href=""><img src={require("./InstagramIcon.svg")}></img></a>
      </Box>
    </Box>
  </CustomFooter>
}

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: 200,
    backgroundColor: '#f3f3f3',
  },
  text: {
    fontSize: 36,
    fontWeight: '600',
  }
})
