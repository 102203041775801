// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";

import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { apiCalling, truthyValue } from '../../../components/src/asset'
import moment from "moment"

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

export interface HistoryData {
    id: number,
    title : string,
    address : string,
    image : string,
    startDate : string,
    endDate : string,
    renterName : string,
    revenue : string
}

interface MonthlyTripData {
    monthName : string,
    bokedSlotInfo : HistoryData[]
}

interface S {
  // Customizable Area Start  
  loading : boolean,
  catalougeId : string,
  totalPage : number,
  currentPage : number,
  perPage : number,
  tripHistoryData : MonthlyTripData[]
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class TripHistoryController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getTripHistoryApiCallId : string = ""
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];
    this.state = {
        loading : true,
        catalougeId: "",
        totalPage: 0,
        currentPage: 1,
        perPage: 9,
        tripHistoryData: []
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if(apiRequestCallId === this.getTripHistoryApiCallId) {
         return this.handleHistoryApiResponse(responseJson)
      }
      
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount(){
     const catalougeId = this.props.navigation.getParam("subId")
      this.getTripHistoryData(catalougeId)
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined): void {
      if(prevState.currentPage !== this.state.currentPage) {
        this.getTripHistoryData(this.state.catalougeId)
      } 
      if(prevState.perPage !== this.state.perPage) {
        this.getTripHistoryData(this.state.catalougeId)
      }
  }

  handleHistoryApiResponse = (responseJson : any) => {
    if(responseJson && responseJson.data && responseJson.data.length > 0) {

        const historyArray = responseJson.data.map((arrayVal : any) => {
            return {
                monthName : arrayVal.month,
                bokedSlotInfo : arrayVal.booked_slot.map((bookedSlotInfo: any) => {
                    const responseObj = bookedSlotInfo.attributes
                    return {
                        id: bookedSlotInfo.id,
                        title: truthyValue(responseObj.catalogue_name),
                        image: this.handleImageUrl(bookedSlotInfo),
                        address: this.handleAddressText(bookedSlotInfo),
                        startDate: moment(responseObj.start_time).format('ddd, MMM D, YYYY, h:mma'),
                        endDate: moment(responseObj.end_time).format('ddd, MMM D, YYYY, h:mma'),
                        renterName: truthyValue(responseObj.renter_name),
                        revenue: `$${truthyValue(responseObj.total_booking_price)}`
                    }
                })
                
            }
        })

        const totalPages = responseJson.total_pages
        const currentPage = responseJson.current_page

        this.setState({ 
            tripHistoryData : historyArray,
            loading : false,
            totalPage: totalPages,
            currentPage: currentPage,
            catalougeId: this.props.navigation.getParam("subId")
        })
    } else {
        this.setState({ loading : false, tripHistoryData: []})
    }

  }

  navToPreviousPage = () => {
    this.props.navigation.goBack()
  }

  getTripHistoryData = (catalougeId : string) => {
    const header = {
        "token": localStorage.getItem("userToken")
      };

      const httpBody = {
          "id": catalougeId,
          "page": this.state.currentPage,
          "per_page": this.state.perPage
      }
      
      this.getTripHistoryApiCallId = apiCalling({
        header: JSON.stringify(header),
        method: configJSON.apiMethodTypePost,
        endPoint: `${configJSON.getTripHistoryApiEndPoint}?id=${httpBody.id}&page=${httpBody.page}&per_page=${httpBody.per_page}`,
      })
  }

  handleImageUrl = (item : any) => {
    let imageUrl : string = ""
    if(item.attributes && item.attributes.images && item.attributes.images.length > 0 && item.attributes.images[0].url ) {
      imageUrl = item.attributes.images[0].url
    }

    return imageUrl
  } 

  handleAddressText = (info : any) => {
    let address : string = ""
     if(info.attributes && info.attributes.address.length > 0 && info.attributes.address[0] ) {
       const addressObj = info.attributes.address[0]
       address = addressObj.address + "," + addressObj.city + "," + addressObj.country
     }

     return address
  }

  handlePageChange = (event : React.ChangeEvent<unknown>, page: number) => {
    this.setState({
        currentPage : page,
        loading: true
    })
  }

  previousPage = () => {
    this.setState({
        currentPage : this.state.currentPage - 1,
        loading: true
    })
  }

  nextPage = () => {
    this.setState({
        currentPage : this.state.currentPage + 1,
        loading: true
    })
  }

  perPageChange = (event: React.ChangeEvent<{ value: unknown }>) => {
      const inputValue = event.target.value as string
     this.setState({
        perPage : parseInt(inputValue),
        currentPage: 1,
        loading: true
    })
  }



  // Customizable Area End
}

// Customizable Area End