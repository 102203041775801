// Customizable Area Start
import React from "react";
import { Box, Typography, styled, Divider, Grid, IconButton, OutlinedInput, InputAdornment, Button,TextField, Popper, Fade } from "@material-ui/core";
import AppHeader from "../../../blocks/notifications/src/TopHeader.web";
import Footer from "../../../components/src/Footer.web";
import { changePhotoIcon, deleteGrayIcon, editBlackIcon, editIcon, infoIcon, pdfIcon, userIcon, verifiedIcon, rightIcon } from "./assets";
import MailOutlineOutlinedIcon from '@material-ui/icons/MailOutlineOutlined';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { getAllCountries, getDefaultCountryCodes } from "../../../components/src/asset";

import UserProfileController, {
    Props,
  } from "./UserProfileController";
import { Autocomplete } from "@material-ui/lab";
import ProfileSidebar from "../../../components/src/ProfileSidebar.web";
import ProfileHeader from "../../../components/src/ProfileHeader.web";
import Loader from "../../../components/src/Loader.web";
import PasswordInput from "../../../components/src/PasswordInput.web";


class UserProfile extends UserProfileController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  renderCurrentPassword = () => {
    return (
      <Box className="textBox">
        <Box>
          <Typography className="photoText">Password</Typography>
        </Box>


        <FieldsBox style={{ margin: "10px 0" }}>
          <Typography className="passwordText" style={{ marginBottom: 5 }}> Current Password</Typography>
          <Box style={{ display: "flex", gap: 15, alignItems: "center" }}>
            <OutlinedInput
              type={this.state.ispasswordVisibleBox ? "text" : "password"}
              className="inputFields"
              data-test-id="currentPassword"
              onChange={this.handleChangeCurrentPasswordFields}
              value={this.state.currentPassword}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility in signup"
                    onClick={this.handleClickShowPassword}
                    onMouseDown={this.handleMouseDownPassword}
                  >
                    {this.state.ispasswordVisibleBox ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
              style={{ width: 510 }}
            />
          </Box>

        </FieldsBox>

      </Box>
    )
  }

  renderNewPassword = () => {
    return (
      <Box className="textBox">
        <Box>
          <Typography className="photoText"></Typography>
        </Box>
        <FieldsBox style={{ margin: "10px 0" }}>
          <Typography className="passwordText" style={{ marginBottom: 5 }}> New Password</Typography>
          <Box style={{ display: "flex", gap: 15, alignItems: "center" }} className="newPasswordBox">
            <PasswordInput  data-test-id="newPassword"  onPasswordChange={(passwordVal) => this.handleChangeNewPasswordFields(passwordVal)} />
            <img src={rightIcon} alt="editPen" />
          </Box>

        </FieldsBox>
      </Box>


    )
  }

  renderConfirmPassword = () => {
    return (
      <Box className="textBox">
        <Box>
          <Typography className="photoText"></Typography>
        </Box>
        <FieldsBox style={{ margin: "10px 0" }}>
          <Typography className="passwordText" style={{ marginBottom: 5 }}> Confirm Password</Typography>
          <Box style={{ display: "flex", gap: 15, alignItems: "center" }}>
            <OutlinedInput
              type={this.state.isConfirmPasswordVisibleBox ? "text" : "password"}
              className="inputFields"
              data-test-id="confirmPassword"
              onChange={this.handleChangeConfirmPasswordFields}
              value={this.state.confirmPassword}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility in signup"
                    onClick={this.handleClickConfirmShowPassword}
                    onMouseDown={this.handleMouseDownPassword}
                  >
                    {this.state.isConfirmPasswordVisibleBox ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
              style={{ width: 455 }}
            />
            <img src={rightIcon} alt="editPen" />
          </Box>

        </FieldsBox>
      </Box>
    )
  }


  render() {
    // Customizable Area Start
    
    return (
      <MainDiv>
         <AppHeader navigation={this.props.navigation} /> 
            
         <MainWrapper>
               <ProfileHeader />

               <Grid container spacing={3}>
                 <Grid item className="mainGrid">
                     <Box style={{ margin: "30px 0"}} className="mainBox"> 
                      <ProfileSidebar activeKey={1} navigation={this.props.navigation} />
                     </Box>
                 </Grid>
                 <Grid item md={9} className="mainBoxSecondary">
                    <Box style={{ margin: "30px 0" }}>

                       <Box style={{ padding: "0 30px"}}>
                           <Box>
                              <Typography className="mainText">Account information</Typography>
                              <Typography className="subText">Update your photo and personal details here.</Typography>
                              <Divider style={{ margin: "20px 0"}} />
                           </Box>
                              {this.state.loading ? <Loader loading={true} /> :
                                <div>

                                  <Box className={this.state.userInfo.profile_image !== "" ? "changePhotoDiv" : "textBox"}>
                                    <Box>
                                      <Typography className="photoText">Your Photo</Typography>
                                      <Typography className="subText">This will be displayed on your profile.</Typography>
                                    </Box>

                                    {this.state.userInfo.profile_image !== "" ?
                                      <Box style={{ position: "relative" }}>
                                        <img src={this.state.userInfo.profile_image} alt="userIcon" style={{ height: 125, width: 125, borderRadius: "100%" }} />
                                        <IconButton data-test-id="openPoper" onClick={this.handlePoperBox} style={{ position: "absolute", bottom: "5px", left: "85px"}}>
                                           <img src={changePhotoIcon}  />
                                        </IconButton>
                                        <Popper open={this.state.openPoper} anchorEl={this.state.anchorEl} placement={"right"} transition>
                                          {({ TransitionProps }) => (
                                            <Fade {...TransitionProps} timeout={350}>
                                                  <Box style={{ display: "flex", flexDirection: "column" }}>
                                                    <ChangePhotoBtn data-test-id="changePhoto" onClick={this.handleChangePhotoBtn} startIcon={<img src={editBlackIcon} />}>Change Photo</ChangePhotoBtn>
                                                    <DeletePhotoBtn startIcon={<img src={deleteGrayIcon} className="deletePhotoText" />}>Delete Photo</DeletePhotoBtn>
                                                </Box>
                                            </Fade>
                                          )}
                                        </Popper>
                                      </Box>
                                      :

                                      <Box style={{ display: "flex", gap: 20 }}>
                                        <Box>
                                          <img src={userIcon} alt="userIcon" />
                                        </Box>
                                        {this.state.selectedUserProfile !== null ?
                                          <Box style={{ display: "flex", border: "1px solid #3173E1", borderRadius: 12, padding: "16px 24px" }}>
                                            <Box textAlign={"center"}>
                                              <img src={verifiedIcon} alt="info" />
                                              <Typography className="clickHereText">File Uploaded</Typography>
                                            </Box>
                                          </Box>

                                          :
                                          <Box style={{ display: "flex", border: "1px solid #3173E1", borderRadius: 12, padding: "16px 24px" }}>
                                            <Box textAlign={"center"}>
                                              <img src={infoIcon} alt="info" />
                                              <Typography className="clickHereText">
                                                <span data-test-id="profileRef" onClick={this.handleProfileRefCall} style={{ color: "#3173E1", fontWeight: 600, cursor: "pointer" }}>Click to upload</span>  or drag and drop</Typography>
                                              <Typography className="subTextForImg">SVG, PNG, JPG or GIF (max. 800x400px)</Typography>
                                              <input
                                                type="file"
                                                data-test-id={"photoRef"}
                                                onChange={this.handleProfileChange}
                                                ref={this.profileImgRef}
                                                style={{ display: "none" }} />
                                            </Box>

                                            <img src={pdfIcon} style={{ marginTop: 35, height: 46 }} alt="pdfIcon" />

                                          </Box>
                                        }
                                      </Box>
                                    }
                                  </Box>
                                  <Divider style={{ margin: "20px 0" }} />

                                  <Box className="textBox">
                                    <Box>
                                      <Typography className="photoText">Name</Typography>
                                    </Box>
                                    <Box style={{ display: "flex", gap: 20 }} className="nameBox">
                                      <OutlinedInput
                                        name="first_name"
                                        fullWidth
                                        onChange={this.handleChangeForProfileFields}
                                        value={this.state.userInfo.first_name}
                                        className="inputFields" />
                                      <OutlinedInput fullWidth value={this.state.userInfo.last_name} disabled readOnly className="inputFields" />
                                    </Box>
                                  </Box>
                                  {this.handleErrorFields(this.state.userInfoError.first_name, "Please enter First Name")}
                                  <Divider style={{ margin: "20px 0" }} />

                                  <Box className="textBox">
                                    <Box>
                                      <Typography className="photoText">Email address</Typography>
                                    </Box>
                                    <Box style={{ display: "flex", gap: 15, alignItems: "center" }} className="emailAddressBox">
                                      <OutlinedInput
                                        startAdornment={<MailOutlineOutlinedIcon style={{ marginRight: 5, color: "#98A2B3", height: 20, width: 20 }} />}
                                        style={{ width: 455 }}
                                        value={this.state.userInfo.email}
                                        className="inputFields emailFields"
                                        name="email"
                                        onChange={this.handleChangeForProfileFields}
                                        disabled={!this.state.isEmailEditable}
                                        readOnly={!this.state.isEmailEditable}
                                      />
                                      <img data-test-id="emailCheck" style={{ cursor: "pointer" }} onClick={() => this.handleEditableFields(true, "isEmailEditable")} src={editIcon} alt="editPen" />
                                    </Box>


                                  </Box>
                                  {this.handleErrorFields(this.state.userInfoError.email, "Please enter Email Address")}

                                  <Divider style={{ margin: "20px 0" }} />

                                  {this.renderCurrentPassword()}

                                  {this.renderNewPassword()}

                                  {this.renderConfirmPassword()}

                                  {this.handleErrorFields(this.state.userInfoError.password, "Please enter Password")}

                                  <Divider style={{ margin: "20px 0" }} />

                                  <Box className="textBox">
                                    <Box>
                                      <Typography className="photoText">Phone number</Typography>
                                    </Box>
                                    <Box style={{ display: "flex", gap: 15, alignItems: "center" }} className="phoneInputBox">
                                      <OutlinedInput
                                        className="inputFields phoneField"
                                        name="phone_number"
                                        onChange={this.handleChangeForProfileFields}
                                        value={this.state.userInfo.phone_number}
                                        disabled={!this.state.isPhoneNoEditable}
                                        readOnly={!this.state.isPhoneNoEditable}
                                        style={{ width: 395 }}
                                        startAdornment={
                                          <Autocomplete
                                            id="combo-box-demo"
                                            className="inputFieldsSelectOpt"
                                            disableClearable
                                            options={getAllCountries()}
                                            disabled={!this.state.isPhoneNoEditable}
                                            onChange={this.hanndleCountryCodeChange}
                                            defaultValue={getDefaultCountryCodes()}
                                            getOptionLabel={(option) => option.phonecode}
                                            renderInput={(params) => <TextField {...params} size="small" variant="outlined" />}
                                          />
                                        }
                                      />
                                      <img src={verifiedIcon} alt="success" />
                                      <img data-test-id="phoneCheck" style={{ cursor: "pointer" }} onClick={() => this.handleEditableFields(true, "isPhoneNoEditable")} src={editIcon} alt="editPen" />
                                    </Box>
                                  </Box>

                                  {this.handleErrorFields(this.state.userInfoError.phone_number, "Please enter valid Phone Number")}

                                  <Divider style={{ margin: "20px 0" }} />

                                  <Box style={{ textAlign: 'end' }}>
                                    <Button data-test-id="saveBtn" onClick={() => this.updateUserInfo()} className="saveBtn">Save</Button>
                                  </Box>

                                </div>
                              }

                       </Box>
                    </Box>
                  </Grid>
               </Grid>
         </MainWrapper>
         <Footer />
      </MainDiv>
    );
    // Customizable Area End
  }
}

export default UserProfile;

const MainDiv = styled('div')({
  overflowX: "hidden",
})

const FieldsBox = styled(Box)({
    "@media(max-width: 700px)": {
      width: "100%"
    }
})

const MainWrapper = styled(Box)({
    maxWidth: "1220px",
    flexWrap: "wrap",
    margin: "auto",
    display: "flex",
    flexDirection: "column",
    "& .mainText" : {
      color :"#101828",
      fontFamily: "Plus Jakarta Sans",
      fontWeight: 600,
      fontSize: "18px"
    },
    "& .subText" : {
      color :"#475467",
      fontFamily: "Plus Jakarta Sans",
      fontWeight: 400,
      fontSize: "16px"
    },
    "& .phoneInputBox": {
      "@media(max-width:700px)": {
          width: "100%"
      }
    },
    "& .newPasswordBox": {
      "@media(max-width:700px)": {
        width: "100%"
      } 
    },
    "& .nameBox": {
      "@media(max-width:700px)": {
        width: "100%"
      }
    },
    "& .emailAddressBox": {
      "@media(max-width:700px)": {
        width: "100%"
      }
    },
    "& .textBox" : {
       display: "flex",
       justifyContent: "space-between",
       alignItems: "center",
       "@media(max-width: 700px)": {
          flexDirection: "column"
       }
    },
    "& .changePhotoDiv" : {
      display: "flex",
      gap: "50px"
    },
    "& .photoText" : {
      color :"#101828",
      fontFamily: "Plus Jakarta Sans",
      fontWeight: 600,
      fontSize: "16px"
    },
    "& .mainBox":{
      "@media(max-width:960px)":{
        display:"flex",
        "& .MuiGrid": {
          maxWidth:"100% !important"
        }
      }
    },
    "& .mainBoxSecondary": {
        width: "100% !important"
      // }
    },
    "& .mainGrid":{
      "@media(max-width:960px)":{
        width:"100% !important",
        overflowX: "scroll",
      }
    },
    "& .inputFields" : {
      height : "40px",
      borderRadius: "30px",
      "@media(max-width:700px)": {
        width: '100% !important'
      }
    },
    "& .MuiOutlinedInput-input" : {
      padding: "10px 0 10px 15px"
    },
    "& .emailFields .MuiOutlinedInput-input" : {
      padding: "10px 5px"
    },
    "& .phoneField .MuiOutlinedInput-input" : {
      padding: "10px 5px"
    },

    "& .passwordText" : {
      fontFamily: 'Plus Jakarta Sans',
      fontSize: '14px',
      fontWeight: 500,
      color:"#667085"
    },
    
    "& .clickHereText" : {
      fontFamily: 'Plus Jakarta Sans',
      fontSize: '14px',
      fontWeight: 600,
      textAlign: 'center',
      color:"#344054"
    },
    "& .subTextForImg" : {
      fontFamily: 'Plus Jakarta Sans',
      fontSize: '12px',
      fontWeight: 400,
      textAlign: 'center',
      color: "#667085"
    },
    "& .saveBtn" : {
        width: '200px',
        height: '45px',
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '14px',
        fontWeight: 600,
        textTransform: "none",
        borderRadius: 30,
        color: "white",
        background: "#3173E1",
        gap: '8px',
        margin: "10px 0",
        padding: '14px 16px',
    },
    "& .infoText" : {
      fontFamily: 'Plus Jakarta Sans',
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: '20px',
      textAlign: 'center',
      color: "#DC6803",
      margin: "5px 0"
    }, 
    "& .inputFieldsAutoComplete .MuiFormControl-root" : {
      border : "none",
      borderRadius: 30,
    },
    "& .inputFieldsAutoComplete .MuiOutlinedInput-notchedOutline" : {
      borderRadius: 30,
    },
    "& .inputFieldsSelectOpt .MuiOutlinedInput-notchedOutline" : {
      borderColor: "white",
    },

    "& .inputFieldsSelectOpt .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root']" : {
       paddingRight:'0px !important'
    }
})

export const ChangePhotoBtn = styled(Button)({
      height: "40px",
      fontFamily: 'Plus Jakarta Sans',
      fontSize: '16px',
      fontWeight: 500,
      textTransform: "none",
      color: "#1D2939",
})

export const DeletePhotoBtn = styled(Button)({
  height: "40px",
  fontFamily: 'Plus Jakarta Sans',
  fontSize: '16px',
  fontWeight: 500,
  textTransform: "none",
  color: "#667085",
})

// Customizable Area End