// Customizable Area Start
import React from "react";
import { Box, Typography, styled, Divider, Grid, Button,IconButton, Dialog, DialogTitle, DialogContent, OutlinedInput, DialogActions,
  Accordion, AccordionDetails, AccordionSummary
} from "@material-ui/core";
import AddIcon from '@material-ui/icons/Add';
import HighlightOffOutlinedIcon from '@material-ui/icons/HighlightOffOutlined';
import { AntSwitch } from "../../../components/src/FilterModal.web";
import { visaIcon, editBlueIcon } from "./assets";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import AppHeader from "../../../blocks/notifications/src/TopHeader.web";
import Footer from "../../../components/src/Footer.web";
import ProfileSidebar from "../../../components/src/ProfileSidebar.web";
import ProfileHeader from "../../../components/src/ProfileHeader.web";
import { truthyValue } from "../../../components/src/asset";
import Loader from "../../../components/src/Loader.web";
import Alert from "@material-ui/lab/Alert";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import MailOutlineOutlinedIcon from '@material-ui/icons/MailOutlineOutlined';


import PaymetSettingsController, {
    Props,
  } from "./PaymetSettingsController";


class PaymetSettings extends PaymetSettingsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  render() {
    // Customizable Area Start
    
    return (
      <div>
         <AppHeader navigation={this.props.navigation} />

         <ProfileWrapper>
            <ProfileHeader />

            <Grid container spacing={3}>
                 <Grid item className="mainGrid">
                     <Box style={{ margin: "30px 0"}}   className="mainBoxStyle">
                        <ProfileSidebar activeKey={2} navigation={this.props.navigation} />
                     </Box>
                 </Grid>
                 <Grid item md={9} className="mainBoxSecondary">
                     <Box style={{ margin: "30px 0"}}>
                          
                            <Box className="paymetInfoBox paddingBox">
                                <Box  style={{ display: "flex", justifyContent: "space-between"}}>
                                    <Box>
                                        <Typography className="mainText">Payment information</Typography>
                                        <Typography className="subText">Update your cards and payment details here.</Typography>
                                    </Box>
                                    <Button 
                                        className="addBtn" 
                                        startIcon={<AddIcon style={{ color: "white" }} />}
                                        data-test-id="addNewBtn"
                                        onClick={this.oepnAddCardModal}
                                    >
                                      Add new
                                    </Button>
                                </Box>
                               
                                <Divider style={{ margin: "20px 0" }} />
                            </Box>

                            {this.state.loading ? <Loader loading={true} /> : 
                            <Box className="savedCardInfo paddingBox">

                                    {this.state.savedCards.length > 0 &&
                                        <div>
                                            <Typography className="savedCardText">Saved Cards</Typography>

                                            <Alert className="alertBox" severity="success" icon={<ErrorOutlineIcon />}>
                                                Your cards information will be safe and secured.
                                            </Alert>
                                        </div>

                                    } 
                                {this.state.savedCards.length > 0 && this.state.savedCards.map(cardValue => {
                                    return (
                                        <Box style={{ margin: "15px 0" }}>
                                            <Accordion className="mainCardBox">
                                                <AccordionSummary className="expandedBg" expandIcon={<ExpandMoreIcon />}>
                                                    <Box className="mainBox" style={{ display: "flex", gap: 15 }}>
                                                        <img src={visaIcon} style={{ height: 32, width: 44 }} />
                                                        <Box>
                                                            <Typography className="cardNoText">{`${truthyValue(cardValue.attributes.payment_method_details.card.brand)} ending ${truthyValue(cardValue.attributes.payment_method_details.card.last4)}`}</Typography>
                                                            <Typography className="expiryText">Expiry {cardValue.attributes.expiry_date}</Typography>
                                                            <Box style={{ display: "flex", gap: 15, alignItems: "center" }}>
                                                                <AntSwitch data-test-id="defaultSwitch" onChange={() => this.defaultCardInfo(cardValue.id)} checked={cardValue.attributes.default_payment_method} title="Default" /> <span className={cardValue.attributes.default_payment_method ? "colorText" : "defaultText"}>Default</span>
                                                                <Button className="editBtn" startIcon={<img src={editBlueIcon} />} >Edit</Button>
                                                            </Box>
                                                        </Box>
                                                    </Box>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <Box style={{ width: "100%"}}>

                                                        
                                                        <Grid container spacing={3}>
                                                            <Grid item md={5}>
                                                                <Box>
                                                                    <Typography className="summaryLabelText">Card Details</Typography>
                                                                </Box>
                                                            </Grid>
                                                            <Grid item md={7}>
                                                                <Box>
                                                                    <Grid container spacing={3}>
                                                                        <Grid item md={6}>
                                                                            <Box>
                                                                                <Typography className="fieldLabelText">Name on card</Typography>
                                                                                <OutlinedInput
                                                                                    fullWidth
                                                                                    className="inputField"
                                                                                    readOnly
                                                                                    disabled
                                                                                    value={truthyValue(cardValue.attributes.card_holder_name)}
                                                                                />
                                                                            </Box>
                                                                        </Grid>
                                                                        <Grid item md={6}>
                                                                            <Box>
                                                                                <Typography className="fieldLabelText">Expiry</Typography>
                                                                                <OutlinedInput
                                                                                    fullWidth
                                                                                    className="inputField"
                                                                                    readOnly
                                                                                    disabled
                                                                                    value={truthyValue(cardValue.attributes.expiry_date)}
                                                                                />
                                                                            </Box>
                                                                        </Grid>
                                                                    </Grid>

                                                                    <Grid container spacing={3}>
                                                                        <Grid item md={9}>
                                                                            <Box>
                                                                                <Typography className="fieldLabelText">Card Number</Typography>
                                                                                <OutlinedInput
                                                                                    fullWidth
                                                                                    className="inputField"
                                                                                    readOnly
                                                                                    disabled
                                                                                    value={`XXXXXXXXXXXX${truthyValue(cardValue.attributes.payment_method_details.card.last4)}`}
                                                                                    startAdornment={<img src={visaIcon} style={{ height: 20, width : 20, margin: "0 5px"}} />}
                                                                                />
                                                                            </Box>
                                                                        </Grid>
                                                                        <Grid item md={3}>
                                                                            <Box>
                                                                                <Typography className="fieldLabelText">CVV</Typography>
                                                                                <OutlinedInput
                                                                                    fullWidth
                                                                                    className="inputField"
                                                                                    readOnly
                                                                                    disabled
                                                                                    value={"***"}
                                                                                />
                                                                            </Box>
                                                                        </Grid>
                                                                    </Grid>
                                                                   
                                                                </Box>
                                                            </Grid>
                                                        </Grid>

                                                         
                                                        <Grid container spacing={3}>
                                                            <Grid item md={5}>
                                                                <Box>
                                                                    <Typography className="summaryLabelText">Billing information</Typography>
                                                                    <Typography className="summarySubLabelText">Update your billing details here.</Typography>
                                                                </Box>
                                                            </Grid>
                                                         </Grid>
                                                        <Divider style={{ margin: "20px 0" }} />

                                                         
                                                        <Grid container spacing={3}>
                                                            <Grid item md={5}>
                                                                <Box>
                                                                    <Typography className="summaryLabelText">Email Address</Typography>
                                                                    <Typography className="summarySubLabelText">Invoices will be sent to this email address.</Typography>
                                                                </Box>
                                                            </Grid>
                                                            <Grid item md={7}>
                                                                <Box>
                                                                    <OutlinedInput
                                                                        fullWidth
                                                                        className="inputField"
                                                                        readOnly
                                                                        disabled
                                                                        placeholder="email@example.com"
                                                                        startAdornment={<MailOutlineOutlinedIcon style={{ marginRight: 10, color: "#98A2B3", height: 20, width: 20 }} />}
                                                                        value={truthyValue(cardValue.attributes.payment_method_details.billing_details.email)}
                                                                    />
                                                                    <Button
                                                                      className="addEmailAddressLink"
                                                                      startIcon={<AddIcon style={{ color: "#3173E1" }} />}
                                                                    >Add Email Address</Button>
                                                                </Box>
                                                            </Grid>
                                                         </Grid>
                                                        <Divider style={{ margin: "20px 0" }} />

                                                        
                                                        <Grid container spacing={3}>
                                                            <Grid item md={5}>
                                                                <Box>
                                                                    <Typography className="summaryLabelText">Country</Typography>
                                                                </Box>
                                                            </Grid>
                                                            <Grid item md={7}>
                                                                <Box>
                                                                    <OutlinedInput
                                                                        fullWidth
                                                                        className="inputField"
                                                                        readOnly
                                                                        disabled
                                                                        endAdornment={<ExpandMoreIcon />}
                                                                        value={truthyValue(cardValue.attributes.payment_method_details.billing_details.address.country)}
                                                                    />
                                                                </Box>
                                                            </Grid>
                                                         </Grid>
                                                        <Divider style={{ margin: "20px 0" }} />

                                                        
                                                        <Grid container spacing={3}>
                                                            <Grid item md={5}>
                                                                <Box>
                                                                    <Typography className="summaryLabelText">City</Typography>
                                                                </Box>
                                                            </Grid>
                                                            <Grid item md={7}>
                                                                <Box>
                                                                    <OutlinedInput
                                                                        fullWidth
                                                                        className="inputField"
                                                                        endAdornment={<ExpandMoreIcon />}
                                                                        readOnly
                                                                        disabled
                                                                        value={truthyValue(cardValue.attributes.payment_method_details.billing_details.address.city)}
                                                                    />
                                                                </Box>
                                                            </Grid>
                                                         </Grid>
                                                        <Divider style={{ margin: "20px 0" }} />

                                                        
                                                        <Grid container spacing={3}>
                                                            <Grid item md={5}>
                                                                <Box>
                                                                    <Typography className="summaryLabelText">Street address</Typography>
                                                                </Box>
                                                            </Grid>
                                                            <Grid item md={7}>
                                                                <Box>
                                                                    <OutlinedInput
                                                                        fullWidth
                                                                        className="inputField"
                                                                        readOnly
                                                                        disabled
                                                                        value={`${truthyValue(cardValue.attributes.payment_method_details.billing_details.address.line1)}`}
                                                                    />
                                                                </Box>
                                                            </Grid>
                                                         </Grid>
                                                        <Divider style={{ margin: "20px 0" }} />

                                                        
                                                        <Grid container spacing={3}>
                                                            <Grid item md={5}>
                                                                <Box>
                                                                    <Typography className="summaryLabelText">State/Province</Typography>
                                                                </Box>
                                                            </Grid>
                                                            <Grid item md={7}>
                                                                <Grid container spacing={3}>
                                                                    <Grid item md={6}>
                                                                        <Box>
                                                                            <OutlinedInput
                                                                                fullWidth
                                                                                className="inputField"
                                                                                endAdornment={<ExpandMoreIcon />}
                                                                                readOnly
                                                                                disabled
                                                                                value={truthyValue(cardValue.attributes.payment_method_details.billing_details.address.state)}
                                                                            />
                                                                        </Box>
                                                                    </Grid>
                                                                    <Grid item md={6}>
                                                                        <Box>
                                                                            <OutlinedInput
                                                                                fullWidth
                                                                                className="inputField"
                                                                                endAdornment={<ExpandMoreIcon />}
                                                                                readOnly
                                                                                disabled
                                                                                value={truthyValue(cardValue.attributes.payment_method_details.billing_details.address.postal_code)}
                                                                            />
                                                                        </Box>
                                                                    </Grid>
                                                                </Grid>
                                                                
                                                            </Grid>
                                                         </Grid>
                                                    </Box>
                                                </AccordionDetails>
                                            </Accordion>
                                        </Box>
                                    )
                                })}

                                {this.state.savedCards.length === 0 && 
                                        <Box textAlign={"center"}>
                                            No Saved Card Available.
                                        </Box>
                                }

                            </Box>}
                     </Box>
                  </Grid>
               </Grid>
         </ProfileWrapper>

         <DialogWrapper 
            open={this.state.openAddCardModal} 
            onClose={this.closeAddCardModal}
            maxWidth="xs"
            fullWidth
         >
                <DialogTitle style={{ borderBottom: "1px solid #EAEAEA", padding: "8px 24px" }}>
                    <Box style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <Typography className='headerText'>Add Payment Method</Typography>
                        <IconButton edge="end" color="inherit" data-test-id="closeBtn" onClick={this.closeAddCardModal}>
                            <HighlightOffOutlinedIcon style={{ color: "#475467" }} />
                        </IconButton>
                    </Box>
                </DialogTitle>
                
                <DialogContent>
                    <Typography className="subHeaderText">Card Information</Typography>
                    <Box className="inputBox">
                        <Typography className="textLabel">Cardholder Name</Typography>
                        <OutlinedInput
                            className="textField"
                            name="card_holder_name"
                            onChange={this.handleChnageOfAddCard}
                            value={this.state.cardDetails.card_holder_name}
                            placeholder="Cardholder Name"
                            fullWidth
                        />
                        {this.handleErrorFields(this.state.cardDetailsErrors.card_holder_name, "Please enter card holder name")}

                    </Box>
                    <Box className="inputBox">
                        <Typography className="textLabel">Card number</Typography>
                        <OutlinedInput
                            className="textField"
                            fullWidth
                            onKeyDown={this.handleKeyDown}
                            name="card_number"
                            onChange={this.handleChnageOfAddCard}
                            value={this.state.cardDetails.card_number}
                            placeholder="Card Number"
                        />
                        {this.handleErrorFields(this.state.cardDetailsErrors.card_number, "Please enter valid card number")}

                    </Box>
                    <Box className="gridBox">
                        <Box className="inputBox">
                            <Typography className="textLabel">Expiry Date</Typography>
                            <OutlinedInput
                                className="textField"
                                type="date"
                                inputProps={{ min: this.handleFormattedDate() }}
                                name="expiry_date"
                                onChange={this.handleChnageOfAddCard}
                                value={this.state.cardDetails.expiry_date}
                                placeholder="Expiry Date"
                                fullWidth
                            />
                            {this.handleErrorFields(this.state.cardDetailsErrors.expiry_date, "Please enter valid expiry date")}
                        </Box>
                        <Box className="inputBox">
                            <Typography className="textLabel">Security Code</Typography>
                            <OutlinedInput
                                className="textField"
                                fullWidth
                                onKeyDown={this.handleKeyDown}
                                name="security_code"
                                onChange={this.handleChnageOfAddCard}
                                value={this.state.cardDetails.security_code}
                                placeholder="Security Code"

                            />
                            {this.handleErrorFields(this.state.cardDetailsErrors.security_code, "Please enter valid security code")}

                        </Box>
                    </Box>
                    <Typography className="subHeaderText">Billing Information</Typography>
                    <Box className="inputBox">
                        <Typography className="textLabel">Street Address</Typography>
                        <OutlinedInput
                            className="textField"
                            name="street"
                            onChange={this.handleChnageOfAddCard}
                            value={this.state.cardDetails.street}
                            placeholder="Street Address"
                            fullWidth
                        />
                        {this.handleErrorFields(this.state.cardDetailsErrors.street, "Please enter valid address")}

                    </Box>
                    <Box className="gridBox">
                        <Box className="inputBox">
                            <Typography className="textLabel">Country</Typography>
                            <OutlinedInput
                                className="textField"
                                name="country"
                                onChange={this.handleChnageOfAddCard}
                                value={this.state.cardDetails.country}
                                placeholder="Country"
                                fullWidth
                            />
                            {this.handleErrorFields(this.state.cardDetailsErrors.country, "Please enter valid country")}

                        </Box>
                        <Box className="inputBox">
                            <Typography className="textLabel">State</Typography>
                            <OutlinedInput
                                className="textField"
                                name="state"
                                onChange={this.handleChnageOfAddCard}
                                value={this.state.cardDetails.state}
                                placeholder="State"
                                fullWidth
                            />
                            {this.handleErrorFields(this.state.cardDetailsErrors.state, "Please enter valid state")}

                        </Box>
                    </Box>
                    <Box className="gridBox">
                        <Box className="inputBox">
                            <Typography className="textLabel">City</Typography>
                            <OutlinedInput
                                className="textField"
                                fullWidth
                                name="city"
                                onChange={this.handleChnageOfAddCard}
                                value={this.state.cardDetails.city}
                                placeholder="City"
                            />
                            {this.handleErrorFields(this.state.cardDetailsErrors.city, "Please enter valid city")}

                        </Box>
                        <Box className="inputBox">
                            <Typography className="textLabel">Zip Code</Typography>
                            <OutlinedInput
                                className="textField"
                                onKeyDown={this.handleKeyDown}
                                name="zip_code"
                                onChange={this.handleChnageOfAddCard}
                                value={this.state.cardDetails.zip_code}
                                placeholder="Zip Code"
                                fullWidth
                            />
                            {this.handleErrorFields(this.state.cardDetailsErrors.zip_code, "Please enter valid zip coder")}

                        </Box>
                    </Box>
                </DialogContent>

                <DialogActions style={{ borderTop: "1px solid #EAEAEA"}}>
                    <Box className="gridBox">
                        <Button data-test-id="cancelBtn" className="cancelBtn" onClick={this.handleCancelBtn}>Cancel</Button>
                        <Button data-test-id="saveBtn" className="saveBtn" onClick={this.saveCardInfo}>Save</Button>
                    </Box>
                </DialogActions>
         </DialogWrapper>

         <Footer />
      </div>
    );
    // Customizable Area End
  }
}

export default PaymetSettings;

const ProfileWrapper = styled(Box)({
    maxWidth: "1220px",
    flexWrap: "wrap",
    margin: "auto",
    display: "flex",
    flexDirection: "column",
    "& .mainBox": {
        "@media(max-width:960px)":{
            display:"flex",
          } 
    },
    "& .mainBoxStyle":{
        "@media(max-width:960px)":{
          display:"flex",
          "& .MuiGrid": {
            maxWidth:"100% !important"
          }
        }
      },
      "& .mainBoxSecondary": {
          width: "100% !important"
      },
      "& .mainGrid":{
        "@media(max-width:960px)":{
          width:"100% !important",
          overflowX: "scroll",
        }
      },
    "& .mainCardBox" : {
       boxShadow: "none",
       border: "1px solid #EAECF0",
       borderRadius : 12
    },
    "& .mainCardBox .Mui-expanded " : {
       background: "#EAECF0",
       borderTopLeftRadius: '12px',
       borderTopRightRadius: '12px'
    },
    "& .paddingBox" : {
        padding : "0 30px",
    },
    "& .paymetInfoBox .mainText": {
        fontWeight: 600,
        color: "#101828",
        fontFamily: "Plus Jakarta Sans",
        fontSize: "18px"
    },
    "& .paymetInfoBox .subText": {
        color: "#475467",
        fontWeight: 400,
        fontFamily: "Plus Jakarta Sans",
        fontSize: "16px"
    },
    "& .addBtn" : {
        height: "40px",
        width: "120px",
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '14px',
        fontWeight: 600,
        textTransform: "none",
        borderRadius: 30,
        color: "white", 
        background: "#3173E1",
        "@media(max-width:450px)": {
            padding: "25px"
        }
    },
    "& .cardNoText" : {
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '14px',
        fontWeight: 500,
        color: "#344054",
        textTransform: "capitalize"
    },
    "& .expiryText" : {
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '14px',
        fontWeight: 500,
        color: "#667085",
    },
    "& .colorText" : {
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '14px',
        fontWeight: 600,
        color: "#3173E1",
    },
    "& .defaultText" : {
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '14px',
        fontWeight: 600,
        color: "#475467",
    },
    "& .editBtn" : {
        height: "20px",
        width: "55px",
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '14px',
        fontWeight: 600,
        textTransform: "none",
        borderRadius: 30,
        color: "#3173E1",
    },
    "& .savedCardText" : {
        fontWeight: 500,
        color: "#101828",
        fontFamily: "Plus Jakarta Sans",
        fontSize: "16px"
    },
    "& .alertBox" : {
        margin : "10px 0",
        height : 40
    },
    "& .inputField" : {
        height : "40px",
        borderRadius: "30px",
        padding: "10px 14px",
        background: "#EAECF0"
      },
      "&  .inputField .MuiOutlinedInput-input" : {
        padding: 0
      },
    "& .fieldLabelText" : {
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '14px',
        fontWeight: 500,
        color: "#667085",
        paddingBottom: "5px"  
    },
    "& .summaryLabelText" : {
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '16px',
        fontWeight: 500,
        color: "#101828",
    },
    "& .summarySubLabelText" : {
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '16px',
        fontWeight: 400,
        color: "#475467",
    },
    "& .addEmailAddressLink" : {
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '14px',
        fontWeight: 600,
        color: "#3173E1",
        background: "white",
        textTransform: "none"
    }
})

const DialogWrapper = styled(Dialog)({
    "& .MuiPaper-rounded" : {
        borderRadius : "16px !important",
    },
    "& .inputBox" : {
       margin: "10px 0"
    },
    "& .headerText" : {
        fontWeight: 600,
        color: "#101828",
        fontFamily: "Plus Jakarta Sans",
        fontSize: "18px",
    },
    "& .subHeaderText" : {
        fontWeight: 600,
        color: "#101828",
        fontFamily: "Plus Jakarta Sans",
        fontSize: "14px",
        margin: "15px 0"
    },
    "& .textLabel" : {
        fontWeight: 500,
        color: "#667085",
        fontFamily: "Plus Jakarta Sans",
        fontSize: "14px",
        marginBottom: "5px"
    },
    "& .textField" : {
        height : "40px",
        borderRadius: "30px",
        padding: "10px 14px",
      },
      "&  .textField .MuiOutlinedInput-input" : {
        padding: 0
      },
    "& .gridBox" : {
        display: "flex",
        gap: "15px"
    },
    "& .saveBtn" : {
        height: "40px",
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '14px',
        fontWeight: 600,
        textTransform: "none",
        borderRadius: 30,
        width: "120px",
        color: "white",
        background: "#3173E1",
    },
    "& .cancelBtn" : {
        height: "40px",
        width: "80px",
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '14px',
        fontWeight: 600,
        textTransform: "none",
        border: "1px solid #D0D5DD",
        borderRadius: 30,
        color: "#475467",
    }
})

// Customizable Area End