// Customizable Area Start
import React from "react";

import {
    Box,
    Grid,
    Typography,
    styled,
    Divider,
    Checkbox,
    Button
} from "@material-ui/core"
import { checkBoxIcon, checkedIcon } from "./assets";
import AppHeader from "../../../blocks/notifications/src/TopHeader.web";
import Footer from "../../../components/src/Footer.web";
import ProfileSidebar from "../../../components/src/ProfileSidebar.web";
import ProfileHeader from "../../../components/src/ProfileHeader.web";
import Loader from "../../../components/src/Loader.web";



import NotificationSettingsController, {
    Props,
  } from "./NotificationSettingsController";


class NotificationSettings extends NotificationSettingsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  render() {
    // Customizable Area Start
    
    return (
      <div>
         <AppHeader navigation={this.props.navigation} />

          <NotificationWrapper>
             
             <ProfileHeader />

             <Grid container spacing={3}>
                 <Grid item className="mainGrid">
                     <Box style={{ margin: "30px 0"}}  className="mainBox">
                        <ProfileSidebar activeKey={3} navigation={this.props.navigation} />
                     </Box>
                 </Grid>
                 <Grid item md={9} className="mainBoxSecondary">
                     <Box style={{ margin: "30px 0"}}>
                            <Box className="paddingBox">
                                <Typography className="mainText">Notifications</Typography>
                                <Typography className="subText">Get emails to find out what's going on when you're not online. You can turn them off anytime.</Typography>
                                <Divider style={{ margin: "20px 0" }} />
                            </Box>

                            {this.state.loading ? <Loader loading={true} /> :
                              <div>
                                <Box className="paddingBox">
                                  <Grid container spacing={2}>
                                    <Grid item md={6}>
                                      <Typography className="mainText">Mobile Notifications</Typography>
                                      <Typography className="subText">Receive the latest news, updates and industry tutorials from us.</Typography>
                                    </Grid>
                                    <Grid item md={6}>
                                      <Box className="checkBoxFlexBox">
                                        <Box>
                                          <Checkbox 
                                              icon={<img src={checkBoxIcon} />} 
                                              checkedIcon={<img src={checkedIcon} />}
                                              checked={this.state.notificationStatus.text_notifications}
                                              name={"text_notifications"}
                                              onChange={this.handleChangeForNotification}
                                            />
                                        </Box>
                                        <Box>
                                          <Typography className="checkBoxText">Enable text notifications</Typography>
                                          <Typography className="checkBoxSubText">News about product and feature updates.</Typography>
                                        </Box>
                                      </Box>
                                      <Box className="checkBoxFlexBox">
                                        <Box>
                                          <Checkbox 
                                              icon={<img src={checkBoxIcon} />} 
                                              checkedIcon={<img src={checkedIcon} />}
                                              checked={this.state.notificationStatus.email_notifications}
                                              name={"email_notifications"}
                                              onChange={this.handleChangeForNotification}
                                          />
                                        </Box>
                                        <Box>
                                          <Typography className="checkBoxText">Push notifications</Typography>
                                          <Typography className="checkBoxSubText">Tips on getting more out of Untitled.</Typography>
                                        </Box>
                                      </Box>
                                      <Box className="checkBoxFlexBox">
                                        <Box>
                                          <Checkbox 
                                              icon={<img src={checkBoxIcon} />}
                                              checkedIcon={<img src={checkedIcon} />}
                                              checked={this.state.notificationStatus.weekly_notifications}
                                              name={"weekly_notifications"}
                                              onChange={this.handleChangeForNotification}
                                          />
                                        </Box>
                                        <Box>
                                          <Typography className="checkBoxText">Weekly Notifications</Typography>
                                          <Typography className="checkBoxSubText">Get involved in our beta testing program or participate in paid product user research.</Typography>
                                        </Box>
                                      </Box>
                                    </Grid>
                                  </Grid>

                                  <Divider style={{ margin: "20px 0" }} />
                                </Box>

                                <Box className="paddingBox">
                                  <Grid container spacing={2}>
                                    <Grid item md={6}>
                                      <Typography className="mainText">Email Notifications</Typography>
                                      <Typography className="subText">These are notifications for comments on your posts and replies to your comments.</Typography>
                                    </Grid>
                                    <Grid item md={6}>
                                      <Box className="checkBoxFlexBox">
                                        <Box>
                                          <Checkbox
                                            icon={<img src={checkBoxIcon} />} 
                                            checkedIcon={<img src={checkedIcon} />}
                                            checked={this.state.notificationStatus.promotions_and_announcements}
                                            name={"promotions_and_announcements"}
                                            onChange={this.handleChangeForNotification}
                                          />
                                        </Box>
                                        <Box>
                                          <Typography className="checkBoxText">Promotional & Announcement</Typography>
                                        </Box>
                                      </Box>
                                      <Box className="checkBoxFlexBox">
                                        <Box>
                                          <Checkbox
                                             icon={<img src={checkBoxIcon} />} 
                                             checkedIcon={<img src={checkedIcon} />}
                                             checked={this.state.notificationStatus.latest_offer}
                                              name={"latest_offer"}
                                              onChange={this.handleChangeForNotification}
                                              />
                                        </Box>
                                        <Box>
                                          <Typography className="checkBoxText">Latest offers & deals</Typography>
                                          <Typography className="checkBoxSubText">Only notify me if I'm mentioned in a comment.</Typography>
                                        </Box>
                                      </Box>
                                    </Grid>
                                  </Grid>

                                  <Divider style={{ margin: "20px 0" }} />
                                </Box>

                                <Box>
                                  <Button data-test-id="saveBtn" onClick={this.updateNotificationStatus} className="saveBtn">Save</Button>
                                </Box>


                              </div>
                            }

                            
                                                   
                      </Box>
                  </Grid>
               </Grid>
          </NotificationWrapper>

         <Footer />
      </div>
    );
    // Customizable Area End
  }
}

export default NotificationSettings;

export const NotificationWrapper = styled(Box)({
    maxWidth: "1220px",
    margin: "auto",
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
    "& .mainText": {
        color: "#101828",
        fontFamily: "Plus Jakarta Sans",
        fontWeight: 600,
        marginBottom: "4px",
        fontSize: "18px"
    },
    "& .subText": {
        color: "#475467",
        fontFamily: "Plus Jakarta Sans",
        fontWeight: 400,
        fontSize: "16px"
    },
    "& .paddingBox": {
        padding: "0 30px",
    },
    "& .checkBoxText": {
      color: "#344054",
      fontFamily: "Plus Jakarta Sans",
      fontWeight: 500,
      fontSize: "14px"
    },
    "& .checkBoxSubText": {
      color: "#475467",
      fontFamily: "Plus Jakarta Sans",
      fontWeight: 400,
      fontSize: "14px"
    },
    "& .mainBox":{
      "@media(max-width:960px)":{
        display:"flex",
        "& .MuiGrid": {
          maxWidth:"100% !important"
        }
      }
    },
    "& .mainBoxSecondary": {
        width: "100% !important"
    },
    "& .mainGrid":{
      "@media(max-width:960px)":{
        width:"100% !important",
        overflowX: "scroll",
      }
    },
    "& .checkBoxFlexBox" : {
        display: "flex",
        gap: 8, 
        alignItems: "baseline",
        marginBottom: "15px"
    },
    "& .saveBtn" : {
      height: "40px",
      width: "120px",
      fontFamily: 'Plus Jakarta Sans',
      fontSize: '14px',
      fontWeight: 600,
      textTransform: "none",
      margin: "15px 30px",
      float: "right",
      borderRadius: 30,
      color: "white",
      background: "#3173E1"
    }
})

// Customizable Area End