// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";

import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { apiCalling, truthyValue } from '../../../components/src/asset'
import { toast } from "react-toastify";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface PreferenceType  {
    minDuration : boolean,
    maxDuration : boolean,
    advanceNotice : boolean,
    weekEndDuration : boolean,
    instantAcceptance : boolean
}

interface PreferenceValue  {
    minDuration : number,
    maxDuration : number,
    advanceNotice : number,
    weekEndDuration : boolean,
    instantAcceptance : boolean
}

type DataItem = {
    id: number;
    label: string;
    value: number;
    isRecommand: boolean;
    matchKey: string;
  };

interface DisplayData {
    minDuration : string,
    maxDuration : string,
    advanceNotice : string,
}

interface S {
  // Customizable Area Start  
  loading : boolean,
  catalougeId : string,
  tripPreferenceId: string,
  tripPreferenceType : PreferenceType,
  tripPreferenceValue : PreferenceValue,
  displayData : DisplayData
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class TripPreferenceController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getCatalougeInfoApiCallId: string = ""
  getTripPreferenceApiCallId : string = ""
  saveTripPreferenceApiCallId : string = ""
  updateTripPreferenceApiCallId : string = ""
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];
    this.state = {
        loading : true,
        catalougeId: "",
        tripPreferenceId: "",
        tripPreferenceType : this.initialPreferenceData,
        tripPreferenceValue : this.initialPreferenceValue,
        displayData : {
            minDuration : "",
            maxDuration : "",
            advanceNotice: ""
        }
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      switch(apiRequestCallId) {
        case this.getCatalougeInfoApiCallId :
            return this.handleResponseForCatalougePreference(responseJson)
        case this.getTripPreferenceApiCallId :
            return this.handleResponseForGetTripPreference(responseJson);
        case this.saveTripPreferenceApiCallId:
            return this.handleResponseForSaveTripPreference(responseJson)
        case this.updateTripPreferenceApiCallId:
           return this.handleResponseForPutTripPreference(responseJson)
      }

      
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount(){
     const catalougeId = this.props.navigation.getParam("subId")
     this.setState({ catalougeId : catalougeId})
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined): void {
      if (this.state.catalougeId !== prevState.catalougeId) {
          this.getCatalougeData()
      }
      if (this.state.tripPreferenceId !== prevState.tripPreferenceId) {
          this.getTripPreferenceData()
      }
      
  }

    handleResponseForGetTripPreference = (responseJson: any) => {
        if (responseJson && responseJson.data) {
            const attributesObj = responseJson.data.attributes
            const tripPreferenceValue = {
                minDuration: this.findValuesFromArray(this.minimumTripDurationData, truthyValue(attributesObj.min_duration), 1),
                maxDuration: this.findValuesFromArray(this.maximumTripDurationData, truthyValue(attributesObj.max_duration), 0),
                advanceNotice: this.findValuesFromArray(this.advancedNoticeData, truthyValue(attributesObj.advanced_notice), 2),
                weekEndDuration: truthyValue(attributesObj.weekend_restriction),
                instantAcceptance: truthyValue(attributesObj.instant_acceptance)
            }

            const displayData = {
                minDuration: this.handleRecommandedCheck(truthyValue(attributesObj.min_duration), truthyValue(attributesObj.recommendations.min_duration)),
                maxDuration: this.handleRecommandedCheck(truthyValue(attributesObj.max_duration), truthyValue(attributesObj.recommendations.maximum_trip)),
                advanceNotice: this.handleRecommandedCheck(truthyValue(attributesObj.advanced_notice), truthyValue(attributesObj.recommendations.advanced_notice))
            }

            this.setState({ tripPreferenceValue: tripPreferenceValue, displayData: displayData, loading: false })

        } else {
            this.setState({ loading: false })
        }
    }

    handleResponseForPutTripPreference = (responseJson: any) => {
        if (responseJson && responseJson.data) {
            toast.success("Preferences have been updated successfully")
            this.setState({ tripPreferenceType: this.initialPreferenceData })
            this.getTripPreferenceData()
        } else {
            this.setState({ loading: false })
        }
    }

    handleResponseForSaveTripPreference = (responseJson: any) => {
        if (responseJson && responseJson.data) {
            const id = truthyValue(responseJson.data.id)
            this.setState({ tripPreferenceId : id})
            
        } else {
            this.setState({ loading: false })
        }
    }

    handleResponseForCatalougePreference = (responseJson: any) => {
        if (responseJson && responseJson.catalogue && responseJson.catalogue.data) {
            const tripPreferenceId = truthyValue(responseJson.catalogue.data.attributes.trip_preference) ? truthyValue(responseJson.catalogue.data.attributes.trip_preference.data.id) : ""
            if(tripPreferenceId !== "") {
                this.setState({ tripPreferenceId : tripPreferenceId})
            } else {
                this.saveTripPreferenceData()
            }
        } else {
            this.setState({ loading: false })
        }
    }

  navToPreviousPage = () => {
    this.props.navigation.goBack()
  }

  initialPreferenceData : PreferenceType = {
    minDuration : false,
    maxDuration: false,
    advanceNotice: false,
    weekEndDuration: false,
    instantAcceptance: false
  }

  initialPreferenceValue : PreferenceValue = {
    minDuration : 1,
    maxDuration: 0,
    advanceNotice: 2,
    weekEndDuration: false,
    instantAcceptance: false
  }

  minimumTripDurationData : DataItem[] = [
    { id: 1, label: "1 Day", value: 1, isRecommand : true, matchKey : "1 Day"},
    { id: 2, label: "2 Days", value: 2, isRecommand : false, matchKey : "2 Days"},
    { id: 3, label: "3 Days", value: 3, isRecommand : false, matchKey : "1 3 Days"},
    { id: 4, label: "5 Days", value: 5, isRecommand : false, matchKey : "5 Days"},
  ]

  maximumTripDurationData : DataItem[] = [
    { id: 1, label: "Any", value: 0, isRecommand : true, matchKey : "Any"},
    { id: 2, label: "1 Day", value: 1, isRecommand : false, matchKey : "1 Day"},
    { id: 3, label: "5 Days", value: 5, isRecommand : false, matchKey : "5 Days"},
    { id: 4, label: "2 Weeks", value: 15, isRecommand : false, matchKey : "15 Days"},
    { id: 5, label: "1 Month", value: 30, isRecommand : false, matchKey : "30 Days"},
    { id: 6, label: "3 Months", value: 90, isRecommand : false, matchKey : "90 Days"},
  ]

  advancedNoticeData: DataItem[] = [
    { id: 1, label: "1 Hour", value: 1,isRecommand : false, matchKey : "1 hour"},
    { id: 2, label: "2 Hours", value: 2,isRecommand : true, matchKey : "2 hours"},
    { id: 3, label: "3 Hours", value: 3,isRecommand : false, matchKey : "3 hours"},
    { id: 4, label: "6 Hours", value: 6,isRecommand : false, matchKey : "6 hours"},
    { id: 5, label: "12 Hours", value: 12,isRecommand : false, matchKey : "12 hours"},
    { id: 6, label: "1 Day", value: 24,isRecommand : false, matchKey : "24 hours"},
  ]

  getCatalougeData = () => {
    const header = {
        token : localStorage.getItem("userToken")
    }

    this.getCatalougeInfoApiCallId = apiCalling({
        header : JSON.stringify(header),
        method: configJSON.apiMethodTypeGet,
        endPoint: `${configJSON.getCatalougeInfoApiEndpoint}/${this.state.catalougeId}`
    })
  }

  getTripPreferenceData = () => {
    const header = {
        token : localStorage.getItem("userToken")
    }

    this.getTripPreferenceApiCallId = apiCalling({
        header : JSON.stringify(header),
        method: configJSON.apiMethodTypeGet,
        endPoint: `${configJSON.tripPreferencesApiEndPoint}/${this.state.tripPreferenceId}`
    })
  }

  saveTripPreferenceData = () => {
    const header = {
        token : localStorage.getItem("userToken")
    }

    const formData = new FormData()

    formData.append("catalogue_id", this.state.catalougeId)

    this.saveTripPreferenceApiCallId = apiCalling({
        header : JSON.stringify(header),
        method: configJSON.apiMethodTypePost,
        endPoint: configJSON.tripPreferencesApiEndPoint,
        body: formData
    })
  }

  updateTripPreferenceData = () => {
    const header = {
        token : localStorage.getItem("userToken")
    }

    this.setState({ loading : true})

    const formData = new FormData()

    formData.append("trip_preference[min_duration]", this.state.tripPreferenceValue.minDuration.toString())
    formData.append("trip_preference[max_duration]", this.state.tripPreferenceValue.maxDuration.toString())
    formData.append("trip_preference[advanced_notice]", this.state.tripPreferenceValue.advanceNotice.toString())
    formData.append("trip_preference[weekend_restriction]", this.state.tripPreferenceValue.weekEndDuration.toString())
    formData.append("trip_preference[instant_acceptance]", this.state.tripPreferenceValue.instantAcceptance.toString())

    this.updateTripPreferenceApiCallId = apiCalling({
        header : JSON.stringify(header),
        method: configJSON.apiMethodTypePut,
        endPoint: `${configJSON.tripPreferencesApiEndPoint}/${this.state.tripPreferenceId}`,
        body: formData
    })
  }

  onEditClick = (value : keyof PreferenceType) => {
    this.setState((prevState) => ({
        ...prevState,
        tripPreferenceType: {
          ...prevState.tripPreferenceType,
          [value]: true,
        },
      }));
  }

  handleChangeforChecks = (event: React.ChangeEvent<HTMLInputElement>, keyName: keyof PreferenceType) => {
        const inputValue = event.target.checked
        this.setState((prevState) => ({
            ...prevState,
            tripPreferenceValue: {
                ...prevState.tripPreferenceValue,
                [keyName]: inputValue,
            },
        }));

   }

   handleTripPreferenceValue = (event: React.ChangeEvent<HTMLInputElement>) => {

      const { name, value } = event.target

      this.setState((prevState) => ({
        ...prevState,
        tripPreferenceValue: {
            ...prevState.tripPreferenceValue,
            [name]: value,
        },
    }));

   }

   findValuesFromArray = (dataArr : DataItem[], matchStr : string, defaultValue : number) => {
     const modifiedArr = dataArr.find(item => item.matchKey.toLowerCase() === matchStr.toLowerCase())
     const value = modifiedArr ? modifiedArr.value : defaultValue
     return value
   }

   handleRecommandedCheck = (mainStr : string, subStr : string) => {
      return mainStr.toLowerCase() === subStr.toLowerCase() ? `${mainStr} (Recommended)` : mainStr
   }


  // Customizable Area End
}

// Customizable Area End