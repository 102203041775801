// Customizable Area Start
import React from "react";

import {
    Box,
    styled,
    Typography,
    Button,
    OutlinedInput,
    Table,
    TableCell,
    TableBody,
    TableContainer,
    TableHead,
    Paper,
    TableRow,
    TableFooter,
    Tooltip,
    Select,
    MenuItem
} from "@material-ui/core"

import AppHeader from "../../../blocks/notifications/src/TopHeader.web"
import Footer from "../../../components/src/Footer.web";
import Loader from "../../../components/src/Loader.web";

import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import Pagination from '@material-ui/lab/Pagination';

import { arrowLeftIcon, arrowRightIcon, dropDownIcon, filterIcon, searchIcon, sortIcon } from "../../../blocks/reservations/src/assets";

import TripHistoryController, {
    Props,
    HistoryData
  } from "./TripHistoryController";

class TripHistory extends TripHistoryController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  renderTripHistoryTable = () => {
    return (
        this.state.tripHistoryData.length > 0 ?
            <Box>
                <Box style={{ margin: "20px 0" }}>

                    <Box style={{ margin: "20px 0", display: "flex", justifyContent: "space-between" }}>
                        <Box>
                            <OutlinedInput
                                className="searchField"
                                placeholder="Search here"
                                data-test-id="searchTrip"
                                startAdornment={<img src={searchIcon} />}
                            />
                        </Box>

                        <Box style={{ display: "flex", gap: 10 }}>
                            <button className="filterIcons">
                                <img src={filterIcon} />Filter
                            </button>
                            <button className="filterIcons">
                                <img src={sortIcon} />Latest  <img style={{ marginLeft: 6 }} src={dropDownIcon} />
                            </button>
                        </Box>
                    </Box>

                    <TableContainer component={Paper}>
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow className="tableRowHeader">
                                    <TableCell className="headerTitleText">Brand/Make</TableCell>
                                    <TableCell className="headerTitleText">Reservation Date (From - To)</TableCell>
                                    <TableCell className="headerTitleText">Renter</TableCell>
                                    <TableCell className="headerTitleText">Revenue</TableCell>
                                    <TableCell className="headerTitleText">Action</TableCell>
                                </TableRow>
                            </TableHead>
                            {this.state.tripHistoryData.map((item, index) =>
                                <TableBody key={index}>
                                    <TableRow>
                                        <TableCell colSpan={5}>
                                            <Typography className="cellTypographyText">{item.monthName}</Typography>
                                        </TableCell>
                                    </TableRow>
                                    {item.bokedSlotInfo.map((history: HistoryData) => {
                                        return (
                                            <TableRow key={history.id}>
                                                <TableCell component="th" scope="row">
                                                    <Box style={{ display: "flex", gap: 10 }}>
                                                        <Box>
                                                            <img
                                                                style={{ height: 48, width: 48, borderRadius: "100%" }}
                                                                src={history.image} />
                                                        </Box>
                                                        <Box>
                                                            <Typography className="cellTypographyText">{history.title}</Typography>
                                                            <Tooltip title={history.address}>
                                                                <Typography className="cellTooltipText" style={{ maxWidth: 200 }} noWrap>{history.address}</Typography>
                                                            </Tooltip>
                                                        </Box>
                                                    </Box>

                                                </TableCell>
                                                <TableCell>
                                                    <Typography className="cellTypographyText" style={{ fontWeight: 500 }}>{history.startDate}</Typography>
                                                    <Typography className="cellTypographyText" style={{ fontWeight: 500 }}>- {history.endDate}</Typography>

                                                </TableCell>

                                                <TableCell>
                                                    <Typography className="cellTypographyText">{history.renterName}</Typography>
                                                </TableCell>

                                                <TableCell>
                                                    <Typography className="cellTypographyText">{history.revenue}</Typography>
                                                </TableCell>

                                                <TableCell>
                                                    <Box style={{ display: "flex", alignItems: "center", gap: 15, cursor: "pointer" }}>

                                                        <Typography className="cellTypographyText">Report an Issue </Typography>
                                                        <ArrowForwardIosIcon style={{ height: 20, width: 20 }} />
                                                    </Box>
                                                </TableCell>
                                            </TableRow>
                                        )
                                    })}

                                </TableBody>
                            )}

                            <TableFooter>
                                <TableRow>
                                    <TableCell style={{ margin: 16 }} colSpan={4}>
                                        <Box style={{ display: 'flex', alignItems: 'center', gap: '30px' }}>
                                            <PaginationWrap
                                                data-test-id="pagination"
                                                count={this.state.totalPage}
                                                page={this.state.currentPage}
                                                onChange={this.handlePageChange}
                                                siblingCount={0}
                                                boundaryCount={3}

                                            />
                                            <Box style={{ display: 'flex', alignItems: 'center', gap: '13px', fontSize: '14px', fontWeight: 700, color: '#475467', cursor: "pointer" }}>
                                                Show:
                                                <Select
                                                    value={this.state.perPage}
                                                    onChange={this.perPageChange}
                                                    disableUnderline
                                                    data-test-id="perPage"
                                                    className="paginationText"
                                                >
                                                    <MenuItem value={2}>2</MenuItem>
                                                    <MenuItem value={5}>5</MenuItem>
                                                    <MenuItem value={9}>9</MenuItem>
                                                </Select>
                                            </Box>
                                        </Box>
                                    </TableCell>
                                    <TableCell style={{ margin: 16 }} colSpan={1}>
                                        <Box style={{ display: "flex", gap: 15 }} >
                                            {this.state.currentPage > 1 &&
                                                <Box data-test-id="previousPage" onClick={this.previousPage} style={{ display: "flex", gap: 8, cursor: "pointer", alignItems: 'center' }}>
                                                    <img style={{ width: "20px" }} src={arrowLeftIcon}></img>
                                                    <Typography className="paginationText">Previous</Typography>
                                                </Box>}
                                            {this.state.currentPage < this.state.totalPage &&
                                                <Box data-test-id="nextPage" onClick={this.nextPage} style={{ display: "flex", gap: 8, alignItems: 'center', marginLeft: "14px", cursor: "pointer" }}>
                                                    <Typography className="paginationText">Next</Typography>
                                                    <img style={{ width: "20px" }} src={arrowRightIcon}></img>
                                                </Box>}
                                        </Box>
                                    </TableCell>
                                </TableRow>
                            </TableFooter>
                        </Table>
                    </TableContainer>

                </Box>
            </Box>
            :
            <Box style={{ textAlign: "center" }}>
                <Typography className="errorText">No Data Available</Typography>
            </Box>
    )
  }
  render() {
    // Customizable Area Start
    
    return (
      <div>
            <AppHeader navigation={this.props.navigation} activeLink="Dashboard" />

            <TripWrapper>
                <Button
                    onClick={this.navToPreviousPage}
                    data-test-id="backBtn"
                    className="navBackBtn"
                    startIcon={<ArrowBackIosIcon style={{ color: "#667085", height: 16, width: 16 }} />}>
                    Back
                </Button>
                <Typography className="headerHistoryText">Trip History</Typography>

                {this.state.loading ? <Loader loading={true} /> : this.renderTripHistoryTable() }

            </TripWrapper>

            <Footer />
      </div>
    );
    // Customizable Area End
  }
}

export default TripHistory;

const TripWrapper = styled(Box)({
    maxWidth: "1220px",
    margin: "40px auto",
    "& .headerHistoryText" : {
        color: '#101828',
        fontFamily: 'Plus Jakarta Sans',
        fontWeight: 600,
        fontSize: '30px',
        margin: "20px 0"
    },
    "& .navBackBtn": {
        fontFamily: 'Plus Jakarta Sans',
        textTransform: "none",
        fontWeight: 500,
        color: '#667085',
        fontSize: '14px',
        background: "white",
    },
    "& .filterIcons" : {
        background: 'white', 
        borderRadius: 30, 
        color: '#475569', 
        padding: '8px 16px', 
        fontSize: 14, 
        fontWeight: 600, 
        display: 'flex', 
        alignItems: 'center', 
        border: '1px solid #D0D5DD', 
        gap: 8 ,
        cursor:"pointer"
      },
      "& .searchField" : {
        height: 40,
        width: 400,
        padding: "10px 16px",
        fontWeight: 600,
        color: "#475467",
        fontFamily: 'Plus Jakarta Sans',
        borderRadius: "12px",
      },
      "& .searchField .MuiOutlinedInput-input" : {
        padding : 10,
      },
      "& .tableRowHeader" : {
        background: "#EAECF0"
      },
      "& .headerTitleText" : {
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '12px',
        fontWeight: 700,
        color: '#475467'
      },
      "& .cellTypographyText" : {
          fontFamily: 'Plus Jakarta Sans',
          fontSize: '16px',
          fontWeight: 600,
          color: '#23395D'
      }, 
      "& .cellTooltipText" : {
          fontFamily: 'Plus Jakarta Sans',
          fontSize: '14px',
          fontWeight: 400,
          color: '#667085'
      },
      "& .errorText" : {
        color: '#101828',
        fontFamily: 'Plus Jakarta Sans',
        fontWeight: 600,
        fontSize: '16px',
        margin: "10px 0"
      },
      "& .paginationText" : {
        color: '#475467',
        fontFamily: 'Plus Jakarta Sans',
        fontWeight: 600,
        fontSize: '14px',
      },
})

const PaginationWrap = styled(Pagination)({
    '& .MuiPagination-ul li:last-child': {
        display: 'none',
    },
    '& .MuiPagination-ul li:first-child': {
      display: 'none',
    },
    '@media(max-width: 767px)': {
      '& .MuiPaginationItem-root': {
        minWidth: 25, height: 25, margin: 0
      }
    }
  })

// Customizable Area End