import { Box, styled } from "@material-ui/core";
import React, { useEffect, useRef, useState, ChangeEvent, KeyboardEvent } from "react";

interface OtpInputProps {
  length?: number;
  onChange?: (otp: string) => void;
  newUser? : boolean
}

const OtpInput: React.FC<OtpInputProps> = ({
  length = 6,
  onChange = () => {},
  newUser= false
}) => {
  const [otp, setOtp] = useState<string[]>(new Array(length).fill(""));
  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);

  useEffect(() => {
    if (inputRefs.current[0]) {
      inputRefs.current[0].focus();
    }
  }, []);

  const handleChange = (index: number, event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (isNaN(Number(value))) return;

    const newOtp = [...otp];
    newOtp[index] = value.substring(value.length - 1);
    setOtp(newOtp);

    const combinedOtp = newOtp.join("");
    if (combinedOtp.length === length) {
      onChange(combinedOtp);
    }

    if (value && index < length - 1 && inputRefs.current[index + 1]) {
      inputRefs.current[index + 1]!.focus();
    }
  };

  const handleClick = (index: number) => {
    if (inputRefs.current[index]) {
      inputRefs.current[index]!.setSelectionRange(1, 1);
    }

    if (index > 0 && !otp[index - 1]) {
      const emptyIndex = otp.indexOf("");
      if (emptyIndex !== -1 && inputRefs.current[emptyIndex]) {
        inputRefs.current[emptyIndex]!.focus();
      }
    }
  };

  const handleKeyDown = (index: number, event: KeyboardEvent<HTMLInputElement>) => {
    if (
      event.key === "Backspace" &&
      !otp[index] &&
      index > 0 &&
      inputRefs.current[index - 1]
    ) {
      inputRefs.current[index - 1]!.focus();
    }
  };

  return (
    <OtpWrapper>
      {otp.map((value, index) => {
        return (
          <input
            key={index}
            type="text"
            ref={(input) => (inputRefs.current[index] = input)}
            value={value}
            onChange={(event) => handleChange(index, event)}
            onClick={() => handleClick(index)}
            onKeyDown={(event) => handleKeyDown(index, event)}
            className={newUser ? "newUserBox" : "otpInput"}
          />
        );
      })}
    </OtpWrapper>
  );
};

const OtpWrapper = styled(Box)({
  "& .otpInput": {
    width: "48px",
    height: "48px",
    margin: "5px",
    textAlign: "center",
    fontFamily: 'Plus Jakarta Sans',
    fontSize: '16px',
    color: "#23395D",
    fontWeight:600,
    boxShadow: '0px 4px 5px 0px #0000000D',
    border: '1px solid #E7E7E7',
    borderRadius: '12px'
  },
  "& .newUserBox" : {
    width: "80px",
    height: "80px",
    margin: "5px",
    textAlign: "center",
    fontFamily: 'Plus Jakarta Sans',
    fontSize: '32px',
    color: "#3173E1",
    fontWeight:600,
    border: '1px solid #3173E1',
    borderRadius: '8px'
  }

})

export default OtpInput;
