// Customizable Area Start
import React from "react";
import {
    Box, 
    styled,
    Typography,
    Input
} from "@material-ui/core"
import { addIcon, sendIcon } from "./assets";


import ReservationChatController, {
    Props,
  } from "./ReservationChatController";


class ReservationChat extends ReservationChatController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  render() {
    // Customizable Area Start
    
    return (
      <div>
         <ChatWrapper>
                <Box className="mainBox">
                    <Box className="senderBox">
                        <img
                            className="imgBox"
                            src={this.state.senderInfo.senderImage}
                        />
                        <Typography className="senderNameBox">{this.state.senderInfo.senderName}</Typography>
                        <Box className="chipBox">
                            <Typography className="innerText">{this.state.senderInfo.senderRole}</Typography>
                        </Box>
                    </Box>

                    <Box className="chatArea">
                        {this.state.chatInfo.map(item => {
                            const userType = this.handleChatTypeCondition(item.chatType)

                            return userType ?
                                <Box key={item.msgId}>
                                    <Box className="senderChatBox">
                                        <Box>
                                            <Box className="commonChatBox senderChatRadius">
                                                <Typography className="chatText">
                                                    {item.msgText}
                                                </Typography>
                                            </Box>
                                            <Typography className="timeTextChatBlock"  style={{ textAlign : "right"}}>{item.chatTime}</Typography>
                                        </Box>
                                        <img className="imgChat" src={item.userImage} />

                                    </Box>
                                </Box>
                                :
                                <Box key={item.msgId}>
                                    <Box className={"receiverChatBox"}>
                                        <img className="imgChat" src={item.userImage} />
                                        <Box>
                                            <Box className={`commonChatBox receiverChatRadius`}>
                                                <Typography className="chatText">
                                                    {item.msgText}
                                                </Typography>
                                            </Box>
                                            <Typography className="timeTextChatBlock">{item.chatTime}</Typography>
                                        </Box>
                                    </Box>
                                </Box>
                        }
                           
                            
                            
                        )}

                        <div ref={this.msgRef}></div>   
                        
                    </Box>

                    


                    <Box className="messageBox">
                        <Box className="textInput">
                            <Input
                                startAdornment={<img src={addIcon} />}
                                endAdornment={<img src={sendIcon} data-test-id="sendIcon" onClick={this.sendMessages} style={{ cursor: "pointer"}} alt="send" />}
                                placeholder="Write your message..."
                                className="inputText"
                                value={this.state.textMsg}
                                onChange={this.handleTextMsgChange}
                                data-test-id="inputText"
                                fullWidth
                                disableUnderline
                            />
                        </Box>
                    </Box>
                </Box>
         </ChatWrapper>
      </div>
    );
    // Customizable Area End
  }
}

export default ReservationChat;

const ChatWrapper = styled(Box)({
    margin: "25px 0",
    "& .mainBox": {
        border: "1px solid #EAECF0",
        borderRadius: "12px"
    },
    "& .senderBox": {
        padding: "20px",
        display: "flex",
        alignItems: "center",
        gap: "12px",
        borderBottom: "1px solid #EAECF0",
        borderTopLeftRadius: "12px",
        borderTopRightRadius: "12px"
    },
    "& .chatArea": {
        padding: "0 20px",
        minHeight: "650px",
        maxHeight: "675px",
        overflowY: "scroll",
        scrollbarWidth: "none"
    },
    "& .imgBox" : {
        borderRadius: "100%",
        height : 32,
        width: 32
    },
    "& .senderNameBox" : {
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '20px',
        fontWeight: 600,
        textAlign: 'left',
        color: '#23395D'
    },
    "& .chipBox" : {
        background:"#F9FAFB",
        border: "1px solid #EAECF0",
        borderRadius: "6px"
    },
    "& .innerText": {
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '12px',
        fontWeight: 500,
        color: '#344054',
        padding: "2px 6px",
        textTransform: "capitalize"
    },
    "& .commonChatBox": {
        background: "#F4F4F5",
        padding: "10px 16px",
        width: "max-content"
    },
    "& .senderChatRadius" : {
        borderRadius: "16px 0px 16px 16px",
    },
    "& .receiverChatRadius" : {
        borderRadius: "0 16px 16px 16px",        
    },
    "& .chatText" : {
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '14px',
        fontWeight: 400,
        textAlign: 'left',
        color: '#475467',
        maxWidth: "465px"
    },
    "& .timeTextChatBlock": {
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '12px',
        fontWeight: 400,
        textAlign: 'left',
        color: '#667085',
        margin: "8px 0"
    },
    "& .receiverChatBox": {
        display: "flex",
        gap: "12px",
        justifyContent: "start",
        margin: "10px 0",
    },
    "& .senderChatBox": {
        display: "flex",
        justifyContent: "end",
        gap: "12px",
        margin: "10px 0",
    },
    "& .imgChat" : {
        height: 40,
        width: 40,
        borderRadius: "100%",
    },
    "& .messageBox" : {
        border: '1px solid #D0D5DD',
        borderRadius: '12px',
        margin: '10px 20px 20px 20px',
    },
    "& .textInput" : {
        padding: '16px'
    },
    "& .inputText" : {
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '14px',
        fontWeight: 500,
        textAlign: 'left',
        color: '#475467',
    },
    "& .inputText .MuiInputBase-input": {
        margin: "0 10px"
    }
})

// Customizable Area End