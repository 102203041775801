// Customizable Area Start
import { IBlock } from "framework/src/IBlock";
import { Message } from "framework/src/Message";
import { BlockComponent } from "framework/src/BlockComponent";
import { runEngine } from "framework/src/RunEngine";
import { EmojiClickData } from "emoji-picker-react";
import MessageEnum, {
  getName
} from "framework/src/Messages/MessageEnum";
import { apiCalling, truthyValue } from "../../../components/src/asset";
import { toast } from "react-toastify";
import { ChangeEvent } from "react";
import { ApexOptions } from "apexcharts"

export const configJSON = require("./config");

export interface DataItem {
  id: string;
  estimate_time: string;
  received: boolean;
  total_revenue: string;
}
export interface ReviewData {
  id: string;
  type: string;
  attributes: {
      id: string;
      catalogue_id: string;
      rating: number;
      comment: string;
      anonymity: boolean;
      reviewer_name: string;
      catalogue_name: string;
      catalogue_type: string;
      profile_img: {
          url: string;
      };
      created_at: string;
      time_ago: string;
      web_time_ago: string;
  }
}

interface Reviews {
  error: string;
  reviews: {
    data: ReviewData[]
  },
  average_rating: string;
  host_created: string;
  total_pages: number;
  current_page: number;
  rating_1: number;
  rating_2: number;
  rating_3: number;
  rating_4: number;
  rating_5: number
}

interface ScheduleData {
  day: string;
  date: number;
  value: number[] | null;
  catalogueType : string[]
}

interface CustomDataValue {
  [key: string]: number;
}

interface CustomData {
  data: {
    [range: string]: CustomDataValue;
  }
}

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start  
  tabValue: number,
  noOfHome: number,
  noOfCars: number,
  listData: any[],
  slider: boolean,
  addListModal: boolean,
  value:number,
  payoutData: DataItem[],
  openPayoutModal: boolean,
  openTransactionModal: boolean,
  reviewData: Reviews,
  replySection:boolean[];
  showMore:boolean[];
  replySectionId:number;
  showEmojiPicker: boolean;
  replyValues: { [key: number]: string };
  selectedReplyIndex:number;
  seeAllBtn:boolean
  loader : boolean;
  options: ApexOptions;
  series: ApexAxisChartSeries,
  hostScheduleData : ScheduleData[],
  loaderOverView : boolean
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class HostDashboardController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  becomeHostAPICallId: string = "";
  listCatalougeApiCallId: string = "";
  earningTabDataAPICallId:string="";
  reviewDataApiCallID: string ="";
  sendReplyApiCallID:string="";
  getHostScheduleApiCallId : string = ""
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];
    this.state = {
        tabValue : 0,
        noOfHome: 0,
        noOfCars: 0,
        listData: [],
        slider: false,
        addListModal: false,
        value:0,
        payoutData:[],
        openPayoutModal:false,
        openTransactionModal: false,
        reviewData:{
          reviews: {
            data: []
          },
          average_rating: "",
          host_created: "",
          total_pages: 0,
          current_page: 0,
          rating_1: 0,
          rating_2: 0,
          rating_3: 0,
          rating_4: 0,
          rating_5: 0,
          error: ""
        },
        replySection:[],
        showMore:[],
        replyValues:{},
        replySectionId:0,
        showEmojiPicker: false,
        selectedReplyIndex:0,
        seeAllBtn:false,
        loader : true,
        series: [
          {
            name: "Series 1",
            data: [20, 30, 32, 25, 38, 50, 45, 25, 23, 35, 40, 45],
          },
        ],
        options: {
          chart: {
            type: "bar",
            height: 350,
          },
          plotOptions: {
            bar: {
              borderRadius: 4,
              horizontal: false,
            },
          },
          dataLabels: {
            enabled: false,
          },
          xaxis: {
            categories: ["Jan", "Feb", "Mar", "Apr", "May", "June", "July", "Aug", "Sep", "Oct", "Nov", "Dec"],
          },
        },
        hostScheduleData : this.generateDynamicSchedule(),
        loaderOverView: true
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      switch (apiRequestCallId) {
        case this.becomeHostAPICallId:
          return this.responseBecomeHostAPI(responseJson)
        case this.listCatalougeApiCallId:
          return this.responseListCatalougeDataAPI(responseJson)
        case this.getHostScheduleApiCallId:
          return this.responseForHostScheduleApi(responseJson)
        case this.earningTabDataAPICallId:
          return this.setPayoutDataCall(responseJson.data)
        case this.reviewDataApiCallID:
          return this.setReviewDataCall(responseJson)
        case this.sendReplyApiCallID:
          return this.setState({ showEmojiPicker: false })
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  setReplySectionFalse = (index:number) => {
    this.setState(prevState => {
      const newReplySection = [...prevState.replySection];
      newReplySection[index] = false;
      return { replySection: newReplySection };
    });
  };
  setReviewDataCall = (response: Reviews) => {
    if(!response.error){
      this.setState({reviewData:response})

    }
  }
  setPayoutDataCall= (response: DataItem[]) => {
    this.setState({payoutData: response})
  }

  async componentDidMount(): Promise<void> {
     this.getHostScheduleData()
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined): void {
    if (this.state.tabValue === 1 && this.state.tabValue !== prevState.tabValue) {
      this.getUserCatalougeData()
    }
    if (this.state.tabValue === 2 && this.state.tabValue !== prevState.tabValue) {
      this.EarningPayoutData()
    }
    if (this.state.tabValue === 3 && this.state.tabValue !== prevState.tabValue) {
      this.getReviewData()
    }
    if (this.state.seeAllBtn !== prevState.seeAllBtn) {
      this.EarningPayoutData()
    }
    if(this.state.tabValue === 0 && this.state.tabValue !== prevState.tabValue) {
      this.getHostScheduleData()
    }
  }
   handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
     this.setState({ tabValue : newValue})
  };

  responseBecomeHostAPI = (responseJson: any) => {
    if(responseJson && responseJson.message) {
      toast.success("Your role has been succesfully changed to Host. Please login to Continue")
      localStorage.clear()
      this.props.navigation.navigate("LogIn")
    } else {
      toast.error("Something went wrong!")
    }
  }

  sliderOn = () => {
    this.setState({ slider : true})
  }

  sliderOff = () => {
    this.setState({ slider : false})
  }

  handleCloseAddListModal = () => {
    this.setState({ addListModal : false})
  }

  handleOpeneAddListModal = () => {
    this.setState({ addListModal : true})
  }

  responseListCatalougeDataAPI = (responseJson: any) => {
    if(responseJson && responseJson.data) {
      const nmberOfCars = responseJson.meta.vehicles
      const nmberOfHomes = responseJson.meta.homes
      this.setState({ noOfCars : nmberOfCars, noOfHome: nmberOfHomes, listData: responseJson.data, loader: false})
    } else {
      this.setState({ listData: [], loader: false})
    }
  }

  responseForHostScheduleApi = (responseJson: CustomData) => {
    if(responseJson && responseJson.data && Object.keys(responseJson.data).length > 0) {

      const results: { range: string, startDate: number | null, value: number | null, catalogueType: string | null }[] = [];

      for (const [rangeKey, valueData] of Object.entries(responseJson.data)) {
        const { startDate, value, catalogueType } = this.extractStartDateAndDetails(rangeKey, valueData);
        results.push({ range: rangeKey, startDate, value, catalogueType });
      }

      const newArr = this.generateResponse(results)

      this.setState({ hostScheduleData : newArr, loaderOverView: false})
    } else {
      this.setState({ loaderOverView: false})
    }
  }

  generateResponse = (results: any[]): ScheduleData[] => {
    const response: any[] = [];
  
    for (let i = 1; i <= 30; i++) {
      response.push({
        day: this.getDayName(i),
        date: i,
        value: [],
        catalogueType: []
      });
    }

    // Update response array based on results
    results.forEach(result => {
      const { startDate, value, catalogueType } = result;

      // Ensure we do not access out of bounds
      if (startDate >= 1 && startDate <= 30) {
        const item = response[startDate - 1];
        if (item) {
          item.value.push(value);
          item.catalogueType.push(catalogueType);
        }
      }
    });

    return response;
  };

  getDayName = (date: number): string => {
    const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    const d = new Date(2024, 8, date); // September is month 8 (0-based index)
    return days[d.getDay()];
  };

  becomeHost = () => {
    const header = {
      "Content-Type": configJSON.productApiContentType,
      "token": localStorage.getItem("userToken")
    };

    this.becomeHostAPICallId = apiCalling({
      header: JSON.stringify(header),
      method: configJSON.apiMethodTypePut,
      endPoint: configJSON.becomeHostApiEndPoint
    })
  }

  getUserCatalougeData = () => {
    const headerWeb = {
      "Content-Type": configJSON.productApiContentType,
      "token": localStorage.getItem("userToken")
    };
    
    this.listCatalougeApiCallId = apiCalling({
      header: JSON.stringify(headerWeb),
      endPoint: configJSON.getUsersCatalougeApiEndpoint,
      method: configJSON.apiMethodTypeGet
    })
  }

  getHostScheduleData = () => {
    const headerWeb = {
      "token": localStorage.getItem("userToken")
    };

    const startDateTime = this.getMonthStartAndEnd().startDateTime + "T00:00:00"
    const endDateTime = this.getMonthStartAndEnd().endDateTime + "T23:59:59"
    
    this.getHostScheduleApiCallId = apiCalling({
      header: JSON.stringify(headerWeb),
      endPoint: `${configJSON.getHostScheduleApiEndPoint}?search_start_date=${startDateTime}&search_end_date=${endDateTime}`,
      method: configJSON.apiMethodTypeGet
    })
  }

  getMonthStartAndEnd(): { startDateTime: string; endDateTime: string } {
    const now = new Date();

    // Get the first day of the current month
    const startDate = new Date(now.getFullYear(), now.getMonth(), 1);
    // Get the last day of the current month
    const endDate = new Date(now.getFullYear(), now.getMonth() + 1, 0);

    // Format date to YYYY-MM-DD
    const formatDate = (date: Date): string => date.toISOString().slice(0, 10); // Get only YYYY-MM-DD

    return {
      startDateTime: formatDate(startDate),
      endDateTime: formatDate(endDate)
    };
  }

  generateDynamicSchedule(): ScheduleData[] {
    const now = new Date();
    const year = now.getFullYear();
    const month = now.getMonth();
    const daysInMonth = new Date(year, month + 1, 0).getDate(); 
    const scheduleData: ScheduleData[] = [];
  
    for (let day = 1; day <= daysInMonth; day++) {
      const date = new Date(year, month, day);
      const dayName = date.toLocaleString('default', { weekday: 'short' });
  
      scheduleData.push({
        day: dayName,
        date: day,
        value: null,
        catalogueType: []
      });
    }
  
    return scheduleData;
  }

  extractStartDateAndDetails = (range: string, data: CustomDataValue): { startDate: number | null, value: number | null, catalogueType: string | null } => {
    const [startStr] = range.split(' to ').map(dateStr => dateStr.trim());
    const date = new Date(`${startStr} 2024`); // Parse date with fixed year
    
    // Extract the catalogue type (assuming a single key-value pair in the data)
    const [catalogueType] = Object.keys(data);
    const value = data[catalogueType] || null;
  
    // Return the start date, value, and catalogue type
    return {
      startDate: !isNaN(date.getDate()) ? date.getDate() : null,
      value,
      catalogueType: catalogueType || null
    };
  }

  navigateToListInfoPage = (catalogueId:number,catalogueType: string ) => {
    this.props.navigation.navigate("ListingSettings",{catalogueId,catalogueType})
  };

  navigateToAddCarListingPage = () => {
    this.props.navigation.navigate("AddCarListing")
  };

  navigateToAddHomeListingPage = () => {
    this.props.navigation.navigate("AddHomeListing")
  };

  navigateToGivenPage = (navUrl : string) => {
    if(navUrl !== "") {
      this.props.navigation.navigate(navUrl)
      return false
    }
  };

  formatCarNumberPlate = (actualString : string) => {

      const letterMatches = actualString.match(/[a-zA-Z]+/g);
      const digitMatches = actualString.match(/\d+/g);
      
      const letters = letterMatches ? letterMatches.join('') : '';
      const digits = digitMatches ? digitMatches.join('') : '';
      
      const formattedLetters = letters.slice(-2).toUpperCase();
      const formattedDigits = digits.slice(-4);
      
      return `${formattedLetters} ${formattedDigits}`;
  }

  handleCardTitle = (item : any) => {
     return item.attributes.name === "Car" ? `${item.attributes.sub_category} (${item.attributes.car_year})` : `${item.attributes.features.find((feature: any) => feature.title === 'home_title')?.value}`
  }

  handleCardHeader = (item : any) => {
    return item.attributes.name === "Car" ? `Plate : ${item.attributes.car_number_plate && this.formatCarNumberPlate(item.attributes.car_number_plate)}` : `${item.attributes.address?.address} ${item.attributes.address?.country}`
 }

 handleCardTrips = (item : any) => {
  return item.attributes.name === "Car" ? `Trips : ${item.attributes.trips_completed} completed` : `Bookings: ${item.attributes.trips_completed} completed`
}
handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
  this.setState({ value: newValue });
};

a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
};
EarningPayoutData = () => {
  const header = {
    "token": localStorage.getItem("userToken")
  };

  this.earningTabDataAPICallId = apiCalling({
    header: JSON.stringify(header),
    method: configJSON.apiMethodTypeGet,
    endPoint: configJSON.earningPayoutDataEndPoints
  })
}
handlePayoutModal = () => {
  this.setState({openPayoutModal: true})
}
closePayoutModal = () => {
  this.setState({openPayoutModal: false})
}

handleTransactionModal = () => {
  this.setState({ openTransactionModal: true })
}

closeTransactionModal = () => {
  this.setState({ openTransactionModal: false })
}
getReviewData = () => {
  const header = {
    "token": localStorage.getItem("userToken")
  };

  this.reviewDataApiCallID = apiCalling({
    header: JSON.stringify(header),
    method: configJSON.apiMethodTypeGet,
    endPoint: configJSON.reviewDataEndPoints
  })
}
handleComment = (index: number) => {
  this.setState(prevState => {
    const newReplySection = [...prevState.replySection];
    newReplySection[index] = !newReplySection[index];
    return { replySection: newReplySection };
  });
  this.setState({replySectionId:index})
}
showComment = (comment:string, index:number) => {
if(comment.length>180 && !this.state.showMore[index]){
  return `${comment.slice(0,181)}...`
}
else{
  return comment
}
}
seeMoreBtn = (index: number) => {
  this.setState(prevState => {
    const newShowMore = [...prevState.showMore];
    newShowMore[index] = true;
    return { showMore: newShowMore };
  });
};

seeLessBtn = (index: number) => {
  this.setState(prevState => {
    const newShowMore = [...prevState.showMore];
    newShowMore[index] = false;
    return { showMore: newShowMore };
  });
};

sendReplyApiCall = (replyId: number | string, index: number) => {
  this.setReplySectionFalse(index)
  const header = {
    token: localStorage.getItem("userToken"),
    "Content-Type": "application/json",
  };
  const httpBody = {
    comment: this.state.replyValues[index],
  };
  this.sendReplyApiCallID = apiCalling({
    header: JSON.stringify(header),
    method: configJSON.apiMethodTypePost,
    endPoint: configJSON.sendReplyEndPoints(replyId),
    body: JSON.stringify(httpBody),
  });

  this.setState((prevState) => ({
    replyValues: {
      ...prevState.replyValues,
      [index]: "",
    },
  }));
};
setSelectedReplyIndex = (index: number) => {
  this.setState({ selectedReplyIndex: index, showEmojiPicker: true });
};
onEmojiClick = (emojiData: EmojiClickData) => {
  const { selectedReplyIndex } = this.state;
  this.setState((prevState) => ({
    replyValues: {
      ...prevState.replyValues,
      [selectedReplyIndex]: (prevState.replyValues[selectedReplyIndex] || "") + emojiData?.emoji,
    },
  }));
};

handleReply = (index: number) => (event: ChangeEvent<HTMLInputElement>) => {
  const value = event.target.value;
  this.setState((prevState) => ({
    replyValues: {
      ...prevState.replyValues,
      [index]: value,
    },
  }));
};

handleKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
  if (event.key === "Enter") {
    this.setState({ showEmojiPicker: false });
  }
};
handleSeeAllBtn = () => {
  this.setState({seeAllBtn: true})
}
handleSeeLessBtn = () => {
  this.setState({seeAllBtn: false})
}
  // Customizable Area End
}

// Customizable Area End