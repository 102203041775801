import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { apiCalling, SearchCriteria, truthyValue } from "../../../components/src/asset";
import { house1, house2, house3, house4, house5, house6 } from "./assets";
import swal from "sweetalert"
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  // Customizable Area End
}

interface S {
  arrayHolder: any;
  token: string;
  // Customizable Area Start
  Homelist: any;
  errorResponse:string,
  listCard: any;
  startSliding:boolean,
  pageCount: number;
  rowPerPage: number;
  openFilterModal: boolean;
  listingName: string;
  selectedDate:any,
  modalValue: any;
  addGuest: boolean,
  openFilter: boolean,
  openDropFilter:boolean,
  adultsCount: number,
  childrenCount: number,
  infantsCount: number,
  searchResult: string,
  searchDropResult:string,
  filteredCities: string[],
  filteredDropCities:string[],
  openDateRangePicker: boolean,
  selectedDateOnAddDates: any[],
  selectedItem:number,
  homeCatogoriesData: any[],
  checkInTime: string,
  checkOutTime: string,
  guestCount : string,
  homeCatogoryId: string | number,
  carCatogoryId: string | number,
  perPageData : number,
  activePage : number,
  totalPages: number,
  loader: boolean
  searchData : any[]
  carCount: number,
  homeCount : number
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class CatalogueController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getProductApiCallId: string = ""
  getHomeListApiCallId: string = ""
  getCatalogueSeachCallID: string = ""
  getHomeTypeApiCallId: string = ""
  getAdvancedFilterApiCallId: string = ""
  getCatogoryApiCallId: string = ""
  getCatalogeCountApiCallId: string = ""
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      arrayHolder: [],
      token: "",
      // Customizable Area Start
      Homelist: [],
      pageCount: 1,
      selectedDate:"",
      errorResponse:"",
      listCard: [],
      startSliding:false,
      rowPerPage: 10,
      openFilterModal: false,
      openDropFilter:false,
      listingName: "Home",
      modalValue: [20, 50],
      addGuest: false,
      openFilter: false,
      adultsCount: 0,
      childrenCount: 0,
      infantsCount: 0,
      searchResult: "",
      searchDropResult: "",
      filteredCities: this.cities,
      filteredDropCities: this.cities,
      openDateRangePicker: false,
      selectedDateOnAddDates:[{
            startDate: new Date(),
            endDate: new Date(),
            key: 'selection'
        }],
        selectedItem: 0,
        homeCatogoriesData: [],
        checkInTime: "",
        checkOutTime: "",
        guestCount: "",
        homeCatogoryId: "",
        carCatogoryId: "",
        perPageData: 9,
        activePage: 1,
        totalPages: 1,
        loader: true,
        searchData: [],
        carCount: 0,
        homeCount: 0
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    // Customizable Area Start
    this.GetHomeCartList();
    this.getCatogoriesData();
    this.getCatalougeCounts()
    // Customizable Area End
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  getListRequest = (token: any) => {
    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getProductApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.productAPiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      this.getListRequest(token);
    }
    var responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    let errorReponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );

    this.handleApiCalls(message)

    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getProductApiCallId != null &&
      this.getProductApiCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      if (responseJson && !responseJson.errors && responseJson.data) {
        this.setState({ arrayHolder: responseJson.data });
        runEngine.debugLog("arrayHolder", this.state.arrayHolder);
      } else {
       
        this.parseApiCatchErrorResponse(errorReponse);
      }
    }
  
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getHomeListApiCallId != null &&
      this.getHomeListApiCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
        this.handleApiResponseForListing(responseJson);
    }
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getCatalogueSeachCallID != null &&
      this.getCatalogueSeachCallID ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
    this.handleApiResponseForsearch(responseJson,errorReponse)
    }
    // Customizable Area End
  }

  // Customizable Area Start

    handleApiResponseForsearch=(responseJson:any,errorReponse:any)=>{
      if (responseJson && !responseJson.errors && responseJson.data) {
        if(responseJson.data.length===0)
        {
          this.setState({Homelist: [],listCard:[], loader: false, searchData: [] },()=>{this.setState({errorResponse:"No Data Available"})})
        }else{
          this.setState({ Homelist: [],listCard:[],errorResponse:"", searchData : responseJson.data, loader: false });
        }
      }      
      else{
        this.setState({errorResponse:errorReponse, loader: false, searchData: []})
      }
    };

    handleApiCalls = (message : Message) => {
      if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
        const apiRequestCallId = message.getData(
          getName(MessageEnum.RestAPIResponceDataMessage)
        );
        const responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );

        switch(apiRequestCallId) {
          case this.getHomeTypeApiCallId:
            return this.handleResponseHomeType(responseJson)
          case this.getAdvancedFilterApiCallId:
            return this.advancedFilteredSearchResponse(responseJson) 
          case this.getCatogoryApiCallId: 
            return this.handleResponseCatogoryType(responseJson)  
          case this.getCatalogeCountApiCallId:
            return this.handleCountResponse(responseJson)    
        }
      
    }

    }

    componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined): void {
      if(prevState.homeCatogoryId !== this.state.homeCatogoryId) {
         this.getHomeTypeData()
      }
      if(prevState.activePage !== this.state.activePage) {
        this.GetHomeCartList()
      } 
      if(prevState.perPageData !== this.state.perPageData) {
        this.GetHomeCartList()
      }
    }

  handleResponseCatogoryType = (responseJson: any) => {
    if(responseJson && responseJson.length > 0) {
      const homeId = responseJson.find((item: any) => item.name === "Home").id
      const carId = responseJson.find((item: any) => item.name === "Car").id

      this.setState({ homeCatogoryId: homeId, carCatogoryId: carId})
    }

  }

  handleCountResponse = (responsjson: any) => {
    if(responsjson && responsjson.data) {
       const carCount = truthyValue(responsjson.data.Car)
       const homeCount = truthyValue(responsjson.data.Home)

       this.setState({ carCount : carCount, homeCount: homeCount})
    }
  }

  handleResponseHomeType = (responseJson: any) => {
    if (responseJson && responseJson.data) {
      const homeTypeData = responseJson.data.map((arrayValue: any) => {
        return {
          id: arrayValue.attributes.id,
          icon: this.handleHomeIcons(arrayValue.attributes.name),
          label: arrayValue.attributes.name,
          name: "home_type"
        }
      })

      this.setState({ homeCatogoriesData : homeTypeData})
    }
  }

  advancedFilteredSearchResponse = (responseJson: any) => {
    if(responseJson && responseJson.data && responseJson.data.length > 0) {
      this.setState({ listCard: responseJson.data})     
    } else {
      this.setState({ errorResponse: "No Catalogue found"})     
    }
  }

  handleHomeIcons = (keyName: string) => {
    switch (keyName) {
      case "Townhouse":
        return house1
      case "Residental House":
        return house2
      case "Apartment":
        return house3
      case "Villa":
        return house4
      case "Guest House":
        return house5
      case "Guest Suite":
        return house6
    }
  }
    

    handleApiResponseForListing=(responseJson:any)=>{
      if (responseJson && !responseJson.error && responseJson.catalogue.data && responseJson.catalogue.data.length > 0) {
        const totalPages = responseJson.total_pages
        const currentPage = responseJson.current_page

        this.setState({
          listCard : responseJson.catalogue.data,
          totalPages: totalPages,
          activePage: currentPage,
          loader : false
        })
        
      } else if( responseJson && responseJson.error) {
        let errMsg = "Internal Server Error!. Please try again later"
        swal(errMsg,"", "error")
        this.setState({errorResponse:errMsg, loader : false})
      }
      else{
        let errorMsg = "No Catalogue found";
        this.setState({errorResponse:errorMsg, loader : false})
      }
    }
  cities = ["New York", "Los Angeles", "Chicago", "Houston", "Phoenix", "Philadelphia", "San Antonio", "San Diego", "Dallas", "San Jose", "Austin", "Jacksonville", "San Francisco", "Indianapolis", "Columbus", "Fort Worth", "Charlotte", "Seattle", "Denver", "Washington", "Mumbai", "Delhi", "Bangalore", "Hyderabad", "Chennai", "Kolkata", "Pune", "Ahmedabad", "Jaipur", "Surat","Bhopal"];
  handleSearch = (value: string) => {
    if (value === "") {
      this.setState({ filteredCities: this.cities },()=>{this.GetHomeCartList()});
      
    }
    const filteredCities = this.cities.filter(city => city.toLowerCase().includes(value.toLowerCase()));
    this.setState({
      searchResult: value,
      filteredCities: filteredCities,
      openFilter: true
    });
  };
  handleDropSearch = (value: string) => {
    if (value === "") {
      this.setState({ filteredDropCities: this.cities })
    }
    const filteredDropCities = this.cities.filter(city => city.toLowerCase().includes(value.toLowerCase()));
    this.setState({
      searchDropResult: value,
      filteredDropCities: filteredDropCities,
      openFilter: true
    });
  };


  handleSearchDataList = (data: any) => {
    this.setState({ searchResult: data });
  };
  handleSearchDataForDropLocationList = (data: string) => {
    this.setState({ searchDropResult: data });
  };

  handleOpenFilter = () => { this.setState({ openFilter: (!this.state.openFilter) }) };
  handleOpenDropFilter = () => { this.setState({ openDropFilter: (!this.state.openDropFilter) }) };

  handleTab = (Tabname: string) => {
    const originalDates =[{
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection'
  }]
    this.setState({ listingName: Tabname, listCard: [], openDateRangePicker: false, selectedItem: 0, selectedDateOnAddDates: originalDates }, () => this.GetHomeCartList())
  }
  decreaseAdultCount = () => {
    if(this.state.adultsCount === 0) {
      this.setState({adultsCount : 0})
      return false
    }
    this.setState({ adultsCount: this.state.adultsCount - 1 })
  }

  increaseAdultCount = () => {
    this.setState({ adultsCount: this.state.adultsCount + 1 })
  }

  decreaseChildrenCount = () => {
    if(this.state.childrenCount === 0) {
      this.setState({childrenCount : 0})
      return false
    }
    this.setState({ childrenCount: this.state.childrenCount - 1 })
  }

  increaseChildrenCount = () => {
    this.setState({ childrenCount: this.state.childrenCount + 1 })
  }

  decreaseInfantsCount = () => {
    if(this.state.infantsCount === 0) {
      this.setState({infantsCount : 0})
      return false
    }
    this.setState({ infantsCount: this.state.infantsCount - 1 })
  }

  increaseInfantsCount = () => {
    this.setState({ infantsCount: this.state.infantsCount + 1 })
  }

  handleGuestModal = () => {
    this.setState({ addGuest: !this.state.addGuest })
  }

  handleCancel = () => {
    this.setState({ addGuest: false, infantsCount: 0, childrenCount: 0, adultsCount: 0 })
  }

  handleGuestsCount = () => {
    const result = (this.state.adultsCount + this.state.infantsCount + this.state.childrenCount).toString();
    this.setState({ guestCount: result, addGuest: false})
  }

  handleDateChange=(date:any)=>{
    this.setState({selectedDate:date})
  }
 
  GetHomeCartList = () => {
    this.setState({ loader : true})
    const header = {
      "Content-Type": configJSON.productApiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getHomeListApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.getHomeCarList}?name=${this.state.listingName}&page=${this.state.activePage}&per_page=${this.state.perPageData}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleSearchForCatalogue = () => {

     this.setState({ listCard : [], openDateRangePicker: false, loader : true})
    let dateObject = new Date();

    let year = dateObject.getFullYear();
    let month = String(dateObject.getMonth() + 1).padStart(2, '0'); 
    let day = String(dateObject.getDate()).padStart(2, '0');
    let hours = String(dateObject.getHours()).padStart(2, '0');
    let minutes = String(dateObject.getMinutes()).padStart(2, '0');
    let seconds = String(dateObject.getSeconds()).padStart(2, '0');
    
    let formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    let data = {
      "start_date":this.state.selectedDateOnAddDates[0].startDate,
      "end_date": this.state.selectedDateOnAddDates[0].endDate,
      "address": this.state.searchResult.toLowerCase(),
      "catalogue_type": this.state.listingName
    }
    const header = {
      "Content-Type": configJSON.productApiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getCatalogueSeachCallID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getSearchedApiListCatalogue
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypePost
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  handleFilter = () => {
    this.setState({ openFilterModal: !(this.state.openFilterModal) });
  }

  filterModalClose = () => {
    this.setState({ openFilterModal: false })
  }

  handleListingForCarAndHome = (listname: string) => {
    this.setState({ listingName: listname }, () => { this.GetHomeCartList() })
  }

  handleChange = (event: any, value: number) => {
    this.setState({ 
      activePage: value, 
      listCard: [],
      loader: true 
    });
  };

  handlePreviousPage = () => {
    this.setState({
      activePage: this.state.activePage - 1,
      listCard: [],
      loader: true
    })
  }

  onOptionClicks = (event: React.ChangeEvent<HTMLInputElement>, seletedId: number) => {

    const array = this.state.selectedDateOnAddDates
    array[0].endDate = this.addDays(array[0].startDate, parseInt(event.target.value));
    this.setState({ selectedDateOnAddDates: array, selectedItem: seletedId })
  }

  handleCheckInChange = (event: React.ChangeEvent<{ value: unknown }>) => {
      this.setState({ checkInTime: event.target.value as string})
  }

  handleCheckOutChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    this.setState({ checkOutTime: event.target.value as string})
}

   addDays(date :Date, days: number) {
    let result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }

  toggleDateRangePicker = () => {
    this.setState((prevState) => ({
      openDateRangePicker: !prevState.openDateRangePicker
    }));
  }
  

  definedRanges = [
    {
      startDate: new Date(),
      endDate: new Date(),
      label: "Exact Date",
      id: 1,
      addDays : 0,
    },
    {
      startDate: new Date(),
      endDate: new Date(new Date().setDate(new Date().getDate() + 1)),
      label: "1 Day",
      id: 2,
      addDays : 1,
    },
    {
      startDate: new Date(),
      endDate: new Date(new Date().setDate(new Date().getDate() + 2)),
      label: "2 Days",
      id: 3,
      addDays : 2,
    },
    {
      startDate: new Date(),
      endDate: new Date(new Date().setDate(new Date().getDate() + 3)),
      label: "3 Days",
      id: 4,
      addDays : 3,
    },
    {
      startDate: new Date(),
      endDate: new Date(new Date().setDate(new Date().getDate() + 7)),
      label: "7 Days",
      id: 5,
      addDays : 7,
    }
  ];

  getHomeTypeData = () => {
    const header = {
      "Content-Type": configJSON.productApiContentType,
    };
    
    this.getHomeTypeApiCallId = apiCalling({
      header: JSON.stringify(header),
      method: configJSON.apiMethodTypeGet,
      endPoint: `${configJSON.getHomeSubListingApiEndPoint}/${this.state.homeCatogoryId}`,
    })
  }

  getCatogoriesData = () => {
    const header = {
      "Content-Type": configJSON.productApiContentType,
    };
    
    this.getCatogoryApiCallId = apiCalling({
      header: JSON.stringify(header),
      method: configJSON.apiMethodTypeGet,
      endPoint: configJSON.getCatogoriesApiEndPoint,
    })
  }

  getAdvancedSearcheData = (payload: SearchCriteria ) => {

    this.setState({listCard : [], openFilterModal : false})
    const header = {
      "Content-Type": configJSON.productApiContentType,
      'token': localStorage.getItem("userToken")
    };

    const body = {
      "q": {
        payload
      }
    }
    
    this.getAdvancedFilterApiCallId = apiCalling({
      header: JSON.stringify(header),
      method: configJSON.apiMethodTypePost,
      endPoint: configJSON.getAdvanceFilterApiEndpoint,
      body: JSON.stringify(body)
    })

  }

  getCatalougeCounts = () => {
    const header = {
      'token': localStorage.getItem("userToken")
    };
    
    this.getCatalogeCountApiCallId = apiCalling({
      header: JSON.stringify(header),
      method: configJSON.apiMethodTypeGet,
      endPoint: configJSON.getCatalogeCountApiEndPoint,
    })
  }

  generateTimeArray() {
    const times = [];
    for (let i = 0; i < 24; i++) {
        let hour = i % 12;
        if (hour === 0) hour = 12; 
        const period = i < 12 ? 'am' : 'pm';
        times.push(`${hour} ${period}`);
    }
    return times;
}

handleCheckInTextLabel = () => {
  return this.state.listingName === "Car" ?  "Pick-up date" : "Check in date"
}
handleCheckOutTextLabel = () => {
  return this.state.listingName === "Car" ?  "Drop-off date" : "Check out date"
}

perPageDataChange = (event: React.ChangeEvent<{ value: unknown }>) => {
  const inputValue = event.target.value as string
 this.setState({
    perPageData : parseInt(inputValue),
    activePage: 1,
    listCard: [],
    loader: true
})
}
  handleNextPage = () => {
    this.setState({
      activePage: this.state.activePage + 1,
      listCard: [],
      loader: true
    })
  }

  handleCountOnDisplay = () => {
    if(this.state.searchData.length === 0) {
      return this.state.listingName === "Car" ? this.state.carCount : this.state.homeCount
    } else {
      return this.state.searchData.length
    }
  }
  handleCarouselOn=()=>{
    this.setState({startSliding:true})
  }
  handleCarouselOff=()=>{
    this.setState({startSliding:false})
  }
  navigateToProductDescription = (page:number) => {this.props.navigation.navigate("ProductDescription",{"id":page,"type":this.state.listingName})};
  // Customizable Area End
}
