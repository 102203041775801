// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";

import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { toast } from "react-toastify"
import { apiCalling } from "../../../components/src/asset";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface ReportedCatogory {
    reportId : number,
    reportTitle : string,
    reportSubTitle : string,
    reportType : string
}

interface S {
  // Customizable Area Start  
  reportedCatogory : ReportedCatogory[],
  selectedIssue : string,
  reportedIssue : boolean,
  userId : string
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class ReportIssueController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getCurrentUserInfoApiCallId : string = ""
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];
    this.state = {
        reportedCatogory : this.reportedCatogoriesData,
        selectedIssue : "",
        reportedIssue : false,
        userId: ""
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if(apiRequestCallId === this.getCurrentUserInfoApiCallId) {
          this.handleUserInfoResponse(responseJson)
      }
      
    }
    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidMount(): Promise<void> {
     this.getCurrentUserProfile()
  }
  
  reportedCatogoriesData :ReportedCatogory[] = [
    {
        reportId : 1,
        reportTitle: "Smoking violation",
        reportSubTitle : "Available up to 72 hours after trip end",
        reportType: "smoke_violation"
    },
    {
        reportId : 2,
        reportTitle: "Cleaning violation",
        reportSubTitle : "Available up to 72 hours after trip end",
        reportType: "cleaning_violation"
    },
    {
        reportId : 3,
        reportTitle: "Late return: Vehicle not returned",
        reportSubTitle : "Available up to 48 hours after trip end",
        reportType: "vechile_not_returned_violation"
    },
    {
        reportId : 4,
        reportTitle: "Late return: Vehicle returned",
        reportSubTitle : "Available up to 48 hours after trip end",
        reportType: "vechile_late_returned_violation"
    },
  ]
   
  handleUserInfoResponse = (responseJson: any) => {
    if (responseJson && responseJson.user && responseJson.user.data) {
      this.setState({ userId: responseJson.user.data.id })
    } else {
      this.setState({ userId: "" })
    }
  }

    redirectToBackPage = () => {
        this.props.navigation.goBack()
    }

    handleCatogoriesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ selectedIssue: event.target.value })
    }

    backtoReportPage =  () => {
        this.setState({ reportedIssue : false})
    }

    onToNextReportStep = () => {
        if(this.state.selectedIssue === "") {
           toast.error("Please select violation issue")
           return false   
        }

        this.setState({ reportedIssue : true})
    }

    handleIsuueTitle = () => {
        const findTitle = this.state.reportedCatogory.find(item => item.reportType === this.state.selectedIssue)?.reportTitle
        return findTitle
    }

    getCurrentUserProfile = () => {
      const header = {
        'token': localStorage.getItem("userToken")
    }
  
    this.getCurrentUserInfoApiCallId = apiCalling({
        header: JSON.stringify(header),
        method: configJSON.apiMethodTypeGet,
        endPoint: configJSON.getUserProfileApiEndPoint,
    })
    }


  // Customizable Area End
}

// Customizable Area End