// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";

import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

type FaqData = {
  id: number;
  type: string;
  question: string;
  answer: string;
};

interface S {
  // Customizable Area Start 
  faqsData : FaqData[],
  filteredFaqsData : FaqData[] | []
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class FaqsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];
    this.state = {
      faqsData: this.faqData,
      filteredFaqsData: this.faqData
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
     // code here
      
    }
    // Customizable Area End
  }

  // Customizable Area Start

  navigateToSupportCenterPage = () => {
    this.props.navigation.navigate("SupportCenterSettings")
  }


  faqData: FaqData[] = [
    {
      id: 1,
      type: "FAQ",
      question: "How do I book a vacation rental or travel house online?",
      answer: "You can easily book a travel house online by visiting our website, selecting your destination, dates, and preferences, and then choosing from the available listings. Follow the booking process, and you'll receive confirmation once the reservation is complete."
    },
    {
      id: 2,
      type: "FAQ",
      question: "What types of accommodations are available for booking?",
      answer: "You can book various types of accommodations including houses, apartments, villas, cabins, and cottages. Each option comes with different amenities and features to suit your needs and preferences."
    },
    {
      id: 3,
      type: "FAQ",
      question: "How can I find the best deals and discounts on travel house bookings?",
      answer: "To find the best deals, check the website’s deals section regularly, sign up for newsletters to receive exclusive offers, and look for promo codes. Booking during off-peak seasons can also result in lower prices."
    },
    {
      id: 4,
      type: "FAQ",
      question: "What is the cancellation policy for travel house bookings?",
      answer: "Cancellation policies vary by property. Some may offer free cancellation up to a certain date, while others might have stricter policies. It is important to review the specific cancellation terms provided during the booking process."
    },
    {
      id: 5,
      type: "FAQ",
      question: "Do I need to pay a security deposit when booking a travel house or vacation rental?",
      answer: "Some properties may require a security deposit, which is typically refundable if no damage occurs. The requirement and amount of the deposit will be detailed in the booking terms and conditions."
    },
    {
      id: 6,
      type: "FAQ",
      question: "Can I book a rental car along with my travel house reservation?",
      answer: "Yes, many booking platforms offer the option to add a rental car to your travel house reservation. This can be done during the booking process, allowing for a more convenient and integrated travel plan."
    },
    {
      id: 7,
      type: "FAQ",
      question: "How can I contact customer support if I encounter issues during my stay or with my car rental?",
      answer: "Customer support can be contacted via phone, email, or live chat. The contact information is usually provided on the booking platform's website or in your reservation confirmation email."
    }
  ];

  searchFAQs = (searchTerm: string) => {
    const filteredData = this.state.filteredFaqsData.filter(faq => faq.question.toLowerCase().includes(searchTerm.toLowerCase()));
    this.setState({ faqsData : filteredData})
  }



  // Customizable Area End
}

// Customizable Area End