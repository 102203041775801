// Customizable Area Start
import React from "react";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";

import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { apiCalling, truthyValue } from '../../../components/src/asset'
import moment from "moment";
import { toast } from "react-toastify";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start  
  renderSection : string
  pageTitle : string
  bookedSlotId: string
  catalougeType : string
  tabValue : number
  conversationId : string
  tripEndDuration : string
  isPhotoUploaded : boolean
  uploadedImagesArr : File[]
  imagesUrlArr : string[],
  odometerVal : string,
  keyProvided : boolean,
  fuelLevel : number,
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class HostDashboardController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  imageRefs : React.RefObject<HTMLInputElement>
  getBookedSlotApiCallId : string = ""
  saveCheckOutApiCallId : string =""
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];
    this.state = {
        renderSection : this.tripSection.infoPhase,
        pageTitle : "Booked Trip",
        catalougeType: "",
        bookedSlotId: "",
        tabValue: 0,
        conversationId: "",
        tripEndDuration: "",
        isPhotoUploaded: true,
        imagesUrlArr: [],
        uploadedImagesArr: [],
        odometerVal: "",
        keyProvided: true,
        fuelLevel: 0
    };
    this.imageRefs = React.createRef()
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      switch(apiRequestCallId) {
        case this.getBookedSlotApiCallId :
            return this.handleBookedSlotResponse(responseJson)
        case this.saveCheckOutApiCallId :
            return this.handleSaveCheckoutResponse(responseJson)
        default:
            break;
      }
      
    }
    // Customizable Area End
  }

  // Customizable Area Start

    async componentDidMount(): Promise<void> {
        const bookedSlotId = this.props.navigation.getParam("reservationId")
        this.setState({ bookedSlotId: bookedSlotId})
        this.getBookSlotData(bookedSlotId)
    }

    handleBookedSlotResponse = (responseJson: any) => {
        if (responseJson && responseJson.catalogue && responseJson.catalogue.data) {
            const responseObj = responseJson.catalogue.data.attributes
            const bookedSlotId = truthyValue(responseObj.id)
            const conversationId = truthyValue(responseObj.conversation.id)
            const catalougeType = truthyValue(responseObj.catalogue_type)
            const endDays = this.countEndDays(responseObj.end_time).toString()

            this.setState({ catalougeType, bookedSlotId, conversationId: conversationId, tripEndDuration: endDays })
        } else {
            this.setState({ catalougeType: "", bookedSlotId: "" })
        }
    }

    handleSaveCheckoutResponse = (responseJson: any) => {
        if (responseJson && responseJson.data) {
            this.navigateToMainPage()
            toast.success("Details saved successfully")
        }
    }

    tripSection = {
        infoPhase: "info",
        photosPhase: "images",
        endPhase: "endTrip"
    }

    pageTitleSection = {
        infoPhase: "Booked Trip",
        photosPhase: "Add Photos",
        endPhase: "Final Checkin Details"
    }

    getBookSlotData = (bookedSlotId: number) => {
        const header = {
            "token": localStorage.getItem("userToken")
        };

        this.getBookedSlotApiCallId = apiCalling({
            method: configJSON.apiMethodTypeGet,
            header: JSON.stringify(header),
            endPoint: `${configJSON.getReservationInfoApiEndPoint}/${bookedSlotId}`,
        })
    }

    saveCheckoutInfo = () => {
        const header = {
            "token": localStorage.getItem("userToken")
        };

        const formData = new FormData()

        
        formData.append("trip_type", "post_trip")
        formData.append("mileage_odometer", this.state.odometerVal)
        formData.append("fuel_level", this.state.fuelLevel.toString())
        formData.append("is_key_provided", `${this.state.keyProvided}`)

        this.state.uploadedImagesArr.forEach(value => {
            formData.append("images[]", value);
          });

        this.saveCheckOutApiCallId = apiCalling({
            method: configJSON.apiMethodTypePost,
            header: JSON.stringify(header),
            endPoint: configJSON.postTripCheckOutEndPoint(this.state.bookedSlotId),
            body: formData
        })

    }

    handleTabChange = (event: React.ChangeEvent<{}>, value: number) => {
        this.setState({ tabValue: value })
    }

    getTabText = () => {
        return this.state.catalougeType === "Car" ? "Car Details" : "Apartment Details"
    }

    countEndDays = (endTime: string) => {
        const today = moment();
  
        const endDate = moment(endTime);
        const differenceInDays = endDate.diff(today, 'days');
  
        return differenceInDays
      }
    
    changeImgPhase = () => {
        this.setState({ renderSection: this.tripSection.photosPhase, pageTitle: this.pageTitleSection.photosPhase })
    }

    changeEndPhase = () => {

        if(this.state.imagesUrlArr.length < 10) {
            this.handleErrorMsg("Please upload atleast 10 Photos to continue")
            return false
        }

        this.setState({ renderSection: this.tripSection.endPhase, pageTitle: this.pageTitleSection.endPhase})
    }

    backInfoPhase = () => {
        this.setState({ renderSection: this.tripSection.infoPhase, pageTitle: this.pageTitleSection.infoPhase})
    }

    backImgPhase = () => {
        this.setState({ renderSection: this.tripSection.photosPhase, pageTitle: this.pageTitleSection.photosPhase})
    }

    handleTogglePreview = () => {
        this.setState({ isPhotoUploaded: false })
    }

    handleImgChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const files = event.target.files;
        if (files) {
            const newFiles = Array.from(files);
            const fileURLs = Array.from(files).map((file) => URL.createObjectURL(file));

            this.setState((prevState) => ({
                uploadedImagesArr: [...prevState.uploadedImagesArr, ...newFiles],
                imagesUrlArr: [...prevState.imagesUrlArr, ...fileURLs]
            }));
        }

    };

    deleteImages = (index: number) => {
        this.setState((prevState) => {
            const updatedFiles = [...prevState.uploadedImagesArr];
            const updatedPreviews = [...prevState.imagesUrlArr];

            updatedFiles.splice(index, 1);
            updatedPreviews.splice(index, 1);

            return {
                uploadedImagesArr: updatedFiles,
                imagesUrlArr: updatedPreviews,
            };
        });
    };

    handleRefs = () => {
        if (this.imageRefs.current) {
            this.imageRefs.current.click();
        }
    }

    handleOdometerTextField = (event : React.ChangeEvent<HTMLInputElement>) => {
        const inputVal = event.target.value
        this.setState({ odometerVal : inputVal})
    }

    handleKeySwitch = (event : React.ChangeEvent<HTMLInputElement>) => {
        const checked = event.target.checked
        this.setState({ keyProvided : checked})
    }

    handleErrorMsg = (message : string) => {
        return toast.error(message)
    }

    navigateToMainPage = () => {
        this.props.navigation.navigate("HostReservation")
    }

    handleFuelLevelChange = (event : React.ChangeEvent<{}>, newValue : number | number[]) => {
        this.setState({ fuelLevel: newValue as number})
    }


  // Customizable Area End
}

// Customizable Area End