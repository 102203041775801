// Customizable Area Start
import React from "react";
import {
    Box,
    Typography,
    Button,
    IconButton,
    styled,
    Grid,
    OutlinedInput,
    InputAdornment,
    Checkbox, 
    LinearProgress,
} from "@material-ui/core"
import { bgImageIcon, messageIcon, cardIcon, skipVectorIcon, scanDL, scanFC } from "./assets";
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import AppHeader from "../../../blocks/notifications/src/TopHeader.web";
import Footer from "../../../components/src/Footer.web";
import OTPInput from "../../../components/src/OtpInput.web";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';


import LogInController, {
    Props,
  } from "./LoginScreenController";


class SignUp extends LogInController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  handleSignUpStepperCSS = (activeKeyFromArray:number, activeRealState: number) => {
    if(activeKeyFromArray === activeRealState) {
       return "SignUpCurrentStepper"
    } else if( activeKeyFromArray < activeRealState) {
      return "SignUpActiveStepper"
    } else {
      return "SignUpStepper"
    }
  }

  handleStepperDisplay = (stepValue : number) => {
    switch(stepValue) {
        case 1 : 
            return this.SignUpStep()
        case 2: 
            return this.VerifyNumberStep()
        case 3:
            return this.scanDriverLicenceStep()
        case 4:
            return this.scanFaceStep()
        case 5:
            return this.AddPaymentStep()
    }
  }

   handleFormattedDate = () => {
        const today = new Date();
        const yyyy = today.getFullYear();
        const month = String(today.getMonth() + 1).padStart(2, '0');
        const currentDate = String(today.getDate()).padStart(2, '0');
        const formattedToday = `${yyyy}-${month}-${currentDate}`;

        return formattedToday
    }
      
  SignUpStep = () => {
    return(
        <Grid container spacing={5}>
            <Grid item md={6} sm={12}>
                {this.backGroundImage()}
            </Grid>
            <Grid item md={5} sm={11}>
                <Wrapper>
                    <Typography className="headerTitle">Get Started with Roam</Typography>

                    <form data-test-id="signupForm" onSubmit={this.handleSignUp}>
                        <Typography className="labelText">First Name</Typography>
                        <OutlinedInput data-test-id="first_name" name="first_name" value={this.state.signupFormData.first_name} onChange={this.handleChangeForSignup} placeholder="First Name" fullWidth className="inputFields" />
                        {this.handleErrorFields(this.state.signupErroFields.first_name, "Please enter first name")}

                        <Typography className="labelText">Last Name</Typography>
                        <OutlinedInput data-test-id="last_name" name="last_name" value={this.state.signupFormData.last_name} onChange={this.handleChangeForSignup} placeholder="Last Name" fullWidth className="inputFields" />
                        {this.handleErrorFields(this.state.signupErroFields.last_name, "Please enter last name")}

                        <Typography className="labelText">Phone Number</Typography>
                        <OutlinedInput data-test-id="phone_number" name="phone_number" value={this.state.signupFormData.phone_number} onChange={this.handleChangeForSignup} placeholder="+91xxxxxxxx69" fullWidth className="inputFields" />
                        {this.handleErrorFields(this.state.signupErroFields.phone_number, "Please enter phone number")}

                        <Typography className="labelText">Email</Typography>
                        <OutlinedInput data-test-id="email" type="email" name="email" value={this.state.signupFormData.email} onChange={this.handleChangeForSignup} placeholder="Email" fullWidth className="inputFields" />
                        {this.handleErrorFields(this.state.signupErroFields.email, "Please enter email")}

                        <Typography className="labelText">Password</Typography>
                        <OutlinedInput
                            data-test-id="password"
                            type={this.state.isPasswordVisible ? "text" : "password"}
                            name="password"
                            value={this.state.signupFormData.password}
                            onChange={this.handleChangeForSignup}
                            placeholder="Password" fullWidth
                            className="inputFields"
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility in signup"
                                        onClick={this.handleClickShowPassword}
                                        onMouseDown={this.handleMouseDownPassword}
                                    >
                                        {this.state.isPasswordVisible ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                        {this.handleErrorFields(this.state.signupErroFields.password, "Please enter password")}

                        <br /><br />

                        <Box style={{ display: "flex", gap: 10, alignItems: "center", marginBottom: 10 }}>
                            <Checkbox  required color="primary" />
                            <Typography className="termsText">I agree to Roam’s <span className="underLineText"> terms of use </span> and <span className="underLineText">  privacy policy </span> </Typography>
                        </Box>

                        <Button className="signUpBtn" type="submit" fullWidth color="primary"> Continue</Button>
                    </form>

                </Wrapper>
            </Grid>
            <Grid item md={1} sm={1}>
                <Box>
                    <Typography data-test-id="nav" onClick={() => this.navigateToLoginPage()} className="signInText">Sign in</Typography>
                </Box>
            </Grid>
        </Grid>
    )
  }

  VerifyNumberStep = () => {
    return(
        <Grid container spacing={5}>
            <Grid item md={6} sm={12}>
                {this.backGroundImage()}
            </Grid>
            <Grid item md={6} sm={11}>
                <Box>
                   <Box>
                      <img src={messageIcon} alt="message" />
                   </Box>
                    <Box style={{ margin: "5px 0"}}>
                        <Typography className="msgText1">Check your</Typography>
                        <Typography className="msgText1">Phone Messages</Typography>
                    </Box>
                    <Box style={{ margin: "5px 0"}}>
                   <Typography className="msgText2">We’ve texted you a verification code.</Typography>
                   <Typography className="msgText2">Please enter it below</Typography>
                   </Box>

                   <Box style={{ margin : "10px 0"}}>
                        <OTPInput data-test-id="otpComponent" length={6} onChange={(otpVal: string) => this.handleVerifyOTP(otpVal)} />
                   </Box>

                   <Typography className="msgText2">Didn’t get a code? <span style={{ color: "#3173E1"}}>Try again</span></Typography>

                   <Button className="verifyBtn" data-test-id="verifyOTP" onClick={() => this.verifyOTP()} fullWidth>Continue</Button>

                   <small>(Use this OTP to continue: {this.state.displayOTP})</small>
                </Box>
            </Grid>
            
        </Grid>
    )
  }

  AddPaymentStep = () => {
    return(
        <Box className="paymentBox">

            <Box className="insidePaymentBox">
                <Typography className="addPaymentText"> Add Payment Method </Typography>
                <Box className="fieldBox">
                    <Typography className="paymentLabelText">Cardholder Name</Typography>
                    <OutlinedInput name="card_holder_name" onChange={this.handleChangeForCardDetails} value={this.state.cardInformation.card_holder_name} placeholder="Cardholder Name" fullWidth className="paymentFields" />
                    {this.handleErrorFields(this.state.cardInformationError.card_holder_name, "Please enter card holder name")}
                
                </Box>
                <Box className="fieldBox">
                    <Typography className="paymentLabelText">Card Number</Typography>
                    <OutlinedInput data-test-id="card_number" startAdornment={<img src={cardIcon} alt="cardIcon" />} endAdornment={<img src={skipVectorIcon} alt="vectorIcon" />} onKeyDown={this.handleKeyDown} name="card_number" onChange={this.handleChangeForCardDetails} value={this.state.cardInformation.card_number}  placeholder="Card Number" fullWidth className="paymentFields" />
                    {this.handleErrorFields(this.state.cardInformationError.card_number, "Please enter valid card number")}
                    
                </Box>
                <Box className="fieldBox" style={{ display: "flex", gap: "10px"}}>
                    <Box>
                        <Typography className="paymentLabelText">Expiry Date</Typography>
                        <OutlinedInput type="date" inputProps={{ min : this.handleFormattedDate()}} name="expiry_date" onChange={this.handleChangeForCardDetails} value={this.state.cardInformation.expiry_date} placeholder="Expiry Date" fullWidth className="paymentFields" />
                        {this.handleErrorFields(this.state.cardInformationError.expiry_date, "Please enter valid expiry date")}                    
                    </Box>
                    <Box>
                        <Typography className="paymentLabelText">Security Code</Typography>
                        <OutlinedInput onKeyDown={this.handleKeyDown} name="security_code" onChange={this.handleChangeForCardDetails} value={this.state.cardInformation.security_code} placeholder="Security Code" fullWidth className="paymentFields" />
                        {this.handleErrorFields(this.state.cardInformationError.security_code, "Please enter valid security code")}
                   
                    </Box>
                </Box>
                <Box className="fieldBox">
                    <Typography className="paymentLabelText">Billing Address</Typography>
                </Box>
                <Box className="fieldBox">
                    <Typography className="paymentLabelText">Street Address</Typography>
                    <OutlinedInput name="street" onChange={this.handleChangeForCardDetails} value={this.state.cardInformation.street} placeholder="Street Address" fullWidth className="paymentFields" />
                    {this.handleErrorFields(this.state.cardInformationError.street, "Please enter valid adress")}
                
                </Box>
                <Box className="fieldBox" style={{ display: "flex", gap: "10px"}}>
                    <Box>
                        <Typography className="paymentLabelText">City</Typography>
                        <OutlinedInput name="city" onChange={this.handleChangeForCardDetails} value={this.state.cardInformation.city} placeholder="City" fullWidth className="paymentFields" />
                        {this.handleErrorFields(this.state.cardInformationError.city, "Please enter valid city")}
                   
                    </Box>
                    <Box>
                        <Typography className="paymentLabelText">State</Typography>
                        <OutlinedInput name="state" onChange={this.handleChangeForCardDetails} value={this.state.cardInformation.state} placeholder="State" fullWidth className="paymentFields" />
                        {this.handleErrorFields(this.state.cardInformationError.state, "Please enter valid state")}
                   
                    </Box>
                </Box>
                <Box className="fieldBox" style={{ display: "flex", gap: "10px"}}>
                    <Box>
                        <Typography className="paymentLabelText">Zip Code </Typography>
                        <OutlinedInput onKeyDown={this.handleKeyDown} name="zip_code" onChange={this.handleChangeForCardDetails} value={this.state.cardInformation.zip_code} placeholder="Zip Code" fullWidth className="paymentFields" />
                        {this.handleErrorFields(this.state.cardInformationError.zip_code, "Please enter valid zip coder")}
                   
                    </Box>
                    <Box>
                        <Typography className="paymentLabelText">Country</Typography>
                        <OutlinedInput name="country" onChange={this.handleChangeForCardDetails} value={this.state.cardInformation.country} placeholder="Country" fullWidth className="paymentFields" />
                        {this.handleErrorFields(this.state.cardInformationError.country, "Please enter valid city")}
                    
                    </Box>
                </Box>
                <br />
            </Box>

            <Box style={{ width:"500"}}>
                <Button className="verifyBtn" data-test-id="addCardInfo" onClick={() => this.addPaymentMethod()} fullWidth>Continue</Button>
            </Box>
        </Box>
    )
  }

  scanDriverLicenceStep = () => {
      return (
          <Grid container spacing={5}>
              <Grid item md={6} sm={12}>
                  {this.backGroundImage()}
              </Grid>
              <Grid item md={6} sm={11}>
                  <Box>
                      <Box>
                          <img src={scanDL} alt="drivingLicence" />
                      </Box>
                      <Box style={{ margin: "5px 0" }}>
                          <Typography className="msgText1">Connect to phone to </Typography>
                          <Typography className="msgText1">Scan Driver License</Typography>
                      </Box>

                      <Typography className="subTextStep3">You will receive :</Typography>

                      <Box style={{ display: "flex", gap: 10, alignItems: "center", margin: "20px 0"}}>
                         <CheckCircleIcon style={{ color: "#3173E1"}} />
                         <Typography className="subTextStep4">Make sure your license is well lit and in focus.</Typography>
                      </Box>
                      <Box style={{ display: "flex", gap: 10, alignItems: "center", margin: "20px 0"}}>
                         <CheckCircleIcon style={{ color: "#3173E1"}} />
                         <Typography className="subTextStep4">Use a dark background for contrast.</Typography>
                      </Box>
                      <Box style={{ display: "flex", gap: 10, alignItems: "center", margin: "20px 0"}}>
                         <CheckCircleIcon style={{ color: "#3173E1"}} />
                         <Typography className="subTextStep4">Capture the whole license in frame.</Typography>
                      </Box>

                      <Box style={{ padding: 22, background: "#EDF4FF", width: "315px", margin: "25px 0", borderRadius:"12px"}}>
                         <Box style={{ display: "flex", gap: 10, alignItems: "center"}}>
                              <VerifiedUserIcon style={{ color: "#3173E1" }} />
                              <Typography className="subTextStep4">
                                 Your information will be stored securely.<span style={{ color: "#3173E1"}}> View our Privacy Policy </span>                              </Typography>
                         </Box>
                      </Box>

                      {this.continueBtn()}
                  </Box>
              </Grid>

          </Grid>
      )
  }

  scanFaceStep = () => {
    return (
        <Grid container spacing={5}>
            <Grid item md={6} sm={12}>
                {this.backGroundImage()}
            </Grid>
            <Grid item md={6} sm={11}>
                <Box>
                    <Box>
                        <img src={scanFC} alt="faceIcon" />
                    </Box>
                    <Box style={{ margin: "5px 0" }}>
                        <Typography className="msgText1">Connect to phone to </Typography>
                        <Typography className="msgText1">Verify your identity</Typography>
                    </Box>

                    <Box style={{ margin: "20px 0" }}>
                        <Typography className="subTextStep4">This section is to ensure you are you,</Typography>
                        <Typography className="subTextStep4">this image will only be accesible by </Typography>
                        <Typography className="subTextStep4">handy man team.</Typography>
                    </Box>

                    {this.continueBtn()}
                </Box>
            </Grid>

        </Grid>
    )
  }
   
  continueBtn = () => {
    return (
        <Button className="verifyBtn" style={{ width: "358px"}} data-test-id="continueBtn" onClick={() => this.handleNextStep()}>Continue</Button>
    )
  }

  backGroundImage = () => {
        return (
            <Box>
                <img src={bgImageIcon} alt="bgImageIconSignup" />
            </Box>
        )
    }

  handleErrorFields = (checkKey: boolean, errMsg: string) => {
        return checkKey && <div style={{ color: "red", marginBottom: "7px" }}>{errMsg}</div>
    }


  render() {
    // Customizable Area Start

    return (
        <div>

            <AppHeader navigation={this.props.navigation} />
            <SignUPMainWrapper>

                 <Box style={{ display: "flex", justifyContent: "space-around"}}>
                 {this.signUpStepperSteps.map((label) => (
                        <div>
                            <Box className={this.handleSignUpStepperCSS(label.activeKey, this.state.activeStepperStep)} key={label.id}>
                                <p style={{ textAlign: "center", margin: "25% 0" }}>{label.id}</p>
                            </Box>
                            <p className="stepperText">{label.label}</p>
                        </div>
                    ))}

                    
                </Box>

                <Box style={{ margin: "30px 0 50px 0" }}>
                        <LinearProgress className="progressBarSignUp" value={Math.floor((this.state.activeStepperStep / this.signUpStepperSteps.length) * 100)} variant="determinate" />
                </Box>

                  {this.handleStepperDisplay(this.state.activeStepperStep)}
            </SignUPMainWrapper>

            <Footer />
        </div>
    );
    // Customizable Area End
  }
}

export default SignUp;

export const SignUPMainWrapper = styled(Box)({
    margin: "40px auto",
    maxWidth: "1220px",
    "& .signInText" : {
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '16px',
        fontWeight: 600,
        cursor: "pointer",
        color: "#3173E1"
    },
    "&  .SignUpStepper": {
        width: "40px",
        height: "40px",
        background: "white",
        borderRadius: "50%",
        border: "1px solid #D0D5DD",
        margin: "auto"
      },
      "&  .SignUpActiveStepper": {
        width: "40px",
        height: "40px",
        background: "#3173E1",
        color:"white",
        borderRadius: "50%",
        border: "1px solid #3173E1",
        margin: "auto"
      },
      "&  .SignUpCurrentStepper": {
        width: "40px",
        height: "40px",
        background: "#E3EDFD",
        color:"#3173E1",
        borderRadius: "50%",
        border: "1px solid #3173E1",
        margin: "auto"
      },
      "& .stepperText" : {
          fontFamily: 'Plus Jakarta Sans',
          fontSize: '12px',
          fontWeight: 700,
          textAlign: 'center'
      },
      "& .progressBarSignUp": {
        height: "10px",
        borderRadius: "10px",
        backgroundColor:"#E3EDFD",
      },
      "& .progressBarSignUp .MuiLinearProgress-barColorPrimary": {
        backgroundColor:"#3173E1"
      },
      "& .msgText1": {
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '32px',
        fontWeight: 600,
        lineHeight: '41.6px',
        letterSpacing: '0.15px',
        textAlign: 'left',
        color: "#23395D"
      },
      "& .msgText2": {
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '16px',
        fontWeight: 500,
        lineHeight: '22.4px',
        textAlign: 'left',
        color:"#686B71"
      },
      "& .verifyBtn": {
        color: "white",
        height: "56px",
        borderRadius: "8px",
        margin: "25px 0",
        background: "#3173E1",
        textTransform: "none"
    },
    "& .paymentBox" : {
        margin: "auto",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "center",
    },
    "& .insidePaymentBox" : {
        border: "1px solid #D0D5DD",
        borderRadius: "8px",
        width: "500"
    },
    "& .fieldBox" : {
        padding : "5px 50px"
    },
    "& .addPaymentText": {
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '22px',
        fontWeight: 700,
        textAlign: 'center',
        color: "#23395D",
        margin: "25px 0"
    },
    "& .paymentLabelText" :  {
        fontFamily: 'Plus Jakarta Sans',
        fontWeight: 700,
        fontSize: '14px',
        textAlign: 'left',
        color: "#23395D",
    }, 

    "& .paymentFields" : {
        height: '56px',
        border: '1px solid transparent',
        gap: '8px',
        margin: "10px 0",
        borderRadius: '8px',
        boxShadow: "0px 4px 5px 0px #E7E7E7"
    }, 
    "& .subTextStep4" : {
        color: "#686B71",
        fontFamily: 'Plus Jakarta Sans',
        fontWeight: 500,
        fontSize: '16px',
    },

    "& .subTextStep3" : {
        color: "#23395D",
        fontFamily: 'Plus Jakarta Sans',
        fontWeight: 600,
        fontSize: '20px',
        margin:"20px 0"
    },


})

const Wrapper = styled(Box) ({

    "& .signUpBtn": {
        background: "#3173E1",
        color: "white",
        borderRadius: "8px",
        height: "56px",
        textTransform: "none"
    },

    "& .headerTitle" : {
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '32px',
        fontWeight: 500,
        lineHeight: '36.4px',
        marginBottom: 30
    },

    "& .labelText" :  {
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '14px',
        fontWeight: 700,
        lineHeight: '22px',
        textAlign: 'left'
    }, 

    "& .inputFields" : {
        height: '56px',
        gap: '8px',
        borderRadius: '8px',
        border: '1px solid transparent',
        margin: "10px 0",
        boxShadow: "0px 4px 5px 0px #E7E7E7"
    }, 

    "& .termsText" : {
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '16px',
        fontWeight: 500,
    },

    "& .underLineText" : {
        textDecoration: "underline",
        color: "#3173E1",
    },
    "& .MuiOutlinedInput-input": {
        padding: "12px 14px"
    }
})

// Customizable Area End