// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";

import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { apiCalling, userAuthDisplayPopup } from '../../../components/src/asset'
import { toast } from "react-toastify";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface ImageFile {
    preview: string;
    file: File | null;
  }

interface S {
  // Customizable Area Start  
  images : ImageFile[],
  userId : string,
  loading: boolean,
  damageValue: string,
  checkoutCompleted : boolean,
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class ReservationCheckoutController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  confirmCheckoutApiCallId : string = ""
  getUserProfileApiCallId : string = ""
  startTripApiCallId: string = ""
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];
    this.state = {
        images : Array(9).fill({ preview: '', file: null }),
        userId: "",
        loading: true,
        damageValue: "",
        checkoutCompleted: false,
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      switch(apiRequestCallId) {
        case this.getUserProfileApiCallId : 
          return this.handleResponseForUserProfileAPiCallId(responseJson)
        case this.confirmCheckoutApiCallId : 
          return this.handleResponseForConfirmCheckoutAPiCallId(responseJson)
        case this.startTripApiCallId:
          return this.handleResponseForStartTripAPiCallId(responseJson)
        default : 
          break  
      }

            
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount(){
    this.getUserProfile()   
  }

   handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const file = event.target.files?.[0];
    if (file) {
      const newImages = [...this.state.images];
      newImages[index] = { preview: URL.createObjectURL(file), file };
      this.setState({ images : newImages})
    }
  };

  handleResponseForUserProfileAPiCallId = (responseJson : any) => {
    if(responseJson && responseJson.user && responseJson.user.data) {
        this.setState({ userId : responseJson.user.data.id, loading : false})
    } else {
        this.setState({ loading : false})
        userAuthDisplayPopup()
    }
  }

  handleResponseForConfirmCheckoutAPiCallId = (responseJson : any) => {
    if(responseJson && responseJson.data && responseJson.data.id !== null) {
        toast.success("All Photos Saved Successfully")
        this.setState({ loading : false, checkoutCompleted : true})
    } else {
        toast.error("Something wenbt wrong")
        this.setState({ loading : false})
    }
  }

  handleResponseForStartTripAPiCallId = (responseJson : any) => {
    if(responseJson && responseJson.message) {
        toast.success("Trip Started Successfully")
        this.navigateToHomePage()
        this.setState({ loading : false})
    } else {
       let errorMsg = "Something went wrong"
        if(responseJson && responseJson.error) {
           errorMsg = responseJson.error
        }
        toast.error(errorMsg)
        this.setState({ loading : false})
    }
  }

   handleSubmit = () => {

    const unfilledImageSlots = this.state.images.filter(image => image.file === null).length;
    
    if (unfilledImageSlots > 0) {
      toast.error('Please upload all images.');
      return;
    }

    this.setState({ loading : true})

    const header = {
        "token": localStorage.getItem("userToken"),
      };

    const bookedSlotId = this.props.navigation.getParam("reservationId")

    const formData = new FormData()

    formData.append("user_booking_update[account_id]", this.state.userId)
    formData.append("user_booking_update[booked_slot_id]", bookedSlotId)
    formData.append("user_booking_update[visible_damage_status]", this.state.damageValue)

    
    this.state.images.forEach((image) => {
        if (image.file) {
          formData.append('user_booking_update[images][]', image.file);
        }
      });

    this.confirmCheckoutApiCallId = apiCalling({
        header : JSON.stringify(header),
        endPoint: configJSON.saveCheckoutApiEndPoint,
        method: configJSON.apiTypePost,
        body: formData
    })

  };

  getUserProfile = () => {
    const header = {
        'token': localStorage.getItem("userToken")
    }

    this.getUserProfileApiCallId = apiCalling({
        header: JSON.stringify(header),
        endPoint: configJSON.getUserProfileApiEndPoint,
        method: configJSON.apiTypeGet
    })

  }

  handleChangeforDamageKey = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ damageValue : event.target.value})
}
   
   navigateToPreviousPage = () => {
     this.props.navigation.goBack()
   }

   navigateToHomePage = () => {
    this.props.navigation.navigate("Home")
  }
   
  navigateToReservationPage = () => {
    this.props.navigation.navigate("Reservation")
  }

  handleDateCondition = (storageKey: string) => {
    return localStorage.getItem(storageKey) ? localStorage.getItem(storageKey) : ""
  }

  startTrip = () => {

    this.setState({ loading : true})
    const header = {
      'token': localStorage.getItem("userToken")
    }

    const bookSlotId = this.props.navigation.getParam("reservationId")

    const formData = new FormData()

    formData.append("booked_slot_id", bookSlotId)
    formData.append("trip_status", "started")

    this.startTripApiCallId = apiCalling({
      header: JSON.stringify(header),
      body: formData,
      endPoint: configJSON.satrtTripApiEndPoint,
      method: configJSON.apiTypePost
    })
  }


  // Customizable Area End
}

// Customizable Area End