// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";

import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { apiCalling, truthyValue } from '../../../components/src/asset'
import { toast } from "react-toastify"

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface SectionAttributes {
    title: string;
    description: string;
    id? :string
}
  
interface SubInstructionAttributes {
    name: string;
    sections_attributes: SectionAttributes[];
    id? : string;
}

interface EditItem {
    checkInInfo : boolean,
    houseRules : boolean,
    locationDetails : boolean,
    accessMethod : boolean,
    parkingInfo : boolean,
    wifiInfo : boolean,
    localRecomadations : boolean,
    otherDetails : boolean
}
interface BodyArray {
    id? : string | number
    name: string;
    sub_instructions_attributes: SubInstructionAttributes[];
}

interface InstructionData {
    instructionID: number,
    cardTitle: string
  }

interface SubInstructionText {
    checkInDateTimeText : string,
    houseRuleText : string,
    locationDetailstext : string
    accessMethodText : string
    partkingInstructionText: string,
    wifiInstructionText: string,
    otherPropertyDetailsText : string
    localRecommandedText: string
}

interface MainInstructionText {
    housePoliciyText : string,
    checkInInstructionText : string
}

interface CarSubInstructionText {
    checkInDateTimeText : string,
    hostGuidelinesText : string,
    pickUpText : string
    returnText : string
    carInstructionText: string,
    carSpecificationText: string,
}

interface MainCarInstructionText {
    carPoliciyText : string
    carPickUpReturnText : string
    carInfoDetailsText : string
}

interface EditCarKeys {
    checkInDateAndTime : boolean
    hostGuidelines : boolean
    pickupInstruction: boolean
    returnInstruction : boolean
    carInstruction : boolean
    carSpecification : boolean
}

interface S {
  // Customizable Area Start  
  catalougeId : string,
  catalougeType : string
  loader: boolean,
  mainResponse : any[]
  editInstruction : EditItem,
  policyInstructionId :  string
  checkInInstructionId : string,
  checkInInstructionArray : SubInstructionAttributes[],
  checkInInfoDetails : InstructionData[],
  houseRulesDetails : InstructionData[],
  houseRulesInstructionArray : SubInstructionAttributes[]
  locationDetails : InstructionData[],
  locationInstructionArray : SubInstructionAttributes[],
  accessMethodDetails : InstructionData[],
  accessMethodInstructionArray : SubInstructionAttributes[],
  parkingDetails : InstructionData[],
  parkingInstructionArray : SubInstructionAttributes[],
  wifiDetails : InstructionData[],
  wifiInstructionArray : SubInstructionAttributes[],
  otherPropertyDetails : InstructionData[],
  otherPropertyDetailsArray : SubInstructionAttributes[],
  localRecommandedDetails : InstructionData[],
  localRecommandedArray : SubInstructionAttributes[]
  mainCarPolicyInstructionId : string
  mainCarPickUpReturnInstructionId : string
  mainCarInformationInstructionId : string
  editCarKeys : EditCarKeys
  carCheckInDetails : InstructionData[],
  carCheckInArray : SubInstructionAttributes[],
  carHostGuidelinesDetails : InstructionData[],
  carHostGuidelineArray : SubInstructionAttributes[]
  carPickUpDetails : InstructionData[]
  carPickUpArray : SubInstructionAttributes[]
  carReturnDetails : InstructionData[]
  carReturnArray : SubInstructionAttributes[]
  carInfoDetails : InstructionData[]
  carInfoArray : SubInstructionAttributes[]
  carSpecificationDetails : InstructionData[]
  carSpecificationArray : SubInstructionAttributes[]
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class GuestInstructionController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getCatalougeApiCallId : string = ""
  getInstructionApiCallId : string = ""
  saveInstructionApiCallId : string = ""
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];
    this.state = {
        catalougeId: "",
        catalougeType: "",
        loader: true,
        mainResponse: [],
        policyInstructionId: "",
        checkInInstructionId: "",
        editInstruction : {
            checkInInfo: false,
            houseRules: false,
            locationDetails: false,
            wifiInfo: false,
            accessMethod: false,
            parkingInfo: false,
            otherDetails: false,
            localRecomadations: false
        },
        checkInInstructionArray: this.checkInInstructionArray,
        checkInInfoDetails: [],
        houseRulesDetails: [],
        houseRulesInstructionArray: this.houseRulesArray,
        locationDetails: [],
        locationInstructionArray : this.locationArray,
        accessMethodDetails: [],
        accessMethodInstructionArray: this.accessMethodInstructionArray,
        parkingDetails: [],
        parkingInstructionArray: this.parkingInstructionArray,
        wifiDetails: [],
        wifiInstructionArray: this.wifiInstructionArray,
        otherPropertyDetails: [],
        otherPropertyDetailsArray : this.otherPropertyInstructionArray,
        localRecommandedDetails: [],
        localRecommandedArray: this.localRecommandedInstructionArray,
        mainCarPolicyInstructionId: "",
        mainCarPickUpReturnInstructionId: "",
        mainCarInformationInstructionId: "",
        editCarKeys: this.carKeys,
        carCheckInDetails: [],
        carCheckInArray: this.carCheckInArray,
        carHostGuidelinesDetails : [],
        carHostGuidelineArray : this.carHostGuidelinesArray,
        carPickUpDetails: [],
        carPickUpArray: this.carPickUpArray,
        carReturnDetails: [],
        carReturnArray: this.carReturnArray,
        carInfoDetails: [],
        carInfoArray: this.carInfoArray,
        carSpecificationDetails: [],
        carSpecificationArray:this.carSpecificationArray
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      switch(apiRequestCallId) {
        case this.getCatalougeApiCallId:
             return this.handleGetCatalougeApiResponse(responseJson)
        case this.getInstructionApiCallId:
            return this.handleGetIntructionApiResponse(responseJson)
        case this.saveInstructionApiCallId:
            return this.handleSaveInstructionResponse(responseJson)
      }
      
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount(){
    const catalougeId = this.props.navigation.getParam("subId")
    this.setState({ catalougeId: catalougeId})
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined): void {
      if(prevState.catalougeId !== this.state.catalougeId) {
         this.getCatalogeDetails()
         this.getInstructionData()
      }
  }

  handleGetIntructionApiResponse = (responseJson : any) => {
        if (responseJson && responseJson.data && responseJson.data.length > 0) {

            const instructionId = this.handleFindInstructionIdFromResponse(responseJson.data, this.mainInstructionText.housePoliciyText)
            const checkInInstructionId = this.handleFindInstructionIdFromResponse(responseJson.data, this.mainInstructionText.checkInInstructionText)

            const checkInArray = this.handleFilteredArrayFromResponse(responseJson.data, this.subInstructionText.checkInDateTimeText)
            const houseRulesArray = this.handleFilteredArrayFromResponse(responseJson.data,this.subInstructionText.houseRuleText)
            const locationDetailsArray = this.handleFilteredArrayFromResponse(responseJson.data, this.subInstructionText.locationDetailstext)
            const accessMethodDetailsArray = this.handleFilteredArrayFromResponse(responseJson.data, this.subInstructionText.accessMethodText)
            const parkingDetailsArray = this.handleFilteredArrayFromResponse(responseJson.data, this.subInstructionText.partkingInstructionText)
            const wifiDetailsArray = this.handleFilteredArrayFromResponse(responseJson.data, this.subInstructionText.wifiInstructionText)
            const propertyDetailsArray = this.handleFilteredArrayFromResponse(responseJson.data, this.subInstructionText.otherPropertyDetailsText)
            const recomandedDetailsArray = this.handleFilteredArrayFromResponse(responseJson.data, this.subInstructionText.localRecommandedText)

            const filteredCheckInArray = checkInArray.map((item: any) => {
                return {
                    instructionID: item.id,
                    cardTitle: `${truthyValue(item.attributes.title)} by ${truthyValue(item.attributes.description)}`
                }
            })

            const filteredHouseRulesArray = houseRulesArray.map((item: any) => {
                return {
                    instructionID: item.id,
                    cardTitle: truthyValue(item.attributes.description)
                }
            })

            const filteredLocationDetailsArray = locationDetailsArray.map((item: any) => {
                return {
                    instructionID: item.id,
                    cardTitle: truthyValue(item.attributes.description)
                }
            })

            const locationInstructionArray : SubInstructionAttributes[] = [
                {
                    "id": this.handleFindSubInstructionId(responseJson.data,checkInInstructionId, this.subInstructionText.locationDetailstext ),
                     "name": this.subInstructionText.locationDetailstext,
                     "sections_attributes":locationDetailsArray.map((item: any) => {
                        return {
                            id: item.id,
                            title: truthyValue(item.attributes.title),
                            description: truthyValue(item.attributes.description),
                        }
                    })
                },
            ]

            const houseRulesInstructionArray : SubInstructionAttributes[] = [
                {
                    "id": this.handleFindSubInstructionId(responseJson.data, instructionId, this.subInstructionText.houseRuleText ),
                     "name": this.subInstructionText.houseRuleText,
                     "sections_attributes": houseRulesArray.map((item: any) => {
                        return {
                            id: item.id,
                            title: "",
                            description: truthyValue(item.attributes.description),
                        }
                    })
                },
            ]

            const checkInInstructionArray : SubInstructionAttributes[] = [
                {
                    "id": this.handleFindSubInstructionId(responseJson.data, instructionId,this.subInstructionText.checkInDateTimeText ),
                     "name":this.subInstructionText.checkInDateTimeText,
                     "sections_attributes": checkInArray.map((item: any) => {
                        return {
                            id: item.id,
                            title: truthyValue(item.attributes.title),
                            description: truthyValue(item.attributes.description),
                        }
                    })
                },
            ]

            const accessMethodDetails =  accessMethodDetailsArray.map((item: any) => {
                return {
                    instructionID: item.id,
                    cardTitle: truthyValue(item.attributes.description)
                }
            })

            const accessMethodInstructionArray : SubInstructionAttributes[] = [
                {
                    "id": this.handleFindSubInstructionId(responseJson.data,checkInInstructionId, this.subInstructionText.accessMethodText ),
                     "name": this.subInstructionText.accessMethodText,
                     "sections_attributes": accessMethodDetailsArray.map((item: any) => {
                        return {
                            id: item.id,
                            title: truthyValue(item.attributes.title),
                            description: truthyValue(item.attributes.description),
                        }
                    })
                },
            ]

            const parkingDetails =  parkingDetailsArray.map((item: any) => {
                return {
                    instructionID: item.id,
                    cardTitle: truthyValue(item.attributes.description)
                }
            })

            const parkingInstructionArray : SubInstructionAttributes[] = [
                {
                    "id": this.handleFindSubInstructionId(responseJson.data,checkInInstructionId, this.subInstructionText.partkingInstructionText ),
                     "name": this.subInstructionText.partkingInstructionText,
                     "sections_attributes": parkingDetailsArray.map((item: any) => {
                        return {
                            id: item.id,
                            title: truthyValue(item.attributes.title),
                            description: truthyValue(item.attributes.description),
                        }
                    })
                },
            ]

            const wifiDetails =  wifiDetailsArray.map((item: any) => {
                return {
                    instructionID: item.id,
                    cardTitle: truthyValue(item.attributes.description)
                }
            })

            const wifiInstructionArray : SubInstructionAttributes[] = [
                {
                    "id": this.handleFindSubInstructionId(responseJson.data,checkInInstructionId, this.subInstructionText.wifiInstructionText ),
                     "name": this.subInstructionText.wifiInstructionText,
                     "sections_attributes": wifiDetailsArray.map((item: any) => {
                        return {
                            id: item.id,
                            title: truthyValue(item.attributes.title),
                            description: truthyValue(item.attributes.description),
                        }
                    })
                },
            ]

            const propertyDetails =  propertyDetailsArray.map((item: any) => {
                return {
                    instructionID: item.id,
                    cardTitle: truthyValue(item.attributes.description)
                }
            })

            const propertyInstructionArray : SubInstructionAttributes[] = [
                {
                    "id": this.handleFindSubInstructionId(responseJson.data,checkInInstructionId, this.subInstructionText.otherPropertyDetailsText ),
                     "name": this.subInstructionText.otherPropertyDetailsText,
                     "sections_attributes": propertyDetailsArray.map((item: any) => {
                        return {
                            id: item.id,
                            title: truthyValue(item.attributes.title),
                            description: truthyValue(item.attributes.description),
                        }
                    })
                },
            ]

            const recomandedDetails =  recomandedDetailsArray.map((item: any) => {
                return {
                    instructionID: item.id,
                    cardTitle: truthyValue(item.attributes.description)
                }
            })

            const recomandedInstructionArray : SubInstructionAttributes[] = [
                {
                    "id": this.handleFindSubInstructionId(responseJson.data,checkInInstructionId, this.subInstructionText.localRecommandedText ),
                     "name": this.subInstructionText.localRecommandedText,
                     "sections_attributes": recomandedDetailsArray.map((item: any) => {
                        return {
                            id: item.id,
                            title: truthyValue(item.attributes.title),
                            description: truthyValue(item.attributes.description),
                        }
                    })
                },
            ]

            const carPolicyId = this.handleFindInstructionIdFromResponse(responseJson.data, this.carMainInstructionText.carPoliciyText)
            const carPickUpReturnId = this.handleFindInstructionIdFromResponse(responseJson.data, this.carMainInstructionText.carPickUpReturnText)
            const carInfoId = this.handleFindInstructionIdFromResponse(responseJson.data, this.carMainInstructionText.carInfoDetailsText)

            const carCheckInArray = this.handleFilteredArrayFromResponse(responseJson.data, this.carSubInstructionText.checkInDateTimeText)
            const carHostGuidelinesArray = this.handleFilteredArrayFromResponse(responseJson.data, this.carSubInstructionText.hostGuidelinesText)
            const carPickupArray = this.handleFilteredArrayFromResponse(responseJson.data, this.carSubInstructionText.pickUpText)
            const carReturnArray = this.handleFilteredArrayFromResponse(responseJson.data, this.carSubInstructionText.returnText)
            const carInfoArray = this.handleFilteredArrayFromResponse(responseJson.data, this.carSubInstructionText.carInstructionText)
            const carSpecificatopnArray = this.handleFilteredArrayFromResponse(responseJson.data, this.carSubInstructionText.carSpecificationText)

            const carCheckInDetails =  carCheckInArray.map((item: any) => {
                return {
                    instructionID: item.id,
                    cardTitle: `${truthyValue(item.attributes.title)} : ${truthyValue(item.attributes.description)}`
                }
            })

            const carCheckInInstructionArray : SubInstructionAttributes[] = [
                {
                    "id": this.handleFindSubInstructionId(responseJson.data,carPolicyId, this.carSubInstructionText.checkInDateTimeText ),
                     "name": this.carSubInstructionText.checkInDateTimeText,
                     "sections_attributes": carCheckInArray.map((item: any) => {
                        return {
                            id: item.id,
                            title: truthyValue(item.attributes.title),
                            description: truthyValue(item.attributes.description),
                        }
                    })
                },
            ]

            const carHostGuidelinesDetails =  carHostGuidelinesArray.map((item: any) => {
                return {
                    instructionID: item.id,
                    cardTitle: truthyValue(item.attributes.description)
                }
            })

            const carHostGuidelinesInstructionArray : SubInstructionAttributes[] = [
                {
                    "id": this.handleFindSubInstructionId(responseJson.data,carPolicyId, this.carSubInstructionText.hostGuidelinesText ),
                     "name": this.carSubInstructionText.hostGuidelinesText,
                     "sections_attributes": carHostGuidelinesArray.map((item: any) => {
                        return {
                            id: item.id,
                            title: truthyValue(item.attributes.title),
                            description: truthyValue(item.attributes.description),
                        }
                    })
                },
            ]

            const carPickUpDetails =  carPickupArray.map((item: any) => {
                return {
                    instructionID: item.id,
                    cardTitle: truthyValue(item.attributes.description)
                }
            })

            const carPickUpInstructionArray : SubInstructionAttributes[] = [
                {
                    "id": this.handleFindSubInstructionId(responseJson.data,carPickUpReturnId, this.carSubInstructionText.pickUpText ),
                     "name": this.carSubInstructionText.pickUpText,
                     "sections_attributes": carPickupArray.map((item: any) => {
                        return {
                            id: item.id,
                            title: truthyValue(item.attributes.title),
                            description: truthyValue(item.attributes.description),
                        }
                    })
                },
            ]

            const carReturnDetails =  carReturnArray.map((item: any) => {
                return {
                    instructionID: item.id,
                    cardTitle: truthyValue(item.attributes.description)
                }
            })

            const carReturnInstructionArray : SubInstructionAttributes[] = [
                {
                    "id": this.handleFindSubInstructionId(responseJson.data,carPickUpReturnId, this.carSubInstructionText.returnText ),
                     "name": this.carSubInstructionText.returnText,
                     "sections_attributes": carReturnArray.map((item: any) => {
                        return {
                            id: item.id,
                            title: truthyValue(item.attributes.title),
                            description: truthyValue(item.attributes.description),
                        }
                    })
                },
            ]

            const carInfoDetails =  carInfoArray.map((item: any) => {
                return {
                    instructionID: item.id,
                    cardTitle: truthyValue(item.attributes.description)
                }
            })

            const carInfoInstructionArray : SubInstructionAttributes[] = [
                {
                    "id": this.handleFindSubInstructionId(responseJson.data,carInfoId, this.carSubInstructionText.carInstructionText ),
                     "name": this.carSubInstructionText.carInstructionText,
                     "sections_attributes": carInfoArray.map((item: any) => {
                        return {
                            id: item.id,
                            title: truthyValue(item.attributes.title),
                            description: truthyValue(item.attributes.description),
                        }
                    })
                },
            ]

            const carSpecificationDetails =  carSpecificatopnArray.map((item: any) => {
                return {
                    instructionID: item.id,
                    cardTitle: `${truthyValue(item.attributes.title)} : ${truthyValue(item.attributes.description)}`
                }
            })

            const carSpecificationInstructionArray : SubInstructionAttributes[] = [
                {
                    "id": this.handleFindSubInstructionId(responseJson.data,carInfoId, this.carSubInstructionText.carSpecificationText ),
                     "name": this.carSubInstructionText.carSpecificationText,
                     "sections_attributes": carSpecificatopnArray.map((item: any) => {
                        return {
                            id: item.id,
                            title: truthyValue(item.attributes.title),
                            description: truthyValue(item.attributes.description),
                        }
                    })
                },
            ]


            this.setState({
                checkInInfoDetails: filteredCheckInArray,
                houseRulesDetails: filteredHouseRulesArray,
                locationDetails: filteredLocationDetailsArray,
                accessMethodDetails: accessMethodDetails,
                parkingDetails: parkingDetails,
                wifiDetails: wifiDetails,
                otherPropertyDetails: propertyDetails,
                localRecommandedDetails: recomandedDetails,
                policyInstructionId: instructionId,
                checkInInstructionId : checkInInstructionId,
                mainResponse: responseJson.data,
                locationInstructionArray: this.handleStateCheck(locationInstructionArray,this.subInstructionText.locationDetailstext) ,
                checkInInstructionArray: this.handleStateCheck(checkInInstructionArray,this.subInstructionText.checkInDateTimeText),
                houseRulesInstructionArray: this.handleStateCheck(houseRulesInstructionArray,this.subInstructionText.houseRuleText),
                accessMethodInstructionArray:  this.handleStateCheck(accessMethodInstructionArray,this.subInstructionText.accessMethodText),
                parkingInstructionArray:  this.handleStateCheck(parkingInstructionArray,this.subInstructionText.partkingInstructionText),
                wifiInstructionArray:  this.handleStateCheck(wifiInstructionArray,this.subInstructionText.wifiInstructionText),
                otherPropertyDetailsArray:  this.handleStateCheck(propertyInstructionArray,this.subInstructionText.otherPropertyDetailsText),
                localRecommandedArray:  this.handleStateCheck(recomandedInstructionArray,this.subInstructionText.localRecommandedText),
                mainCarPolicyInstructionId : carPolicyId,
                mainCarPickUpReturnInstructionId: carPickUpReturnId,
                mainCarInformationInstructionId: carInfoId,
                carCheckInDetails : carCheckInDetails,
                carHostGuidelinesDetails : carHostGuidelinesDetails,
                carPickUpDetails: carPickUpDetails,
                carReturnDetails: carReturnDetails,
                carInfoDetails: carInfoDetails,
                carSpecificationDetails: carSpecificationDetails,
                carCheckInArray: this.handleStateCheck(carCheckInInstructionArray, this.carSubInstructionText.checkInDateTimeText),
                carHostGuidelineArray: this.handleStateCheck(carHostGuidelinesInstructionArray, this.carSubInstructionText.hostGuidelinesText),
                carPickUpArray: this.handleStateCheck(carPickUpInstructionArray, this.carSubInstructionText.pickUpText),
                carReturnArray: this.handleStateCheck(carReturnInstructionArray, this.carSubInstructionText.returnText),
                carInfoArray: this.handleStateCheck(carInfoInstructionArray, this.carSubInstructionText.carInstructionText),
                carSpecificationArray: this.handleStateCheck(carSpecificationInstructionArray, this.carSubInstructionText.carSpecificationText),
                loader: false
            })
        } else {
            this.setState({
                loader: false
            })
        }
    }

  handleSaveInstructionResponse = (responseJson : any) => {
      if (responseJson && responseJson.data && responseJson.data.length > 0) {
          this.getInstructionData()
          toast.success("Instruction updated successfully")
          this.setState({ editInstruction : this.handleAllErrorFalse(), editCarKeys: this.carKeys})
      } else {
         let errMsg = "Something went wrong"
         if(responseJson && responseJson.errors) {
            errMsg = responseJson.errors[0]
         }
          toast.error(errMsg)
          this.setState({
              loader: false
          })
      }
  }

  handleGetCatalougeApiResponse = (responseJson : any) => {
     if(responseJson && responseJson.catalogue && responseJson.catalogue.data) {
        const catalougeType = truthyValue(responseJson.catalogue.data.attributes.account)
        this.setState({ catalougeType: catalougeType})
     }
  }

  handleFilteredArrayFromResponse = (array : any, filterVal : string) => {
      const modifiedArr = array
          .flatMap((instruction: any) => instruction.attributes.sub_instructions.data)
          .filter((subInstruction: any) => subInstruction.attributes.name === filterVal)
          .flatMap((subInstruction: any) => subInstruction.attributes.sections.data);

     return modifiedArr
  }

  handleFindInstructionIdFromResponse = (array : any, instructionName : string) => {
      const findId = array.filter((item: any) => item.attributes.name === instructionName).map((item:any) => item.id)
      const instructionId = findId.length > 0 ? truthyValue(findId.toString()) : ""
      return instructionId
  }

  handleFindSubInstructionId = (array : any[],instructionId : string, subInstructionName : string) => {
    const findSubInstructionId = array.filter((instruction) => instruction.id === instructionId)
    .flatMap(instruction => instruction.attributes.sub_instructions.data)
    .map(subInstruction => subInstruction)
    .filter(item => item.attributes.name === subInstructionName)
    .map(item => item.id)
      const subInstructionId = findSubInstructionId.length > 0 ? truthyValue(findSubInstructionId.toString()) : ""
      return subInstructionId
  }

  goBack = () => {
    this.props.navigation.goBack()
  }

  getCatalogeDetails = () => {
    const headerWeb = {
        "token": localStorage.getItem("userToken")
      };
      
      this.getCatalougeApiCallId = apiCalling({
        header: JSON.stringify(headerWeb),
        method: configJSON.apiMethodTypeGet,
        endPoint: `${configJSON.getCatalougeInfoApiEndpoint}/${this.state.catalougeId}`,
      })
  }

  saveInstructions = (bodyArray : BodyArray[]) => {

      if (!this.handleAllEmptyValidation(bodyArray)) {
          toast.error("Please fill all details")
          return false
      }

    this.setState({ loader : true})
    const header = {
        "token": localStorage.getItem("userToken"),
        "Content-Type" : "application/json"
      };

      const body  = {
        instructions_attributes : bodyArray
      }
      
      this.saveInstructionApiCallId = apiCalling({
        header: JSON.stringify(header),
        method: configJSON.apiMethodTypePost,
        endPoint: configJSON.instructionApiEndpoint(this.state.catalougeId),
        body: JSON.stringify(body)
      })
  }

  getInstructionData = () => {
    const header = {
        "token": localStorage.getItem("userToken")
      };
      
      this.getInstructionApiCallId = apiCalling({
        header: JSON.stringify(header),
        method: configJSON.apiMethodTypeGet,
        endPoint: configJSON.instructionApiEndpoint(this.state.catalougeId),
      })
  }

  handleAllEmptyValidation = (array : BodyArray[]) => {
    const allDescriptionsFilled = array[0].sub_instructions_attributes.every(subInstruction =>
        subInstruction.sections_attributes.every(section => section.description !== "")
    );

    return allDescriptionsFilled
  }

    mainInstructionText : MainInstructionText = {
        checkInInstructionText : "Check-in Instructions",
        housePoliciyText: "House Policies"
    }

    subInstructionText : SubInstructionText = {
        checkInDateTimeText : "Check-In Date and Time",
        houseRuleText: "House Rules",
        locationDetailstext: "Location Details",
        accessMethodText: "Access Method",
        partkingInstructionText: "Parking Instructions",
        wifiInstructionText: "Wi-Fi Connection Details",
        otherPropertyDetailsText: "Other Property Details",
        localRecommandedText: "Local Recommendations"
    }


    checkInInstructionArray : SubInstructionAttributes[] = [
        {
            "name": this.subInstructionText.checkInDateTimeText,
            "sections_attributes": [
                {
                    title: "Check-In Time",
                    description: "",
                },
                {
                    title: "Check-out Time",
                    description: "",
                }
            ]
        },
    ]

    houseRulesArray : SubInstructionAttributes[] = [
        {
            "name": this.subInstructionText.houseRuleText,
            "sections_attributes": [
                {
                    title: "",
                    description: "No Smoking Allowed",
                },
                {
                    title: "",
                    description: "No Pets over 10lbs",
                },
                {
                    title: "",
                    description: "Quiet hours are from 10:00 PM to 7:00 AM.",
                }
            ]
        },
    ]

    locationArray : SubInstructionAttributes[] = [
        {
            "name": this.subInstructionText.locationDetailstext,
            "sections_attributes": [
                {
                    title: "Property Address",
                    description: "",
                },
                {
                    title: "Additional Instructions",
                    description: "",
                }
            ]
        },
    ]

    accessMethodInstructionArray : SubInstructionAttributes[] = [
        {
            "name": this.subInstructionText.accessMethodText,
            "sections_attributes": [
                {
                    title: "Access Method",
                    description: "",
                },
                {
                    title: "Main Entrance",
                    description: "",
                }
            ]
        },
    ]

    parkingInstructionArray : SubInstructionAttributes[] = [
        {
            "name": this.subInstructionText.partkingInstructionText,
            "sections_attributes": [
                {
                    title: "Parking",
                    description: "",
                },
            ]
        },
    ]

    wifiInstructionArray : SubInstructionAttributes[] = [
        {
            "name": this.subInstructionText.wifiInstructionText,
            "sections_attributes": [
                {
                    title: "Wi-Fi Network",
                    description: "",
                },
                {
                    title: "Wi-Fi Password",
                    description: "",
                }
            ]
        },
    ]

    otherPropertyInstructionArray : SubInstructionAttributes[] = [
        {
            "name": this.subInstructionText.otherPropertyDetailsText,
            "sections_attributes": [
                {
                    title: "",
                    description: "",
                },
                {
                    title: "",
                    description: "",
                }
            ]
        },
    ]

    localRecommandedInstructionArray : SubInstructionAttributes[] = [
        {
            "name": this.subInstructionText.localRecommandedText,
            "sections_attributes": [
                {
                    title: "Description",
                    description: "",
                },
                {
                    title: "Description",
                    description: "",
                }
            ]
        },
    ]

     addNewSection = (data : SubInstructionAttributes[], stateName : keyof S) => {
        const newSection = { title: "", description: "" };
        const updatedData = [...data];
        updatedData[0].sections_attributes.push(newSection);

        this.setState({ 
            ...this.state,
            [stateName] : updatedData
        })
        
    };

    handleHouseRuleInputChange  =(event: React.ChangeEvent<HTMLInputElement>, index : number) => {
        const { value } = event.target;
        
        this.setState(prevState => {
          const updatedHouseRulesArr = [...prevState.houseRulesInstructionArray];
          updatedHouseRulesArr[0].sections_attributes[index].description = value;
          return {houseRulesInstructionArray: updatedHouseRulesArr };
        });
      };  

    handleLocationInputChange =(event: React.ChangeEvent<HTMLInputElement>, index : number) => {
        const { value } = event.target;
        
        this.setState(prevState => {
          const updatedLocationArr = [...prevState.locationInstructionArray];
          updatedLocationArr[0].sections_attributes[index].description = value;
          return {locationInstructionArray: updatedLocationArr };
        });
      }; 

    handleInputChange = (event: React.ChangeEvent<HTMLInputElement>, index : number) => {
        const { value } = event.target;
        
        this.setState(prevState => {
          const updatedCheckInArr = [...prevState.checkInInstructionArray];
          updatedCheckInArr[0].sections_attributes[index].description = value;
          return { checkInInstructionArray: updatedCheckInArr };
        });
      };

    handleEditClick = (keyName : keyof EditItem) => {
        this.setState({
            editInstruction : {
                ...this.state.editInstruction,
                [keyName] : true
            }
        })
    }

    appyCheckInSubInstruction = () => {
        const checkInArrPass : BodyArray[] = [
            {
                name : this.mainInstructionText.housePoliciyText,
                id : this.state.policyInstructionId !== "" ? this.state.policyInstructionId : "",
                sub_instructions_attributes : this.state.checkInInstructionArray
            }
        ]

        this.saveInstructions(checkInArrPass)
    }
    
    applyHouseRoleSubInstruction = () => {
        
            const houseRulesPass = [
                {
                    name : this.mainInstructionText.housePoliciyText,
                    id : this.state.policyInstructionId !== "" ? this.state.policyInstructionId : "",
                    sub_instructions_attributes : this.state.houseRulesInstructionArray
                }
            ]
        
        this.saveInstructions(houseRulesPass)
    }

    applyLocationSubInstructions = () => {

        const locationDetailsPass : BodyArray[] = [
            {
                name : this.mainInstructionText.checkInInstructionText,
                id : this.state.checkInInstructionId !== "" ? this.state.checkInInstructionId : "",
                sub_instructions_attributes : this.state.locationInstructionArray
            }
        ]

        this.saveInstructions(locationDetailsPass)
    }

    handleAccessMethodInputChange = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
        const { value } = event.target;

        this.setState(prevState => {
            const updatedAccessMethodArr = [...prevState.accessMethodInstructionArray];
            updatedAccessMethodArr[0].sections_attributes[index].description = value;
            return {accessMethodInstructionArray: updatedAccessMethodArr };
        });
    };

    applyAccessMethodSubInstructions = () => {

        const accessMethodPass: BodyArray[] = [
            {
                name: this.mainInstructionText.checkInInstructionText,
                id: this.state.checkInInstructionId !== "" ? this.state.checkInInstructionId : "",
                sub_instructions_attributes: this.state.accessMethodInstructionArray
            }
        ]

        this.saveInstructions(accessMethodPass)
    }

    handleParkingInputChange = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
        const { value } = event.target;

        this.setState(prevState => {
            const updatedParkingArr = [...prevState.parkingInstructionArray];
            updatedParkingArr[0].sections_attributes[index].description = value;
            return {parkingInstructionArray: updatedParkingArr };
        });
    };

    applyParkingSubInstructions = () => {

        const parkingPass: BodyArray[] = [
            {
                name: this.mainInstructionText.checkInInstructionText,
                id: this.state.checkInInstructionId !== "" ? this.state.checkInInstructionId : "",
                sub_instructions_attributes: this.state.parkingInstructionArray
            }
        ]

        this.saveInstructions(parkingPass)
    }

    handleWifiInputChange = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
        const { value } = event.target;

        this.setState(prevState => {
            const updatedWifiArr = [...prevState.wifiInstructionArray];
            updatedWifiArr[0].sections_attributes[index].description = value;
            return { wifiInstructionArray: updatedWifiArr };
        });
    };

    applyWifiSubInstructions = () => {

        const parkingPass: BodyArray[] = [
            {
                name: this.mainInstructionText.checkInInstructionText,
                id: this.state.checkInInstructionId !== "" ? this.state.checkInInstructionId : "",
                sub_instructions_attributes: this.state.wifiInstructionArray
            }
        ]

        this.saveInstructions(parkingPass)
    }

    handleOtherPropertyInputChange = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
        const { value } = event.target;

        this.setState(prevState => {
            const updateOtherPropertyArr = [...prevState.otherPropertyDetailsArray];
            updateOtherPropertyArr[0].sections_attributes[index].description = value;
            return { otherPropertyDetailsArray: updateOtherPropertyArr };
        });
    };

    applyOtherSubInstructions = () => {

        const otherPropertyPass: BodyArray[] = [
            {
                name: this.mainInstructionText.checkInInstructionText,
                id: this.state.checkInInstructionId !== "" ? this.state.checkInInstructionId : "",
                sub_instructions_attributes: this.state.otherPropertyDetailsArray
            }
        ]

        this.saveInstructions(otherPropertyPass)
    }

    handleLocalRecommandedInputChange = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
        const { value } = event.target;

        this.setState(prevState => {
            const localRecommandedArr = [...prevState.localRecommandedArray];
            localRecommandedArr[0].sections_attributes[index].description = value;
            return { localRecommandedArray: localRecommandedArr };
        });
    };

    applyLocalRecomandedSubInstructions = () => {

        const localReccomandedPass: BodyArray[] = [
            {
                name: this.mainInstructionText.checkInInstructionText,
                id: this.state.checkInInstructionId !== "" ? this.state.checkInInstructionId : "",
                sub_instructions_attributes: this.state.localRecommandedArray
            }
        ]

        this.saveInstructions(localReccomandedPass)
    }

    handleAllErrorFalse = () => {
        const allCheckFalse : EditItem = {
            checkInInfo: false,
            houseRules: false,
            locationDetails: false,
            wifiInfo: false,
            accessMethod: false,
            parkingInfo: false,
            otherDetails : false,
            localRecomadations: false
        }

        return allCheckFalse
    }

    handleViewOnly = (index : number) => {
        return index === 0 || index === 1 || index === 2 ? true : false
    }

    handleStateCheck = (array : SubInstructionAttributes[], keyVal : string) => {

        let finalArr: SubInstructionAttributes[] = []
        if (array[0].sections_attributes.length > 0) {
            return array
        } else {
            switch (keyVal) {
                case this.subInstructionText.checkInDateTimeText:
                    finalArr.push(this.checkInInstructionArray[0])
                    break;
                case this.subInstructionText.locationDetailstext:
                    finalArr.push(this.locationArray[0])
                    break;
                case this.subInstructionText.houseRuleText:
                    finalArr.push(this.houseRulesArray[0])
                    break;
                case this.subInstructionText.accessMethodText:
                    finalArr.push(this.accessMethodInstructionArray[0])
                    break;
                case this.subInstructionText.partkingInstructionText:
                    finalArr.push(this.parkingInstructionArray[0])
                    break;
                case this.subInstructionText.wifiInstructionText:
                    finalArr.push(this.wifiInstructionArray[0])
                    break;
                case this.subInstructionText.otherPropertyDetailsText:
                    finalArr.push(this.otherPropertyInstructionArray[0])
                    break;
                case this.subInstructionText.localRecommandedText:
                    finalArr.push(this.localRecommandedInstructionArray[0])
                    break;
                case this.carSubInstructionText.checkInDateTimeText:
                    finalArr.push(this.carCheckInArray[0])
                    break;
                case this.carSubInstructionText.hostGuidelinesText:
                    finalArr.push(this.carHostGuidelinesArray[0])
                    break;
                case this.carSubInstructionText.pickUpText:
                    finalArr.push(this.carPickUpArray[0])
                    break;
                case this.carSubInstructionText.returnText:
                    finalArr.push(this.carReturnArray[0])
                    break;
                case this.carSubInstructionText.carInstructionText:
                    finalArr.push(this.carInfoArray[0])
                    break;
                case this.carSubInstructionText.carSpecificationText:
                    finalArr.push(this.carSpecificationArray[0])
                    break;
            }

            return finalArr
        }
    }

    handleTypeCheck = (keyCheck : string) => {
        return keyCheck === "Car" ? true : false
    }

    handleCarEdit = (keyValue : keyof EditCarKeys) => {
        this.setState({
            editCarKeys : {
                ...this.state.editCarKeys,
                [keyValue] : true
            }
        })
    }

    carMainInstructionText : MainCarInstructionText = {
        carPoliciyText : "Car Policies",
        carPickUpReturnText: "Pick-up & Return Instructions",
        carInfoDetailsText: "Car Information & Details",
    }

    carSubInstructionText : CarSubInstructionText = {
        checkInDateTimeText : "Fuel Policy",
        hostGuidelinesText: "Host Guidelines",
        pickUpText: "Pick-up Instructions",
        returnText: "Return Instructions",
        carInstructionText: "Car Instructions",
        carSpecificationText: "Car Specifications",
    }

    carKeys : EditCarKeys = {
        checkInDateAndTime : false,
        hostGuidelines : false,
        pickupInstruction: false,
        returnInstruction: false,
        carInstruction: false,
        carSpecification : false
    }

    carCheckInArray : SubInstructionAttributes[] = [
        {
            "name": this.carSubInstructionText.checkInDateTimeText,
            "sections_attributes": [
                {
                    title: "Fuel Type",
                    description: "",
                },
                {
                    title: "Description",
                    description: "",
                }
            ]
        },
    ]

    handleCarCheckInInputChange = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
        const { value } = event.target;

        this.setState(prevState => {
            const carCheckInArr = [...prevState.carCheckInArray];
            carCheckInArr[0].sections_attributes[index].description = value;
            return { carCheckInArray: carCheckInArr };
        });
    };

    applyCarCheckInSubInstructions = () => {

        const carCheckINBodyPass: BodyArray[] = [
            {
                name: this.carMainInstructionText.carPoliciyText,
                id: this.state.mainCarPolicyInstructionId !== "" ? this.state.mainCarPolicyInstructionId : "",
                sub_instructions_attributes: this.state.carCheckInArray
            }
        ]

        this.saveInstructions(carCheckINBodyPass)
    }

    carHostGuidelinesArray : SubInstructionAttributes[] = [
        {
            "name": this.carSubInstructionText.hostGuidelinesText,
            "sections_attributes": [
                {
                    title: "",
                    description: "No Smoking",
                },
                {
                    title: "",
                    description: "No Pets",
                },
                {
                    title: "",
                    description: "Notify if you plan to leave the state",
                }
            ]
        },
    ]

    handleCarHostGuidelineInputChange = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
        const { value } = event.target;

        this.setState(prevState => {
            const carHostGuidelineArr = [...prevState.carHostGuidelineArray];
            carHostGuidelineArr[0].sections_attributes[index].description = value;
            return { carHostGuidelineArray: carHostGuidelineArr };
        });
    };

    applyCarHostGuidelinesSubInstructions = () => {

        const carHostGuidelineBodyPass: BodyArray[] = [
            {
                name: this.carMainInstructionText.carPoliciyText,
                id: this.state.mainCarPolicyInstructionId !== "" ? this.state.mainCarPolicyInstructionId : "",
                sub_instructions_attributes: this.state.carHostGuidelineArray
            }
        ]

        this.saveInstructions(carHostGuidelineBodyPass)
    }

    carPickUpArray : SubInstructionAttributes[] = [
        {
            "name": this.carSubInstructionText.pickUpText,
            "sections_attributes": [
                {
                    title: "",
                    description: "",
                },
                {
                    title: "",
                    description: "",
                }
            ]
        },
    ]

    handleCarPickUpInputChange = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
        const { value } = event.target;

        this.setState(prevState => {
            const carPickUpArr = [...prevState.carPickUpArray];
            carPickUpArr[0].sections_attributes[index].description = value;
            return { carPickUpArray: carPickUpArr };
        });
    };

    applyCarPickUpSubInstructions = () => {

        const carPickUpBodyPass: BodyArray[] = [
            {
                name: this.carMainInstructionText.carPickUpReturnText,
                id: this.state.mainCarPickUpReturnInstructionId !== "" ? this.state.mainCarPickUpReturnInstructionId : "",
                sub_instructions_attributes: this.state.carPickUpArray
            }
        ]

        this.saveInstructions(carPickUpBodyPass)
    }

    carReturnArray : SubInstructionAttributes[] = [
        {
            "name": this.carSubInstructionText.returnText,
            "sections_attributes": [
                {
                    title: "",
                    description: "",
                },
                {
                    title: "",
                    description: "",
                }
            ]
        },
    ]

    handleCarReturnInputChange = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
        const { value } = event.target;

        this.setState(prevState => {
            const carReturnArr = [...prevState.carReturnArray];
            carReturnArr[0].sections_attributes[index].description = value;
            return { carReturnArray: carReturnArr };
        });
    };

    applyCarReturnSubInstructions = () => {

        const carReturnBodyPass: BodyArray[] = [
            {
                name: this.carMainInstructionText.carPickUpReturnText,
                id: this.state.mainCarPickUpReturnInstructionId !== "" ? this.state.mainCarPickUpReturnInstructionId : "",
                sub_instructions_attributes: this.state.carReturnArray
            }
        ]

        this.saveInstructions(carReturnBodyPass)
    }

    carInfoArray : SubInstructionAttributes[] = [
        {
            "name": this.carSubInstructionText.carInstructionText,
            "sections_attributes": [
                {
                    title: "",
                    description: "",
                },
                {
                    title: "",
                    description: "",
                },
                {
                    title: "",
                    description: "",
                }
            ]
        },
    ]

    handleCarInfoInputChange = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
        const { value } = event.target;

        this.setState(prevState => {
            const carInfoArr = [...prevState.carInfoArray];
            carInfoArr[0].sections_attributes[index].description = value;
            return { carInfoArray : carInfoArr };
        });
    };

    applyCarInfoSubInstructions = () => {

        const carInfoBodyPass: BodyArray[] = [
            {
                name: this.carMainInstructionText.carInfoDetailsText,
                id: this.state.mainCarInformationInstructionId !== "" ? this.state.mainCarInformationInstructionId : "",
                sub_instructions_attributes: this.state.carInfoArray
            }
        ]

        this.saveInstructions(carInfoBodyPass)
    }

    carSpecificationArray : SubInstructionAttributes[] = [
        {
            "name": this.carSubInstructionText.carSpecificationText,
            "sections_attributes": [
                {
                    title: "Fuel Efficiency",
                    description: "",
                },
                {
                    title: "Top Speed",
                    description: "",
                },
                {
                    title: "Acceleration",
                    description: "",
                },
                {
                    title: "Seat Capacity",
                    description: "",
                }
            ]
        },
    ]

    handleCarSpecificatinInputChange = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
        const { value } = event.target;

        this.setState(prevState => {
            const carSpecificationArr = [...prevState.carSpecificationArray];
            carSpecificationArr[0].sections_attributes[index].description = value;
            return { carSpecificationArray : carSpecificationArr };
        });
    };

    applyCarSpecificationSubInstructions = () => {

        const carSpecificationBodyPass: BodyArray[] = [
            {
                name: this.carMainInstructionText.carInfoDetailsText,
                id: this.state.mainCarInformationInstructionId !== "" ? this.state.mainCarInformationInstructionId : "",
                sub_instructions_attributes: this.state.carSpecificationArray
            }
        ]

        this.saveInstructions(carSpecificationBodyPass)
    }

  // Customizable Area End
}

// Customizable Area End