import React, { useState, ChangeEvent } from 'react';
import { OutlinedInput, InputAdornment, IconButton, Typography, Box, List, ListItem, ListItemIcon, ListItemText, Divider, styled } from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

interface Requirement {
  label: string;
  fulfilled: boolean;
}

interface Props {
  onPasswordChange : (password : string) => void
}

const PasswordInput: React.FC<Props> = ({ onPasswordChange}) => {
  const [password, setPassword] = useState<string>('');
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [passwordStrength, setPasswordStrength] = useState<string>('');
  const [showRequirements, setShowRequirements] = useState<boolean>(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handlePasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newPassword = event.target.value;
    setPassword(newPassword);
    const strength = checkPasswordStrength(newPassword);
    setPasswordStrength(strength);
    setShowRequirements(newPassword.length > 0 && strength !== 'Strong');
    onPasswordChange(newPassword)
  };

  const checkPasswordStrength = (password: string): string => {
    let strength = 'Weak';
    const conditions = [
      password.length >= 8,
      /[A-Z]/.test(password),
      /[0-9]/.test(password),
      /[^A-Za-z0-9]/.test(password),
    ];
    const fulfilledConditions = conditions.filter(Boolean).length;
    if (fulfilledConditions === 4) {
      strength = 'Strong';
    } else if (fulfilledConditions >= 2) {
      strength = 'Medium';
    }
    return strength;
  };

  const requirements: Requirement[] = [
    { label: 'Minimum 8 characters', fulfilled: password.length >= 8 },
    { label: 'At least 1 capital letter', fulfilled: /[A-Z]/.test(password) },
    { label: 'At least 1 number', fulfilled: /[0-9]/.test(password) },
    { label: 'At least 1 special character', fulfilled: /[^A-Za-z0-9]/.test(password) },
  ];

  return (
    <NewPasswordBox>
      <OutlinedInput
        type={showPassword ? 'text' : 'password'}
        value={password}
        style={{
            width: 455,
            height: "40px",
            borderRadius: "30px",
        }}
        className='newPassword'
        onChange={handlePasswordChange}
        endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility in signup"
                onClick={handleClickShowPassword}
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
          }
        fullWidth
      />
      <Typography
        style={{
          fontFamily: 'Plus Jakarta Sans',
          fontSize: '14px',
          fontWeight: 500,
          color: "#667085"
        }} >
        Password security: <span style={{ color: passwordStrength !== "Strong" ? "#DC6803" : "#17B26A" }} >{passwordStrength}</span>
      </Typography>
      {showRequirements && (
        <div style={{
          border: "1px solid #EAECF0",
          borderRadius: "12px",
          padding: "15px"
        }}>

          <p>Your password must meet the following requirements</p>
          <Divider style={{ margin : "10px 0"}} />
          <List>
            {requirements.map((req, index) => (
              <ListItem key={index}>
                <ListItemIcon>
                   <CheckCircleOutlineIcon  style={{ color : "#079455"}} />
                </ListItemIcon>
                <ListItemText
                  primary={req.label}
                  style={{
                    textDecoration: req.fulfilled ? 'line-through' : 'none',
                    fontFamily: 'Plus Jakarta Sans',
                    fontSize: '16px',
                    fontWeight: 500,
                    lineHeight: '24px',
                    textAlign: 'left',
                    color: '#101828'
                  }}
                />
              </ListItem>
            ))}
          </List>
        </div>
        
      )}
    </NewPasswordBox>
  );
};

const NewPasswordBox = styled(Box)({
  "@media(max-width:700px)": {
    width: "100% !important"
  },
  "& .newPassword": {
    "@media(max-width:700px)": {
      width: "100% !important"
    }
  }
})

export default PasswordInput;
