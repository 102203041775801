// Customizable Area Start
import React from "react";
import CommonReservationInfo from "../../../components/src/CommonReservationInfo.web"

import {
    Box,
    styled,
    Tab,
    Tabs,
    Button,
    Typography,
} from "@material-ui/core"

import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { flightIcon } from "./assets";
import ReservationChat from "../../../blocks/chat/src/ReservationChat.web";



import HostReservationDetailController, {
    Props,
  } from "./HostReservationDetailController";


class HostReservationDetail extends HostReservationDetailController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

    renderCommonDetails = () => {
        return (
            <Box>
                <Box className="outerBox">
                    <Box style={{ display: "flex", gap: 15, alignItems: "center" }}>
                        <img src={flightIcon} />
                        <Box>
                            <Typography className="infoTextpart1">LAX International Airport, Terminal 5</Typography>
                            <Typography className="infoTextpart2">Flight: AA 2570</Typography>
                        </Box>
                    </Box>
                </Box>

                <Box className="outerBox">
                    <Box>
                        <Typography className="infoTextpart1">Trip add-ons</Typography>
                        <Typography className="infoTextpart2">Extras requested by renter</Typography>
                    </Box>
                    {this.state.catalougeType === "Car" ?
                        <Box>
                            <Typography className="infoColorText">Pre-Paid Fuel</Typography>
                        </Box>
                        :
                        <Box>
                            <Typography className="infoColorText">$0</Typography>
                            <Typography className="infoTextpart3">+$0 deposit</Typography>
                        </Box>

                    }

                </Box>

                <Box className="outerBox">
                    <Box>
                        <Typography className="infoTextpart1">Trip cost</Typography>
                        <Typography className="infoTextpart2">Click for breakdown</Typography>
                    </Box>
                    <Box>
                        <Typography className="infoColorText">$0</Typography>
                        <Typography className="infoTextpart3">+$0 deposit</Typography>
                    </Box>
                </Box>

                <Box className="outerBox" >
                    <Typography className="infoTextpart1">Add Photos</Typography>
                    <Button data-test-id="addPhotos" onClick={this.navigateToPhotosCheckout}>
                        <ArrowForwardIosIcon style={{ color: "#23395D", height: 24, width: 24 }} />
                    </Button>
                </Box>

                <Box className="outerBox">
                    <Typography className="infoTextpart1">Your {this.state.catalougeType === "Car" ? "Trip" : "Stay"} Starts in: <span className="infoColorText"> {this.state.reservationInfo.duration} Days </span></Typography>
                    <Button className="modifyBtn">Modify Trip</Button>
                </Box>


            </Box>
        )
    }

  render() {
    // Customizable Area Start
    
    return (
      <div>
            <CommonReservationInfo
                navigation={this.props.navigation}
                id="ReservationInfoPage"
            >
                <Box style={{ position: "relative"}}>


                    <Box>
                        <Tabs
                            className="mainTabsDiv"
                            value={this.state.tabNo}
                            data-test-id="tabs"
                            onChange={this.tabChange}
                        >
                            <Tab label={this.getTabLabel()} className="tabLabelText"></Tab>
                            <Tab label="Messages" className="tabLabelText"></Tab>
                        </Tabs>
                        <Box style={{
                            background: "#EAECF0",
                            borderRadius: "16px",
                            padding: "2px 10px",
                            position: "absolute",
                            left: "285px",
                            top: "12px"
                        }}>
                            <Typography style={{
                                fontFamily: 'Plus Jakarta Sans',
                                fontSize: '14px',
                                fontWeight: 500,
                                color: '#344054'
                            }}>{this.state.msgCount}</Typography>
                        </Box>
                    </Box>

                    {this.state.tabNo === 0 &&

                        <Box>
                            {this.state.catalougeType === "Car" ?
                                <Box>
                                    {this.renderCommonDetails()}
                                </Box>
                                :
                                <Box>

                                    {this.renderCommonDetails()}


                                    <Box className="outerBox">
                                        <Typography className="infoTextpart1">Check-In Instructions</Typography>
                                        <Button data-test-id="instructionPage" onClick={this.navigateToInstructionPage}>
                                            <ArrowForwardIosIcon style={{ color: "#23395D", height: 24, width: 24 }} />
                                        </Button>
                                    </Box>

                                    <Box className="outerBox">
                                        <Typography className="infoTextpart1">Property Rules and Guidelines</Typography>
                                        <Button>
                                            <ArrowForwardIosIcon style={{ color: "#23395D", height: 24, width: 24 }} />
                                        </Button>
                                    </Box>

                                    <Box className="outerBox" >
                                        <Typography className="infoTextpart1">Add Photos</Typography>
                                        <Button data-test-id="addPhotos" onClick={this.navigateToPhotosCheckout}>
                                            <ArrowForwardIosIcon style={{ color: "#23395D", height: 24, width: 24 }} />
                                        </Button>
                                    </Box>

                                    <Box className="outerBox">
                                        <Box>
                                            <Typography className="infoTextpart1">Cleaning Schedule</Typography>
                                            <Typography className="infoTextpart2">Cleaned and prepared on August 19, 2024</Typography>
                                        </Box>
                                        <Button>
                                            <CheckCircleIcon style={{ color: "#3173E1", height: 24, width: 24 }} />
                                        </Button>
                                    </Box>
                                </Box>
                            }
                        </Box>
                    }

                    {
                        this.state.tabNo === 1 && 
                        <Box>
                            <ReservationChat 
                                navigation={this.props.navigation} 
                                id="Chat" 
                                receiverId={this.state.conversationId}
                                updateCount={(msgCount) => this.updateCountFn(msgCount)}  
                            />
                        </Box>    
                    }
                </Box>
            </CommonReservationInfo>
      </div>
    );
    // Customizable Area End
  }
}

export default HostReservationDetail;

// Customizable Area End