// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";

import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { apiCalling, truthyValue } from '../../../components/src/asset'
import { toast } from "react-toastify";
import  React from 'react'

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface UserInfo {
  first_name : string,
  last_name: string,
  email: string,
  password: string,
  phone_number: string,
  country_code: string,
  profile_image : string,
  emailVerified: boolean,
  phoneVerified: boolean
}

interface UserInfoErrors {
  first_name : boolean,
  email: boolean,
  password: boolean,
  phone_number: boolean,
}

interface S {
  // Customizable Area Start  
  userInfo : UserInfo,
  isEmailEditable : boolean,
  isPasswordEditable : boolean,
  isPhoneNoEditable : boolean,
  ispasswordVisibleBox: boolean
  userInfoError: UserInfoErrors
  selectedUserProfile : File | null
  loading : boolean,
  openPoper: boolean,
  anchorEl : HTMLButtonElement | null,
  confirmPassword : string,
  currentPassword : string,
  newPassword : string,
  isConfirmPasswordVisibleBox : boolean
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class UserProfileController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  profileImgRef: React.RefObject<HTMLInputElement>
  getUserInfoApiCallId: string = ""
  updateUserInfoApiCallId: string =""
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];
    this.state = {
      userInfo: {
        first_name: "",
        last_name: "",
        email: "",
        password: "",
        phone_number: "",
        country_code: "",
        profile_image: "",
        emailVerified: false,
        phoneVerified: false
      },
      isEmailEditable: false,
      isPasswordEditable: false,
      isPhoneNoEditable: false,
      ispasswordVisibleBox: false,
      userInfoError: {
        first_name: false,
        email: false,
        password: false,
        phone_number: false,
      },
      selectedUserProfile: null,
      loading: true,
      openPoper: false,
      anchorEl: null,
      confirmPassword: "",
      currentPassword: "",
      newPassword: "",
      isConfirmPasswordVisibleBox : false
    };
    this.profileImgRef = React.createRef()
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      switch(apiRequestCallId) {
        case this.getUserInfoApiCallId : 
          return this.getUserInfoApiResponse(responseJson)
        case this.updateUserInfoApiCallId :
          return this.saveUserInfoApiResponse(responseJson)
        default :
          break;
      }
      
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount(){
      this.getUserInfo()
  }

  getUserInfoApiResponse = (responseJson: any) => {
      if(responseJson && responseJson.user) {
        const { data } = responseJson.user
         const userData = {
           first_name: truthyValue(data.attributes.first_name),
           last_name: truthyValue(data.attributes.last_name),
           email: truthyValue(data.attributes.email),
           password: "",
           phone_number: truthyValue(data.attributes.phone_number),
           country_code: truthyValue(data.attributes.country_code).toString(),
           profile_image: truthyValue(data.attributes.profile_image_url.url),
           emailVerified: truthyValue(data.attributes.verified.email),
           phoneVerified: truthyValue(data.attributes.verified.phone)
         }

         this.setState({ userInfo : userData, loading: false})
      }
  }

  saveUserInfoApiResponse = (responseJson: any) => {
    if(responseJson && responseJson.message) {
      toast.success("Profile Updated Successfully")
      this.getUserInfo()
      this.setState({ selectedUserProfile: null, isEmailEditable: false, isPasswordEditable: false, isPhoneNoEditable: false, openPoper: false, anchorEl: null})
    } else {
      let errorMsg = ""
      if(responseJson && responseJson.error) {
        errorMsg = responseJson.error
      }

      toast.error(errorMsg)
      this.setState({ loading : false})

    }
   }

  getUserInfo = () => {
    const header = {
        'token': localStorage.getItem("userToken")
    }

    this.getUserInfoApiCallId = apiCalling({
        header: JSON.stringify(header),
        endPoint: configJSON.getUserDetailsApiEndPoint,
        method: configJSON.apiMethodTypeGet
    })
  }

  updateUserInfo = () => {

    if(!this.handleAllValidationOnSave()) {
      return false;
   }

   if( this.state.newPassword !== this.state.confirmPassword) {
     toast.error("Password & confirm password must match")
     return false;
   }

    this.setState({ loading : true})
    const header = {
      'token': localStorage.getItem("userToken")
    }

    const formData = new FormData();
    formData.append("first_name", this.state.userInfo.first_name)
    formData.append("email", this.state.userInfo.email)
    this.state.newPassword !== "" && formData.append("password", this.state.newPassword)
    this.state.currentPassword !== "" && formData.append("current_password", this.state.currentPassword)
    formData.append("full_phone_number", `+${this.state.userInfo.country_code}${this.state.userInfo.phone_number}`)
    this.state.selectedUserProfile !== null && formData.append("profile_image", this.state.selectedUserProfile)

    

    this.updateUserInfoApiCallId = apiCalling({
      header: JSON.stringify(header),
      method: configJSON.apiMethodTypePatch,
      endPoint: configJSON.updateUserInfoApiEndpoint,
      body: formData
    })


  }

  handleAllValidationOnSave = () => {
    let checkValidatin = {
        first_name: false,
        email: false,
        password: false,
        phone_number: false,
      };
  
      if (this.state.userInfo.first_name === "") {
        checkValidatin.first_name = true;
      } if (this.state.userInfo.email === "") {
        checkValidatin.email = true;
      } if (this.state.userInfo.phone_number === "") {
        checkValidatin.phone_number = true;
      }
  
      this.setState({ userInfoError : checkValidatin})
  
      return Object.values(checkValidatin).every((value) => value === false);
  }

  sidebarContent = [
    { id: 1, label : "Account Information", activeMenu : 1},
    { id: 2, label : "Payment Information", activeMenu : 2},
    { id: 3, label : "Notifications", activeMenu : 3},
    { id: 4, label : "Approval Status", activeMenu : 4},
    { id: 5, label : "Support Center", activeMenu : 5},
    { id: 6, label : "Became a Host", activeMenu : 6},
    { id: 7, label : "Terms of Services", activeMenu : 7},
  ]

  handleEditableFields = (currentValue: boolean, stateVal: keyof S) => {
    this.setState((prevState) => ({
      ...prevState,
      [stateVal]: currentValue,
    }));
  }

  handleChangeForProfileFields = (event : React.ChangeEvent<HTMLInputElement>) => {
    const formName = event.target.name
    const formValue = event.target.value

    if(formValue.trim() === "") {
        this.setState({ userInfoError: { ...this.state.userInfoError, [formName] : true }})
    } else {
        this.setState({ userInfoError: { ...this.state.userInfoError, [formName] : false }})
    }

    this.setState({ userInfo: { ...this.state.userInfo, [formName] : formValue }})
    
  }

  handleChangeCurrentPasswordFields = (event : React.ChangeEvent<HTMLInputElement>) => {
    const formValue = event.target.value
    this.setState({ currentPassword : formValue })
    
  }

  handleChangeConfirmPasswordFields = (event : React.ChangeEvent<HTMLInputElement>) => {
    const formValue = event.target.value
    this.setState({ confirmPassword : formValue })
    
  }

  handleChangeNewPasswordFields = (newPassword : string) => {
    this.setState({ newPassword : newPassword })
  }

  handleClickConfirmShowPassword = () => {
    this.setState({ isConfirmPasswordVisibleBox : !this.state.isConfirmPasswordVisibleBox})
  };
  handleClickShowPassword = () => {
    this.setState({ ispasswordVisibleBox : !this.state.ispasswordVisibleBox})
  };

  handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  handleErrorFields = (checkKey: boolean, errMsg: string) => {
    return checkKey && <div style={{ color: "red", textAlign: "center" }}>{errMsg}</div>
}
   
  hanndleCountryCodeChange = (event : any, newVal: any) => {
    
    this.setState({ userInfo : { ...this.state.userInfo, country_code : newVal.phonecode}})
  }

  handleProfileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      this.setState({ selectedUserProfile: event.target.files[0] });
    }

  };

  handleProfileRefCall = () => {
    if (this.profileImgRef.current) {
      this.profileImgRef.current.click();
    }
  }

  handlePoperBox = (event: React.MouseEvent<HTMLButtonElement>) => {
    this.setState({ openPoper : !this.state.openPoper, anchorEl: event.currentTarget})
  }

  handleChangePhotoBtn = () => {
    this.setState({ userInfo : { ...this.state.userInfo, profile_image: ""}})
  }

  // Customizable Area End
}

// Customizable Area End