// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";

import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { dollarIcon, faqIcon, safetyIcon, ticketIcon } from "./assets";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

type SupportCenterData = {
  id: number;
  headerText: string;
  subHeader1: string;
  subHeader2: string;
  pageToNavigate: string;
  ticketIcon : string
};

interface S {
  // Customizable Area Start  
  supportCenterArr : SupportCenterData[]
  filteredSupportCenterArr : SupportCenterData[] | []
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class SupportCenterController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];
    this.state = {
      supportCenterArr : this.supportCenterData,
      filteredSupportCenterArr : this.supportCenterData
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      // some code here
      
    }
    // Customizable Area End
  }

  // Customizable Area Start

  supportCenterData : SupportCenterData[] = [
    {
      id : 1,
      ticketIcon: safetyIcon,
      headerText: "Safety Center",
      subHeader1: "Get in touch incase of immediate danger.",
      subHeader2: "Call emergency services.",
      pageToNavigate: "",
    },
    {
      id : 2,
      ticketIcon: ticketIcon,
      headerText: "Ticket Support",
      subHeader1: "Reach out to our dedicated support team.",
      subHeader2: "Reply within 24hrs.",
      pageToNavigate: "TicketCenterSettings",
    },
    {
      id : 3,
      ticketIcon: dollarIcon,
      headerText: "Dispute and Insurance",
      subHeader1: "Manage trip disputes and insurance claims.",
      subHeader2: "",
      pageToNavigate: "",
    },
    {
      id : 4,
      ticketIcon: faqIcon,
      headerText: "How LuxGo Works",
      subHeader1: "Rent homes & cars your next trip. Host to earn an extra income!",
      subHeader2: "",
      pageToNavigate: "",
    },
    {
      id : 5,
      ticketIcon: faqIcon,
      headerText: "Frequently Asked Questions",
      subHeader1: "Search frequently asked questions about LuxGo.",
      subHeader2: "",
      pageToNavigate: "Faqs",
    },
  ]

  navigateToPage = (navigateString : string) => {
    if(navigateString !== "") {
      this.props.navigation.navigate(navigateString)
      return false
    }
  }

  searchFunction = (searchTerm: string) => {
    const filteredData = this.state.filteredSupportCenterArr.filter(faq => faq.headerText.toLowerCase().includes(searchTerm.toLowerCase()));
    this.setState({ supportCenterArr : filteredData})
  }


  // Customizable Area End
}

// Customizable Area End