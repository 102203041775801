// Customizable Area Start
export const calenderIcon = require("../assets/calenderIcon.png")
export const arrowRightIcon = require("../assets/arrow_right.png")
export const arrowLeftIcon = require("../assets/arrow-left.png")
export const dropDownIcon = require("../assets/dropDown.svg")
export const filterIcon = require("../assets/filterIcon.svg")
export const sortIcon = require("../assets/sortIcon.svg")
export const searchIcon = require("../assets/searchIcon.svg")
export const editPenIcon = require("../assets/editPenIcon.svg")
export const carIcon = require("../assets/car.png")
export const starIcon = require("../assets/star-yellow.svg")
export const checkedIcon = require("../assets/checkedIcon.svg")
export const tipsIcon = require("../assets/tipsIcon.svg")
export const radioIcon = require("../assets/radioIcon.svg")
export const Speed = require("../assets/speed.svg")
export const ShieldKey = require("../assets/shieldkey.svg")
export const Fuel = require("../assets/fuel.svg")
export const CircleCross= require("../assets/circlecross.svg")
export const Paper = require("../assets/paper.svg")
export const Form = require("../assets/form.png")

// Customizable Area End
