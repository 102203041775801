import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Typography from '@material-ui/core/Typography';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  stepper: {
    backgroundColor: 'transparent',
  },
  iconContainer: {
    transform: 'scale(1.25)',
    color: '#3f51b5',
    height: 20,
    width: 20
  },
  labelContainer: {
    marginLeft: theme.spacing(2),
  },
  stepContent: {
    marginLeft: theme.spacing(4),
  },
  stepLabel: {
    display: 'flex',
    alignItems: 'center',
  },
  amount: {
      fontFamily: 'Plus Jakarta Sans',
      fontSize: '16px',
      fontWeight: 600,
      textAlign: 'left',
      color: '#3173E1',
      marginLeft: "auto"
  },
  details: {
      fontFamily: 'Plus Jakarta Sans',
      fontSize: '12px',
      fontWeight: 500,
      textAlign: 'left',
      color: '#B0B0B0'
  },
  subDetails: {
    fontSize: '0.875rem',
    color: '#3173E1',
  },
  stepLabelText : {
      fontFamily: 'Plus Jakarta Sans',
      fontSize: '16px',
      fontWeight: 500,
      textAlign: 'left',
      color: '#23395D'
  }
}));

interface StepProps {
    label: string,
    details: string,
    subDetails: string,
    amount: string,
}

interface StepperProps {
  steps : StepProps[]
}

const CustomStepper : React.FC<StepperProps> = ({ steps }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Stepper className={classes.stepper} style={{ padding: "10px"}} orientation="vertical">
        {steps.map((step, index) => (
          <Step key={index} active={true}>
            <StepLabel
              icon={<CheckCircleIcon className={classes.iconContainer} />}
              classes={{ iconContainer: classes.iconContainer }}
              StepIconComponent={() => <CheckCircleIcon className={classes.iconContainer} />}
            >
              <div className={classes.stepLabel}>
                <div>
                  <Typography className={classes.stepLabelText}>{step.label}</Typography> 
                  <Typography className={classes.details}>{step.details}<span className={classes.subDetails}>{step.subDetails}</span></Typography>
                </div>
                <Typography className={classes.amount}>{step.amount}</Typography>
              </div>
            </StepLabel>
            <StepContent className={classes.stepContent} />
          </Step>
        ))}
      </Stepper>
    </div>
  );
};

export default CustomStepper;
