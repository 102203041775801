import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { apiCalling, checkIfLogged } from "../../../components/src/asset";
import { toast } from "react-toastify";
import swal from "sweetalert";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

export interface ProductData {
    id: number | null,
    name: string | null,
    account: string | null,
    category_id: number | null,
    sub_category_id: number | null,
    brand_id: number | null,
    miles: string | null,
    features: [
      {
        title: string | null,
        value: string | string[] | null,
      },
    ],
    earnings: number | null,
    taxes: number | null,
    total_nightly_price: number | null,
    addtional_miles_charge: number | null,
    status: string | null,
    car_number_plate: string | null,
    car_year: string | null,
    miles_per_day: number | null,
    car_vin: string | null,
    wishlist_liked: boolean | null,
    category: string | null,
    sub_category: string | null,
    luxgo_service: number | null,
    insurance_fee: number | null,
    rate: number | null,
    trips_completed: number | null,
    specification: {
      data: any | null,
    },
    feature: {
      data: any | null,
    },
    address: {
      id: number | null,
      country: string | null,
      latitude: number | null,
      longitude: number | null,
      address: string | null,
      addressble_id: number | null,
      addressble_type: string | null,
      address_type: string | null,
      created_at: string | null,
      updated_at: string | null,
      pincode: number | null,
      city: string | null,
    },
    delivery_address: any[] | null,
    rating: number | null,
    images: [
      {
        url: string | null,
      },
    ] | null,
    hosted_by: {
      data: {
        id: string | null,
        type: string | null,
        attributes: {
          hosted_by: {
            full_name: string | null,
            joined: string | null,
            activated: boolean | null,
            host_trip_completed: number | null,
            about: string | null,
          },
          host_profile_image: {
            url: string | null,
          },
        },
      },
    } | null,  
  }

export interface MetaData {
  discount_day: number | null;
  discount_rate: number | null;
  discount_title: string | null;
  insurance_cost: number | null;
  luxgo_service: number | null;
  number_of_days: number | null;
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;

  // Customizable Area Start
  meta: MetaData | null;
  rentalPrice: number | null,
  rate: number | null,
  number_of_days:number |null,
  refundable_deposit: number | null,
  total_nightly_price:number |null,
  totalPriceDue: number | null,
  discount: number | null,
  focusedImageIndex: number;
  options:any,
  product_id: number,
  productData: ProductData | null;
  isloading: boolean;
  currentDate:string,
  type:string,
  selectedPayment: any;
  tabValue:number;
  showLogin: boolean;
  selectCodeNumber: string;
  selectPhoneNumber: string | null;
  startDate: string | null;
  endDate: string | null;
  savedCards: any[];
  userName: string,
  userEmail: string,
  userPassword: string,
  isPasswordShow: boolean
  // Customizable Area End
}

const initialProductData: ProductData = {
  id: null,
  name: null,
  account: null,
  category_id: null,
  sub_category_id: null,
  brand_id: null,
  miles: null,
  features: [
    {
      title: null,
      value: null,
    },
  ],
  earnings: null,
  taxes: null,
  total_nightly_price: null,
  addtional_miles_charge: null,
  status: null,
  car_number_plate: null,
  car_year: null,
  miles_per_day: null,
  car_vin: null,
  wishlist_liked: null,
  category: null,
  sub_category: null,
  luxgo_service: null,
  insurance_fee: null,
  rate: null,
  trips_completed: null,
  specification: {
    data: null,
  },
  feature: {
    data: null,
  },
  address: {
    id: null,
    country: null,
    latitude: null,
    longitude: null,
    address: null,
    addressble_id: null,
    addressble_type: null,
    address_type: null,
    created_at: null,
    updated_at: null,
    pincode: null,
    city: null,
  },
  delivery_address: null,
  rating: null,
  images: [
    {
      url: null,
    },
  ],
  hosted_by: {
    data: {
      id: null,
      type: null,
      attributes: {
        hosted_by: {
          full_name: null,
          joined: null,
          activated: null,
          host_trip_completed: null,
          about: null,
        },
        host_profile_image: {
          url: null,
        },
      },
    },
  },
};

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ProductCheckoutController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  productApiItemCallId: string = '';
  createBookSlotApiId: string = '';
  getCardInfoApiCallId : string = "";
  signUpApiCallId: string ="";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      isloading: false,

      // Customizable Area Start
      totalPriceDue:0,
      rentalPrice:0,
      total_nightly_price:0,
      number_of_days:0,
      refundable_deposit: 0,
      options:["4 Adults","3 Adults","2 Adults","1 Adults"] ,
      productData: initialProductData,
      rate:0,
      tabValue:0,
      discount:0,
      product_id: 0,
      type:"",
      focusedImageIndex: 1,
      currentDate:"",
      showLogin: false,
      selectCodeNumber: "91",
      selectPhoneNumber: null,
      meta: null,
      startDate: localStorage.getItem("startDate"),
      endDate: localStorage.getItem("endDate"),
      savedCards: [],
      selectedPayment: null,
      userName: "",
      userEmail: "",
      userPassword: "",
      isPasswordShow: false
      // Customizable Area End
    };

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIRequestMethodMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage),
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.RestAPIResponceErrorMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
      getName(MessageEnum.RestAPIRequestBodyMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const response = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      const errorReponseJson = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (apiRequestCallId && apiRequestCallId === this.productApiItemCallId) {
        this.handleProductData(response,errorReponseJson);
      }
      if (apiRequestCallId && apiRequestCallId === this.createBookSlotApiId && response) {
        if (!!response.error ) {
          toast.error("Invalid Token.");
        }
        
        if (!!response.catalogue) {
          toast.success("Successful reservation");
          this.props.navigation.navigate("Home");
        }
      }
      this.handleApiCalls(apiRequestCallId, response);
    }
    // Customizable Area End
  }

  // Customizable Area Start
  handleApiCalls = (apiRequestCallId: string, responseJson: any ) => {
    if (apiRequestCallId && apiRequestCallId === this.getCardInfoApiCallId) {
      const cardArray = responseJson.data.filter((cardValue :any) => cardValue.attributes.payment_method_details !== null)
      this.handleCardData(cardArray);
    } else if(apiRequestCallId === this.signUpApiCallId){
        this.responseForSignup(responseJson)
    }
  }

  responseForSignup = (responseJson: any) => {
      if(responseJson && responseJson.data) {
        const role = responseJson.data.attributes.role
        const token = responseJson.meta.token
        const otpResponse = responseJson.meta.pin
        const id = responseJson.meta.id
          localStorage.setItem("userRole", role)
          localStorage.setItem("userToken", token)
          localStorage.setItem("accountId", id)
          localStorage.setItem("verifyOtp", otpResponse)
          this.props.navigation.navigate("VerifyOtp")
      } else if(responseJson && responseJson.errors ) {
          const errors = responseJson.errors[0]
          toast.error(errors)
      }
  }

  handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  handleChangePaymentMethod = (card: any) => {
    this.setState({ selectedPayment: card });
  };

  handleClickShowPassword = () => {
    this.setState({ isPasswordShow : !this.state.isPasswordShow})
  };

  handleCardData =(cards: any) => {
    this.setState({ savedCards: cards });
  };

  handleValidation = () => {
    if (this.state.userName === "" || this.state.userEmail === ""  || this.state.userPassword === ""  || this.state.selectPhoneNumber === null) {
      toast.error("Please fill required details")
      return false
    }  else {
       return true
    }
  }

  handleContinueLogin = () => {

    if(!this.handleValidation()) {
      return false
    }

    const fullName = this.state.userName;
    const nameParts = fullName.split(" ");

    if(nameParts.length <= 1) {
      toast.error("Please enter your full name")
      return false
    }

    const httpBody = {
      data : {
          attributes : {
              "first_name": nameParts[0],
              "last_name": nameParts[1],
              "full_phone_number": `+${this.state.selectCodeNumber}${this.state.selectPhoneNumber}`,
              "email": this.state.userEmail,
              "password": this.state.userPassword,
              "activated": "false"
          }
      }
  }
    const header = {
      "Content-Type": configJSON.productApiContentType,
    };

    this.signUpApiCallId = apiCalling({
      header: JSON.stringify(header),
      body: JSON.stringify(httpBody),
      method: configJSON.apiPOSTMethod,
      endPoint: configJSON.signUpApiEndPoint,
  })
  };

  handleChangeForForm  = (event : React.ChangeEvent<HTMLInputElement>, stateVal : keyof S) => {
    const inputValue = event.target.value;
    this.setState((prevState) => ({
      ...prevState,
      [stateVal]: inputValue,
    }));
  };

  navigateToPaymentPage = () => {
    this.props.navigation.navigate("PaymentSettings")
  }
  
  handleCountryCodeChange = (event : any, newVal: any) => {
    this.setState({ selectCodeNumber : newVal.phonecode})
  }

  handlePhoneNumber  = (event : React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ selectPhoneNumber: event.target.value});
  };
  
  onGoBack = () => {
    this.props.navigation.navigate("ProductDescription",{"id":this.state.product_id,"type":"ProductDescription"})
  };
  
  getProductDescriptionId=()=>{
    if (!localStorage.getItem("userToken")) {
      this.setState({showLogin: true});
    } else {
      this.getSavedCards();
    }
    this.setState({product_id:parseInt(this.props.navigation.getParam("id")),type:this.props.navigation.getParam("type")},()=>{this.getPostData()});
  }

  reserveCheckout = () => {
    if(!checkIfLogged()) {
      this.setState({ showLogin: true });
    } else if (this.state.selectedPayment) {
      this.postReserveNowCatalog();
    }  else if (this.state.savedCards.length === 0) {
      swal("Please add card to continue.","For adding card, click on Add new payment link.", "error");
    } else { 
      swal("Select a card to continue.","", "error");
    }
  };

  postReserveNowCatalog = () => {
    const catalogue_id = this.state.product_id;
    const distance = this.state.productData?.miles;
    const startDate = this.state.startDate && (new Date(this.state.startDate)).toString();
    const endDate = this.state.endDate && (new Date(this.state.endDate)).toString();
    const account_id = localStorage.getItem("accountId");
    const token = localStorage.getItem("userToken");

    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify({
        "booked_slots": 
        {
            "start_time": `${startDate}`,
            "end_time": `${endDate}`,
            "catalogue_id": `${catalogue_id}`,
            "distance": `${distance}`,
            "account_id" : `${account_id}`,
            "total_booking_price": `${account_id}`,
        },
        "address_type": "pickup_location",
      })
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.productAPiPostMethod
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.createBookSlot
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    this.createBookSlotApiId = requestMessage.messageId;
    return true;
  }
  
  getPostData= ()=> {
    const date = new Date();
    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();
    
    
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    
    const header = {
      "Content-Type": configJSON.productApiContentType,
    };
    
    this.setState({currentDate:`${day}/${month}/${year}`});
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.productAPiEndPoint}?id=${this.state.product_id}`
    );
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.productAPiPostMethod
    );
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify( {"end_date": `${day}/${month}/${year}`,
      "start_date": `${day}/${month}/${year}`})
    );
    
    runEngine.sendMessage(requestMessage.id, requestMessage);
    this.productApiItemCallId = requestMessage.messageId;
    return true;
  }

  getPricingBreakDown=()=>{
    let productData=this.state.productData;
    let meta= this.state.meta;
    let total_nightly_price=productData?.total_nightly_price ?? 0
    let number_of_days=meta?.number_of_days ?? 1
    let discount_rate =meta?.discount_rate ?? 0
    let refundable_deposit=this.state.refundable_deposit ?? 0
    let discount=(total_nightly_price*discount_rate)/100;
    let totalPriceDue= (total_nightly_price*number_of_days) - discount +refundable_deposit;
    this.setState({
      refundable_deposit:refundable_deposit,
      number_of_days:number_of_days,
      total_nightly_price:total_nightly_price,
      rate:discount_rate,
      rentalPrice:(total_nightly_price*number_of_days),
      discount:discount},()=>{this.setState({totalPriceDue:totalPriceDue})});
  }

  handleProductData=(responseJson:any,errorReponse:any)=>{ 
  if (responseJson) {
    this.setState({ productData: responseJson.catalogue.data.attributes, meta: responseJson.catalogue.meta});
    this.getPricingBreakDown();
  } else {
    this.parseApiErrorResponse(errorReponse);
  }}

  getSavedCards = () => {
    const token = localStorage.getItem("userToken");

    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: token,
    };
    
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getSavedCardsApiEndPoint
    );
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.productAPiMethod
    );
    
    this.getCardInfoApiCallId = requestMessage.messageId;
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  // Customizable Area End
}
