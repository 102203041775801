// Customizable Area Start
import { IBlock } from "framework/src/IBlock";
import { Message } from "framework/src/Message";
import { BlockComponent } from "framework/src/BlockComponent";
import { runEngine } from "framework/src/RunEngine";
import MessageEnum, {
  getName
} from "framework/src/Messages/MessageEnum";
import { apiCalling } from "../../../components/src/asset";

export const configJSON = require("./config");
export interface CatalogueItem {
    id: number;
    title: string;
    price: string;
    check: boolean;
    catalogue_id: number;
}
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start  
  openEditModal:boolean;
  extraAddOnData: CatalogueItem[];
  addOnName:string;
  setPrice:string;
  selectedOption:string;
  setEdit:boolean;
  editId:number| string;
  catalogueId: string;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class ExtraAddOnController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getExtraAddApiCallID:string="";
  postAddExtraAddApiCallID:string ="";
  putAddExtraAddApiCallID:string =""
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];
    this.state = {
      openEditModal:false,
      extraAddOnData:[],
      addOnName:"",
      setPrice:"",
      selectedOption:"",
      setEdit:false,
      editId:0,
      catalogueId: ""
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if(apiRequestCallId === this.getExtraAddApiCallID){
        const extraData = responseJson.filter((data: any) => {
          return data.catalogue_id == this.state.catalogueId
        })
        this.setState({extraAddOnData: extraData})
      }
      if(apiRequestCallId === this.postAddExtraAddApiCallID){
        this.setState({openEditModal:false}, () => this.getExtraAddApi())
      }
      if(apiRequestCallId === this.putAddExtraAddApiCallID){
        this.setState({openEditModal:false}, () => this.getExtraAddApi())
      }
    }

    // Customizable Area End
  }

  // Customizable Area Start
   async componentDidMount(){
    const catalougeId = this.props.navigation.getParam("subId")
    this.setState({ catalogueId: catalougeId})
    this.getExtraAddApi()
  }

  handleModal = () => {
    this.setState({openEditModal:true, addOnName:"", setPrice:"", selectedOption:"", setEdit:false})
  }
  handleCloseEditModal = () => {
    this.setState({openEditModal: false, addOnName:"", setPrice:"", selectedOption:""})
  }
  getExtraAddApi = () => {
    const header = {
      token: localStorage.getItem("userToken"),
    };
    this.getExtraAddApiCallID = apiCalling({
      header: JSON.stringify(header),
      method: configJSON.apiMethodTypeGet,
      endPoint: configJSON.getExtraAddEndPoint,
    });
  }
  handleAddOnName = (event:React.ChangeEvent<HTMLInputElement>) => {
    this.setState({addOnName : event.target.value})
  }
  handleSetPrice = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (/^\d*$/.test(value)) {
      this.setState({ setPrice: value });
    }
  };
  handleSelectTrip = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ selectedOption: event.target.value });
  };
  postAddExtraAddOn = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if(this.state.setEdit){
      this.handleEditExtraAddOn()
    }
    else{
      this.createExtraAddonAddition()
    }
  }
  createExtraAddonAddition = () => {
    const {setPrice, addOnName} = this.state
    const header = {
      token: localStorage.getItem("userToken"),
    };
    const formdata = new FormData();
formdata.append("extra_addon[title]", addOnName);
formdata.append("extra_addon[price]", setPrice);
formdata.append("extra_addon[check]", "true");
formdata.append("extra_addon[catalogue_id]", this.state.catalogueId);

    this.postAddExtraAddApiCallID = apiCalling({
      header: JSON.stringify(header),
      method: configJSON.apiMethodTypePost,
      endPoint: configJSON.createExtraAddEndPoint,
      body: formdata
    });
  }
  handleOpenEditModal = (data : CatalogueItem) => {
this.setState({openEditModal: true, setEdit:true, addOnName : data.title, setPrice:data.price, editId: data.id})
  }
  handleEditExtraAddOn = () => {
    const {editId, setPrice, addOnName} = this.state
    const header = {
      token: localStorage.getItem("userToken"),
    };
    const formdata = new FormData();
    formdata.append("extra_addon[title]", addOnName);
    formdata.append("extra_addon[price]", setPrice);

    this.putAddExtraAddApiCallID = apiCalling({
      header: JSON.stringify(header),
      method: configJSON.apiMethodTypePut,
      endPoint: configJSON.editExtraAddEndPoint(editId),
      body: formdata
    });
  }

  handleBackButton = () => {
    this.props.navigation.goBack()
  }
  // Customizable Area End
}

// Customizable Area End