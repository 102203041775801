// Customizable Area Start
import React from "react";
import {
   Box,
   styled,
   Typography,
   Button,
} from "@material-ui/core"
import AppHeader from "../../notifications/src/TopHeader.web"
import OtpInput from "../../../components/src/OtpInput.web";
import Loader from "../../../components/src/Loader.web";
import { MailIcon } from "./assets";

import VerifyOTPController, {
    Props,
  } from "./VerifyOtpController";

class VerifyOtp extends VerifyOTPController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  render() {
    // Customizable Area Start
    
    return (
      <div>
        <AppHeader navigation={this.props.navigation} />

        {this.state.loader ? <Loader loading={true} /> :
           <VerifyWrapper>

                <Box textAlign={"center"} style={{ margin: "10px 0"}}>
                    <img src={MailIcon} />
                </Box>

                    {!this.state.successVerified ?
                        <Box style={{ textAlign: "center" }}>
                            <Typography className="checkPhoneText">Check your phone</Typography>
                            <Typography className="codeText">We sent a verification code to</Typography>
                            <Typography className="mobileInfoText">+{this.state.registerdMobileNo}</Typography>
                            <Box style={{ margin: "20px 0" }}>
                                <OtpInput data-test-id="OtpComponent" length={6} onChange={(otpVal: string) => this.handleOTPChange(otpVal)} newUser={true} />
                            </Box>
                            <Button onClick={this.verifyOTPNumber} data-test-id="verifyBtn" className="verifyBtn">Verify</Button>

                            <Typography className="resendText">Didn't receive the code? <span className="colorText">Click to resend</span></Typography>
                            <br />
                            <small style={{ fontWeight: "bold" }}>(Use this OTP to continue: {this.state.displayOtp})</small>
                        </Box>
                        :
                        <Box style={{ textAlign: "center" }}>
                            <Typography className="checkPhoneText">Your phone verification has been </Typography>
                            <Typography className="checkPhoneText">successfully completed.</Typography>
                            <Typography className="codeText">Now let’s move forward to complete your profile setup by adding</Typography>
                            <Typography className="codeText">payment method and verifying your identity.</Typography>
                            <br />
                            <Button className="verifyBtn" data-test-id="continueBtn" onClick={this.navigateToPaymentPage} style={{ margin: "10px 0"}}>Continue</Button>
                        </Box>
                    }
           </VerifyWrapper>
        }
      </div>
    );
    // Customizable Area End
  }
}

export default VerifyOtp;

const VerifyWrapper = styled(Box)({
    maxWidth: "1220px",
    margin: "40px auto",
    "& .checkPhoneText" : {
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '24px',
        fontWeight: 600,
        color: '#23395D'
    },
    "& .codeText" : {
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '14px',
        fontWeight: 400,
        color: '#475467'
    },
    "& .mobileInfoText" : {
        fontFamily: 'Plus Jakarta Sans',
        fontSize: '14px',
        fontWeight: 600,
        color: '#3173E1',
        textDecoration: "underline"
    },
    "& .colorText" : {
        color: "#3173E1",
        cursor: "pointer",
        fontWeight: 600,
    },
    "& .resendText" : {
        fontFamily: 'Plus Jakarta Sans',
        fontWeight: 400,
        fontSize: '14px',
        color: '#475467'
    },
    "& .verifyBtn" : {
        fontFamily: 'Plus Jakarta Sans',
        fontWeight: 600,
        fontSize: '14px',
        color: 'white',
        background: "#3173E1",
        borderRadius: "12px",
        width: 180,
        height: 40,
        textTransform: "none",
        marginBottom: "25px"
    }

})

// Customizable Area End