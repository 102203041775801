// Customizable Area Start
import React from "react";
import AppHeader from "../../../components/src/AppHeader.web";


import TopNotificationController, {
    Props,
  } from "./TopHeaderController";


class TopHeader extends TopNotificationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  render() {
    // Customizable Area Start
    
    return (
      <>
         <AppHeader activeLink={this.props.activeLink} navigation={this.props.navigation} notificationData={this.state.notificationData} />
      </>
    );
    // Customizable Area End
  }
}

export default TopHeader;

// Customizable Area End