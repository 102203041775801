// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";

import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { apiCalling} from '../../../components/src/asset'
import moment from 'moment'

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start  
  tabValue: number
  reservationData : any[],
  reservationHistoryData: any[],
  filteredReservationHistoryData: any[],
  pageNo: number
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class ReservationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getUpcomingReservationApiCallId : string = ""
  getReservationHistoryApiCallId : string = ""
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];
    this.state = {
        tabValue: 0,
        reservationData: [],
        reservationHistoryData: [],
        filteredReservationHistoryData: [],
        pageNo: 1
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      switch(apiRequestCallId) {
        case this.getUpcomingReservationApiCallId: 
           return this.responseForUpcomingReservationApi(responseJson)
        case this.getReservationHistoryApiCallId:
           return this.responseForReservationHistoryApi(responseJson)
        default:
           break;
      }
      
    }
    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidMount(): Promise<void> {
      this.getUpcomingReservationData()
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined): void {
    if(prevState.tabValue !== this.state.tabValue && this.state.tabValue === 1) {
      this.getReservationHistoryData()
    }
  }
  
  handleTabChange = (event: React.ChangeEvent<{}>, value: number) => {
    this.setState({ tabValue: value})
  }

  responseForUpcomingReservationApi = (responseJson: any) => {
    if(responseJson && responseJson.catalogue && responseJson.catalogue.data && responseJson.catalogue.data.length > 0) {
      const acceptedArr = responseJson.catalogue.data.filter((itemValue: any) => itemValue.attributes.status === "accepted")
       this.setState({ reservationData : acceptedArr})
    } else {
      this.setState({ reservationData : []})
    }
  }

  responseForReservationHistoryApi = (responseJson: any) => {
    if(responseJson && responseJson.data ) {
       this.setState({ 
        reservationHistoryData : responseJson.data.slice(0,9), 
        filteredReservationHistoryData: responseJson.data
      
      })
    } else {
      this.setState({ reservationHistoryData : []})
    }
  }

  getUpcomingReservationData = () => {
    const header = {
      "Content-Type": configJSON.contentType,
      "token": localStorage.getItem("userToken")
    };

    this.getUpcomingReservationApiCallId = apiCalling({
      header: JSON.stringify(header),
      method: configJSON.apiTypeGet,
      endPoint: configJSON.upcomingReservationsApiEndPoint
    })
  }

  getReservationHistoryData = () => {
    const header = {
      "token": localStorage.getItem("userToken"),
      "Content-Type": configJSON.contentType,
    };

    this.getReservationHistoryApiCallId = apiCalling({
      header: JSON.stringify(header),
      method: configJSON.apiTypeGet,
      endPoint: configJSON.getReservationHistoryApiEndPoint
    })
  }

  handleRefundedByText = (textValue : string) => {
    const refundedByText = textValue.split("by")[0]
    const refundedByDate = textValue.split("by")[1]

    return { refundedByText, refundedByDate}
  }


  upcomingReservationData = (item : any) => {

    // Catalouge Name
    let catalogueName: string = ""
    if (item.attributes && item.attributes.catalogue_name) {
      catalogueName = item.attributes.catalogue_name
    }

    // address Text
    let address : string = ""
    if(item.attributes && item.attributes.address.length > 0 && item.attributes.address[0] ) {
      const addressObj = item.attributes.address[0]
      address = addressObj.address + "," + addressObj.city + "," + addressObj.country
    }

    // pincode
    let pinCode : string = ""
    if(item.attributes && item.attributes.address.length > 0 && item.attributes.address[0] ) {
      const addressObj = item.attributes.address[0]
      pinCode = addressObj.pincode
    }

    //CheckIn Time
    let checkInTime: string = ""
    if (item.attributes && item.attributes.start_time) {
      checkInTime =  moment(item.attributes.start_time).format('dddd, MMM DD')
    }

     //CheckOut Time
     let checkOutTime: string = ""
     if (item.attributes && item.attributes.end_time) {
       checkOutTime =  moment(item.attributes.end_time).format('dddd, MMM DD')
     }

    //  Price Due
    let priceDue : string = ""
    if(item.attributes.booking_info && item.attributes.booking_info.total_price_due) {
      priceDue = item.attributes.booking_info.total_price_due
    }

    // refund text
    let refundedText : string = ""
    let refundedDate : string = ""
    if(item.attributes.booking_info && item.attributes.booking_info.refunable_deposit) {
      refundedText = this.handleRefundedByText(item.attributes.booking_info.refunable_deposit).refundedByText
      refundedDate = this.handleRefundedByText(item.attributes.booking_info.refunable_deposit).refundedByDate
    }

    // imageUrl
    let imgUrl : string = this.handleImageUrl(item).imgUrl


     return { imgUrl,  catalogueName, checkOutTime, checkInTime, address, pinCode, refundedText, refundedDate, priceDue}
  }

  reservationHistoryData = (item : any) => {

     // Catalouge Name
     let catalogueName: string = ""
     if (item.attributes && item.attributes.catalogue_name) {
       catalogueName = item.attributes.catalogue_name
     }

     // imageUrl
     let imgUrl : string = this.handleImageUrl(item).imgUrl

     //CheckIn Time
    let checkInTime: string = ""
    if (item.attributes && item.attributes.start_time) {
      checkInTime =  moment(item.attributes.start_time).format('ddd, MMM D, YYYY, h:mma')
    }

     //CheckOut Time
     let checkOutTime: string = ""
     if (item.attributes && item.attributes.end_time) {
       checkOutTime =  moment(item.attributes.end_time).format('ddd, MMM D, YYYY, h:mma')
     }

 
     // address Text
     let address : string = ""
     if(item.attributes && item.attributes.address.length > 0 && item.attributes.address[0] ) {
       const addressObj = item.attributes.address[0]
       address = addressObj.address + "," + addressObj.city + "," + addressObj.country
     }

     return { catalogueName, imgUrl, checkInTime, checkOutTime, address}

  }
  handleImageUrl = (item : any) => {
    // imageUrl
    let imgUrl : string = ""
    if(item.attributes && item.attributes.images && item.attributes.images.length > 0 && item.attributes.images[0].url ) {
      imgUrl = item.attributes.images[0].url
    }

    return { imgUrl }
  }

  handlePaginationChange = (event: any, value: number) => {
    this.setState({ 
      pageNo: value, 
      reservationHistoryData: this.state.filteredReservationHistoryData.slice((value - 1) * 9, value* 9) })
  };

  onPreviousPage = () => {
    if (this.state.pageNo > 1) {
      this.setState(prevState => ({
        pageNo: prevState.pageNo - 1
      }), () => { this.setState({ reservationHistoryData: this.state.filteredReservationHistoryData.slice((this.state.pageNo - 1) * 9, this.state.pageNo * 9) }) });
    }
  }

  onNextPage = () => {
    if (this.state.pageNo < Math.ceil(this.state.filteredReservationHistoryData.length / 9)) {
      this.setState(prevState => ({
        pageNo: prevState.pageNo + 1
      }), () => { this.setState({ reservationHistoryData: this.state.filteredReservationHistoryData.slice((this.state.pageNo - 1) * 9, this.state.pageNo * 9) }) })
    }
  }

  navigateToInfoPage = (catalougeId : number, catalogueType : string) => {
    if(catalogueType === "Car") {
      this.props.navigation.navigate("ReservationDetails", { actualId : catalougeId })
      return false
    }
  }

  // Customizable Area End
}

// Customizable Area End