//@ts-nocheck

import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  Tabs,
  Paper,
  Tab,
  TextField,
  Card,
  CardContent,
  LinearProgress,
  styled
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { ProductImage, PhotoOne, PhotoTwo, PhotoThree, Group, PhotoFour,StarYellow, Location, Lamp, Pet, Broom, DropDown, Drop, ShowMoreIcon, ShowMore ,Place,Place1,Place2,star,Verified1, Share, Heart, home, Miles, GasStation, Cancel, Warning, Bell, Insurance, flash, speed, StarIcon} from "./assets";
import { createTheme, withStyles, ThemeProvider } from "@material-ui/core/styles";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
import Footer from "../../../components/src/Footer.web";
import CircularProgress from '@material-ui/core/CircularProgress';
import { getUserRole, apiKey, truthyValue } from "../../../components/src/asset";

import { Rating } from "@material-ui/lab";
const CheckOutDiv = styled('div')({
  display:"flex",flexDirection:"column",gap:14,
  '& label':{
    fontSize:14,
    color:"#667085"
  },
  '& .MuiOutlinedInput-input': {
    padding: '14.5px 8px',
    fontSize: 14
  },
  '& .MuiOutlinedInput-root': {
    borderRadius: 12,
  }
})
const FlexParentBox = styled(Box)({
  '& .left-div': {
    width: '75%',
  },
  '& .right-div': {
    width: '25%',
    padding: '20px'
  },
  '& .reg-second': {
    display: 'none'
  },
  '@media(max-width: 1260px)': {
    paddingInline: 20,
    flexDirection: 'column',
    '& .reg-first': {
      display: 'none !important'
    },
    '& .reg-second': {
      display: 'block !important'
    },
    '& .left-div': {
      width: '100% !important',
    },
    '& .right-div': {
      padding: '0px',
      width: '50% !important'
    },
    '& .inner-img-box': {
      display: 'none'
    },
    '& .offer-second-box': {
      display: 'none !important'
    },
    '& .offer-parent-box': {
      gap: '50px !important'
    }
  },
  '@media(max-width: 960px)': {
    '& .review-parent-box': {
      flexDirection: 'column',
    },
  },
  '@media(max-width: 767px)': {
    paddingInline: 10,
    '& .specification-box': {
      gridTemplateColumns: 'auto auto !important',
    },
    '& .policy-inner-box': {
      flexDirection: 'column'
    },
    '& .header-text': {
      fontSize: '18px !important',
      marginBottom: 6,
      marginTop: 4,
    },
    '& .registration-parent-box': {
      flexDirection: 'column',
    },
    '& .registration-box': {
      border: 'none !important',
      padding: '0px !important'
    },
    '& .right-div': {
      width: '100% !important'
    },
    '& .offer-parent-box': {
      gap: '0px !important',
      '& img': {
        width: '15px !important',
        height: 20,
      },
      '& p': {
        lineHeight: '-1%',
        fontSize: '14px !important'
      }
    }
  },
  "& .reviewPointer" : {
    fontFamily: 'Plus Jakarta Sans',
    fontSize: '14px',
    fontWeight: 500,
    color: '#3173E1'
  }
})
const CustomParentBox = styled(Box)({
  "@media(max-width:900px)":{
margin:"0px !important",
"& .customBox":{
  width:"100%"
}
  },
  '@media(max-width: 1260px)': {
    '& .second-img-box': {
      display: 'none'
    },
    '& .first-img': {
      width: '97%',
      borderRadius: 20,
      margin: 'auto'
    }
}, 
  "& .normalStyleBtn" : {
    background: '#3173E1',
    borderRadius: 12,
    border: 'none',
    height: 48,
    width: '100%',
    color: 'white',
    textTransform: "none",
    cursor: "pointer"
  }, 
  "& .disabledStyleBtn" : {
    background: '#ccc',
    borderRadius: 12,
    border: 'none',
    height: 48,
    width: '100%',
    textTransform: "none",
    color: 'white',
    cursor: "not-allowed"
  }
})
const CustomCircularProgress = styled(CircularProgress)({
  '& circle': {
    strokeWidth: '5px',
    r: '19.5px'
  }
})
const BorderLinearProgress = withStyles((theme) => ({
  root: {
    width: 204,
    height: 12,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
    backgroundColor:"#3173E1",
  },
}))(LinearProgress);

const theme = createTheme({
  palette: {
    primary: {
      main: "#3173E1",
      contrastText: "#3173E1",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
function CircularProgressWithLabel(props) {
  return (
    <Box position="relative" display="inline-flex">
      <CustomCircularProgress style={{width: 104, height: 104}} variant="determinate" {...props} />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
        style={{border: '13px solid #00000030', borderRadius: '50%'}}
      >
        <Typography variant="caption" component="div" color="textSecondary" style={{display: 'flex',fontSize:"16px",fontWeight:"600", gap: 10, alignItems: 'center'}}><img src={StarIcon} />{`${parseInt(props.value)/20}`}.0</Typography>
      </Box>
    </Box>
  );
}
import AppHeader from "../../../blocks/notifications/src/TopHeader.web";
// Customizable Area End

import ProductDescriptionController, {
  Props,
  configJSON,
} from "./ProductDescriptionController";
import { dummyProduct } from "./assets";

export default class ProductDescription extends ProductDescriptionController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    this.getProductDescriptionId();
  }

  reserveNowBtn = () => {
    return (
      <Button
        onClick={this.reserveCatalogueData}
        className={getUserRole() === "host" ? "disabledStyleBtn" : "normalStyleBtn"}
        disabled={getUserRole() === "host" ? true : false}
        data-test-id="reserveBtn"
      >
        Reserve Now
      </Button>
    )
  }

  tabsComponent = () => {
    return (
      <Tabs
        data-test-id="HandleTabChange"
        value={this.state.tabValue}
        indicatorColor="primary"
        onChange={this.handleTabChange}
        textColor="primary"
        aria-label="disabled tabs example"
      >
        <Tab label={this.getTabLabel()} style={{ textTransform: "unset" }} />
        <Tab data-test-id="testAllPolicies" label="Policies" onClick={this.getAllPolicies} style={{ textTransform: "unset" }} />
        <Tab data-test-id="testAllReviews" label="Reviews" onClick={this.getAllReviews} style={{ textTransform: "unset" }} />
      </Tabs>
    )
  }

  policyData = () => {
    return (
      this.state.policyData.length > 0 ? <Box style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", marginTop: 24 }}>
        <Typography style={{ letterSpacing: 'normal', color: '#23395D', fontSize: 20, marginBottom: 18, fontWeight: 600 }}>
          Mileage Policy
        </Typography>
        <Box style={{ border: '1px solid #EAECF0', padding: 20, borderRadius: 12, marginBottom: 32 }}>
          <Box style={{ display: "flex", gap: 8, alignItems: 'center', marginBottom: 14 }}>
            <img src={Miles}></img>
            <Typography style={{ letterSpacing: 'normal', fontSize: 16, lineHeight: '20px', color: '#23395D', fontWeight: 600 }}>350 mile included</Typography>
          </Box>
          <Typography style={{ letterSpacing: 'normal', color: '#475467', fontSize: '16px', fontWeight: 500, marginBottom: 4 }}>$3.50 fee for each additional mile.</Typography>
          <Typography style={{ letterSpacing: 'normal', color: '#475467', fontSize: '14px', fontWeight: 400 }}>Any additional mileage charge will be processed after the rental automatically.</Typography>
        </Box>
        <Box>
          <Typography style={{ letterSpacing: 'normal', color: '#23395D', fontSize: 20, marginBottom: 18, fontWeight: 600 }}>Fuel Policy</Typography>
          <Box style={{ border: '1px solid #EAECF0', padding: 20, borderRadius: 12, marginBottom: 32 }}>
            <Box style={{ display: "flex", gap: 8, alignItems: 'center', marginBottom: 14 }}>
              <img src={GasStation}></img>
              <Typography style={{ letterSpacing: 'normal', fontSize: 16, lineHeight: '20px', color: '#23395D', fontWeight: 600 }}>Fuel Type: Premium Only (91+)</Typography>
            </Box>
            <Typography style={{ letterSpacing: 'normal', color: '#475467', fontSize: '16px', fontWeight: 500, marginBottom: 4 }}>Return with the same fuel level.</Typography>
            <Typography style={{ letterSpacing: 'normal', color: '#475467', fontSize: '14px', fontWeight: 400 }}>A $10 convience fee + the fuel replacement cost will be charged.</Typography>
          </Box>
        </Box>
        <Box>
          <Typography style={{ letterSpacing: 'normal', color: '#23395D', fontSize: 20, marginBottom: 18, fontWeight: 600 }}>Cancellation  Policy</Typography>
          <Box style={{ border: '1px solid #EAECF0', padding: 20, borderRadius: 12, marginBottom: 32 }}>
            <Box style={{ display: "flex", gap: 8, alignItems: 'center', marginBottom: 14 }}>
              <img src={Cancel}></img>
              <Typography style={{ letterSpacing: 'normal', fontSize: 16, lineHeight: '20px', color: '#23395D', fontWeight: 600 }}>Free Cancellation (24-hour prior)</Typography>
            </Box>
            <Typography style={{ letterSpacing: 'normal', color: '#475467', fontSize: '16px', fontWeight: 500, marginBottom: 4 }}>Return with the same fuel level.</Typography>
            <Typography style={{ letterSpacing: 'normal', color: '#475467', fontSize: '14px', fontWeight: 400 }}>Receive a full refund if you cancel before October 21, 10:30 AM. (Local time of car)</Typography>
          </Box>
        </Box>
        <Box className="policy-inner-box" style={{ display: "flex", gap: 32 }}>
          <Box style={{ flex: 1 }}>
            <Typography style={{ letterSpacing: 'normal', color: '#23395D', fontSize: 20, marginBottom: 18, fontWeight: 600 }}>Host  Guidelines</Typography>
            <Box style={{ border: '1px solid #EAECF0', padding: 20, borderRadius: 12 }}>
              <Box style={{ display: 'flex', gap: 8, alignItems: 'center', color: '#23395D', fontSize: 16, lineHeight: 20, paddingBottom: 14 }}><img src={Warning}></img>
                <Typography style={{ letterSpacing: 'normal', fontSize: 16, lineHeight: '20px', color: '#23395D', fontWeight: 600 }}>Smoking not allowed</Typography></Box>
              <Box style={{ display: 'flex', gap: 8, alignItems: 'center', color: '#23395D', fontSize: 16, lineHeight: 20, paddingBottom: 14 }}>
                <img src={Pet}></img>
                <Typography style={{ letterSpacing: 'normal', fontSize: 16, lineHeight: '20px', color: '#23395D', fontWeight: 600 }}>Pets are not allowed</Typography>
              </Box>
              <Box style={{ display: 'flex', gap: 8, alignItems: 'center', color: '#23395D', fontSize: 16, lineHeight: 20 }}>
                <img src={Bell}></img>
                <Typography style={{ letterSpacing: 'normal', fontSize: 16, lineHeight: '20px', color: '#23395D', fontWeight: 600 }}>Notify if you plan to leave the state</Typography>
              </Box>
            </Box>
          </Box>
          <Box style={{ flex: 1 }}>
            <Typography style={{ letterSpacing: 'normal', color: '#23395D', fontSize: 20, marginBottom: 18, fontWeight: 600 }}>Insurance & Protection</Typography>
            <Box style={{ border: '1px solid #EAECF0', padding: 20, borderRadius: 12 }}>
              <Box style={{ display: 'flex', gap: 8, alignItems: 'center', fontSize: 16, lineHeight: 20, paddingBottom: 14, color: '#23395D' }}>
                <img src={Insurance}></img>
                <Typography><b>Full Coverage</b> by Loyds of London</Typography>
              </Box>
              <Typography style={{ letterSpacing: 'normal', fontSize: 14, lineHeight: '20px', color: '#475467', fontWeight: 400 }}>The Loyds of London Insurance policy provides coverage for third-party liability standard with $1,000,000 coverage.</Typography>
            </Box>
          </Box>
        </Box>
      </Box> : <Box style={{ textAlign: "center"}}>
          <Typography style={{ letterSpacing: 'normal', fontWeight: 600, fontSize: 20, marginBottom: "18px", color: '#23395D', marginTop: "24px", }}>
            No Data Available 
          </Typography>
      </Box>
    )
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <AppHeader navigation={this.props.navigation}/>
        <CustomParentBox style={{ maxWidth: '1216px', margin: 'auto',marginBottom:96 }}>
          <Box
            style={{
              display: "flex",
              gap: 12,
              padding: "20px 0px",
              borderRadius: 20,
              overflow: 'hidden'
            }}
          >
            <Box style={{display: 'flex'}} className="customBox">
              <img style={{ maxHeight:400,width:792, objectFit:"cover"}} src={this.state.productData.data?.attributes.images[0]?.url ?? ProductImage} className='first-img'
                alt={`Product`}
              />
            </Box>
            <Box className="second-img-box">
              <Box style={{ display: "flex", gap: 12 }}>
                <img style={{maxWidth: 200,height: 194,objectFit:"cover",width:"100%"}} src={this.state.productData.data?.attributes.images[1]?.url ?? PhotoOne} ></img>
                <img style={{maxWidth: 200,height: 194,objectFit:"cover",width:"100%"}} src={this.state.productData.data?.attributes.images[2]?.url ?? PhotoFour} ></img>
              </Box>
              <Box style={{ display: "flex", gap: 12, marginTop: 12 }}>
                <img style={{maxWidth: 200,height: 194,objectFit:"cover",width:"100%"}} src={this.state.productData.data?.attributes.images[3]?.url ?? PhotoThree} ></img>
                <Box style={{position: 'relative', width: '100%'}} >
                <img style={{maxWidth: 200,height: 194,objectFit:"cover",width:"100%",position:"absolute"}} src={this.state.productData.data?.attributes.images[4]?.url ?? PhotoTwo} ></img>
               {this.state.productData.data?.attributes.images.length>5 && <Box style={{backgroundColor:"white",borderRadius:"30px",position:"absolute",bottom:"22px",left:"9px", border: '1px solid #D0D5DD', padding: '10px 16px'}}>
                <Typography style={{letterSpacing: 'normal', color: '#475467', fontWeight: 600, fontSize: 14, cursor:"pointer"}}>See all photos ({this.state.productData.data?.attributes.images.length})</Typography>
                </Box>}
                </Box>
              </Box>
            </Box>
          </Box>
          <FlexParentBox style={{ display: 'flex', gap: 48 }}>
            {
              this.state.tabValue === 0 &&
              <div className="left-div">
                <Box style={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography style={webStyle.commonLetterSpacingStyles}>{this.state.productData?.data?.attributes.address.city}, {this.state.productData?.data?.attributes.address.country}</Typography>
                  <Box style={{cursor:"pointer", display: "flex",  gap: 10 ,fontSize: 20,}} >
                    <img src={Share}></img>
                    <Typography className="inner-img-box">
                      Share
                    </Typography>
                    <img src={Heart}></img>
                    <Typography className="inner-img-box">
                      Save
                    </Typography>
                  </Box>
                </Box>
                <Box>
                  <Typography className="header-text" style={{letterSpacing: 'normal',  fontWeight: 600, color: '#23395D',fontSize: "30px" }}>{this.state.productData?.data?.attributes?.address?.address}, {this.state.productData?.data?.attributes?.address?.pincode}</Typography>
                  <Box style={{ display: "flex", gap: 10 }}>
                    <img src={StarYellow}></img>
                    <Typography style={webStyle.commonLetterSpacingStyles}>
                      4.2 44 reviews
                    </Typography>
                  </Box>
                </Box>
                <Paper square style={{boxShadow: 'none', borderBottom: '1px solid #EAECF0'}}>
                   {this.tabsComponent()}
                </Paper>
                <Typography style={{letterSpacing: 'normal',fontWeight: 600,  fontSize: 20,  marginBottom: "18px",  color: '#23395D',marginTop: "24px", }}>
                  Description
                </Typography>
                <Box style={{ fontSize: "16px", marginBottom: "32px" }}>
                  <Typography style={{letterSpacing: 'normal',  color: '#475467',marginBottom: "12px",}} >
                    Dolor enim eu tortor urna sed duis nulla. Aliquam vestibulum, nulla odio nisl vitae. In aliquet pellentesque aenean hac vestibulum turpis mi bibendum diam. Tempor integer aliquam in vitae malesuada fringilla. Elit nisi in eleifend sed nisi. Pulvinar at orci, proin imperdiet commodo consectetur convallis risus.
                  </Typography>
                  <Typography>
                    <ul>
                      <li style={{color: '#475467'}}>
                        Ipsum sit mattis nulla quam nulla. Gravida id gravida ac enim mauris id.Diam elit, orci, tincidunt aenean tempus. Quis velit eget ut tortor tellus. Sed vel, congue felis elit erat nam nibh orci.
                      </li>
                      <li style={{color: '#475467'}}>
                        Non pellentesque congue eget consectetur turpis.
                      </li>
                      <li style={{color: '#475467'}}>
                        Sapien, dictum molestie sem tempor. Diam elit, orci, tincidunt aenean tempus. Quis velit eget ut tortor tellus. Sed vel, congue felis elit erat nam nibh orci.            </li>
                    </ul>
                  </Typography>
                </Box>
               { this.state.type==="Home" ?<><Typography style={{fontSize: 20, fontWeight: 600, letterSpacing: 'normal',   marginBottom: "32px", color: '#23395D' }}>
                  What this place offers?
                </Typography>
                <Box className="offer-parent-box" style={{ display: "flex", gap: 116, marginBottom: "32px" }}>
                 <Box style={{ display: "flex", flexDirection: "column", gap: 14 }}>
                    <Box style={{ display: "flex", gap: "8px" }}>
                      <img src={home} style={{width: 24}}></img>
                      <Typography style={{ letterSpacing: '-1%',color: '#475467',}}>1600sq. feet space</Typography>
                    </Box>
                    <Box style={{ display: "flex", gap: "8px" }}>
                      <img src={Lamp}></img>
                      <Typography style={{color: '#475467', letterSpacing: '-1%'}}>Natural Lighting</Typography>
                    </Box >
                    <Box style={{ display: "flex", gap: "8px" }}>
                      <img src={Pet}></img>
                      <Typography style={{color: '#475467', letterSpacing: '-1%'}}>Pet Friendly</Typography>
                    </Box>
                  </Box>
                 <Box className="offer-second-box" style={{ display: "flex", flexDirection: "column", gap: 14 }}>
                    <Box style={{ display: "flex", gap: "8px" }}>
                      <img  style={{width: 24}} src={home}></img>
                      <Typography style={{ letterSpacing: '-1%', color: '#475467',}}>1600sq. feet space</Typography>
                    </Box>
                    <Box style={{ display: "flex", gap: "8px" }}>
                      <img src={Lamp}></img>
                      <Typography style={{letterSpacing: '-1%',color: '#475467'}}>Natural Lighting</Typography>
                    </Box >
                    <Box style={{ display: "flex", gap: "8px" }}>
                      <img src={Pet}></img>
                      <Typography style={{ color: '#475467',letterSpacing: '-1%',}}>Pet Friendly</Typography>
                    </Box>
                  </Box>
                  
                  <Box style={{ display: "flex", flexDirection: "column", gap: 14 }}>
                    <Box style={{ display: "flex", gap: "8px" }}>
                      <img src={Location}></img>
                      <Typography style={{color: '#475467', letterSpacing: '-1%'}}>2.6 mi away from city</Typography>
                    </Box>
                    <Box style={{ display: "flex", gap: "8px" }}>
                      <img src={Broom}></img>
                      <Typography style={{color: '#475467', letterSpacing: '-1%'}}>Cleaning Service</Typography>
                    </Box>
                    <Box style={{ display: "flex", gap: "8px" }}>
                      <img src={Lamp}></img>
                      <Typography style={{color: '#475467', letterSpacing: '-1%'}}>Buffet Food</Typography>
                    </Box>
                  </Box>

                </Box></>:<Box>
                <Typography style={{fontSize: 20, fontWeight: 600, letterSpacing: 'normal',   marginBottom: "32px", color: '#23395D' }}>
                  Car Specification
                </Typography>
                <Box className="specification-box" style={{display: 'grid', gap: 12, gridTemplateColumns: 'auto auto auto', marginBottom: 32}}>
                  <Box style={{border: '1px solid #EAECF0', borderRadius: 12, padding: '20px', background: 'white'}}>
                    <Typography style={{color: '#475467', fontSize: 16,display:"flex",gap:6,alignItems:"center"}}><img src={flash}></img>Engine/Cylinders:</Typography>
                    <Typography style={{color: '#101828', fontSize: 16}}>v12</Typography>
                  </Box>
                  <Box style={{border: '1px solid #EAECF0', borderRadius: 12, padding: '20px', background: 'white'}}>
                    <Typography style={{color: '#475467', fontSize: 16,display:"flex",gap:6,alignItems:"center"}}><img src={flash}></img>Max Torque:</Typography>
                    <Typography style={{color: '#101828', fontSize: 16}}>603nm</Typography>
                  </Box>
                  <Box style={{border: '1px solid #EAECF0', borderRadius: 12, padding: '20px', background: 'white'}}>
                    <Typography style={{color: '#475467', fontSize: 16,display:"flex",gap:6,alignItems:"center"}}><img src={flash}></img>Max Power:</Typography>
                    <Typography style={{color: '#101828', fontSize: 16}}>630ps</Typography>
                  </Box>
                  <Box style={{border: '1px solid #EAECF0', borderRadius: 12, padding: '20px', background: 'white'}}>
                    <Typography style={{color: '#475467', fontSize: 16,display:"flex",gap:6,alignItems:"center"}}><img src={speed}></img>Top Speed:</Typography>
                    <Typography style={{color: '#101828', fontSize: 16}}>201 mph</Typography>
                  </Box>
                  <Box style={{border: '1px solid #EAECF0', borderRadius: 12, padding: '20px', background: 'white'}}>
                    <Typography style={{color: '#475467', fontSize: 16,display:"flex",gap:6,alignItems:"center"}}><img src={speed}></img>Acceleration:</Typography>
                    <Typography style={{color: '#101828', fontSize: 16}}>0-60mph/3.5sec</Typography>
                  </Box>
                  <Box style={{border: '1px solid #EAECF0', borderRadius: 12, padding: '20px', background: 'white'}}>
                    <Typography style={{color: '#475467', fontSize: 16,display:"flex",gap:6,alignItems:"center"}}><img src={flash}></img>Transmissions:</Typography>
                    <Typography style={{color: '#101828', fontSize: 16}}>8 Speed touchtronic || </Typography>
                  </Box>
                </Box>
                  </Box>}
                <Box className="inner-img-box">
                <Box style={{display:"flex",gap:20,marginBottom:"16px"}}>
                  <img style={{flex: 1}} src={Place1}></img>
                  <img style={{flex: 1}} src={Place}></img>
                </Box>
                <img style={{width: '100%'}} src={Place2}></img>
                </Box>
                <Box>
                 {this.state.type==="Home"? <Box>
                <Typography style={{ fontSize: 20, fontWeight: 600, marginTop: "32px", color: '#23395D' }}>
                Our location on map
                </Typography>
                {console.log("first>>>>", this.state.productData)}
                <iframe style={{border: 'none', borderRadius: 12, marginTop: 18}} 
                  src={`https://www.google.com/maps/embed/v1/place?key=${apiKey}&q=${truthyValue(this.state.lat)},${truthyValue(this.state.long)}`} 
                width="100%" height="296" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                </Box>:<Box>
                <Typography style={{ fontSize: 20, fontWeight: 600, marginTop: "32px", color: '#23395D' }}>
                Mileage/Insurance Coverage
                </Typography>
                <Box className="specification-box" style={{display: 'grid',gridRow:"", gap: 12, gridTemplateColumns: 'auto auto auto', marginBottom: 32}}>
                  <Box style={{padding: '20px', background: 'white'}}>
                    <Typography style={{color: '#475467', fontSize: 16,display:"flex",gap:6, alignItems:"center",flexDirection:"column"}}><img src={flash}></img>Engine/Cylinders:</Typography>
                    <Typography style={{color: '#101828', fontSize: 16,textAlign:"center"}}>300 mile</Typography>
                  </Box>
                  <Box style={{padding: '20px', background: 'white'}}>
                    <Typography style={{color: '#475467', fontSize: 16,display:"flex",gap:6,alignItems:"center",flexDirection:"column"}}><img src={flash}></img>Max Torque:</Typography>
                    <Typography style={{color: '#101828', fontSize: 16,textAlign:"center"}}>Full Coverage</Typography>
                  </Box>
                  <Box style={{padding: '20px', background: 'white'}}>
                    <Typography style={{color: '#475467', fontSize: 16,display:"flex",gap:6,alignItems:"center",flexDirection:"column"}}><img src={flash}></img>Max Power:</Typography>
                    <Typography style={{color: '#101828', fontSize: 16,textAlign:"center"}}>Fuel Policy</Typography>
                  </Box>
                  </Box>
                
                  </Box>}
                </Box>
                <Box style={{marginTop:32}}>
                  <Card style={{boxShadow: 'none'}}>
                    <Box style={{display:"flex"}}>                   
                  <img src={Group} style={{ width: 58, height: 58, borderRadius: '50%'}} alt="" />
                  <Box style={{display:"flex",flexDirection:"column"}}>
                  <Typography style={{letterSpacing: 'normal', color: '#23395D', fontSize:20, fontWeight: 600}}>Emmanuel Ackers</Typography>
                  <Box style={{display:"flex",gap:29,}}>
                  <Typography>165 trips</Typography>
                  <Typography>Host since 2021</Typography>
                  </Box>
                  </Box>
                  </Box>
                  <Box style={{display:"flex",gap:14}}>
                    <Box style={{display:"flex",gap:8, alignItems: 'center'}}>
                    <img style={{width:"16px"}} src={star}></img><Typography>512 reviews</Typography>
                    </Box>
                    <Box style={{display:"flex",gap:8, alignItems: 'center'}}>
                    <img style={{width:"16px"}} src={Verified1}></img><Typography>Identity Verified</Typography>
                    </Box>
                  </Box>
                  <Box style={{marginTop:"24px"}}>
                      <Typography style={{letterSpacing: 'normal', fontWeight:600,fontSize:"16px",marginBottom:"12px",color: '#23395D'}}>During your stay</Typography>
                      <Typography>
                      Dolor enim eu tortor urna sed duis nulla. Aliquam vestibulum, nulla odio nisl vitae. In aliquet pellentesque aenean hac vestibulum turpis mi bibendum diam. Tempor integer aliquam in vitae malesuada fringilla. Elit nisi in eleifend sed nisi. Pulvinar at orci, proin imperdiet commodo consectetur convallis risus.
                      </Typography>
                    </Box>
                    <Box className="registration-parent-box" style={{display:"flex",gap:12,width:"100%", marginTop: 24}}>
                        <Box className="registration-box" style={{padding: 20, border: '1px solid #EAECF0', borderRadius: 12, flex: 1}}>
                        <Typography className="reg-first" variant="body1" m={0} style={{color:"#475467"}}>Registration number:</Typography>
                        <Typography className="reg-second" variant="body1" m={0} style={{color:"#475467"}}>Reg number:</Typography>
                        <Typography variant="body1" style={{color:"#475467",fontSize:"18px",lineHeight:"28px"}} fontWeight={600}>PCC-000917</Typography>
                        </Box>     
                        <Box className="registration-box" style={{padding: 20, border: '1px solid #EAECF0', borderRadius: 12, flex: 1}}>
                        <Typography variant="body1" m={0} style={{color:"#475467"}}>Response rate:</Typography>
                        <Typography variant="body1" style={{color:"#475467",fontSize:"18px",lineHeight:"28px"}} fontWeight={600}>100%</Typography>
                        </Box>     
                        <Box className="registration-box" style={{padding: 20, border: '1px solid #EAECF0', borderRadius: 12, flex: 1}}>
                        <Typography variant="body1" m={0} style={{color:"#475467"}}>Response time:</Typography>
                        <Typography variant="body1" style={{color:"#475467",fontSize:"18px",lineHeight:"28px"}} fontWeight={600}>within an hour</Typography>
                        </Box>                   
                    </Box>
                  </Card>
                </Box>
              </div>
            }
            {
              this.state.tabValue === 1 &&            
              <div className="left-div">
              <Box style={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography style={webStyle.commonLetterSpacingStyles}>New York, NY, United Kingdom</Typography>
                  <Box style={{ display: "flex", fontSize: 20, gap: 10 }} >
                    <img src={Share}></img>
                    <Typography className="inner-img-box">
                      Share
                    </Typography>
                    <img src={Heart}></img>
                    <Typography className="inner-img-box">
                      Save
                    </Typography>
                  </Box>
                </Box>
                <Box>
                  <Typography className="header-text" style={{letterSpacing: 'normal',  fontSize: "30px", fontWeight: 600, color: '#23395D' }}>8502 Preston Rd. Inglewood,Maine 98380</Typography>
                  <Box style={{ display: "flex", gap: 10 }}>
                    <img src={StarYellow}></img>
                    <Typography style={webStyle.commonLetterSpacingStyles}>
                      4.7 44 reviews
                    </Typography>
                  </Box>
                </Box>
                <Paper square style={{boxShadow: 'none', borderBottom: '1px solid #EAECF0'}}>
                  {this.tabsComponent()}
                </Paper>
                 {this.policyData()}
                </div>
            }
            {this.state.tabValue === 2 && 
            <div className="left-div">
            <Box style={{ display: "flex", justifyContent: "space-between" }}>
                  <Typography style={{ color: '#23395D',letterSpacing: 'normal'}}>New York, NY, United States</Typography>
                  <Box style={{ display: "flex", gap: 10, fontSize: 20 }} >
                    <img src={Share}></img>
                    <Typography className="inner-img-box">
                      Share
                    </Typography>
                    <img src={Heart}></img>
                    <Typography className="inner-img-box">
                      Save
                    </Typography>
                  </Box>
                </Box>
                <Box>
                  <Typography className="header-text" style={webStyle.addressStyle}>8503 Preston Rd. Inglewood,Maine 98380</Typography>
                  <Box style={{ display: "flex", gap: 10 }}>
                    <img src={StarYellow}></img>
                    <Typography style={{ color: '#23395D',letterSpacing: 'normal',}}>
                      4.5 44 reviews
                    </Typography>
                  </Box>
                </Box>
                <Paper square style={{boxShadow: 'none', borderBottom: '1px solid #EAECF0'}}>
                   {this.tabsComponent()}
                </Paper>
                
              <Box style={{ display: "flex", justifyContent: "space-between", marginTop: "24px" }}>
                <Typography style={webStyle.customerReviewText}>Customers Review</Typography>
                <Button style={{ display: "flex", gap: "5px" }} >
                  <img src={DropDown}></img>
                  Latest
                  <img src={Drop}></img>
                </Button>
              </Box>
              <Box>
              <Box className="review-parent-box" style={{display:"flex",gap:"24px",flexWrap:"wrap", maxWidth: 1280}} sx={webStyle.productContainer}>
                <Card style={{maxWidth:'392px', width: '100%' ,overflow: 'hidden', boxShadow: 'none'}}>
                  <Box style={{ display: "flex",gap:40,marginTop:"10px",marginBottom:"10ox"}}>
                    <Box>
                    <CircularProgressWithLabel value={(parseInt(this.state.allReviews.average_rating)/5)*100} />
                    </Box>
                        <Box>
                          {[5,4,3,2,1].map(arrVal => 
                            <Box style={webStyle.arrValStyle}>
                              <Typography className="reviewPointer">{arrVal}</Typography>
                              <BorderLinearProgress variant="determinate" value={arrVal*20} />
                            </Box>
                          )}
                        </Box>
                        
                  </Box>
                  <Typography style={{letterSpacing: 'normal', color:"#3173E1"}}>
                  Total {this.state.allReviews?.reviews?.data.length} reviews
                  </Typography>
                </Card>              
                {this.state.allReviews.reviews && this.state.allReviews.reviews.data.map((data)=>{
                  return  <Card style={webStyle.cardStyles}>
                  <CardContent>
                    <Box style={webStyle.profileImgStyle}>
                      <img style={{ borderRadius : "100%", height: 45, width: 45}} src={data.attributes.profile_img.url}></img>
                      <Box style={{ display: "flex", flexDirection: "column", width: "100%" }} >
                        <Box style={{ display: "flex", justifyContent: "space-between" }}>
                          <Typography style={webStyle.reviewerName}>{data.attributes.reviewer_name} </Typography>
                          <Typography style={webStyle.createdAtStyle}>{data.attributes.created_at}</Typography>
                        </Box>
                        <Rating name="size-small" defaultValue={data.attributes.rating} size="small" />
                      </Box>
                    </Box>
                    <Box style={{ borderBottom: "0.5px solid #D0D5DD", marginBottom: "8px", paddingBottom: 8 }}>
                      <Typography style={{letterSpacing: 'normal',  fontSize: "14px", fontWeight: 500, color: '#475467', fontFamily: "Plus Jakarta Sans" }}>{data.attributes.web_time_ago}</Typography>
                    </Box>
                    <Box>
                      <Typography style={webStyle.commentStyle}>
                        {data.attributes.comment}
                      </Typography>
                    </Box>
                    {data.attributes.comment.length>184 &&  <Box style={{ display: "flex", gap: "7.5px", position: "absolute" }}>
                    <Button style={webStyle.seeMoreBtn}>See more</Button>
                      <img style={{ width: "5px", height: "10px", position: "relative", top: 13 }} src={ShowMoreIcon}></img>
                    </Box>}
                  </CardContent>
                </Card>
                  })}  
                 { this.state.allReviews.reviews?.data.length>5 &&
                <Button variant="outlined" style={webStyle.seeAllReviewsBtn}>See all reviews<img style={{marginLeft:"8px"}}src={ShowMore}></img></Button>}
              </Box>
              </Box>
              <Box>
              </Box>
            </div>}

            <div className="right-div">
              {this.state.type==="Home"?<div>
              <div style={{ display: "flex", alignItems: 'baseline', marginBottom: 8 }}>
                <Typography style={webStyle.rentalPriceStyles}> ${this.state.rentalPrice}/ </Typography>
                <Typography style={{letterSpacing: 'normal',  fontWeight: 300 }}>
                  night
                </Typography>
              </div>
              <Typography style={{letterSpacing: 'normal',  fontSize: 14, marginBottom: 34 }}>Total before taxes</Typography>
              <CheckOutDiv style={{ display: 'flex', gap: 14, marginBottom: 14 }}>
                <div style={webStyle.locationAndDateStyles}>
                  <label htmlFor="">Check In</label>
                  <TextField style={{ borderRadius: "12px"}} variant="outlined" type="date" inputProps={{ min: this.handleMinimumCurrentDate() }} onChange={this.handleStartDate} ></TextField>
                </div>
                <div style={webStyle.locationAndDateStyles}>
                  <label htmlFor="">Check Out</label>
                  <TextField style={{ borderRadius: 12}} variant="outlined" type="date" onChange={this.handleEndDate} ></TextField>
                </div>
              </CheckOutDiv>
              <div style={{ marginBottom: 20 }}>
                <Typography style={webStyle.guestTypography}>
                  Guests
                </Typography>
                <select style={webStyle.adultSelect}>
                  <option>4 Adults</option>
                  <option>3 Adults</option>
                  <option>2 Adults</option>
                  <option>1 Adults</option>
                </select>
              </div>
              </div>:
              <CheckOutDiv style={{ marginBottom: 14 }}>
              <div style={webStyle.locationAndDateStyles}>
                <label htmlFor="">Pick-up Location</label>
                <TextField style={{ borderRadius: "12px"}} variant="outlined" type="text" ></TextField>
              </div>
              <div style={webStyle.locationAndDateStyles}>
                <label htmlFor="">Pick-up Date</label>
                <TextField style={{ borderRadius: 12}} inputProps={{ min: this.handleMinimumCurrentDate() }}  onChange={this.handleStartDate} variant="outlined" type="date" defaultValue={this.state.currentDate}></TextField>
              </div>
              <div style={webStyle.locationAndDateStyles}>
                <label htmlFor="">Drop-off Location</label>
                <TextField style={{ borderRadius: 12}} variant="outlined" type="text" ></TextField>
              </div>  <div style={webStyle.locationAndDateStyles}>
                <label htmlFor="">Drop-off Date</label>
                <TextField style={{ borderRadius: 12}} onChange={this.handleEndDate} variant="outlined" type="date" defaultValue={this.state.currentDate}></TextField>
              </div>
            </CheckOutDiv>}
              <div style={{ padding: 14 }}>
                <Typography style={webStyle.pricingBreakdownTypography} >Pricing Breakdown</Typography>
               <div>
                  <div style={{ paddingTop: 14, paddingBottom: 6, display: 'flex', gap: 10, justifyContent: 'space-between' }}>
                    <Typography style={webStyle.commonLetterSpacingStyles}>Rental Price</Typography>
                    <Typography style={webStyle.commonColorLetterSpacingStyles}>$ {this.state.rentalPrice}</Typography>
                  </div>
                  <Typography style={{letterSpacing: 'normal',  fontSize: 14, color: '#667085' }}>${this.state.total_nightly_price}/day<span style={{color:"#3173E1",letterSpacing:"-1%", marginLeft: 10}}>(x{this.state.number_of_days} days)</span></Typography>
                </div>
                <div>
                  <div style={{fontSize:16, paddingTop: 14, paddingBottom: 6, display: 'flex', gap: 10, justifyContent: 'space-between' }}>
                    <Typography style={webStyle.commonLetterSpacingStyles}>{this.state.productData?.meta?.discount_title}</Typography>
                    <Typography style={webStyle.commonColorLetterSpacingStyles}>-${this.state.discountPrice}</Typography>
                  </div>
                  <Typography style={{letterSpacing: 'normal',  fontSize: 14, color: '#667085' }}>Extended trip discount<span style={{color:"#3173E1",letterSpacing:"-1%", marginLeft: 10}}>({this.state.productData?.meta?.discount_rate}%)</span></Typography>
                </div>
                <div style={{paddingBottom: 14}}>
                  <div style={{ paddingTop: 14, fontSize:16,paddingBottom: 6,  gap: 10,display: 'flex', justifyContent: 'space-between' }}>
                    <Typography style={webStyle.commonLetterSpacingStyles}>Refundable deposit</Typography>
                    <Typography style={webStyle.commonColorLetterSpacingStyles}>${this.state.refundable_deposit}</Typography>
                  </div>
                  <Typography style={{letterSpacing: 'normal',  fontSize: 14, color: '#667085' }}>Refunded by<span style={{color:"#3173E1",letterSpacing:"-1%", marginLeft: 10}}>Oct 14th</span></Typography>
                </div>
                <div>
                  <div style={webStyle.totalPriceDue}>
                    <Typography style={webStyle.commonLetterSpacingStyles}>Total Price Due</Typography>
                    <Typography style={webStyle.commonColorLetterSpacingStyles}>${this.state.totalPriceDue}</Typography>
                  </div>
                  <Typography style={{letterSpacing: 'normal', color: '#667085',  fontSize: 14 }}>${this.state.refundable_deposit} Refunded by<span style={{color:"#3173E1",letterSpacing:"-1%", marginLeft: 10}}>Oct 14th</span></Typography>
                </div>
                <div style={{color:"#475467",fontSize:12, marginTop:"14px"}}>
                  <span style={{ color: 'red' }}>*</span>
                  Your total rent amount will be calculated depending on the pick-up and drop-off dates.
                </div>
              </div>
              <div style={{ marginTop: 20, marginBottom: 40 }}>
                {this.reserveNowBtn()}
              </div>
              <div>
                <div style={{ display: 'flex', alignItems: 'center', gap: 10, marginBottom: 20 }}>
                  <img src={this.state.productData?.data?.attributes.hosted_by.data.attributes.host_profile_image.url} style={{ width: 58, height: 58, borderRadius: '50%'}} alt="" />
                  <div>
                    <Typography style={webStyle.hostedByFullName}>{this.state.productData?.data?.attributes.hosted_by.data.attributes.hosted_by.full_name}</Typography>
                    <Typography style={{letterSpacing: 'normal',  fontSize: 14, color: '#23395D' }}>{this.state.productData?.data?.attributes.hosted_by.data.attributes.hosted_by.host_trip_completed} trips . Host since {this.state.productData?.data?.attributes.hosted_by.data.attributes.hosted_by.joined}</Typography>
                  </div>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', gap: 20 }}>
                  <button style={webStyle.viewProfileStyle}>View Profile</button>
                  <button style={{ background: 'white', border: '1px solid #dcdcdc', borderRadius: 12, padding: 12, flex: 1, cursor:"pointer"}}>Contact</button>
                </div>
              </div>
            </div>
          </FlexParentBox>
        </CustomParentBox>
         <Footer/>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const webStyle = {
  productContainer: {
    display: "flex",
    flex:"0 0 30%",
    flexDirection: "row",
    flexWrap: "wrap",
    width: "100%",
    backgroundColor: "white",
    marginTop: "20px",
  },
  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
  adultSelect: { 
    width: '100%', 
    border: '1px solid #dcdcdc', 
    borderRadius: 12, 
    padding: '10px 14px',
    background:"white",
    color:"#23395D" 
  },
  guestTypography: {
    letterSpacing: 'normal',  
    fontSize: "14px", 
    marginBottom: 6 ,
    color:"#667085"
  },
  locationAndDateStyles: {
    display: 'flex', 
    flexDirection: 'column', 
    gap: 6, 
    flex: 1
  },
  pricingBreakdownTypography: {
    letterSpacing: 'normal',  
    fontSize: 18, 
    fontWeight: 700, 
    borderBottom: '1px solid #EAECF0', 
    paddingBottom: 14, 
    color: '#23395D' 
  },
  rentalPriceStyles: {
    letterSpacing: 'normal',  
    fontWeight: 500, 
    fontSize: "30px", 
    lineHeight: 1, 
    color: '#23395D' 
  },
  commonLetterSpacingStyles: {
    letterSpacing: 'normal', 
    color: '#23395D'
  },
  commonColorLetterSpacingStyles: {
    letterSpacing: 'normal', 
    color: '#475467'
  },
  viewProfileStyle: { 
    background: 'white', 
    border: '1px solid #3173E1', 
    color: '#3173E1', 
    borderRadius: 12, 
    padding: 12, 
    flex: 1, 
    cursor:"pointer" 
  },
  hostedByFullName: {
    letterSpacing: 'normal',  
    fontSize: 20, 
    lineHeight: '20px', 
    marginBottom: 6, 
    color: '#23395D' 
  },
  totalPriceDue: { 
    fontSize:16,
    paddingBottom: 6, 
    paddingTop: 14, 
    display: 'flex',
    borderTop: '1px solid #EAECF0', 
    gap: 10, 
    justifyContent: 'space-between' 
  },
  seeAllReviewsBtn: {
    maxWidth:"808px", 
    width: '100%',
    borderRadius:"30px",
    fontSize:"14px", 
    textTransform: 'unset'
  },
  seeMoreBtn: { 
    color: "#3173E1", 
    textTransform: 'unset', 
    paddingLeft: 0 
  },
  commentStyle: {
    letterSpacing: 'normal', 
    fontFamily: "Plus Jakarta Sans", 
    fontSize: "14px", 
    fontWeight: 400, 
    color: '#475467' 
  },
  reviewerName: {
    letterSpacing: 'normal', 
    color: '#23395D', 
    fontSize: 14, 
    fontWeight: 600, 
    fontFamily: "Plus Jakarta Sans"
  },
  createdAtStyle: {
    letterSpacing: 'normal', 
    color: '#475467', 
    fontSize: 14, 
    fontWeight: 400, 
    fontFamily: "Plus Jakarta Sans"
  },
  profileImgStyle: { 
    padding: "10px 0", 
    display: "flex",
    gap:12, 
    borderBottom: "0.5px solid #D0D5DD", 
    height: "44px", 
    marginBottom: "8px" 
  },
  cardStyles: { 
    maxWidth: '392px', 
    width: '100%', 
    overflow: 'hidden', 
    boxShadow: 'none' 
  },
  arrValStyle: { 
    display: "flex", 
    gap: "12px", 
    alignItems: "center", 
    margin: "5px 0" 
  },
  customerReviewText: {
    letterSpacing: 'normal',  
    color: '#23395D', 
    marginBottom: 18, 
    fontWeight: 600, 
    fontSize: "20px" 
  },
  addressStyle: { 
    fontSize: "30px", 
    fontWeight: 600,
    letterSpacing: 'normal',  
    color: '#23395D' 
  }
};

// Customizable Area End
