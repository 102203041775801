// Customizable Area Start
import React from 'react';
import { Box, Typography, styled, Divider } from '@material-ui/core';


const ProfileHeader = () => {

  return (
      <HeaderTextWrapper>
          <Box className='mainBox'>
              <Typography className="settingsText">Settings</Typography>
          </Box>
          <Divider />
      </HeaderTextWrapper>
  )
}

export default ProfileHeader

const HeaderTextWrapper = styled(Box)({
    "& .mainBox": {
        margin: "20px 0",
        "@media(max-width: 1200px)": {
            margin: "20px 20px",
        }
    },
    "& .settingsText" : {
        color :"#101828",
        fontFamily: "Plus Jakarta Sans",
        fontWeight: 600,
        fontSize: "30px"
      },
})


// Customizable Area End