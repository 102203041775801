//@ts-nocheck

import React from "react";
  // Customizable Area Start
import {
  Box,
  Button,
  Typography,
  styled,
  Divider,
  TextField,
  OutlinedInput,
  Grid,
  InputAdornment,
  IconButton
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { AppleCircle, CircleCross, CircleSpeed, Cripto, Email, Google, Like, MasterCard, Paypal, PinMap, Plus, SelectedGroup, SelectGroup, SpeedLightBlue, Star, Visa } from "../../productdescription/src/assets";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { getAllCountries, getDefaultCountryCodes } from "../../../components/src/asset";
import { Autocomplete } from "@material-ui/lab";
import moment from "moment"
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

import Footer from "../../../components/src/Footer.web";
const FlexParentBox = styled(Box)({
  '& .left-div': {
    width: '75%',
  },
  '& .right-div': {
    width: '25%',
    padding: '20px'
  },
  '& .reg-second': {
    display: 'none'
  },
  '@media(max-width: 1260px)': {
    paddingInline: 20,
    flexDirection: 'column',
    '& .reg-first': {
      display: 'none !important'
    },
    '& .reg-second': {
      display: 'block !important'
    },
    '& .left-div': {
      width: '100% !important',
    },
    '& .inner-img-box': {
      display: 'none'
    },
    '& .right-div': {
      padding: '0px',
      width: '50% !important'
    },
    '& .offer-parent-box': {
      gap: '50px !important'
    },
    '& .offer-second-box': {
      display: 'none !important'
    },
  },
  '@media(max-width: 960px)': {
    '& .review-parent-box': {
      flexDirection: 'column',
    },
  },
  '@media(max-width: 767px)': {
    '& .specification-box': {
      gridTemplateColumns: 'auto auto !important',
    },
    paddingInline: 10,
    '& .header-text': {
      fontSize: '18px !important',
      marginBottom: 6,
      marginTop: 4,
    },
    '& .policy-inner-box': {
      flexDirection: 'column'
    },
    '& .registration-parent-box': {
      flexDirection: 'column',
    },
    '& .right-div': {
      width: '100% !important',
    },
    '& .registration-box': {
      border: 'none !important',
      padding: '0px !important'
    },
    '& .offer-parent-box': {
      gap: '0px !important',
      '& img': {
        height: 20,
        width: '15px !important',
      },
      '& p': {
        fontSize: '14px !important',
        lineHeight: '-1%',
      }
    }
  }
})
const CustomParentBox = styled(Box)({
  '@media(max-width: 1260px)': {
    '& .second-img-box': {
      display: 'none'
    },
    "& .normalStyleBtn" : {
      borderRadius: 12,
      background: '#3173E1',
      cursor: "pointer",
      border: 'none',
      height: 48,
      width: '100%',
      color: 'white',
      textTransform: "none",
    },
    '& .first-img': {
      width: '97%',
      borderRadius: 20,
      margin: 'auto'
    },
  }
})

const theme = createTheme({
  palette: {
    primary: {
      contrastText: "#3173E1",
      main: "#3173E1",
    },
  },
  typography: {
    subtitle1: {
      margin: "20px 0px",
    },
    h6: {
      fontWeight: 500,
    },
  },
});

const boxStyle = (isSelected) => ({
  margin: '16px 0',
  padding: 16,
  border: isSelected ? '2px solid #007bff' : '1px solid #EAECF0',
  borderRadius: 12,
  display: 'flex',
  backgroundColor: isSelected ? '#e7f3ff' : '#fff',
  justifyContent: 'space-between',
  alignItems: 'center',
  cursor: 'pointer',
});

const descriptionStyle = {
  color: '#6c757d',
  fontSize: 12,
};

const contentStyle = {
  display: 'flex',
  alignItems: 'center',
};

import AppHeader from "../../notifications/src/TopHeader.web";
// Customizable Area End

import ProductCheckoutController, {
  Props,
} from "./ProductCheckoutController";

export default class ProductCheckout extends ProductCheckoutController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    this.getProductDescriptionId();
  }

  renderSignUpForm = () => {
    return (
      <>
        <Typography style={{ letterSpacing: 'normal', fontWeight: 600, fontSize: 20, marginBottom: "18px", color: '#23395D', marginTop: "24px", }}>
          Log in or sign up to complete your booking
        </Typography>
        <Box sx={{ display: 'flex' }}>
          
          
        </Box>
          <Grid container spacing={2}>
            <Grid item md={6}>
              <Box sx={{  textAlign: 'center', borderRadius: 12, marginRight: 8 }}>
                <Typography style={{ color: '#22395D', fontSize: 14, marginBottom: 8, textAlign: 'left', fontWeight: '500', fontFamily: 'Plus Jakarta Sans' }}>
                  Full Name*
                </Typography>
                <TextField 
                 data-test-id="userName"
                 onChange={(event) => this.handleChangeForForm(event, "userName")}
                 variant="outlined" 
                 fullWidth
                 InputProps={{ style: { borderRadius: 12, height: 44 }, }} />
              </Box>
            </Grid>
            <Grid item md={6}>
              <Box sx={{  textAlign: 'center', borderRadius: 12 }}>
                <Typography style={{ color: '#22395D', fontSize: 14, marginBottom: 8, textAlign: 'left', fontWeight: '500', fontFamily: 'Plus Jakarta Sans' }}>
                  Email address*
                </Typography>
                <TextField
                  fullWidth
                  variant="outlined"
                  InputProps={{ style: { borderRadius: 12, height: 44 }, }}
                  data-test-id="userEmail"
                  onChange={(event) => this.handleChangeForForm(event, "userEmail")}
                />
              </Box>
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item md={6}>
              <Box sx={{  textAlign: 'center', borderRadius: 12, marginRight: 8 }}>
                <Typography style={{ color: '#22395D', fontSize: 14, marginBottom: 8, textAlign: 'left', fontWeight: '500', fontFamily: 'Plus Jakarta Sans' }}>
                  Password*
                </Typography>
                <TextField 
                    type={this.state.isPasswordShow ? "text" :"password"} 
                    data-test-id="userPassword"
                    onChange={(event) => this.handleChangeForForm(event, "userPassword")} 
                    fullWidth 
                    variant="outlined" 
                    InputProps={{ style: { borderRadius: 12, height: 44 },
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility in signup"
                          onClick={this.handleClickShowPassword}
                          onMouseDown={this.handleMouseDownPassword}
                        >
                          {this.state.isPasswordShow ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    )
                  
                  }}
                 />
              </Box>
            </Grid>
            <Grid item md={6}>
              <MainWrapper>
                <Box sx={{ flex: 1,textAlign: 'center', borderRadius: 12 }}>
                  <Typography style={{ color: '#22395D', fontSize: 14, marginBottom: 8, textAlign: 'left', fontWeight: '500', fontFamily: 'Plus Jakarta Sans' }}>
                    Phone number*
                  </Typography>
                  <Box style={{ display: "flex", alignItems: "center" }}>
                    <OutlinedInput
                      className="inputFields"
                      name="phone_number"
                      onChange={this.handlePhoneNumber}
                      value={this.state.selectPhoneNumber}
                      startAdornment={
                        <Autocomplete
                          id="combo-box-demo"
                          className="inputFieldsSelectOpt"
                          disableClearable
                          options={getAllCountries()}
                          onChange={this.handleCountryCodeChange}
                          defaultValue={getDefaultCountryCodes()}
                          getOptionLabel={(option) => option.phonecode}
                          renderInput={(params) => <TextField {...params} size="small" variant="outlined" />}
                        />
                      }
                    />
                  </Box>
                </Box>
              </MainWrapper>
            </Grid>
          </Grid>
        <Box style={{ margin : "20px 0"}}>
          <Button
            onClick={this.handleContinueLogin}
            style={{ background: '#3173E1', borderRadius: 12, border: 'none', height: 48, width: '100%', color: 'white', textTransform: "none", cursor: "pointer" }}
            data-test-id="continueBtn"
          >
            Continue
          </Button>
        </Box>
        <Box sx={{ marginTop: 24 }}>
          <Typography style={{ color: '#475467', fontSize: 14, fontWeight: '500', marginBottom: 16, fontFamily: 'Plus Jakarta Sans' }}>
            Or log in with
          </Typography>
          <Box sx={{ display: 'flex' }}>
            <Box sx={{ flex: 1, padding: 24, textAlign: 'center', border: '1px #D0D5DD solid', borderRadius: 12, marginRight: 16, display: 'flex', justifyContent: 'center', height: 8 }}>
              <Typography style={{ color: '#22395D', alignItems: 'center', fontSize: 14, display: 'flex', }}>
                <img src={Google} style={{ marginRight: 6, marginBottom: 6 }}></img>
                Google
              </Typography>
            </Box>
            <Box sx={{ flex: 1, padding: 24, marginRight: 16, border: '1px #D0D5DD solid', borderRadius: 12, display: 'flex', textAlign: 'center', justifyContent: 'center', height: 8 }}>
              <Typography style={{ fontSize: 14, display: 'flex', color: '#22395D', alignItems: 'center' }}>
                <img src={AppleCircle} style={{ marginRight: 6, marginBottom: 6 }}></img>
                Apple Pay
              </Typography>
            </Box>
            <Box sx={{ flex: 1, padding: 24, textAlign: 'center', border: '1px #D0D5DD solid', borderRadius: 12, display: 'flex', justifyContent: 'center', height: 8 }}>
              <Typography style={{ color: '#22395D', fontSize: 14, display: 'flex', alignItems: 'center' }}>
                <img src={Email} style={{ marginBottom: 6, marginRight: 6 }}></img>
                Email
              </Typography>
            </Box>
          </Box>
        </Box>
      </>
    )
  }

  renderCardScreen = () => {
    const { selectedPayment, savedCards } = this.state; 
    return (
      <>
        <Typography style={{ letterSpacing: 'normal', fontWeight: 600, fontSize: 20, marginBottom: "18px", color: '#23395D', marginTop: "24px", }}>
          Payment Method
        </Typography>
        <Box style={{ padding: 16, margin: '16px 0', borderRadius: 12, border: '1px #EAECF0 solid' }}>
          <Box>
            <Typography variant="body2" style={{ marginBottom: 16, marginTop: 8, color: '#23395D' }}>
              Saved Cards
            </Typography>
            {savedCards && savedCards.map(card => {
              const brand =
                card.attributes.payment_method_details.card.brand.charAt(0).toUpperCase() +
                card.attributes.payment_method_details.card.brand.slice(1);
              const last4 = card.attributes.payment_method_details.card.last4;
              const expMonthDate = card.attributes.payment_method_details.card.exp_month;
              const expYearDate = card.attributes.payment_method_details.card.exp_year;

              return (
                <Box
                  onClick={() => this.handleChangePaymentMethod(card)}
                  style={boxStyle(selectedPayment && selectedPayment.id === card.id)}
                  key={card.id}
                  data-test-id="changeMethod"
                >
                  <Box style={contentStyle}>
                    {brand == "Visa" && <img src={Visa} alt="Visa" style={{ marginRight: 16 }} />}
                    {brand == "Mastercard" && <img src={MasterCard} alt="MasterCard" style={{ marginRight: 16 }} />}
                    {brand == "Paypal" && <img src={Paypal} alt="Paypal" style={{ marginRight: 16 }} />}

                    <Box>
                      <Typography variant="body2" style={{ fontWeight: '500', fontSize: 14 }} data-test-id={`card-${card.id}-details`}>{brand} ending in {last4}</Typography>
                      <Typography style={descriptionStyle} name="expiry_dates">Expiry {expMonthDate}/{expYearDate}</Typography>
                    </Box>
                  </Box>
                  {selectedPayment && selectedPayment.id === card.id ? (
                    <img src={SelectedGroup} alt="Selected" style={{ color: '#22395D' }} />
                  ) : (
                    <img src={SelectGroup} alt="Not Selected" style={{ color: '#22395D' }} />
                  )}
                </Box>
              );
            })}
          </Box>

          <Box>
            <Typography data-test-id="addPaymentNavigate" onClick={this.navigateToPaymentPage} variant="body2" style={{cursor: "pointer", color: '#3173E1', paddingTop: 16, display: 'flex', alignItems: 'center' }}>
              <img src={Plus} style={{ marginRight: 6 }} /> Add new payment
            </Typography>
          </Box>
        </Box>
        <Box sx={{ marginTop: 18 }}>
          <Typography style={{ color: '#475467', fontSize: 14, fontWeight: '500', marginBottom: 16 }}>
            Or checkout with
          </Typography>
          <Box sx={{ display: 'flex' }}>
            <Box sx={{ flex: 1, padding: 24, textAlign: 'center', border: '1px #EAECF0 solid', borderRadius: 12, marginRight: 16, display: 'flex', justifyContent: 'center' }}>
              <Typography style={{ color: '#22395D', fontSize: 14, display: 'flex', alignItems: 'center' }}>
                <img src={Cripto} style={{ marginRight: 6, marginBottom: 6 }}></img>
                Cripto
              </Typography>
            </Box>
            <Box sx={{ flex: 1, padding: 24, textAlign: 'center', border: '1px #EAECF0 solid', borderRadius: 12, display: 'flex', justifyContent: 'center' }}>
              <Typography style={{ color: '#22395D', fontSize: 14, display: 'flex', alignItems: 'center' }}>
                <img src={AppleCircle} style={{ marginRight: 6, marginBottom: 6 }}></img>
                Apple Pay
              </Typography>
            </Box>
          </Box>
        </Box>
        <Divider style={{ backgroundColor: '#80808026', margin: '32px 0' }} />
        <Box sx={{ marginBottom: 16 }}>
          <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2 }}>
            <Box sx={{ padding: '16px 20px', textAlign: 'left', border: '1px #EAECF0 solid', width: '100%', borderRadius: 12, marginRight: 16 }}>
              <Typography style={{ color: '#22395D', fontSize: 16, fontWeight: '600', display: 'flex', marginBottom: 12 }}>
                <img src={CircleCross} style={{ marginRight: 6, marginBottom: 6 }}></img>
                Free Cancellation (24-hour prior)
              </Typography>
              <Typography style={{ color: '#22395D', fontSize: 16, fontFamily: 'Plus Jakarta Sans', fontWeight: '500' }}>Return with the same fuel level.</Typography>
              <Typography style={{ color: '#475467', fontSize: 14, fontWeight: '400' }}>Receive a full refund if you cancel before October 21, 10:30 AM. (Local time of car)</Typography>
            </Box>
            <Box sx={{ padding: '16px 20px', textAlign: 'left', border: '1px #EAECF0 solid', width: '100%', borderRadius: 12 }}>
              <Typography style={{ color: '#22395D', fontSize: 16, fontWeight: '500', marginBottom: 12, fontFamily: 'Plus Jakarta Sans' }}>$3.50 fee for each additional mile.</Typography>
              <Typography style={{ color: '#22395D', fontSize: 16, display: 'flex', fontWeight: '600', fontFamily: 'Plus Jakarta Sans' }}>
                <img src={CircleSpeed} style={{ marginRight: 6, marginBottom: 6 }}></img>
                350 mile included
              </Typography>
              <Typography style={{ color: '#475467', fontSize: 14, fontWeight: '400', fontFamily: 'Text sm/Regular' }}>Any additional mileage charge will be processed after the rental automatically.</Typography>
            </Box>
          </Box>
        </Box>
      </>
    )
  }

  // Customizable Area End

  render() {
    const { productData, meta, showLogin } = this.state; 
    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <AppHeader navigation={this.props.navigation}/>
        <CustomParentBox style={{ minWidth: '1000px', maxWidth: '1216px', margin: 'auto', marginBottom:120 }}>
          <Box style={{ display: "flex", gap: "5px", marginTop: 32 }}>
            <ArrowBackIosIcon style={{ color: "#667085", height: 16, width: 16 }} />
            <Typography 
            style={{
                fontSize: '14px',
                fontFamily: 'Plus Jakarta Sans',
                marginTop: '-2px',
                color: "#667085",
                cursor: "pointer"
            }}
            data-test-id="backLink" 
              onClick={this.onGoBack}
            > Back</Typography>
          </Box>
          <FlexParentBox style={{ display: 'flex', gap: 48 }}> 
            <div className="left-div">
              <div style={{ padding: '26px 0 8px 0' }}>
                <Typography variant="body2" style={{ color: '#667085', fontSize: 14, display: 'flex'}}>
                  Home Page &nbsp;&gt;&nbsp; {productData?.name} Listing &nbsp;&gt;&nbsp;    <Typography variant="body2">{productData?.name} Details</Typography>&nbsp;&gt;&nbsp; <Typography variant="body2" style={{color:'#3173E1'}}>Checkout</Typography>
                </Typography>
              </div>
              {showLogin ? this.renderSignUpForm() : this.renderCardScreen()}
            </div>
              <div className="right-div" style={{ backgroundColor: '#f6fafdde', borderRadius: 12, padding: 16 }}>
              <div style={{ marginBottom: 14 }}>
                <Typography style={{ fontSize: 16, fontFamily: 'Plus Jakarta Sans', fontWeight: '600' }}>Booking Details</Typography>
                <Divider style={{ backgroundColor: '#80808026', margin: '12px 0 16px 0' }} />
                <div style={{ display: 'flex', flexDirection: 'column', gap: 6, borderRadius: 12, backgroundColor: 'white' }}>
                  <div style={{ position: 'relative', borderRadius: 12, width: '100%' }}>
                    <img src={productData.images[0].url} alt="Product" style={{ borderRadius: 12, width: '100%', maxHeight: 155 }} />
                    <div style={{ position: 'absolute', top: 12, left: 12, display: 'flex', alignItems: 'center', backgroundColor: '#f0f0f0', padding: '4px 6px 2px 6px', borderRadius: 12 }}>
                      <Typography style={{ fontSize: 12, fontWeight: '500', color: '#22395D', fontFamily:'Plus Jakarta Sans' }}>{productData?.rate}</Typography>
                      <img src={Star} alt="Star" style={{ marginLeft: 4, width: 12, height: 12 }} />
                    </div>
                    <Box style={{ position: 'absolute', top: 18, right: 18 }}><img src={Like} /></Box>
                  </div>
                  <div style={{ textAlign: 'center' }}>
                    <Typography style={{ fontSize: 14, fontFamily: 'Plus Jakarta Sans', fontWeight: '600', color: '#23395D' }}>{productData.name}</Typography>
                  </div>
                  <div style={{ display: 'flex', justifyContent: 'space-between', padding: 16 }}>
                    <Box style={{ textAlign: 'center' }}>
                      <img src={PinMap} alt="PinMap" />
                      <Typography style={{ fontSize: 12, fontFamily: 'Plus Jakarta Sans', fontWeight: '400', color: '#23395D', marginTop: 4 }}>0</Typography>
                    </Box>
                    <Typography style={{ fontSize: 14, fontFamily: 'Plus Jakarta Sans', fontWeight: '600', color: '#3173E1' }}>
                      ${productData?.total_nightly_price}
                      <Typography component="span" style={{ fontSize: 12, fontFamily: 'Plus Jakarta Sans', fontWeight: '500', color: '#3173E1' }}>
                        /day
                      </Typography>
                    </Typography>
                    <Box style={{ textAlign: 'center' }}>
                      <img src={SpeedLightBlue} alt="SpeedLightBlue" />
                      <Typography style={{ fontSize: 12, fontFamily: 'Plus Jakarta Sans', fontWeight: '400', color: '#23395D', marginTop: 4 }}>{productData?.miles}</Typography>
                    </Box>
                  </div>
                </div>
              </div>

              <div style={{ display: 'flex', flexDirection: 'column', backgroundColor: 'white', padding: 16, borderRadius: 12 }}>
                <div style={{ marginBottom: 8 }}>
                  <Typography variant="body2" color="textSecondary">
                    Pick-up location
                  </Typography>
                </div>
                <div style={{ display: 'flex', marginBottom: 8, alignItems: 'center'}}>
                  <Typography variant="body2" style={{ fontFamily: 'Plus Jakarta Sans',  fontWeight: '500',fontSize: 14, textAlign: "left" }}>
                    New York, USA
                  </Typography>
                  <Box style={{ color: 'blue', marginLeft: 'auto'}} onClick={() => {}}>
                    <Typography style={{fontSize: 14, fontWeight: '500', fontFamily: 'Plus Jakarta Sans'}}>Edit</Typography>
                  </Box>
                </div>
                <Divider style={{ backgroundColor: '#80808026', width: '100%', marginBottom: 12 }} />

                <div style={{ marginBottom: 8 }}>
                  <Typography variant="body2" color="textSecondary">
                    Pick-up date
                  </Typography>
                </div>
                <div style={{ display: 'flex', alignItems: 'center', marginBottom: 8 }}>
                  <Typography variant="body2" style={{ fontFamily: 'Plus Jakarta Sans', fontSize: 14, fontWeight: '500', textAlign: "left" }}>
                    {moment(this.state.startDate).format('MMMM Do, YYYY')}
                  </Typography>
                  <Box onClick={() => {}} style={{ color: 'blue', marginLeft: 'auto'}}>
                    <Typography style={{fontSize: 14, fontWeight: '500', fontFamily: 'Plus Jakarta Sans'}}>Edit</Typography>
                  </Box>
                </div>
                <Divider style={{ backgroundColor: '#80808026', width: '100%', marginBottom: 12 }} />

                <div style={{ marginBottom: 8 }}>
                  <Typography variant="body2" color="textSecondary">
                    Drop-off location
                  </Typography>
                </div>
                <div style={{ alignItems: 'center', display: 'flex',marginBottom: 8 }}>
                  <Typography style={{ fontFamily: 'Plus Jakarta Sans',textAlign: "left", fontSize: 14, fontWeight: '500'  }} variant="body2" >
                    New York, USA
                  </Typography>
                  <Box onClick={() => {}} style={{ marginLeft: 'auto',color: 'blue'}}>
                    <Typography style={{ fontWeight: '500',fontSize: 14, fontFamily: 'Plus Jakarta Sans'}}>Edit</Typography>
                  </Box>
                </div>
                <Divider style={{ width: '100%', marginBottom: 12, backgroundColor: '#80808026' }} />

                <div style={{ marginBottom: 8 }}>
                  <Typography variant="body2" color="textSecondary">
                    Drop-off date
                  </Typography>
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Typography variant="body2" style={{ fontFamily: 'Plus Jakarta Sans', fontSize: 14, fontWeight: '500', textAlign: "left" }}>
                    {moment(this.state.endDate).format('MMMM Do, YYYY')}
                  </Typography>
                  <Box onClick={() => {}} style={{ color: 'blue', marginLeft: 'auto'}}>
                    <Typography style={{fontSize: 14, fontWeight: '500', fontFamily: 'Plus Jakarta Sans'}}>Edit</Typography>
                  </Box>
                </div>
              </div>

              <div style={{ padding: 14 }}>
                <Typography style={{ fontSize: 16, fontFamily: 'Plus Jakarta Sans', fontWeight: '600' }}>Pricing Breakdown</Typography>
               <div>
                  <div style={{ paddingTop: 14, paddingBottom: 6, display: 'flex', gap: 10, justifyContent: 'space-between' }}>
                    <Typography style={{letterSpacing: 'normal', color: '#23395D'}}>Rental Price</Typography>
                    <Typography style={{letterSpacing: 'normal', color: '#475467'}}>$ {productData?.total_nightly_price}</Typography>
                  </div>
                  <Typography style={{letterSpacing: 'normal',  fontSize: 14, color: '#667085' }}>${this.state.total_nightly_price}/day<span style={{color:"#3173E1",letterSpacing:"-1%", marginLeft: 10}}>(x{this.state.number_of_days} days)</span></Typography>
                </div>
                <div>
                  <div style={{fontSize:16, paddingTop: 14, paddingBottom: 6, display: 'flex', gap: 10, justifyContent: 'space-between' }}>
                    <Typography style={{letterSpacing: 'normal', color: '#23395D'}}>{meta?.discount_title}</Typography>
                    <Typography style={{letterSpacing: 'normal', color: '#475467'}}>-${this.state.discountPrice}</Typography>
                  </div>
                  <Typography style={{letterSpacing: 'normal',  fontSize: 14, color: '#667085' }}>Extended trip discount<span style={{color:"#3173E1",letterSpacing:"-1%", marginLeft: 10}}>({meta?.discount_rate}%)</span></Typography>
                </div>
                <div style={{paddingBottom: 14}}>
                  <div style={{fontSize:16, paddingTop: 14, paddingBottom: 6, display: 'flex', gap: 10, justifyContent: 'space-between' }}>
                    <Typography style={{letterSpacing: 'normal', color: '#23395D'}}>Refundable deposit</Typography>
                    <Typography style={{letterSpacing: 'normal', color: '#475467'}}>${this.state.refundable_deposit}</Typography>
                  </div>
                  <Typography style={{letterSpacing: 'normal',  fontSize: 14, color: '#667085' }}>Refunded by<span style={{color:"#3173E1",letterSpacing:"-1%", marginLeft: 10}}>Oct 14th</span></Typography>
                </div>
                <div>
                  <div style={{ fontSize:16,paddingTop: 14, paddingBottom: 6, display: 'flex', gap: 10,borderTop: '1px solid #EAECF0', justifyContent: 'space-between' }}>
                    <Typography style={{letterSpacing: 'normal', color: '#23395D'}}>Total Price Due</Typography>
                    <Typography style={{letterSpacing: 'normal', color: '#475467'}}>${this.state.totalPriceDue}</Typography>
                  </div>
                  <Typography style={{letterSpacing: 'normal',  fontSize: 14, color: '#667085' }}>${this.state.refundable_deposit} Refunded by<span style={{color:"#3173E1",letterSpacing:"-1%", marginLeft: 10}}>Oct 14th</span></Typography>
                </div>
                <div style={{marginTop:"14px",color:"#475467",fontSize:12}}>
                  <span style={{ color: 'red' }}>*</span>
                  Your total rent amount will be calculated depending on the pick-up and drop-off dates.
                </div>
              </div>
              <div style={{ marginTop: 20, marginBottom: 40 }}>
                <Button
                  onClick={this.reserveCheckout}
                  style={{background: '#3173E1',borderRadius: 12,border: 'none',height: 48, width: '100%',color: 'white',textTransform: "none",cursor: "pointer"}}
                  data-test-id="reserveBtn"
                >
                  Reserve Now
                </Button>
              </div>
            </div>
          </FlexParentBox>
        </CustomParentBox>
         <Footer/>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const MainWrapper = styled(Box)({
  flexWrap: "wrap",
  maxWidth: "1220px",
  display: "flex",
  margin: "auto",
  flexDirection: "column",
  "& .inputFields" : {
    borderRadius: "12px",
    height : "44px",
    padding: "10px 8px",
    width: '100%',
  },
  "& .MuiOutlinedInput-input" : {
    padding: 0,
  },
  "& .inputFieldsAutoComplete .MuiFormControl-root" : {
    borderRadius: 12,
    border : "none",
  },
  "& .inputFieldsAutoComplete .MuiOutlinedInput-notchedOutline" : {
    borderRadius: 12,
    padding: '8px 0px',
  },
  "& .inputFieldsSelectOpt .MuiOutlinedInput-notchedOutline" : {
    borderColor: "white",
  },

  "& .inputFieldsSelectOpt .MuiAutocomplete-hasPopupIcon.MuiAutocomplete-hasClearIcon .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root']" : {
     paddingRight: 0
  },
  "& .MuiAutocomplete-inputRoot[class*='MuiOutlinedInput-root'] .MuiAutocomplete-input:first-child" : {
    padding: '7px 0px'
  }
})

// Customizable Area End
