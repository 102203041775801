import {CircularProgress,
    CircularProgressProps, Typography,Box, styled, LinearProgress, Grid} from "@material-ui/core"
    import { rateIcon } from "../../blocks/catalogue/src/assets";
    import React from 'react';

    interface CircularProgressReviewProps extends CircularProgressProps {
        rating1: number;
        rating2: number;
        rating3: number;
        rating4: number;
        rating5: number;

      }
      interface TabPanelProps {
        children?: React.ReactNode;
        index: number;
        value: number;
      }
export default function CircularProgressWithLabel(props:CircularProgressProps) {
    return (
      <CustomCircularBox position="relative" display="inline-flex">
        <CircularProgress variant="determinate" size={190} thickness={8} value={100} className="circularProgressParentStyle"/>
                      <CircularProgress
                        variant="determinate"
                        className="circularProgressStyle"
                        value={props.value}
                        size={190} thickness={8}
                      />
                      <Typography className="showRate">$46.2K</Typography>
                      <Box className="showCount">
                        <Typography className="count">+4</Typography>
                        <img src={rateIcon} width={15} height={15}/>
                      </Box>
      </CustomCircularBox>
    );
  }
  export function CircularProgressReview(props:CircularProgressReviewProps) {
    const { value, rating1, rating2, rating3, rating4, rating5 } = props;
    return (
        <CustomBox>
      <CustomCircularBox position="relative" display="inline-flex">
        <CircularProgress variant="determinate" size={190} thickness={8} value={100} className="circularProgressParentReview"/>
                      <CircularProgress
                        variant="determinate"
                        className="circularProgressReview"
                        value={props.value ? props.value * 20 : 0}
                        size={190} thickness={8}
                      />
                      <Box className="showRatingText">
                      <Typography className="showRateReview">$46.2K</Typography>
                      </Box>
      </CustomCircularBox>
      <Box className="ratingBox">
    <Box className="showRatingBar">
      <span className="ratingNumber">5</span>
      <LinearProgress variant="determinate" value={rating5} className="linearBar"/>
      </Box>
      
      <Box className="showRatingBar">
      <span className="ratingNumber">4</span>
      <LinearProgress variant="determinate" value={rating4} className="linearBar"/>
      </Box>

      <Box className="showRatingBar">
      <span className="ratingNumber">3</span>
      <LinearProgress variant="determinate" value={rating3} className="linearBar"/>
      </Box>

      <Box className="showRatingBar">
      <span className="ratingNumber">2</span>
      <LinearProgress variant="determinate" value={rating2} className="linearBar"/>
      </Box>

      <Box className="showRatingBar">
      <span className="ratingNumber">1</span>
      <LinearProgress variant="determinate" value={rating1} className="linearBar"/>
      </Box>
      </Box>

      </CustomBox>
    );
  }

  const CustomCircularBox = styled(Box)({
    "& .circularProgressStyle":{
      color: "#FFFFFF",
        position: "absolute",
        left: 0,
        padding:'20px'
    },
    "& .circularProgressParentStyle":{
      color:"#836DD2",
      padding:"20px"
    },
    "& .showRate":{
      position: "absolute",
      top: "42%",
      left: "25%",
      color: "#FFFFFF",
      fontWeight: 600,
      fontFamily: 'Plus Jakarta Sans',
      fontSize: '32px',
      lineHeight: '33px',
    },
    "& .showCount":{
      position:"absolute",
       right:"-2%",
       bottom:"10%",
       display:"flex", 
       gap: "7px",
       alignItems:'center'
    },
    "& .count":{
      color: "#08C299",
      fontWeight: 600,
      fontFamily: 'Plus Jakarta Sans',
      fontSize: '22px',
      lineHeight: '30px',
    },
    "& .circularProgressReview":{
      color: "#3173E1",
        position: "absolute",
        left: 0,
        padding:'20px'
    },
    "& .circularProgressParentReview":{
      color:"#D0D5DD",
      padding:"20px"
    },
    "& .showRateReview":{
      color: "#3173E1",
      fontWeight: 600,
      fontFamily: 'Plus Jakarta Sans',
      fontSize: '25px',
      lineHeight: '38px',
    },
    "& .showRatingText":{
        width:'100%',
        display:"flex",
        alignItems:"center",
        justifyContent:"center",
        position: "absolute",
        top: "41%",
    }
    })

    export const TabPanel = (props: TabPanelProps) => {
        const { children, value, index, ...other } = props;
      
        return (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`wrapped-tabpanel-${index}`}
                aria-labelledby={`wrapped-tab-${index}`}
                {...other}
            >
                {value === index && (
                    <Grid >
                        <Typography>{children}</Typography>
                    </Grid>
                )}
            </div>
        );
      }

    const CustomBox = styled(Box)({
        display: "flex",
        gap: "34px",
        alignItems: "center",
        "@media(max-width:600px)":{
        flexDirection:"column"
        },
        "& .MuiLinearProgress-root":{
            height:"18px",
            borderRadius:"30px"
        },
        "& .MuiLinearProgress-colorPrimary":{
            backgroundColor:"#D0D5DD"
        },
        "& .MuiLinearProgress-barColorPrimary":{
            borderRadius:"30px",
             backgroundColor:"#3173E1"
        },
        "& .ratingBox": {
            display:"flex",
            flexDirection: "column",
            gap: "18px",
            width: "50%"
        },
        "& .showRatingBar": {
            display: "flex",
            alignItems: "center",
            gap: "15px"
        },
        "& .linearBar": {
            width: "100%"
        },
        "& .ratingNumber": {
            fontFamily: "Plus Jakarta Sans",
            fontSize: "21px",
            fontWeight: 500,
            lineHeight: "29.96px",
            color: "#3173E1"
        }
    })