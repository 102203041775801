// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";

import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { apiCalling, truthyValue } from '../../../components/src/asset'

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface ProtectionPlanData {
    planId : string,
    planTitle : string,
    hostTakeTitle : string,
    damagesTitle : string,
    coverageTitle : string,
    deductTitle : string,
}

interface S {
  // Customizable Area Start  
  loading : boolean
  protectionPlanData : ProtectionPlanData[],
  selectedPlanId: string
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class ProtectionPlanController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getProtectionPlanApiCallId : string = ""
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];
    this.state = {
        loading : true,
        protectionPlanData: [],
        selectedPlanId : "",
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if(apiRequestCallId === this.getProtectionPlanApiCallId) {
         if(responseJson && responseJson.length > 0) {
             const planId = responseJson[0].id
             const planData = responseJson.map((arrayVal:any) => {
                 return {
                     planId: truthyValue(arrayVal.id),
                     planTitle: truthyValue(arrayVal.name),
                     hostTakeTitle: truthyValue(arrayVal.host_take),
                     damagesTitle: truthyValue(arrayVal.damages),
                     coverageTitle: truthyValue(arrayVal.coverage),
                     deductTitle: truthyValue(arrayVal.deductible),
                 }
             })
             this.setState({ loading : false, protectionPlanData : planData, selectedPlanId: planId})
         } else {
            this.setState({ loading : false, protectionPlanData: [], selectedPlanId: ""})
         }
      }
      
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount(){
      this.getProtectionPlan()
  }

  navToBackPage = () => {
    this.props.navigation.goBack()
  }

  getProtectionPlan = () => {
    const header = {
        "token": localStorage.getItem("userToken")
      };
      
      this.getProtectionPlanApiCallId = apiCalling({
        header: JSON.stringify(header),
        endPoint: configJSON.getProtectionPlansApiEndpoint,
        method: configJSON.apiMethodTypeGet
      })
  }

  changeActiveBox = (planId : string) => {
    this.setState({ selectedPlanId : planId})
  }



  // Customizable Area End
}

// Customizable Area End