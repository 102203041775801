export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const slider1 = require("../assets/slider-1.jpg");
export const slider2 = require("../assets/slider-2.jpg");
export const slider3 = require("../assets/slider-3.jpg");
export const dummyProduct = require("../assets/dummy-image.jpg");
export const ProductImage = require("../assets/Product.png");
export const PhotoOne = require("../assets/imageOne.png");
export const PhotoTwo = require("../assets/ImageTwo.png");
export const PhotoThree = require("../assets/ImageThree.png");
export const PhotoFour = require("../assets/ImageFour.png");
export const Star = require("../assets/ReviewsIcon.png");
export const StarYellow = require("../assets/star-yellow.svg");
export const Broom = require("../assets/broom.png");
export const Home = require("../assets/home.png");
export const Location = require("../assets/location.png");
export const Lamp = require("../assets/lamp.png");
export const Group = require("../assets/group.png");
export const Pet = require("../assets/pet.png");
export const DropDown = require("../assets/DropDown.png");
export const Drop= require("../assets/Drop.png");
export const ProfileIcon= require("../assets/ProfileIcon.png");
export const ShowMoreIcon= require("../assets/ShowMoreIcon.png");
export const Review= require("../assets/review.png");
export const ShowMore= require("../assets/showMore.png");
export const Place= require("../assets/Place.png");
export const Place1= require("../assets/Place1.png");
export const Place2= require("../assets/Place2.png");
export const star= require("../assets/Star.png");
export const Verified= require("../assets/Verified.png");
export const Verified1= require("../assets/Verified1.png");
export const Share= require("../assets/share.svg");
export const Heart= require("../assets/heart.svg");
export const home= require("../assets/home.svg");
export const Miles= require("../assets/Miles.svg");
export const GasStation= require("../assets/GasStation.svg");
export const Cancel= require("../assets/Cancel.svg");
export const Warning= require("../assets/Warning.svg");
export const Bell= require("../assets/Bell.svg");
export const Insurance= require("../assets/Insurance.svg");
export const flash= require("../assets/flash.svg");
export const speed= require("../assets/speed.svg");
export const StarIcon= require("../assets/Star_icon.svg");
export const CircleCross= require("../assets/circlecross.svg");
export const CircleSpeed= require("../assets/speedcircle.svg");
export const Cripto= require("../assets/cripto.svg");
export const AppleCircle= require("../assets/circleapple.svg");
export const MasterCard= require("../assets/mastercard.svg");
export const Visa= require("../assets/visa.svg");
export const Paypal= require("../assets/paypal.svg");
export const SelectGroup= require("../assets/selectgroup.svg");
export const SelectedGroup= require("../assets/selectedgroup.svg");
export const Plus= require("../assets/plus.svg");
export const PinMap= require("../assets/pinmap.svg");
export const SpeedLightBlue= require("../assets/speedlight.svg");
export const Like= require("../assets/like.svg");
export const Email= require("../assets/email.svg");
export const Google= require("../assets/google.svg");
export const MailIcon= require("../assets/mailIcon.svg");









