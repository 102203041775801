// Customizable Area Start
import { IBlock } from "framework/src/IBlock";
import { Message } from "framework/src/Message";
import { BlockComponent } from "framework/src/BlockComponent";
import { runEngine } from "framework/src/RunEngine";
import MessageEnum, {
  getName
} from "framework/src/Messages/MessageEnum";
import { apiCalling } from "../../../components/src/asset";

export const configJSON = require("./config");
export interface Address {
  id: number;
  country: string;
  latitude: number;
  longitude: number;
  address: string;
  addressble_id: number;
  addressble_type: string;
  address_type: string;
  created_at: string;
  updated_at: string;
  pincode: number;
  city: string;
  price: number | null;
}
interface CatalogueAttributes {
  id: number;
  name: string;
  account: string;
  category_id: number;
  sub_category_id: number;
  brand_id: number | null;
  miles: string;
  addtional_miles_charge: number;
  car_number_plate: string | null;
  car_year: string | null;
  miles_per_day: number;
  unlimited_miles: number;
  car_vin: string;
  wishlist_liked: boolean;
  category: string;
  sub_category: string;
  luxgo_service: number;
  insurance_fee: number;
  rate: number;
  address: Address;
  delivery_address: Address[];
  rating: number;
}

interface Catalogue {
  data: {
    id: string;
    type: string;
    attributes: CatalogueAttributes;
  }
}

interface State {
  [key: string]: { [index: number]: string };
}

interface CatalogueData {
  catalogue: Catalogue;
}
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start  
  openEdit: boolean;
  addLocationBox: boolean;
  location: string;
  loacationDistanceData: CatalogueData;
  milesEditOpen: string;
  mileChargeEdit: string;
  unmilitedEditValue: string;
  cityName:string[];
  countryName:string[];
  pinCodename:string[];
  additionMiles:boolean;
  milesdatEdit:boolean;
  unlimitedMileageEdit: boolean;
  catalogueId: string
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class LocationDistancesController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getLocationDataCallID: string = "";
  putLocationDataCallID: string = "";
  putPickupLocationDataCallId: string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];
    this.state = {
      openEdit: false,
      addLocationBox: false,
      location: "",
      milesEditOpen: "",
      mileChargeEdit: '',
      unmilitedEditValue: '',
      additionMiles:false,
      milesdatEdit:false,
      unlimitedMileageEdit: false,
      loacationDistanceData: {
        catalogue: {
          data: {
            id: "",
            type: "",
            attributes: {
              id: 0,
              name: "",
              account: "",
              category_id: 0,
              sub_category_id: 0,
              brand_id: null,
              miles: "",
              addtional_miles_charge: 0,
              car_number_plate: null,
              car_year: null,
              miles_per_day: 0,
              unlimited_miles: 0,
              car_vin: "",
              wishlist_liked: false,
              category: "",
              sub_category: "",
              luxgo_service: 0,
              insurance_fee: 0,
              rate: 0,
              address: {
                id: 0,
                country: "",
                latitude: 0,
                longitude: 0,
                address: "",
                addressble_id: 0,
                addressble_type: "",
                address_type: "",
                created_at: "",
                updated_at: "",
                pincode: 0,
                city: "",
                price: null
              },
              delivery_address: [],
              rating: 0
            }
          }
        }
      },
      cityName:[],
      countryName:[],
      pinCodename:[],
      catalogueId: ""
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiRequestCallId === this.getLocationDataCallID) {
        this.setLocationData(responseJson)
      }
      if (apiRequestCallId === this.putLocationDataCallID) {
        this.getLocationDistanceData()
      }
      if (apiRequestCallId === this.putPickupLocationDataCallId) {
        this.setState({countryName:[], pinCodename:[], cityName:[]}, () => this.getLocationDistanceData())
      }
    }

    // Customizable Area End
  }

  // Customizable Area Start
  async componentDidMount() {
    const catalougeId = this.props.navigation.getParam("subId")
    this.setState({ catalogueId: catalougeId})
    this.getLocationDistanceData()
  }
  setLocationData = (response: CatalogueData) => {
    this.setState({ loacationDistanceData: response })
  }
  handleOpenEdit = () => {
    this.setState({ openEdit: true })
  }
  handleLocationField = (event:React.ChangeEvent<HTMLInputElement>) => {
    this.setState({location:event.target.value, addLocationBox:true})
    }
    handleCloseLoaction = () => {
      this.setState({addLocationBox:false})
    }
  getLocationDistanceData = () => {
    const header = {
      token: localStorage.getItem("userToken"),
      "Content-Type": "application/json",
    };
    this.getLocationDataCallID = apiCalling({
      header: JSON.stringify(header),
      method: configJSON.apiMethodTypeGet,
      endPoint: configJSON.getLocationData(this.state.catalogueId),
    });
  }
  handleInputChange = (field: string) => (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const value = event.target.value;
    if (/^\d*$/.test(value)) {
      this.setState({ [field]: value } as unknown as Pick<S, keyof S>);
    }
  }
  handleEditLocationData = () => {
  this.setState({additionMiles: false, milesdatEdit:false, unlimitedMileageEdit:false})
  const { milesEditOpen, mileChargeEdit, unmilitedEditValue } = this.state
    let catalogueId = 731;
    const formdata = new FormData();
     milesEditOpen && formdata.append("catalogue[miles_per_day]", milesEditOpen) ;
    { mileChargeEdit && formdata.append("catalogue[addtional_miles_charge]", mileChargeEdit) };
    { unmilitedEditValue && formdata.append("catalogue[unlimited_miles]", unmilitedEditValue) };

    const header = {
      token: localStorage.getItem("userToken")
    };
    this.putLocationDataCallID = apiCalling({
      header: JSON.stringify(header),
      method: configJSON.apiMethodTypePut,
      endPoint: configJSON.getLocationData(this.state.catalogueId),
      body: formdata,
    });
  }
  handleEditLocations = (locationData:Address ,index: number) => {
    const formData = new FormData();
    const { cityName, countryName, pinCodename } = this.state;

    formData.append("id", String(locationData.id));
    formData.append("address[address]", "Illienka st 29");

    if (countryName[index] !== undefined) formData.append("address[country]", countryName[index]);
    if (pinCodename[index] !== undefined) formData.append("address[pincode]", pinCodename[index]);
    if (cityName[index] !== undefined) formData.append("address[city]", cityName[index]);

    formData.append("address[price]", String(locationData.price));

    const header = {
        token: localStorage.getItem("userToken"),
    };

    this.putPickupLocationDataCallId = apiCalling({
        header: JSON.stringify(header),
        method: configJSON.apiMethodTypePut,
        endPoint: "bx_block_address/update_catalogue_address",
        body: formData,
    });
};

handleDeliveryChange = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
    const { name, value } = event.target;
    this.setState((prevState) => {
        if (name === 'cityName' || name === 'countryName' || name === 'pinCodename') {
            const updatedArray = [...prevState[name]];
            updatedArray[index] = value;
            return {
                ...prevState,
                [name]: updatedArray,
            };
        }
        return prevState;
    });
};
handleAdditionMiles = () => {
  this.setState({additionMiles: true})
}
handleCloseEditBtn = () => {
  this.setState({additionMiles: false, mileChargeEdit:"", milesdatEdit:false, milesEditOpen:"", unlimitedMileageEdit: false, unmilitedEditValue: ""})
}
handleEditDayMiles = () => {
  this.setState({milesdatEdit: true})
}
handleunlimitedMileageEdit = () => {
  this.setState({unlimitedMileageEdit: true})
}
handleBackButton = () => {
  this.props.navigation.goBack()
}
  // Customizable Area End
}

// Customizable Area End