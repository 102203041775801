// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";

import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { payoutModuleIcon, claimsModuleIcon, traHistoryModuleIcon, taxInfoModuleIcon, upIcon, downIcon } from "./assets";
import { apiCalling, truthyValue } from "../../../components/src/asset";
import moment from "moment"
import { toast } from "react-toastify";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface ClaimsData {
    id: number
    claimTitle: string,
    claimSubTitle: string,
    claimStatus: string,
    claimDate: string
}

interface Transaction {
  id: number;
  payOutIcon: string; 
  payOutText: string;
  payOutPrice: string;
  payOutDate: string;
}

interface PayOut {
  id: string;
  payOutMonth: string;
  transactions: Transaction[];
}

type GraphData = [string, string | number];

interface S {
  // Customizable Area Start  
  moduleName : string
  claimTab : number
  claimData : ClaimsData[]
  filteredClaimData : ClaimsData[],
  loader : boolean
  graphData : GraphData[],
  payoutType : string
  payOutData : PayOut[],
  selectedYear : number,
  grossEarning : number,
  roamFees : number,
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class HostSettingsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getClaimsApiCallId : string = ""
  getPayoutScheduleApiCallId : string = ""
  updatePayoutTypeApiCallId : string = ""
  searchClaimsApiCallId: string = ""
  getTaxInfoApiCallId: string = ""
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];
    this.state = {
        moduleName: "settingsModule",
        claimTab: 0,
        claimData: [],
        filteredClaimData: [],
        loader: false,
        graphData: this.graphMockData,
        payoutType: "automatic",
        payOutData : [],
        selectedYear: new Date().getFullYear(),
        grossEarning: 0,
        roamFees: 0
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
        const apiRequestCallId = message.getData(
            getName(MessageEnum.RestAPIResponceDataMessage)
        );

        const responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );

        switch(apiRequestCallId) {
           case this.getClaimsApiCallId:
             return this.handleClaimsApiResponse(responseJson)
          case this.searchClaimsApiCallId:
              return this.handleClaimsApiResponse(responseJson)
          case this.getPayoutScheduleApiCallId:
            return this.handlePayOutScheduleResponse(responseJson)
          case this.updatePayoutTypeApiCallId:
            return this.handleUpdatePayOutResponse(responseJson)
          case this.getTaxInfoApiCallId:
            return this.handleTaxInfoResponse(responseJson)
        }

    }
    // Customizable Area End
  }

  // Customizable Area Start

  async componentDidMount(): Promise<void> {
    this.getClaimsData()  
    this.getPayoutScheduleData()
    this.getTaxInfoData()
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined): void {
    if(prevState.selectedYear !== this.state.selectedYear) {
       this.getTaxInfoData()
    }
  }

  graphMockData: GraphData[] = [
      ["Year", ""],
      ["JAN", 0],
      ["FEB", 0],
      ["MAR", 0],
      ["APR", 0],
      ["MAY", 0],
      ["JUN", 0],
      ["JUL", 0],
      ["AUG", 0],
      ["SEPT", 0],
      ["OCT", 0],
      ["NOV", 0],
      ["DEC", 0]
  ]

  settingModules = [
    {
        id: 1,
        icon : payoutModuleIcon,
        title: "Payout Schedule",
        moduleName: "payoutModule" 
    },
    {
        id: 2,
        icon : traHistoryModuleIcon,
        title: "Transaction History",
        moduleName: "transactionHistoryModule" 
    },
    {
        id: 3,
        icon : taxInfoModuleIcon,
        title: "Tax Information",
        moduleName: "taxInfoModule" 
    },
    {
        id: 4,
        icon : claimsModuleIcon,
        title: "Claims",
        moduleName: "claimsModule" 
    },
  ]
 
  navigateToBack = () => {
    this.props.navigation.goBack()
  }

  changeSettingsModule = (value : string) => {
    this.setState({ moduleName : value})
  }

  backToSettingsModule = () => {
    this.setState({ moduleName : "settingsModule"})
  }

  handleTabChanges = (event: React.ChangeEvent<{}>, value: number) => {
    this.setState({ claimTab: value})
  }

  handleSearch = (event : React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value

    const header = {
      token: localStorage.getItem("userToken")
    }

    this.searchClaimsApiCallId = apiCalling({
      header: JSON.stringify(header),
      method: configJSON.apiMethodTypeGet,
      endPoint: `${configJSON.searchClaimsApiEndPoint}?query=${inputValue}`
    })
    
  }

  getClaimsData = () => {
    const header = {
        token : localStorage.getItem("userToken")
    }

    this.getClaimsApiCallId = apiCalling({
        header : JSON.stringify(header),
        method: configJSON.apiMethodTypeGet,
        endPoint: configJSON.getAllClaimsApiEndPoint
    })
  }

  getPayoutScheduleData = () => {
    const header = {
        token : localStorage.getItem("userToken")
    }

    this.getPayoutScheduleApiCallId = apiCalling({
        header : JSON.stringify(header),
        method: configJSON.apiMethodTypeGet,
        endPoint: configJSON.getPayoutScheduleApiEndPoint
    })
  }

  getTaxInfoData = () => {
    const header = {
      token: localStorage.getItem("userToken")
    }

    this.getTaxInfoApiCallId = apiCalling({
      header: JSON.stringify(header),
      method: configJSON.apiMethodTypeGet,
      endPoint: configJSON.getTaxInfoApiEndPoint(this.state.selectedYear)
    })
  }

  updatePayoutTypeData = (payOutType : string) => {

    this.setState({ loader : true})
    const header = {
        token : localStorage.getItem("userToken")
    }

    const formData = new FormData();

    formData.append("payout_type", payOutType)

    this.updatePayoutTypeApiCallId = apiCalling({
        header : JSON.stringify(header),
        method: configJSON.apiMethodTypePut,
        endPoint: configJSON.updatePayOutTypeApiEndPoint,
        body: formData
    })
  }

  handleTaxInfoResponse  = (responseJson: any) => {
    if(responseJson && responseJson.gross_earnings && responseJson.roam_fees) {
        const earnings = truthyValue(responseJson.gross_earnings.total)
        const fees = truthyValue(responseJson.roam_fees)
        const breakdown = truthyValue(responseJson.gross_earnings.breakdown)

        const updatedGraphData: GraphData[] = this.state.graphData.map(([month, value]) => {
          if (month !== "Year") {
            const formattedMonth = this.formatMonth(month);
            if (formattedMonth in breakdown) {
              return [month, breakdown[formattedMonth]];
            }
          }
          return [month, value];
        });

        this.setState({ grossEarning: earnings, roamFees: fees, loader: false, graphData: updatedGraphData})
    } else {
        this.setState({ loader: false, grossEarning: 0, roamFees: 0, graphData: this.graphMockData})
    }
 }

  handleClaimsApiResponse = (responseJson: any) => {
     if(responseJson && responseJson.claims && responseJson.claims.data && responseJson.claims.data.length > 0 ) {

       const claimArray = responseJson.claims.data.map((claimVal: any) => {
        return {
          id: truthyValue(claimVal.attributes.id),
          claimTitle: truthyValue(claimVal.attributes.title),
          claimSubTitle: truthyValue(claimVal.attributes.body),
          claimStatus: truthyValue(claimVal.attributes.status),
          claimDate: moment(claimVal.attributes.created_at).format('YYYY-MM-DD')
        }
       })
        this.setState({ filteredClaimData : claimArray, claimData: claimArray})
     } else {
       this.setState({ filteredClaimData : [], claimData: []})
     }
  }

  handlePayOutScheduleResponse = (responseJson: any) => {
    if(responseJson && responseJson.data && responseJson.data.length > 0) {
      const payOutsArr = [
        {
          id: "1",
          payOutMonth: moment(responseJson.data[0].attributes.created_at).format("MMMM YYYY"),
          transactions: responseJson.data.map((arrVal: any) => {
            return {
              id: arrVal.id,
              payOutText: this.handleAutoText(truthyValue(arrVal.attributes.payout_type)),
              payOutPrice: truthyValue(arrVal.attributes.amount),
              payOutDate: moment(arrVal.attributes.created_at).format('MMM DD, YYYY'),
              payOutIcon: this.handleIcon(truthyValue(arrVal.attributes.payout_type))
            }
          })
        }
      ]

      const payOutType = truthyValue(responseJson.data[0].attributes.account.payout_type)

      this.setState({ payOutData: payOutsArr, payoutType: payOutType, loader: false })
    } else {
      this.setState({ payOutData: [], loader: false })
    }
  }

  handleUpdatePayOutResponse  = (responseJson: any) => {
    if(responseJson && responseJson.message ) {
      this.getPayoutScheduleData()
      toast.success(responseJson.message)
      const updatePayout = this.state.payoutType === "automatic" ? "manual" : "automatic"
      this.setState({ payoutType: updatePayout})
    } else {
      toast.error("Something went wrong!. Please Try again later")
      this.setState({ loader : false})
    }
 }

  lastFiveYearsArray = () => {
    const currentYear = new Date().getFullYear();
    const yaearArray = [];

    for (let i = 0; i < 5; i++) {
        yaearArray.push(currentYear - i);
    }

    return yaearArray;
  }

  transactionHistoryData = [
    {
      id: "1",
      monthTittle: "February 2023",
      data: [
        {
          id: 1,
          icon: upIcon,
          title: "Adjustment",
          price: "$00.00",
          date: "Feb 08, 2023"
        },
        {
          id: 2,
          icon: upIcon,
          title: "Adjustment",
          date: "Feb 12, 2023",
          price: "$00.00",
        },
        {
          id: 3,
          icon: downIcon,
          title: "Payment",
          date: "Feb 25, 2023",
          price: "$00.00",
        },
      ]

    },
    {
      id: "1",
      monthTittle: "January 2023",
      data: [
        {
          id: 1,
          icon: upIcon,
          title: "Adjustment",
          price: "$00.00",
          date: "Jan 06, 2023"
        },
        {
          id: 2,
          icon: upIcon,
          title: "Adjustment",
          date: "Jan 19, 2023",
          price: "$00.00",
        },
        {
          id: 3,
          icon: downIcon,
          title: "Payment",
          date: "Jan 30, 2023",
          price: "$00.00",
        },
      ]

    },
  ]

  payoutSelection = [
    {
      id : 1,
      payOutMode : "automatic",
      payOutTitle : "Automatic Payout",
      payOutSubTitle : "Receive 1-3 days after rentals completed"
    },
    {
      id : 2,
      payOutMode : "manual",
      payOutTitle : "Manual Payout",
      payOutSubTitle : "Balance stores until payout requested"
    },
  ]

  handlePayouSelectionCondition = (keyValue : string) => {
    return this.state.payoutType === keyValue ? true : false
  }

  changePayoutMode = (event: React.ChangeEvent<HTMLInputElement>) => {
    const payOutType =  event.target.value
    this.updatePayoutTypeData(payOutType)
  }

  payOutsData = [
    {
      id: "1",
      payOutMonth: "February 2023",
      transactions: [
        {
          id: 1,
          payOutIcon: upIcon,
          payOutText: "Automatic payout",
          payOutPrice: "$00.00",
          payOutDate: "Feb 08, 2023"
        },
        {
          id: 2,
          payOutIcon: upIcon,
          payOutText: "Payment",
          payOutPrice: "$00.00",
          payOutDate: "Feb 12, 2023"
        },
        {
          id: 3,
          payOutIcon: downIcon,
          payOutText: "Payment",
          payOutPrice: "$00.00",
          payOutDate: "Feb 25, 2023"
        },
        {
          id: 4,
          payOutIcon: downIcon,
          payOutText: "Payment",
          payOutPrice: "$00.00",
          payOutDate: "Feb 25, 2023"
        }
      ]

    },
  ]

  handleIcon = (value : string) => {
    return value === "automatic" ? upIcon : downIcon
  }

  handleAutoText = (value : string) => {
    return value === "automatic" ? "Automatic payout" : "Manual payout"
  }

  handleYearChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const inputValue = event.target.value as number
    this.setState({
      selectedYear: inputValue,
      loader: true
    })
}
  formatMonth = (month: string): string => {
    const monthMap: { [key: string]: string } = {
      "JAN": "Jan",
      "FEB": "Feb",
      "MAR": "Mar",
      "APR": "Apr",
      "MAY": "May",
      "JUN": "Jun",
      "JUL": "Jul",
      "AUG": "Aug",
      "SEPT": "Sep",
      "OCT": "Oct",
      "NOV": "Nov",
      "DEC": "Dec"
    };
    return monthMap[month] || month;
  };

  // Customizable Area End
}

// Customizable Area End